import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const styles = theme => ({
  popper: { opacity: 1 },
  tooltip: { margin: 'auto', opacity: 1, fontSize: theme.spacing(1) + 2 }
})
//<Tooltip key={name} title={item.title} placement="right" classes={{popper: 'menu-popper', tooltip: 'menu-popper-tooltip'}}>
class CustomTooltip extends Component {
  render () {
    const { classes } = this.props
    return <Tooltip {...this.props} classes={classes} />
  }
}

export default withStyles(styles)(CustomTooltip)
