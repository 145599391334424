import React from "react";
import _ from "lodash";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'highcharts'
import ConfigIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip'
import { Responsive, WidthProvider } from "react-grid-layout";
import DragIndicatorIcon from '@material-ui/icons/FlipToFront';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TextField from "../../Components/common/TextField";
import './style.scss'
import Layout from "../../Layout";
import { checkPrivileges, widgetCategories } from "../../Helpers";
import Button from "../../Components/common/Button";
import { Collapse } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import { Component as Component1 } from './../PremiumDashboard/Components/Component1'
import { Component as Component2 } from './../PremiumDashboard/Components/Component2'
import { Component as Component3 } from './../PremiumDashboard/Components/Component3'
import { Component as Component4 } from './../PremiumDashboard/Components/Component4'
import { Component as Component5 } from './../PremiumDashboard/Components/Component5'
import { Component as Component6 } from './../PremiumDashboard/Components/Component6'
import { Component as Component7 } from './../PremiumDashboard/Components/Component7'
import Notifications from "react-notification-system-redux";
import { fetchDashboards } from "../../Actions/Devices";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardBuilder extends React.Component {
  static defaultProps = {
    className: "layout",
    rowHeight: 60,
    onLayoutChange: function() {},
    onDropDragOver: function() {},
    cols: { lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }
  };

  state = {
    currentBreakpoint: "lg",
    compactType: "vertical",
    mounted: false,
    layouts: { lg: generateLayout()},
    widgetArray: {},
    item: {i: "0", w: 3, h: 3},
    counter: 0,
    dragging: false,
    title: "Dashboard Title",
    selectedItem: null,
    show: false,
    selectedIndex: null,
    collapseKey: 1
  };

  fetchDashboard = id => {
    if(id) {
      this.setState({loading: true}, () => {
        axios.get(`/api/dashboards/${id}`).then(({data: {data : { dashboard, dashboardGadgets } }}) => {
          if(dashboardGadgets && dashboardGadgets.length) {
            const counter = Math.max(...dashboardGadgets?.map(a => Number(a.attributes.i)) || 0) + 1;
            this.setState({loading: false, title: dashboard?.name, widgetArray: dashboardGadgets && dashboardGadgets.length ? Object.assign({}, dashboardGadgets) : {}, counter}, () => {
            })
          }
          else {
            this.setState({loading: false});
          }
        }).catch(err => {
          this.setState({loading: false});
        })
      })
    }
  }

  getSnapshotBeforeUpdate(props) {
    if(props.match.params.id !== this.props.match.params.id && this.props.match.params.id) {
      this.fetchDashboard(this.props.currentId)
    } 
    return null
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.fetchDashboard(this.props.currentId)
    this.setState({ mounted: true });
  }

  setItem = (w, h, mw, mh) => {
    console.log(Number(this.state.counter));
    this.setState({dragging: true, item: {i: this.state.counter+"", w, h, minW: w, minH: h, maxH: mh || 12, maxW: mw || 12}, counter: Number(this.state.counter) + 1 });
  }
  clearItem = () => {
    this.setState({dragging: false})
  }
  onRemoveItem(i) {
      const tempArray = {};
      for(const key in this.state.widgetArray) {
        if(this.state.widgetArray[key].attributes.i !== i) {
          tempArray[key] = this.state.widgetArray[key];
        }
      };
      this.setState({widgetArray: {}}, () => {this.setState({widgetArray: tempArray})});
  }

  drawDOM = (gadget) => {
    switch(gadget.gadgetId) {
      case 1: 
        return <Component1 gadget={gadget} />
      case 2: 
        return <Component2 gadget={gadget} />
      case 3:
        return <Component3 handleItem={null} gadget={gadget} />
      case 4:
        return <Component4 handleItem={null} gadget={gadget} />
      case 5: 
        return <Component5 handleItem={null} gadget={gadget} />
      case 6: 
        return <Component6 handleItem={null} gadget={gadget} />
      case 7:
        return <Component7 handleItem={null} gadget={gadget} />
      default: 
        return null;
    }
  }

  generateDOM = () => {
    const items = [];
    for(const key in this.state.widgetArray) {
      const obj = this.state.widgetArray[key];
      const { attributes } = obj; 
      if(attributes && attributes.w !== undefined) {
        items.push(<div className={` column-${attributes?.w} row-${attributes?.h} widget-${obj.gadgetId}`} key={key} 
        data-grid={{
          x: attributes?.x,
          y: attributes?.y,
          w: attributes?.w,
          h: attributes?.h,
          i: attributes?.key,
          isDraggable: checkPrivileges('gadgetUpdate') ,
          isResizable: checkPrivileges('gadgetUpdate') ,
        }}
        >
          {checkPrivileges('gadgetUpdate')||checkPrivileges('gadgetDelete') ? (<div className="widget-actions">
            {checkPrivileges('gadgetUpdate')&&(<Tooltip title={<Translate id="edit"/>}><a href={null} onClick={e => this.setSelectedItem(obj, key)}><ConfigIcon /></a></Tooltip>)}
            {checkPrivileges('gadgetDelete')&&(<Tooltip title={<Translate id="delete"/>}><a href={null} onClick={e => this.onRemoveItem(attributes.i)}><CloseIcon /></a></Tooltip>)}
          </div>) : null}
          {this.drawDOM(obj)}
      </div>);
      }
    }
    return items;
  }

  onBreakpointChange = breakpoint => {
    this.setState({
      currentBreakpoint: breakpoint
    });
  };

  onLayoutChange = (layout, layouts) => {

    const tempArray = this.state.widgetArray;
      this.props.onLayoutChange(layout, layouts);
      layout.map((position) => {
        if(tempArray && tempArray[Number(position.i)]) {
            tempArray[Number(position.i)].attributes.x = position.x;
            tempArray[Number(position.i)].attributes.y = position.y;
            tempArray[Number(position.i)].attributes.w = position.w;
            tempArray[Number(position.i)].attributes.h = position.h;
        }
      });
      this.setState({widgetArray: tempArray});
  };

  
  onDrop = (layout, layoutItem, _event) => {
    const tempArray = JSON.parse(JSON.stringify(this.state.widgetArray));
    const { widgets } = this.props;
    const widget = widgets.find(e => parseInt(e.gadgetId) === parseInt(_event.dataTransfer.getData("text")));
    if(widget) {
      const { description, ...rest } = widget;
      tempArray[layoutItem.i] = Object.assign({}, { ...rest, id: 0, attributes: {...layoutItem}});
      layout.map(position => {
        tempArray[position.i].attributes.x = position.x;
        tempArray[position.i].attributes.y = position.y;
        tempArray[position.i].attributes.w = position.w;
        tempArray[position.i].attributes.h = position.h;
        return null;
      })
      this.setState({widgetArray: {}}, () => {
        this.setState({widgetArray: Object.assign({}, tempArray)});
      });
    }
  };


  onSave = () => {
    const items = [];
    Object.values(this.state.widgetArray).map(({ attributes, category, description, gadgetId, name, type }) => {
      items.push({attributes, category, description, gadgetId, name, type})
    });
    const body = {
      dashboard: {
        id: this.props.currentId || 0,
        name: this.state.title,
        description: '',
        attributes: {}
      },
      dashboardGadgets: items
    }
    console.log(body);
    if(items.length && this.state.title) {
      if(this.props.currentId) {
        axios.put(`/api/dashboards/${this.props.currentId}`, body).then(response => {
          if(response.data.status === "success"){
            this.props.history.push(`/premium_dashboard/${this.props.currentId}`);
            fetchDashboards(this.props.dispatch,this.props.logInUser)
            this.props.dispatch(
              Notifications.success({
                message: <Translate id="dashboardIsUpdated" />,
                autoDismiss: 10
              })
            )
          }else{
            this.props.dispatch(
              Notifications.error({
                message: <Translate id="noAccessOfDashboardUpdate" />,
                autoDismiss: 10
              })
            )
          }
        }).catch(e=>e)
      } else {
        axios.post('/api/dashboards', body).then(response => {
          if(response.status === 200){
            this.props.history.push(`/premium_dashboard/${response?.data?.data?.dashboard?.id}`);
            fetchDashboards(this.props.dispatch,this.props.logInUser)
            this.props.dispatch(
              Notifications.success({
                message: <Translate id="dashboardIsCreated" />,
                autoDismiss: 10
              })
            )
          }else{
            this.props.dispatch(
              Notifications.error({
                message: <Translate id="somthingWentWrongMessage" />,
                autoDismiss: 10
              })
            )
          }
        }).catch(e=>e)
      }
    }
  }

  groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  onChange = event => {
    this.setState({title: event.target.value})
  }

  setSelectedItem = (selectedItem, selectedIndex) => {
    this.setState({selectedItem, selectedIndex, show: true})
  }
  reset = () => {
    this.setState({
      selectedItem: null,
      selectedIndex: null,
      show: false
    })
  }

  
  onChangeSelected = (name, event) => {
    if(this.state.selectedIndex === 'title') {
      this.setState({selectedItem: event.target.value})
    }
    else {
      this.setState({selectedItem: {...this.state.selectedItem, [name]: event.target.value}})
    }
  }

  saveSelected = () => {
    if(this.state.selectedIndex === 'title') {
      this.setState({title: this.state.selectedItem}, () => {
        this.reset()
      })
    }
    else {
      const items = [];
      Object.values(this.state.widgetArray).map((item, index) => {
        if(Number(index) === Number(this.state.selectedIndex)) {
          items.push(this.state.selectedItem);
        }
        else {
          items.push(item)
        }
      });
      this.setState({
        widgetArray: Object.assign({}, items)
      }, () => {
        this.reset();
      })
    }
  }

  openCollapse = collapseKey => {
    this.setState({collapseKey: parseInt(collapseKey) === parseInt(this.state.collapseKey) ? 0 : collapseKey})
  }
  render() {
    const { themecolors } = this.props
    const View = this.state.show ? <div className="overlayform">
      <h4><span><Translate id="settingsTitle" /></span> <span><CloseIcon style={{cursor: 'pointer'}} onClick={this.reset} /></span></h4>
      <div className="form-body">
        <TextField fullWidth label={<Translate id="sharedName" />} value={(this.state.selectedIndex === 'title' ? this.state.selectedItem : this.state.selectedItem?.name) || ""} onChange={e => this.onChangeSelected('name', e)} />
        <Button className="submit-button" onClick={this.saveSelected}><Translate id="sharedSave" /></Button>
      </div>
    </div> : null;
    const { widgets } = this.props;
    const final = this.groupBy(Object.values(widgets), 'category');
    return (<Layout {...this.props} noSidebar classFromChildren="dashboard-page" afterView={View}>

      <div className="dashboard-layout-container">
        <div className="sidebar">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <strong>{this.state.title}</strong>
            <div>
            {checkPrivileges('dashboardUpdate')&&(<Tooltip title={<Translate id="updateTitle" />}><a href={null} onClick={e => this.setSelectedItem(this.state.title, 'title')}><ConfigIcon/></a></Tooltip>)}
            </div>
          </div>
          {(this.props.currentId ? checkPrivileges('dashboardUpdate') : checkPrivileges('dashboardCreate'))&&(<div>
            <Button fullWidth onClick={this.onSave}><SaveIcon style={{marginRight: 10}} /> <Translate id="saveDashboard"/> </Button>
          </div>)}
          <p><strong><Translate id="widget"/></strong></p>
          {Object.entries(final).map(([key, itms]) => <div key={key}>
            <h4 className={`category-item ${parseInt(this.state.collapseKey) === parseInt(key) ? 'opened' : ''}`} onClick={e => this.openCollapse(key)}><span>{widgetCategories[key]}</span> <KeyboardArrowUpIcon className="open-icon" /></h4>
            <Collapse in={parseInt(this.state.collapseKey) === parseInt(key)}>
            {itms.map((item, index) => <div
              key={index}
              className="droppable-element"
              draggable={true}
              width={item.attributes.w}
              unselectable="on"
              onDragStart={e => { this.setItem(item.attributes.w, item.attributes.h);e.dataTransfer.setData("text/plain", item.gadgetId)}}
              onDragEnd={this.clearItem}
            >
              <span>{item.name}</span> <DragIndicatorIcon />
            </div>)}
            </Collapse>
          </div>)
          }
        </div>
        
        <div className={'grid-container'} style={{color: themecolors.textColor}}>
          <ResponsiveReactGridLayout
            {...this.props}
            layouts={this.state.layouts}
            onBreakpointChange={this.onBreakpointChange}
            onLayoutChange={this.onLayoutChange}
            onDropDragOver={this.onDropDragOver}
            onDrop={this.onDrop}
            droppingItem={this.state.item}
            // WidthProvider option
            measureBeforeMount={false}
            // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
            // and set `measureBeforeMount={true}`.
            useCSSTransforms={this.state.mounted}
            compactType={this.state.compactType}
            preventCollision={!this.state.compactType}
            isDroppable={(this.props.currentId ? checkPrivileges('dashboardUpdate') : checkPrivileges('dashboardCreate'))}
            className={(this.state.dragging ? 'dragging' : '')}
          >
            {this.generateDOM()}
          </ResponsiveReactGridLayout>
          {!this.state.dragging && Object.keys(this.state.widgetArray).length === 0 ? <div className="dnd-placeholder">
            <DragIndicatorIcon className="related-icon" style={{ width: 100, height: 100 }}  />
            <div><KeyboardBackspaceIcon className="bounce-left" /> <Translate id="DropWidgetsHere"/></div>
          </div> : null}
          {(this.props.currentId ? checkPrivileges('dashboardUpdate') : checkPrivileges('dashboardCreate'))&&(<div className="form-title-section">
            <Button onClick={this.onSave}><SaveIcon style={{marginRight: 10}} /> <Translate id="saveDashboard"/></Button>
          </div>)}
        </div>
      </div>
      </Layout>
    );
  }
}


const mapStateToProps = (state, props) => {
  return {
    logInUser: state.logInUsers,
    currentId: props.match.params.id,
    themecolors: state.themeColors,
    widgets: state.widgets.data && state.widgets.data.map(e => ({...e, gadgetId: e.id})) || []
  }
}

export default connect(mapStateToProps)(DashboardBuilder)
function generateLayout() {
  return [];
}
