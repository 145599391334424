import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleGroupDevices } from './../../Actions/Devices'
import isEqual from 'react-fast-compare'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { checkPrivileges } from '../../Helpers'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import DeleteIcon from '@material-ui/icons/Delete';
import Collapse from '@material-ui/core/Collapse'
import './index.scss'
import instance from '../../axios'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ChevronRight"

library.add(faSatelliteDish)

class Devices extends Component {
  constructor (props) {
    super(props)

    this.devices = []
    this.state = {
      groupsCall: false,
      calendarsCall: false,
      driversCall: false
    }
  }

  componentWillReceiveProps (NextProps) {
    this.props.isChecked(this.devices)
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) // equals() is your implementation
  }

  render () {
     if (this.props.switchView === 'units') {
      if (this.props.devices.length) {
        const data = this.props.devices.map((device, index) => (
          <ListRow
            key={device.id + '__' + index}
            item={device}
            {...this.props}
            devicesIcons={this.props.devicesIcons}
          />
        ))
        return (
          <div className='individual-view list-row-condest'>
            <ul className='list-view'>{data}</ul>
          </div>
        )
      } else {
        return <div />
      }
    } else {
       if (this.props.itemPagination2.items&&this.props.itemPagination2.items.length) {
        this.props.itemPagination2.items.map((group, index) => {
          group.children = []

          this.props.itemPagination2.items.map(device => {
            if (device.groupId === group.id) {
              return group.children.push(device)
            }
            return ''
          })
          return ''
        })
      }

      return <DrawGroups groups={this.props.itemPagination2.data} {...this.props} />
    }
  }
}

export class DrawGroupRow extends Component {
  constructor (props) {
    super(props)

    this.toggleGroupDevices = this.toggleGroupDevices.bind(this)

    this.state = {
      isChecked: true,
      checked: false,
      childUnits:[]
    }
  }

  componentWillReceiveProps (NextProps) {
    this.setState({ isChecked: true })
    NextProps.group&&NextProps.group.children&&NextProps.group.children.map(item => {
      if (item.visible === false) {
        this.setState({ isChecked: false })
      }
      return ''
    })
  }

  toggleGroupDevices (event) {
    this.props.dispatch(
      toggleGroupDevices({
        checked: event.target.checked,
        id: this.props.group.id
      })
    )
    this.setState({
      isChecked: event.target.checked
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state) // equals() is your implementation
  }

  findResourceUnits = (e,id)=>{
     if(e){
      instance({
        url: `/api/devices`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          groupId: id,
         }
      })
      .then(res=>{ 
         this.setState({
          childUnits:res,
          checked:true
        })
      })
      .catch(err=>{console.log('err ===', err)})
    }
    else{
      this.setState({checked:false})
    }
  }

  render () {
    const emptyLinks = null

    if (this.props.group) {
      return (
        <li  key={this.props.group.id}>
          <div className='clearfix group-list-row list-row'  >
           
          <label
          onClick={e => this.findResourceUnits(!this.state.checked, this.props.group.id)}
          //  onClick={e => this.props.selectGroup(this.props.group.id)}
            className='checkbox'>

              <span
              className='unit-name'
              style={{ minWidth: 200, maxWidth: 200, marginLeft: 0 }}
              >
                <a
                  href={emptyLinks}
                  title='Additional Menu'
                >
                  
                  {this.state.checked ? (
                      <ExpandMoreIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} />
                  ) : (
                    <ExpandLessIcon style={{width: 20, height: 20, verticalAlign: 'middle', marginRight: 5}} />
                  )}
                </a>
                {this.props.group.name}
              </span>
            {/* <label className=''> */}
              {/* <span className='unit-name'>{this.props.group.name}</span> */}
            </label>
             
          </div>
          <Collapse in={this.state.checked} timeout='auto' unmountOnExit>
            <ListRows children={this.state.childUnits} {...this.props} />
          </Collapse>
           {/* <ListRows children={this.props.group} {...this.props}  /> */}
        </li>
      )
    } else {
      return null
    }
  }
}

export class DrawGroups extends Component {
  render () {
    return (
      <div className='group-view list-row-condest'>
        <ul className='list-view-group'>
           {this.props.groups.map((group, index) => (
            <DrawGroupRow key={index} group={group} {...this.props} />
          ))}
        </ul>
      </div>
    )
  }
}

export class ListRows extends Component {
  render () {
    if (this.props.children&&this.props.children.length) {
      return (
        <ul className='list-view'>
          {this.props.children.map(data => (
            <ListRow key={data.id} item={data} {...this.props} />
          ))}
        </ul>
      )
    } else {
      return ''
    }
  }
}

export class ListRow extends Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (event) {
    this.props.collapseSidebar()
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state) // equals() is your implementation
  }


  render () {
    return this.props.item && this.props.item.id ? (
      <li>
        <Link
          onClick={this.props.resetFrom}
          className={
            'clearfix list-row ' +
            (parseInt(this.props.match && this.props.match.params && this.props.match.params.id) ===
            parseInt(this.props.item && this.props.item.id)
              ? 'active'
              : '')
          }
          to={'/units/' + this.props.item.id}
        >
          <Tooltip
            title={this.props.item.name}
            placement="right-start"     //hang issue fixed, tooltip come on right side, infinite scroll have height auto, so hang with tooltip default padding
            classes={{
              popper: 'menu-popper',
              tooltip: 'menu-popper-tooltip'
            }}
          >
            <label className='checkbox'>
              <span className='unit-name'>
                <img
                  src={
                    '/assets/category/default/' +
                    (this.props.item.category || 'default') +
                    '.svg'
                  }
                  // alt={this.props.item.name}
                />{' '}
                {this.props.item.name}
              </span>
            </label>
          </Tooltip>
          <div className='pull-right'>
            {checkPrivileges('deviceDelete') && (
              <span
                className='action-item hovered'
                title={this.props.translate('delete')}
                onClick={e => this.props.removeDevice(e, this.props.item)}
              >
                <DeleteIcon style={{fontSize: 16}}/>
              </span>
            )}
          </div>
        </Link>
      </li>
    ) : null
  }
}

const mapState = state => {
  return {
    groups: state.groups,
    searchRecently: state.searchRecently
  }
}

const mapStateToProps = connect(mapState)

export const DevicesShortList = mapStateToProps(Devices)
