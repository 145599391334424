import React from 'react'
import TextField from '../../../Components/common/TextField'
import MenuItem from '../../../Components/common/MenuItem'
import Grid from '@material-ui/core/Grid'
import Button from '../../../Components/common/Button'
import Checkbox from '../../../Components/common/Checkbox'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {FormControl, FormControlLabel, RadioGroup, Tooltip} from '@material-ui/core';
import SingleSelect from '../../../Components/common/SingleSelect'
import Radio from '../../../Components/common/Radio'

export default function FuelSettings (props) {
  const { unitParkingAttributesform } = props
  let selectedlang = props.languages.find(id => id.code===unitParkingAttributesform.lang)
  let selectedDriver = props.drivers.find(id => id.id===unitParkingAttributesform.fixDriverId)
  return (
    <div>
       <div style={{ textAlign: 'right', padding: '16px' }}  className='clearfix'>
          <h4 className='v-tab-heading pull-left with-border'>
            {props.translate('fuelSensor')}
          </h4>
        </div>
        <div style={{ padding: '0 16px' }}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <TextField
                id='fuelSensor1'
                label={props.translate('fuelSensor1')}
                value={props?.selecteditem?.attributes?.fuelSensor1 || ''}
                onChange={e => props.handleFuelChange('fuelSensor1', e)}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                id='fuelSensor2'
                label={props.translate('fuelSensor2')}
                value={props?.selecteditem?.attributes?.fuelSensor2 || ''}
                onChange={e => props.handleFuelChange('fuelSensor2', e)}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
          </Grid>
           
          
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              {props.isVisablBtn && (
                <Button
                  disabled={props.isDisableFuelBtn}
                   onClick={props.fuelSave}
                >
                  {props.translate('sharedSave')}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      <div style={{ textAlign: 'right', padding: '16px 16px 0px 16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('fuelCounter')}
        </h4>
      </div>
      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={12} lg={12} xl={12} xs={12}>
              <RadioGroup
                aria-label="fuelCounter"
                name="fuelCounter"
                value={unitParkingAttributesform.fuelCounter || 'basic'}
                onChange={props.handleChange('fuelCounter')}
                row
              >
                
                <FormControlLabel
                  value="standard"
                  control={<Radio />}
                  label={props.translate('standard')}
                />
                <FormControlLabel
                  value="basic"
                  control={<Radio />}
                  label={props.translate('basic')}
                />
              
                <FormControlLabel
                  value="advance"
                  control={<Radio />}
                  label={props.translate('advance')}
                />
              </RadioGroup>
            </Grid>





            {/* <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                id='fuelCounter'
                label={props.translate('fuelCounter')}
                type='text'
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.fuelCounter) ||
                  'basic'
                }
                onChange={props.handleChange('fuelCounter')}
                variant='outlined'
                margin='dense'
                fullWidth
                select
              >
                <MenuItem value='basic'>{props.translate('basic')}</MenuItem>
                <MenuItem value='standard'>{props.translate('standard')}</MenuItem>
                <MenuItem value='advance'> {props.translate('advance')} </MenuItem>
              </TextField>
            </Grid> */}
            {(unitParkingAttributesform&&unitParkingAttributesform.fuelCounter ==="basic") ?
            (<Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('basicL100Consumption') + ' (10L/100KM)'}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.basicL100Consumption) ||
                  ''
                }
                onChange={props.handleChange('basicL100Consumption')}
                id='fuelAccuracy'
                fullWidth
              />
            </Grid>)
            : unitParkingAttributesform&&unitParkingAttributesform.fuelCounter ==="advance" ?
            (<><Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('advL100CityConsumption') + " (L/100KM)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advL100CityConsumption) ||
                  ' '
                }
                onChange={props.handleChange('advL100CityConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
            </Grid>
            
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advL100HighwayConsumption')+ " (L/100KM)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advL100HighwayConsumption) ||
                  ' '
                }
                onChange={props.handleChange('advL100HighwayConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('advL100AverageConsumption') + " (L/100KM)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advL100AverageConsumption) ||
                  ' '
                }
                onChange={props.handleChange('advL100AverageConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
        
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advCitySpeed')+ " (km/h)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advCitySpeed) ||
                  ' '
                }
                onChange={props.handleChange('advCitySpeed')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advHighwaySpeed')+ " (km/h)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advHighwaySpeed) ||
                  ' '
                }
                onChange={props.handleChange('advHighwaySpeed')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advAverageSpeed')+ " (km/h)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advAverageSpeed) ||
                  ' '
                }
                onChange={props.handleChange('advAverageSpeed')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advCorrectionCoefficient')}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advCorrectionCoefficient) ||
                  ' '
                }
                onChange={props.handleChange('advCorrectionCoefficient')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advIdlLHFuelConsumption') + " (L/H)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advIdlLHFuelConsumption) ||
                  ' '
                }
                onChange={props.handleChange('advIdlLHFuelConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advHighSpeedAddonPercent') + "(%)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advHighSpeedAddonPercent) ||
                  ' '
                }
                onChange={props.handleChange('advHighSpeedAddonPercent')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('advEveryKmhHighwayConsumption') + " (km/h)"}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.advEveryKmhHighwayConsumption) ||
                  ' '
                }
                onChange={props.handleChange('advEveryKmhHighwayConsumption')}
                id='fuelAccuracy'
                fullWidth
              />
              </Grid>

            </>)

            : null }
          </Grid>
        </div>
      </div>

      <div style={{ textAlign: 'right', padding: '16px' }} className='clearfix'>
        <h4 className='v-tab-heading pull-left with-border'>
          {props.translate('fuelFillingdrainDetection')}
        </h4>
      </div>

      <div style={{ padding: '0 16px' }}>
        <div
          style={{
            marginTop: 16,
            marginBottom: 8
          }}
        >
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                id='minimalFuelFillingVolume'
                label={props.translate('minimalFuelFillingVolume')}
                helperText={props.errorInFuelFilling? "Value Should be Greater than 1":null}
                type='number'
                error={props.errorInFuelFilling}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.minimalFuelFillingVolume) ||
                  ''
                }
                onChange={props.handleChange('minimalFuelFillingVolume')}
                variant='outlined'
                margin='dense'
                fullWidth
              >
              </TextField>
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('minimalFuelDrainVolume')}
                error={props.errorInFuelDrain}
                helperText={props.errorInFuelDrain?"Value Should be Greater than 1":null}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.minimalFuelDrainVolume !== undefined
                    ? unitParkingAttributesform.minimalFuelDrainVolume
                    : ''
                }
                onChange={props.handleChange('minimalFuelDrainVolume')}
                id='minimalFuelDrainVolume'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('consecutiveFuelFillingTimeout')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.consecutiveFuelFillingTimeout !== undefined
                    ? unitParkingAttributesform.consecutiveFuelFillingTimeout
                    : '120'
                }
                onChange={props.handleChange('consecutiveFuelFillingTimeout')}
                id='consecutiveFuelFillingTimeout'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={props.translate('consecutiveFuelDrainTimeout')}
                value={
                  unitParkingAttributesform &&
                  unitParkingAttributesform.consecutiveFuelDrainTimeout !== undefined
                    ? unitParkingAttributesform.consecutiveFuelDrainTimeout
                    : '120'
                }
                onChange={props.handleChange('consecutiveFuelDrainTimeout')}
                id='consecutiveFuelDrainTimeout'
                fullWidth
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12} style={{marginTop:'10px'}}>
              <Checkbox
                id='detectionInMotion'
                onChange={props.handleChange('detectionInMotion')}
                checked={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.detectionInMotion) ||
                  false
                }
                label={props.translate('detectionInMotion')}
              />
            </Grid>
            <Grid item sm={6} lg={4} xl={3} xs={12} style={{marginTop:'10px'}}>
              <Checkbox
                id='useFuelAccuracy'
                onChange={props.handleChange('useFuelAccuracy')}
                checked={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.useFuelAccuracy) ||
                  false
                }
                label={props.translate('useFuelAccuracy')}
              />
            </Grid>
            {(unitParkingAttributesform&&unitParkingAttributesform.useFuelAccuracy) &&
            (<Grid item sm={6} lg={4} xl={3} xs={12}>
              <TextField
                type='number'
                label={ props.translate('fuelAccuracy')+ ' (%)'}
                value={
                  (unitParkingAttributesform &&
                    unitParkingAttributesform.fuelAccuracy) ||
                  '5'
                }
                onChange={props.handleChange('fuelAccuracy')}
                id='fuelAccuracy'
                fullWidth
              />
            </Grid>)}
          </Grid>
        </div>
        {/* <Grid item xs={12} style={{ textAlign: 'right' }}>
          {props.isVisablBtn && (
            <Button
              disabled={!props.isVisableParkingAttBtn}
              onClick={props.onSubmit}
            >
              {props.translate('sharedSave')}
            </Button>
          )}
        </Grid> */}
      </div>

   



      
      <div style={{ padding: '0 16px' }}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
            {props.isVisablBtn && (
              <Button
                disabled={!props.isVisableParkingAttBtn}
                onClick={props.onSubmit}
              >
                {props.translate('sharedSave')}
              </Button>
            )}
          </Grid>
        </div>

    </div>
  )
}
