import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Style from 'style-it'
import SearchField from '../common/SearchField'
import { withLocalize } from 'react-localize-redux'
import Scrollbar from 'react-scrollbars-custom'
class NotificationTypeSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      list: {},
      displayList: [],
      search: '',
      isChecked: false
    }
  }

  componentWillMount () {
    let list = {}
    if (this.props.value && this.props.value.length) {
      this.props.value.map(d => (list[d] = true))
      this.setState({ list })
    }

    this.prepareNotifications(this.props)
  }

  componentWillUnmount () {
    this.setState({
      list: {},
      search: '',
      displayList: [],
      isChecked: false
    })
  }
  componentWillReceiveProps (n) {
    this.prepareNotifications(n)
  }

  prepareNotifications = n => {
    let displayList = n.notificationList.map(({type}) => ({type, label: n.translate('notification.'+type)}))
    this.setState({ displayList })
  }

  sendToParent () {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list))
    }
  }

  onChange = (e, v) => {
    let list = { ...this.state.list }
    if (e.target.checked) {
      list[v.type] = true
    } else {
      delete list[v.type]
    }
    this.setState(
      {
        list,
        isChecked:
          this.props.notificationList.length === Object.keys(list).length
      },
      () => {
        this.sendToParent()
      }
    )
  }

  searchItem = text => {
    this.setState({ search: text.target.value })
  }

  toggleItems = event => {
    if (event.target.checked) {
      let list = {}
      this.props.notificationList.map(d => (list[d.type] = true))
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent()
      })
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent()
      })
    }
  }

  render () {
    let list = this.state.displayList.filter(
      item => (item.type+item.label).toLowerCase().indexOf(this.state.search) !== -1
    )
    return (
      <Style>
        {`
          .selector-list li > label.active,
          .selector-list li > label:hover {
            background: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
          }
          .selector-list li {
              margin-bottom: 6px;
            }
            .selector-list li > label {
              cursor: pointer;
              display: block;
              padding: 2px 6px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              min-height: 26px;
              background: ${this.props.themecolors.themeListingColor};
          }
          .selector-list {
              padding: 0;
              margin: 0;
              color: ${this.props.themecolors.themeListingInverse};
              list-style: none
          }
          .selector-label {
              font-size: 12px;
              line-height: 1.5;
              margin-left: 6px;
          }
          .selector-image {
              width: 26px;
              margin-left: 6px;
          }
          .selector-image img {
              height: auto;
              width: 26px;
              height: 26px;
              display: block;
              margin: auto;
          }
          .selector-check {
              height: 26px;
              display: inline-flex;
              align-items: center;
          }
          .selector-count {
              padding: 0 0 6px;
              font-size: 10px
          }`}
        <div>
          <div
            className='section-head clearfix section-head-filter'
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className='filter-row pull-left'>
              <li>
                <SearchField
                  label={this.props.translate('searchNotification')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate('selectAll')}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className='selector-count'>
              {' '}
              {Object.keys(this.state.list).length}{' '}
              {this.props.translate('NotificationSelected')}
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation={true}
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 32) *
                (this.props.rows || 10)
            }}
            scrollerProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id='scrollableDivNotification'
                  />
                )
              }
            }}
          >
            <ul className='selector-list'>
              {list.map(row => (
                <li key={row.type}>
                  <label
                    className={this.state.list[row.type] ? 'active' : null}
                  >
                    {!this.props.readOnly ? (
                      <span className='selector-check'>
                        <Checkbox
                          checked={this.state.list[row.type]}
                          value={row}
                          onChange={this.onChange}
                        />
                      </span>
                    ) : null}
                    <span className='selector-label'>
                      {row.label}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  notificationList: state.notificationType,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export default mapStateToProps(withLocalize(NotificationTypeSelector))
