import React, { Component } from 'react'

import './Style.scss'

import { LoginForm, ForgotForm } from './Component'
import { connect } from 'react-redux'
import Style from 'style-it'
import { themes } from '../../Reducers/Theme'
class Login extends Component {
  constructor (props) {
    super(props)
    this.state = {
      view: 'login'
    }
  }
  switchView = view => {
    this.setState({ view })
  }
  render () {
    const { view } = this.state
    return (<React.Fragment>
          <Style>
          {`
          a {
            color: ${this.props.themecolors.textColor}
          }
          .loginWrapperInner {
            background: ${this.props.themecolors.backgroundColor};
          }
          .loginLoaderWrapper {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
          .login-footer {
            border-top: 1px solid ${this.props.themecolors.themeLightColor};
          }
          `}
          
        </Style>
        <div className='loginWrapper'>
          <div className='loginWrapperInner'>
            {view === 'login' ? (
              <LoginForm {...this.props} switchView={this.switchView} />
              ) : null}
            {view === 'forgot' ? (
              <ForgotForm {...this.props} switchView={this.switchView} />
              ) : null}
          </div>
          <div
            className='white-label-bg'
            style={{ backgroundImage: `url("${this.props.whiteLabling.logInPageImage}")` }}
            ></div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
	themecolors: themes[2],
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(Login);