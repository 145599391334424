import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import TextField from './../../../Components/common/SearchField'
import GroupsShortList from './../../../Components/Groups/shortList'
import { checkPrivileges } from '../../../Helpers'
import Style from 'style-it'
import Loader from './../../Loader'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'

export default class GroupsSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      switchView: 'units',
      isPopupVisable: false,
      is_gm_Visable: false
    }
    this.switchView = this.switchView.bind(this)
  }
  componentWillMount () {
    if (this.props.fetchMoreItems2 && this.props.itemPagination2.total === 0) {
      this.props.fetchMoreItems2()
    }
  }

  switchView (type, e) {
    this.setState({
      switchView: type,
      isChecked: true,
      sort: 'ASC',
      search: ''
    })
  }

  render () {
    const endMessage =
      this.props.itemPagination2 && this.props.itemPagination2.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div>
          <div className='section-head clearfix section-head-filter'>
            <ul className='filter-row clearfix pull-right'>
              <li>
                <TextField
                  label={this.props.translate('searchGroup')}
                  type='search'
                  placeholder='exp: Name'
                  value={this.state.search}
                  onChange={this.props.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                {checkPrivileges('groupCreate') && (
                  <Button size='small' onClick={() => this.props.goAddGroup()}>
                    {' '}
                    {this.props.translate('Create')}
                  </Button>
                )}
              </li>
            </ul>
          </div>
          <div className='sidebar-devices-list'>
            <Scrollbar
              disableTracksWidthCompensation={true}
              scrollerProps={{
                renderer: props => {
                  const { elementRef, ...restProps } = props
                  return (
                    <div {...restProps} ref={elementRef} id='scrollableDiv4' />
                  )
                }
              }}
            >
              {this.props.itemPagination2 ? (
                <InfiniteScroll
                  dataLength={this.props.itemPagination2.items.length}
                  next={this.props.fetchMoreItems2}
                  hasMore={this.props.itemPagination2.hasNext}
                  loader={<Loader defaultStyle={true} />}
                  scrollableTarget='scrollableDiv4'
                  endMessage={endMessage}
                >
                  <GroupsShortList
                    {...this.state}
                    {...this.props}
                    editItem={this.editItem}
                    removedItem={this.props.removedItem}
                    //selecteItem={this.selecteItem}
                    collapseSidebar={this.props.collapseSidebar}
                    //selectedItemId={this.props.selectedItemId}
                    translate={this.props.translate}
                  />
                </InfiniteScroll>
              ) : null}
            </Scrollbar>
          </div>
        </div>
      </Style>
    )
  }
}
