import React, { Component } from 'react'
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '../common/MenuItem'
import { errorHandler } from '../../Helpers';
import CustomDialog from './Dialog';
import Loader from '../../Layout/Loader';
import MaintenanceFilter from './MaintenanceFilter';

export default  class ExportExcel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openMaintenanceModal:false,
      loader:false,
      countChecked: 0,
      anchorEl: null,
    }
  }
  manageExportMaintenance = ()=>{
    this.setState({
      openMaintenanceModal:true
    })
  }
  onCloseDueMaintenanceModel = () =>{
    this.setState({
      openMaintenanceModal:false,
      countChecked: 0,
      anchorEl: null,
    })
  }
  exportFile = (fileName, Dform) => {
    let api, Accept;
        if(['vehicles','drivers','devices','binders','users','services'].includes(fileName)){
            api = `/api/${fileName}/export?sample=${this.props.sample}`;
        }
        else if(fileName === 'geofences'&& !this.props.polygon){
          api = `/api/geofences/export/circle?sample=${this.props.sample}`;
        } else if(fileName === 'geofences' && this.props.polygon){
          api = `/api/geofences/export/polygon?sample=${this.props.sample}` 
        } else if(fileName === 'Dlogs'){
          api = `/api/positions/export?from=${Dform.from}&to=${Dform.to}&deviceId=${Dform.device}&detail=${Dform?.detail||false}`;
        }
        if(this.props.type === 'PDF'){
          Accept = 'application/pdf'
        }else{
          Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        axios({
          method: 'GET',
          url: api,
          headers:{
            Accept: Accept
          },
          responseType: 'blob'
        })
        .then(response => {
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          if(this.props.type === 'PDF'){
            a.setAttribute('download', `${fileName}.pdf`);
          }else{
            a.setAttribute('download', `${fileName}.xlsx`);
          }
          document.body.appendChild(a)
          a.href = url
          a.click()
        }
        )
        
        .catch(error => {errorHandler(error, this.props.dispatch)})

  }

  formSubmit = (data)=>{
    const {selectedAreaId,from, to,selectedMaintenanceId,selectedvehicleId } = data
    this.setState({loader:true})
    let api, Accept, fileName='services';
    if(this.props.type === 'PDF'){
      Accept = 'application/pdf'
    }else{
      Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    api = `/api/services/export?sample=false${selectedMaintenanceId}&from=${from}&to=${to}${selectedvehicleId}${selectedAreaId}`;
    axios({
      method: 'GET',
      url: api,
      headers:{
        Accept: Accept
      },
      responseType: 'blob'
    })
    .then(response => {
      const a = document.createElement('a')
      const url = window.URL.createObjectURL(new Blob([response.data]))
      if(this.props.type === 'PDF'){
        a.setAttribute('download', `${fileName}.pdf`);
      }else{
        a.setAttribute('download', `${fileName}.xlsx`);
      }
      document.body.appendChild(a)
      a.href = url
      a.click()
      this.setState({loader:false})
    }
    )
    
    .catch(error => {errorHandler(error, this.props.dispatch)})
  }



  render () {
     return (
      <div>
          <Tooltip title={this.props.translate('downloadExcel')}>
              <MenuItem
                style={{ minWidth: 'inherit' }}
                component="label"
                onClick={this.props.isMaintenance ? this.manageExportMaintenance : e=>this.exportFile(this.props.fileName,this.props.polygon)}
              >
                {this.props.fileName !== 'geofences'?
                this.props.translate('download') +" " + this.props.translate(this.props.title) :
                  this.props.polygon ? this.props.translate('Polygon Geofences')+ " " + this.props.translate(this.props.title) + " " +this.props.translate('download')
                  : this.props.translate('Circle Geofences')+ " " + this.props.translate(this.props.title) + " " +this.props.translate('download') }
              </MenuItem>
            </Tooltip>
          {this.state.openMaintenanceModal ? <>
            <CustomDialog
              title ={this.props.translate('downloadServices')}
              // themecolors={themecolors}
              visable={true}
              onClose={this.onCloseDueMaintenanceModel}
              bodyPadding={10}
              isVisableBtn
              noFullScreen
              disableCloseBtn
            
             >
             {(
                <>
                  <aside className='sidebar-bg-layer'>
                    <MaintenanceFilter 
                      {...this.props}
                      formSubmit={this.formSubmit}
                      gridSize={6}
                      loader={this.state.loader}
                    />
                  </aside>
               </>)
             }
            </CustomDialog>
        </>: null}
      </div>
  );


   {/*  return (
      <>
       <Tooltip title='Download Excel'>
          <Button style={{ minWidth: 'inherit' }} onClick={this.export}>
            <GetAppIcon />
          </Button>
        </Tooltip>
        <ExcelExport
          data={this.props.data}
          fileName={this.props.fileName + '.xlsx'}
          ref={exporter => {
            this._exporter = exporter
          }}
        >

           {this.props.columns.map(col => {
            let w = 100
            if (col.key) {
              switch (col.key) {
                case 'id':
                case 'model':
                case 'deviceId':
                case 'vehicleType':
                  w = 60
                  break
                case 'vin':
                case 'make':
                  w = 120
                  break
                case 'chassisNumber':
                case 'garage':
                case 'label':
                  w = 250
                  break
                default:
                  w = null
                  break
              }
            }
            return (
              <ExcelExportColumn
                key={col.key}
                field={col.key}
                title={col.value}
                width={w}
              />
            )
          })} 
        </ExcelExport>
      </>
    )*/}
  }
}