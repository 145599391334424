
import React, { Component} from 'react'
import styles from "./invoice-style.scss";
import {  Grid } from '@material-ui/core'
import instance from '../../axios';
class Invoice extends Component {
    constructor (props) {
      super(props)
      this.state = {
        data: {},
        laoder: true,
    }
    }
    componentWillMount () {
        var baseUrl = window.location.origin;
        const queryString = window.location.search;
        var res = queryString.split('token=')
        instance({
          method: 'GET',
          url: `/api/services/invoice/${res[1]}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(response=>{
            this.setState({data:response})
        })
        .catch(error=>{console.log('error ====', error)})
    }
  render() {
      const { data } = this.state
      if(data){
      const Pack = data&&data.Package
      const Service = data&&data.Service
          console.log('data ===', data)
          return (
                  <Grid id="invoiceholder">
                      <Grid id="invoice" class="effect2">
                      
                      <Grid id="invoice-top">
                          <Grid class="title-invoice text-center">
                              <h3 class='head3'>فاتورة ضريبة القيمة المضافة</h3>
                              <h3 class='head3'>TAX INVOICE</h3>
                          </Grid>
                      </Grid>
                      <Grid class="invoice-mid"> 
                          <Grid container spacing={1}>
                              <Grid item md={6} style={{padding: '12px 0 0 12px'}}>
                                      <p class='ptag'>{data.serviceId}</p>
                                      <p class='ptag'>{data.stationName}</p>
                                      <p class='ptag'>{data.stationCode}</p>
                                      <p class='ptag'>{data.stationCity}</p>
                                      <p class='ptag'>{data.serviceDate}</p>
                                      <p class='ptag'>Ph: +966 59 631 3008</p>
                                      <p class='ptag'>Email: m.nazir@petromin.com</p>
                              </Grid>
                              <Grid item md={6} style={{textAlign:'right', padding: '12px 12px 0 0'}} >
                                      <p class='ptag'>شركة الوطنية لحلول النقل المحدودة </p>      
                                      <p class='ptag'>ص.ب.1432 المحمدية</p>
                                      <p class='ptag'>جدة ,21431 ,المملكة العربية السعودية</p>
                                      <p class='ptag'>الرقم الضريبي للمنشأة: 300000603210003 </p>
                                      <p class='ptag'>+966 59 631 3008 : الهاتف</p>
                                      <p class='ptag'>m.nazir@petromin.com : الإلكتروني البريد</p>
                              </Grid>
                          </Grid>  
                          {/* <Grid container spacing={1} style={{backgroundColor:'#6c757d'}}>
                              <Grid item md={6} style={{padding: '10px 0 10px 30px'}}>
                                      <strong class="text-white">ITEM DETAILS</strong>
                              </Grid>
                              <Grid item md={6} style={{textAlign:'right', padding: '10px 30px 10px 0'}}>
                                  <strong class="text-white"> والسيارة العميل بيانات</strong>
                              </Grid>
                          </Grid>  
                         */}
                        <Grid class="row">
                            <table class="border">
                                <tr class="tableHead text-white">
                                    <td><strong>Item Name</strong></td>
                                    <td><strong>Item Code</strong></td>
                                    <td><strong>Item Quantity</strong></td>
                                    <td><strong>Item Unit Price</strong></td>
                                    <td><strong>Item VAt Amount</strong></td>
                                    <td><strong>Item Net Price</strong></td>
                                    <td><strong>Item Total Price</strong></td>
                                </tr>
                                {data.Item && data.Item.map(item=>(
                                        <tr>
                                            <td>{item.ItemName}</td>
                                            <td>{item.ItemCode}</td>
                                            <td>{item.ItemQty}</td>
                                            <td>{item.ItemPrice}</td>
                                            <td>{item.ItemVATAmount}</td>
                                            <td>{item.ItemNetAmount}</td>
                                            <td>{item.ItemTotalAmount}</td>
                                        </tr>
                                    ))}
                            </table>
                        </Grid>
                                    
                          {/* <Grid class="row bg-light mt-3 mb-3 p-2">
                              <table>
                                  <tr>
                                      <td rowspan="5" class="border-0">img</td>
                                      <td>Invoice No:</td>
                                      <td class="text-center">92085</td>
                                      <td class="text-right">: الفاتورة رقم</td>
                                  </tr>
                                  <tr>
                                      <td>Reference no:</td>
                                      <td class="text-center">M-1760-13273</td>
                                      <td class="text-right">: المرجع رقم</td>
                                  </tr>
                                  <tr>
                                      <td>Invoice Date:</td>
                                      <td class="text-center">2022-04-01 21:00:00</td>
                                      <td class="text-right">: الفاتورة تاريخ</td>
                                  </tr>
                                  <tr>
                                      <td>Contract name/Ref Id :</td>
                                      <td class="text-center"></td>
                                      <td class="text-right">: اسم العقد/ معرف المرجع</td>
                                  </tr>
                                  <tr>
                                      <td>Package Name:</td>
                                      <td class="text-center">Gold Service</td>
                                      <td class="text-right">: الحزمة اسم</td>
                                  </tr>
                              </table>
                          </Grid> */}
                          {Pack && (<>
                              <Grid class="row">
                                    <table class="border">
                                        <tr class="tableHead text-white">
                                            <td><strong>Booking Name</strong></td>
                                            <td><strong>Item Name</strong></td>
                                            <td><strong>Item Quantity</strong></td>
                                            <td><strong>Item Unit Price</strong></td>
                                            <td><strong>Item VAt Amount</strong></td>
                                            <td><strong>Item Net Price</strong></td>
                                            <td><strong>Item Total Price</strong></td>
                                        </tr>
                                        {Pack && Pack.map(pk =>(
                                          <>
                                            {pk.Service && pk.Service.map(s1=>(
                                                <tr>
                                                    <td>{s1.ServiceName}</td>
                                                    <td>{s1.item && s1.item.map(item=>(
                                                            <p>- {item.ItemName}</p>   ))}</td>
                                                    <td>{s1.item && s1.item.map(item=>(
                                                            <p>{item.ItemQty}</p>   ))}</td>
                                                    <td>{s1.item && s1.item.map(item=>(
                                                            <p>{item.ItemPrice}</p>   ))}</td>
                                                    <td>{s1.item && s1.item.map(item=>(
                                                            <p>{item.ItemVATAmount}</p>   ))}</td>
                                                    <td>{s1.item && s1.item.map(item=>(
                                                            <p>{item.ItemNetAmount}</p>   ))}</td>
                                                    <td>{s1.item && s1.item.map(item=>(
                                                            <p>{item.ItemTotalAmount}</p>   ))}</td>
                                                </tr>
                                            ))}
                                          </>
                                        ))}
                                </table>
                              </Grid>
                            </>)}

                          {Service && (<>
                            {console.log('service ====', Service)}
                              <Grid class="row">
                                    <table class="border">
                                        <tr class="tableHead text-white">
                                            <td><strong>Service Name</strong></td>
                                            <td><strong>Item Name</strong></td>
                                            <td><strong>Item Quantity</strong></td>
                                            <td><strong>Item Unit Price</strong></td>
                                            <td><strong>Item VAt Amount</strong></td>
                                            <td><strong>Item Net Price</strong></td>
                                            <td><strong>Item Total Price</strong></td>
                                        </tr>
                                        {Service && Service.map(serv =>(
                                            <tr>
                                                <td>{serv.ServiceName}</td>
                                                <td>{serv.item && serv.item.map(item=>(
                                                        <p>- {item.ItemName}</p>
                                                ))}</td>
                                                <td>{serv.item && serv.item.map(item=>(
                                                        <p>{item.ItemQty}</p>
                                                ))}</td>
                                                <td>{serv.item && serv.item.map(item=>(
                                                        <p>{item.ItemPrice}</p>
                                                ))}</td>
                                                <td>{serv.item && serv.item.map(item=>(
                                                        <p>{item.ItemVATAmount}</p>
                                                ))}</td>
                                                <td>{serv.item && serv.item.map(item=>(
                                                        <p>{item.ItemNetAmount}</p>
                                                ))}</td>
                                                <td>{serv.item && serv.item.map(item=>(
                                                        <p>{item.ItemTotalAmount}</p>
                                                ))}</td>
                                               
                                            </tr>
                                        ))}
                                </table>
                              </Grid>
                            </>)}
                          
                                  {/* console.log('s1 ====', s1)
                                  return(
                                      <Grid class="row">
                                            <table class="border">
                                                <tr class="tableHead text-white">
                                                    <td><strong>رقم التسلسلي SL NO</strong></td>
                                                    <td><strong>تفاصيل قطع الغيار PARTICULARS OF SPARES</strong></td>
                                                    <td><strong>الكمية QTY</strong></td>
                                                    <td><strong>المبلغ UNIT PRICE</strong></td>
                                                    <td><strong>المبلغ الخاضع للضريبة TOTAL PRICE EXCL. VAT</strong></td>
                                                    <td><strong>ضريبه القيمه المضافه VAT @ 15%</strong></td>
                                                    <td><strong>المبلغ AMOUNT</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>GOLD SERVICE</td>
                                                    <td>1.00</td>
                                                    <td>95.48</td>
                                                    <td>95.48</td>
                                                    <td>14.32</td>
                                                    <td>109.80</td>
                                                </tr>
                                            </table>
                                    </Grid>
                                  ) */}
                          
                            {/* <Grid class="row">
                                  <table class="border">
                                      <tr class="tableHead text-white">
                                          <td><strong>رقم التسلسلي SL NO</strong></td>
                                          <td><strong>تفاصيل قطع الغيار PARTICULARS OF SPARES</strong></td>
                                          <td><strong>الكمية QTY</strong></td>
                                          <td><strong>المبلغ UNIT PRICE</strong></td>
                                          <td><strong>المبلغ الخاضع للضريبة TOTAL PRICE EXCL. VAT</strong></td>
                                          <td><strong>ضريبه القيمه المضافه VAT @ 15%</strong></td>
                                          <td><strong>المبلغ AMOUNT</strong></td>
                                      </tr>
                                      <tr>
                                          <td>1</td>
                                          <td>GOLD SERVICE</td>
                                          <td>1.00</td>
                                          <td>95.48</td>
                                          <td>95.48</td>
                                          <td>14.32</td>
                                          <td>109.80</td>
                                      </tr>
                                      <tr class="bg-light">
                                          <td colspan="6" class="text-right"><strong>SUB TOTAL المجموع الفرعي</strong></td>
                                          <td><strong>141.48</strong></td>
                                      </tr>
                                      <tr class="bg-light">
                                          <td colspan="6" class="text-right"><strong>VAT @ 15%  ضريبه القيمه المضافه</strong></td>
                                          <td><strong>21.22</strong></td>
                                      </tr>
                                      <tr class="bg-light">
                                          <td colspan="6" class="text-right"><strong>PARTS NET AMOUNT  المبلغ الغيار لقطع الصافي</strong></td>
                                          <td><strong>162.70</strong></td>
                                      </tr>
                      
                                  </table>
                          </Grid> */}
          
                      </Grid>
                      
                      <footer class="text-center">
                          <Grid class="legalcopy clearfix" >
                          <p class="text-center">شكرا لزيارتك <br/> THANK YOU FOR YOUR VISIT </p>
                          </Grid>
                      </footer>
                      </Grid>
              </Grid>
            )
      }
  }
}
 
export default Invoice;

