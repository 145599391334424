import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import Loader from './../../Loader'
import Grid from '@material-ui/core/Grid'
import DateRangePicker from './../../../Components/common/DateRangePicker'
import Style from 'style-it'
import { checkPrivileges } from '../../../Helpers'
import Scrollbar from 'react-scrollbars-custom'
import DeviceSelector from '../../../Components/Devices/DeviceSelector'
import SearchDevices from '../../../Components/Devices/searchItems'
import Slider from '@material-ui/core/Slider'
import moment from 'moment'

export default class Timemachine extends Component {
  constructor (props) {
    super(props)
    this.goBack = this.goBack.bind(this)
  }
  createReport = type => {
    this.props.collapseSidebarWithButton()
    this.props.createReport(type)
  }
  goBack () {
    this.props.openForm()
  }

  valuetext = value => {
    return moment('2016-03-13')
      .startOf('day')
      .add(value, 'minutes')
      .format('HH:mm')
  }

  render () {
    let viewScreen

    viewScreen = (
      <form style={{ padding: 20 }}>
        <h3 className='page-title'>
          {this.props.translate('timemachineForm')}
        </h3>
        <Grid container spacing={1}>
          <Grid item xs={12}>
             <DateRangePicker
              fill
              {...this.props.timelineRanges}
              selectedDate={this.props.selectedDay}
              label={
                this.props.translate('selectedDay')
              }
              onEvent={this.props.showDates}
              timeMachine
              datepickerOnly={false}
            />
          </Grid>


          <Grid item xs={12} style={{margin:"25px 0px 5px 0px"}}>
          <Slider
              value={this.props.controlTime}
              onChange={this.props.handleTimeControl}
              valueLabelDisplay='on'
              aria-labelledby='range-slider'
              valueLabelFormat={this.valuetext}
              max={1439}
              classes={{
                root: 'theme-range-slider',
                thumb: 'theme-range-slider-thumb',
                track: 'theme-range-slider-track',
                rail: 'theme-range-slider-rail',
                valueLabel: 'theme-range-slider-label'
              }}
              step={5}
            />

          </Grid>

        </Grid>
        
        <SearchDevices
            isMulti={false}
            api='devices'
            isClearable
            onChange={this.props.selectedDevices}
            value={this.props.deviceId}
            placeholder={this.props.translate('searchUnits')}
          />
        <Button
          disabled={!this.props.validatedForm}
          style={{ marginTop: 20 }}
          variant='contained'
          onClick={this.createReport}
        >
          {this.props.translate('submit')}
        </Button>
      </form>
    )

    if (viewScreen) {
      return (
        <Style>
          {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
          <aside className='fms-sidebar sidebar-bg-layer'>
            {checkPrivileges('track') && <Scrollbar>{viewScreen}</Scrollbar>}
          </aside>
        </Style>
      )
    } else {
      return this.props.trackLoading ? <Loader /> : null
    }
  }
}
