import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '../../../common/TextField'
import { GeofencesModal } from '../Screen2/geofences'
import Paper from '@material-ui/core/Paper'
import { Screen3 } from '../Screen3'

const Modal = props => {
  const paperStyle = {
    background: 'transparent',
    marginBottom: 5
  }
  return (
    <>
      <div style={{ padding: 20, minHeight: 270 }}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Paper style={paperStyle} elevation={0}>
              <h4 style={{ marginTop: 0 }}>{props.translate('basic')}</h4>{' '}
              <Grid item md={12} xs={12}>
                <TextField
                  id='name'
                  label={props.translate('sharedName')}
                  required
                  placeholder=''
                  value={props.form.name}
                  onChange={props.handleChange('name')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  id='description'
                  label={props.translate('sharedDescription')}
                  placeholder=''
                  value={props.form.description}
                  onChange={props.handleChange('description')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              {props.expirationCheck ? null :
                <Grid item md={12} xs={12}>
                <TextField
                  id='initialTrigger'
                  label={props.translate('initialTrigger')}
                  placeholder=''
                  value={props.form.initialTrigger}
                  onChange={props.handleChange('initialTrigger')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>}
              {/* <Grid item md={12} xs={12}>
                <TextField
                  id='alarmState'
                  label={props.translate('alarmState') + ' /sec'}
                  // required
                  type={'number'}
                  placeholder=''
                  value={props.form.alarmState}
                  onChange={props.handleChange('alarmState')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid> */}
              {props.expirationCheck ? null :
              <Grid item md={12} xs={12}>
                <TextField
                  id='interval'
                  label={props.translate('interval/min')}
                  // required
                  type={'number'}
                  placeholder=''
                  value={props.form.interval}
                  onChange={props.handleChange('interval')}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  error={
                    props.form.interval < 5 
                        ? true
                        : false
                    }
                  helperText={props.translate("enterValueAboveThan4")}
                />
              </Grid>}
            </Paper>
          </Grid>
          {props.wizardData &&
            props.wizardData.form2 &&
            props.wizardData.form2.form &&
            props.wizardData.form2.form.type !== 'deviceExitGeofence' &&
            props.wizardData.form2.form.type !== 'deviceEnterGeofence' &&
            props.wizardData.form2.form.type !== 'deviceOnline' &&
            props.wizardData.form2.form.type !== 'deviceUnknown' &&
            !props.expirationCheck &&
            props.wizardData.form2.form.type !== 'deviceOffline' && (
              <Grid item md={9} xs={12}>
                <Paper style={paperStyle} elevation={0}>
                  <h4 style={{ marginTop: 0 }}>
                    {props.translate('Geofences')}
                  </h4>
                  <GeofencesModal
                    onlyOneSelection
                    columns
                    height={150}
                    logInUser={props.logInUser}
                    geofencesHandelChange={props.geofencesHandelChange}
                    geoFences={props.form.geoFences}
                    themecolors={props.themecolors}
                    showTwoInList
                  />
                </Paper>
              </Grid>
            )}
        </Grid>
      </div>
      <Grid
        container
        spacing={0}
        style={{ borderTop: `1px solid ${props.themecolors.themeLightColor}` }}
      >
        {props.expirationCheck ? null :
        <Grid item md={6} xs={12}>
          <div style={{ padding: 20 }}>
            <h4 style={{ marginTop: 0 }}>{props.translate('timeControl')}</h4>
            <Screen3 {...props} />
          </div>
        </Grid>}
      </Grid>
    </>
  )
}
export default Modal
