import React, { Component } from 'react'
import SimpleModalWrapped from '../common/Modal'
import { Translate } from 'react-localize-redux'
import Grid from '@material-ui/core/Grid'
import TextField from '../common/TextField'
import SearchDevices from '../Devices/searchItems'
import { withStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from './../common/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CrossIcons from '@material-ui/icons/Remove'
import moment from 'moment'
import { Api, checkPrivileges, plateTypes } from '../../Helpers'
import Select from '../common/Select'
import Notifications from 'react-notification-system-redux'
import WarningIcon from '@material-ui/icons/Warning';
import axios from 'axios'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Button from '../common/Button'
import SingleSelect from '../common/SingleSelect'

const styles = theme => ({})

class addVehicle extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false,
      inquiryResponse: '',
      errorResponse: '',

    }
    this.addAttributes = this.addAttributes.bind(this)
  }

  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }
  uploadFile = (e) => {
    console.log("image =",e.target.files[0])
  }

  handleChange = (a,b) => {
    this.props.handleChangeForAttributesValues(a, {target: b});
  }

  verify = () => {
    this.setState({errorResponse: '', inquiryResponse: ''})
    if(this.props.form.attributes.sequenceNumber) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        hook: 'getVehicle',
        account_id: this.props.serversettings.id,
        data: JSON.stringify({
          sequenceNumber: this.props.form.attributes.sequenceNumber,
          activity: 'DEFAULT'
        })
      }).then(response => {
        
        if(response.status === 200 && response.data && response.data.referenceKey) {
          this.props.handleChangeForAttributesValues('WASLKey', { target: {value: response.data.referenceKey} } );
          this.props.formSubmit();
          this.props.dispatch(
            Notifications.success({
              message: <Translate id='Integrated With Wasl' />,
              autoDismiss: 10
            })
          )
        }

      }).catch(error => {
        if(error.response && error.response.data) {
          if(error.response.data && error.response.data.message) {
            const { message, data } = error.response.data;
            this.setState({ inquiryResponse: data?.resultCode || (message.toLowerCase() === 'bad request' ? 'Not Registered' : message) })
          }
          else {
            const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
              this.setState({ inquiryResponse: resultMsg || resultCode || errorMsg || errorCode })

          }
        }
      })
    }
    else {
      this.props.dispatch(Notifications.error({
        message: <Translate id='Please enter sequence number first' />,
        autoDismiss: 10
      }))
    }
  }

  submitWasl = () => {
    this.setState({errorResponse: '', inquiryResponse: ''})
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    if(waslCompanyRefKey) {
      const { number, leftLetter, rightLetter, plateType, middleLetter, sequenceNumber } = this.props.form.attributes;
      const { uniqueId } = this.props.vehicleTracker;
      if(number && leftLetter && rightLetter && plateType && middleLetter && sequenceNumber && uniqueId) {
        axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
          hook: 'registerVehicle',
          account_id: this.props.serversettings.id,
          data: JSON.stringify({
            uniqueId,
            sequenceNumber,
            activity: 'DEFAULT',
            attributes: {
              rightLetter,
              number,
              leftLetter,
              middleLetter,
              plateType,
            }
          }),
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          axios.get(`${Api}/refreshCache`).then(response => {}).catch(error => {})
          if(response.status === 200 && response.data && response.data.success) {
            if(response.data&&response.data.result && response.data.success &&response.data.result.referenceKey) {
              this.props.handleChangeForAttributesValues('WASLKey', { target: {value: response.data.result.referenceKey} } );
              this.props.formSubmit();
            }
          }
        }).catch(error => {
          if(error.response && error.response.data) {
            const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
              this.setState({ errorResponse: resultMsg || resultCode || errorMsg || errorCode })
              // this.props.handleChangeForAttributesValues('WASLKey', { target: {value:error.response.data&&error.response.data.data&&error.response.data.data.result.referenceKey} } );
              // this.props.formSubmit();
          }
        })
      } else {
        this.setState({errorResponse: 'integration form incomplete'});
      }
    }
    else {
      this.props.dispatch(Notifications.error({
        message: <Translate id='Please Register your company first' />,
        autoDismiss: 10
      }));
    }
  }

  changeIMEI = () => {
    this.setState({errorResponse: '', inquiryResponse: ''})
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    const { WASLKey, sequenceNumber } = this.props.form.attributes;
    const { uniqueId } = this.props.vehicleTracker;
    if(waslCompanyRefKey && WASLKey) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        hook: 'updateVehicle',
        account_id: this.props.serversettings.id,
        data: JSON.stringify({sequenceNumber, imeiNumber: uniqueId, activity: 'DEFAULT'})
      }).then(response => {
        if(response && response.data && response.data.resultCode);
        this.setState({inquiryResponse: response.data.resultCode},()=>this.props.formSubmit())
      }).catch(error => {
        if(error.response && error.response.data) {
          const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
            this.setState({ errorResponse: resultMsg || resultCode || errorMsg || errorCode })
        }
        let errMsg = (error.response&&error.response.data&&error.response.data.data&&error.response.data.data.resultCode) || 'somethingWentWrong'
        this.props.dispatch(
          Notifications.error({
            message: <Translate id={errMsg}/>,
            autoDismiss: 10
          })
        )
      });
      
    }
  }
  
  unlinkWasl = () => {
    this.setState({errorResponse: '', inquiryResponse: ''})
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    const { WASLKey } = this.props.form.attributes;
    
    if(waslCompanyRefKey && WASLKey) {
      axios.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, { 
        hook: 'deleteVehicle',
        account_id: this.props.serversettings.id,
        hook_prefix: waslCompanyRefKey,
        hook_postfix: WASLKey,
        data: {
          activity: 'DEFAULT'
        }
      }).then(response => {
        this.props.handleChangeForAttributesValues('WASLKey', { target: {value: ''} },false );
        this.props.formSubmit();
      }).catch(error => {
        console.log(error);
        if(error.response && error.response.data) {
          const { data: { resultCode, resultMsg, errorMsg, errorCode } } = error.response.data;
            this.setState({ errorResponse: resultMsg || resultCode || errorMsg || errorCode })
        }
        let errMsg = (error.response&&error.response.data&&error.response.data.data&&error.response.data.data.resultCode) || 'somethingWentWrong'
        this.props.dispatch(
          Notifications.error({
            message: <Translate id={errMsg}/>,
            autoDismiss: 10
          })
        )
      })
    }
    else {
      this.props.dispatch(
        Notifications.error({
          message: <Translate id='Please Register your company first' />,
          autoDismiss: 10
        })
      )
    }
  }

  UNSAFE_componentWillReceiveProps (n) {
    if(n.form.id !== this.props.form.id) {
      this.setState({
        inquiryResponse: '',
        errorResponse: ''
      })
    }
  }

  render () {
    const { form: { attributes: { WASLKey } } } = this.props
    const { waslEnabled, waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    return (
      <div style={{ height: '100%' }}>
        <SimpleModalWrapped
          visable={true}
          notShowAttributesBtn
          isButtonVisable={true}
          title='sharedVehicle'
          formSubmit={this.props.formSubmit}
          modalControle={this.props.onClose}
          buttonText={this.props.buttonText}
          btnCloseText='sharedBack'
          isVisableBtn={this.props.isVisableUserBtn}
          selectedItemId={this.props.selectedVehicle.id}
          waslFooter={
            waslEnabled ? (WASLKey ? <><Button onClick={this.changeIMEI}>Change IMEI</Button><Button style={{ marginLeft: 10 }} onClick={this.unlinkWasl} >Un-Link WASL</Button></> : <Button onClick={this.submitWasl}>Link WASL</Button>) : null 
          }
          content={
            <form autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='label'
                    label={<Translate id='label' />}
                    required
                    placeholder=''
                    value={this.props.form.label || ''}
                    onChange={e => this.props.handleChange('label', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vehicleLicensePlate'
                    label={<Translate id='vehicleLicensePlate' />}
                    required
                    placeholder=''
                    value={this.props.form.vehicleLicensePlate || ''}
                    onChange={e =>
                      this.props.handleChange(
                        'vehicleLicensePlate',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='country'
                    label={<Translate id='country' />}
                    placeholder=''
                    value={this.props.form?.attributes?.country || ''}
                    onChange={e => this.props.handleChangeForAttributesValues('country', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='registrationNumber'
                    label={<Translate id='registrationNumber' />}
                    placeholder=''
                    value={this.props.form.attributes.registrationNumber || ''}
                    onChange={e => this.props.handleChangeForAttributesValues('registrationNumber', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='variant'
                    label={<Translate id='variant' />}
                    placeholder=''
                    value={this.props.form.attributes.variant || ''}
                    onChange={e => this.props.handleChangeForAttributesValues('variant', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='wheels'
                    label={<Translate id='wheels' />}
                    placeholder=''
                    value={this.props.form.attributes.wheels || ''}
                    onChange={e => this.props.handleChangeForAttributesValues('wheels', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                   <SingleSelect
                      array={this.props?.trasmissionList || []}
                      selectName='trasmissionType'
                      label={<Translate id='trasmissionType' />}
                        value={this.props.form&&this.props.form.attributes&&this.props.form.attributes.trasmissionType
                          ? { id:this.props.form.attributes.trasmissionType,
                              label:this.props.form.attributes.trasmissionType,
                              value: this.props.form.attributes.trasmissionType,
                            }
                          : ''
                        }
                      handleChange={this.props.handleChangeForAttributesValues}
                      canRemove={true}
                      canAssign={true}
                      />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='make'
                    label={<Translate id='make' />}
                    placeholder=''
                    value={this.props.form.attributes.make || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'make',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='model'
                    label={<Translate id='model' />}
                    placeholder=''
                    value={this.props.form.model || ''}
                    onChange={e => this.props.handleChange('model', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='year'
                    label={<Translate id='year' />}
                    placeholder=''
                    value={this.props.form.attributes.year || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'year',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='color'
                    label={<Translate id='attributeColor' />}
                    placeholder=''
                    value={this.props.form.attributes.color || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'color',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='fuel_type'
                    label={<Translate id='fuel_type' />}
                    placeholder=''
                    value={this.props.form.attributes.fuel_type || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'fuel_type',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='capacity'
                    label={<Translate id='capacity' />}
                    placeholder=''
                    value={this.props.form.attributes.capacity || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'capacity',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='garage'
                    label={<Translate id='garage' />}
                    placeholder=''
                    value={this.props.form.garage || ''}
                    onChange={e => this.props.handleChange('garage', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  style={{ postion: 'relative', zIndex: 2 }}
                >
                  <SearchDevices
                    isMulti={false}
                    api='devices'
                    isClearable
                    onChange={this.props.onChangedDevice}
                    value={this.props.vehicleTracker}
                    placeholder={<Translate id='searchUnits' />}
                  />
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vehicleType'
                    label={<Translate id='sharedType' />}
                    placeholder=''
                    value={this.props.form.vehicleType || ''}
                    onChange={e => this.props.handleChange('vehicleType', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='vin'
                    label={<Translate id='vin' />}
                    placeholder=''
                    value={this.props.form?.vin || ''}
                    onChange={e => this.props.handleChange('vin', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='chassisNumber'
                    label={<Translate id='chassisNumber' />}
                    placeholder=''
                    value={this.props.form.attributes.chassisNumber || ''}
                    onChange={e =>
                      this.props.handleChangeForAttributesValues(
                        'chassisNumber',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                {/* start          -----------pending task from backEnd--------- Expiration Date*/}
                <Grid item md={4} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      error={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf('day').toISOString()
                      }
                      helperText={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf('day').toISOString()
                          ? <Translate id='licenseExpired' />
                          : ''
                      }
                      margin='dense'
                      label={<Translate id='V/LExpirationTime' />}
                      variant='outlined'
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.expirationTime
                          ? new Date(
                              `${this.props.form.expirationTime}`
                            ).toString()
                          : null
                      }
                      onChange={this.props.handleChangeLicenseExpDate('expirationTime')}
                      InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        },
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Tooltip title={<Translate id='noLimit' />}>
                              <IconButton
                                onClick={e => this.props.setExpirationTime(e)}
                                style={{ color: 'inherit' }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        )
                      }}
                      InputLabelProps={{
                        classes: {
                          root: 'theme-label',
                          focused: 'theme-label-focused',
                          shrink: 'theme-label-shrink'
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {checkPrivileges('area') ?
                <Grid item md={4} sm={6} xs={12}>
                   <SingleSelect
                      array={this.props?.areaList || []}
                      selectName='areaId'
                      label={<Translate id='sharedArea' />}
                        value={this.props.form&&this.props.form.areaId&&this.props.form.areaId.key
                          ? {
                              id: this.props.form.areaId.key,
                              value: this.props.form.areaId.key,
                              label: this.props.form.areaId.name,
                              uniqueId:this.props.form.areaId.uniqueId,
                            }
                          : ''
                        }
                        // value={this.props.form.vehicleType || ''}
                      // onChange={e => this.props.handleChange('vehicleType', e)}
                      handleChange={this.props.handleChange}
                      canRemove={true}
                      canAssign={true}
                      />
                </Grid>
                :null}
                  {/*---end--- */}

                  {/* ------state-------------upload pdf file-----pending task from backEnd
                <Grid item md={4} sm={6} xs={12}>
                  <Tooltip title='Upload pdf'>
                    <Button
                      style={{ float: 'right', maxWidth: '40px',minWidth: '40px' }}
                      variant='contained'
                      margin='dense'
                      component="label"
                      onChange={e => this.uploadFile(e)}
                    >
                      <PublishIcon  style={{ color: '#fff'}}/>
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                ----end---- */}
                {/* <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='permittedSpeed'
                    type='number'
                    label={<Translate id='permittedSpeed' />}
                    placeholder=''
                    value={this.props.form.attributes.permittedSpeed || ''}
                    onChange={e =>
                      this.props.handleChangeForAttributesValues(
                        'permittedSpeed',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                  <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className='with-border'>
                    {<Translate id='tags' />}
                  </h3>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {this.props.form.attributes.tags}
                  <TextField
                    id='tag_1'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt1
                        ? this.props.logInUsers.attributes.vt1
                        : <Translate id='tag_1' />
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_1 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_1',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_2'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt2
                        ? this.props.logInUsers.attributes.vt2
                        : <Translate id='tag_2' />
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_2 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_2',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_3'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt3
                        ? this.props.logInUsers.attributes.vt3
                        : <Translate id='tag_3' />
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_3 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_3',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_4'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt4
                        ? this.props.logInUsers.attributes.vt4
                        : <Translate id='tag_4' />
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_4 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_4',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_5'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt5
                        ? this.props.logInUsers.attributes.vt5
                        : <Translate id='tag_5'/>
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_5 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_5',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              
                <Grid item xs={12}>
                  <h3 style={{ margin: 0 }} className='with-border'>
                    {<Translate id='integration' />}
                  </h3>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <label style={{position: 'static'}}>{<Translate id='sequenceNumber' />}</label>
                  <TextField
                    id='sequenceNumber'
                    label={<Translate id='sequenceNumber' />}
                    placeholder=''
                    value={this.props.form.attributes.sequenceNumber || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'sequenceNumber',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                  <small><em>{this.state.inquiryResponse}</em></small>
                </Grid>
                <Grid item md={8} sm={6} xs={12}>
                    <label style={{position: 'static', marginBottom: 8, display: 'block'}}>&nbsp;</label>
                    {waslEnabled && waslCompanyRefKey ? <Button onClick={this.verify}>Check already registered</Button> : null}
                </Grid>
                <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 0}}>
                  <h4 style={{marginBottom: 0}}>{<Translate id='vehicleLicensePlate' />}</h4>
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 8}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='plateType' />}</label>
                  <Select
                    key='plateType'
                    array={plateTypes}
                    multi={this.props.form.attributes.plateType && plateTypes.filter(e => this.props.form.attributes.plateType == e.id)}
                    onChange={e=>this.handleChange('plateType', e)}
                    canAssign={true}
                    canRemove={true}
                  />
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 6}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='leftLetter' />}</label>
                  <TextField
                    id='leftLetter'
                    label={null}
                    placeholder=''
                    value={this.props.form.attributes.leftLetter || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'leftLetter',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 4}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='middleLetter' />}</label>
                  <TextField
                    id='middleLetter'
                    label={null}
                    placeholder=''
                    value={this.props.form.attributes.middleLetter || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'middleLetter',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 2}} md={2} sm={6} xs={12}>
                  <label>{<Translate id='rightLetter' />}</label>
                  <TextField
                    id='rightLetter'
                    label={null}
                    placeholder=''
                    value={this.props.form.attributes.rightLetter || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'rightLetter',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <label>{<Translate id='number' />}</label>
                  <TextField
                    id='number'
                    label={null}
                    placeholder=''
                    value={this.props.form.attributes.number || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'number',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                
              </Grid>
              {this.state.errorResponse ? <p>WASL ERROR: {this.state.errorResponse}</p>: null}
              {this.props.vehicleStatus ? <ul className="process-list">
                <li>Vehicle Status: {this.props.vehicleStatus}</li>
            </ul> : null}
            </form>
          }
        />
      </div>
    )
  }
}

export default addVehicle