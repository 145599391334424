import React from 'react'
import { Route } from 'react-router-dom'
import Header from './../Layout/Header'

const CustomRoute = props => {
  return (
    <>
      <Header {...props} />
      <Route {...props} />
    </>
  )
}

export default CustomRoute
