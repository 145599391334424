import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import Checkbox from '../../Components/common/Checkbox'
import Button from '../../Components/common/Button'
import { addGarages, getGarages } from '../../Actions/Garages'
import Notifications from 'react-notification-system-redux'
import Grid from '@material-ui/core/Grid'
import EmptyState from '../../Components/common/EmptyState'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import instance from '../../axios'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import EditIcon from '@material-ui/icons/Place'
import IconButton from '@material-ui/core/IconButton'
import CustomDialog from '../../Components/common/Dialog'
import MarkerMap from '../../Components/common/Maps'
import Tooltip from '../../Components/common/Tooltip'
import { removedGarages } from '../../Actions/Garages'
import axios from 'axios'

const CancelToken = axios.CancelToken
let source

class Garage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      garageForm: {
        map:'osm',
        latitude:'',
        longitude:'',
      },
      mode: '',
      isVisableSubmitBtn: true,

      model: false,
      assignMode: '',
      currentCommand: '',
      entityId: 0,

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      searchText:"",
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 1,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      deleteForm: {},
      garagesS:[],
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
    }
  }

  componentWillUnmount () {
    this.setState({
      garageForm: {},
      mode: '',
      model: false,
      assignMode: '',
      currentCommand: '',
      entityId: 0,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 1,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }

  handleChangeLicenseExpDate = name => event => {
    if(name === 'created'){
      this.setState(
        {
          garageForm: {
            ...this.state.garageForm,
              [name]: event.toISOString()
            
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  fetchMoreItems = (a, b, c) => {
    let pagination
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      pagination=true
    )
  }

  searchItems = text => {
    let pagination
    if(source){
        source.cancel()
      }
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchData(this.props.logInUser,1,50,pagination=true)
      }
    )
  }

  fetchGarages = (text, garages) => {
    const u = garages&&garages.data.filter(u =>
      (u.id + u.name).toLowerCase().includes(text.toLowerCase())
    )
    this.setState(
      {
        garagesS:u
      }
    )
  }

  fetchData = (logInUser, page, perPage, pagination) => {
    source = CancelToken.source()
    // let searchText = this.state.searchText
    // if (searchText) {
    //   searchText = '&search=' + searchText
    // } else {
    //   searchText = ''
    // }
    let items = this.state.itemPagination&&this.state.itemPagination.items
    // this.setState(
    //   {
    //     itemPagination: {
    //       ...this.props.garages,
    //       items: items.concat(this.props.garages.data)
    //     },
    //     currentPage: this.props.garages.hasNext ? this.props.garages.page + 1 : this.props.garages.page,
    //     garagesS:this.props.garages.data
    //   })


     instance({
      method: 'GET',
      url: `/api/garages/get?userId=${logInUser.id}&page=${page}&limit=${perPage}&all=${true}&search=${this.state.searchText}`,
      cancelToken: source.token
    })
        .then(res => {
          this.setState(
            {
              itemPagination: {
                ...res,
                items: items.concat(res.data)
              },
              currentPage: res.hasNext ? res.page + 1 : res.page,
              garagesS:pagination?items.concat(res.data):res.data
            },
            () => {
              this.props.dispatch(getGarages(res))
            }
          )
 
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }


  openCreateFrom = () => {
    this.setState({ mode: '', garageForm: {} }, () => {
      this.setState({
        mode: 'create',
        garageForm: {
          attributes: {},
          created:" ",
          modified:null,
          parentId:1,
          directAccess: true,
          groupAccess: true,
        }
      })
    })
  }

  editCommandForm = garageForm => {
    this.setState({ mode: '', garageForm: {} }, () => {
      this.setState({
        mode: 'update',
        garageForm: {
          ...garageForm,
          attributes: { ...garageForm.attributes },
        }
      })
    })
  }

  closeCreateFrom = () => {
    this.setState({
      mode: '',
      garageForm: ''
    },
    () => this.checkRequiredFields())
  }
 

  handleChange = (name, value) => {
      this.setState({
        garageForm: {
          ...this.state.garageForm,
          attributes:{},
          [name]: value
        }
      },
      () => this.checkRequiredFields())
  }
  checkRequiredFields = () => {
    // let { name,description,email,phone,garageArea,garageManager,garageNumber, latitude,longitude,address } = this.state.garageForm
    // if (name&&description&&email&&phone&&garageArea&&garageManager&&garageNumber&& latitude&&longitude&&address) {
    let { name} = this.state.garageForm
    if (name){
      this.setState({
        isVisableSubmitBtn: false
      })
    } else {
      this.setState({
        isVisableSubmitBtn: true
      })
    }
  }



  submitForm = () => {
    let {
      attributes,
      id,
      description,
    } = this.state.garageForm
    let obj = {...this.state.garageForm}

    let params = ''
    if (this.state.mode === 'update') {
      params = id
    }
    this.setState({
      isVisableSubmitBtn: true
    })
    if (obj) {
      instance({
        url: `/api/garages/${params}`,
        method: `${this.state.mode === 'update' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
          ...obj
        }
      })
          .then(garage => {
            if (this.state.mode === 'update') {
              this.props.dispatch(addGarages(garage))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('garageIsUpdated'),
                  autoDismiss: 10
                })
              )
              this.closeCreateFrom()
            } else {
              this.props.dispatch(addGarages(garage))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('garageIsCreated'),
                  autoDismiss: 10
                })
              )
              this.editCommandForm(garage)
            }
            this.fetchData(this.props.logInUser,1,50)
      }).catch(error => {
         console.log('error ===', error) 
        errorHandler(error, this.props.dispatch)})
    }
  }

  onDelete = (id) => {
    if (id) {
      instance({
        url: `api/garages/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.props.dispatch(removedGarages({ id:id }))
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('GaragesIsDeleted'),
              autoDismiss: 10
            })
          )
          // this.props.modeEmpty()
          // this.setState({ item: {} })
          this.fetchData(this.props.logInUser)
          this.closeCreateFrom()
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  onRemovedItem = item => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

  handleClickShowPostion = ()=>{
        this.setState({
          isMapModal: true,
          isVisableAddlocBtn: false
    })
  }

  //loc
  getPostion = (latlng) => {
    if (latlng.lat && latlng.lng ) {
      this.setState({
        localPostionObj: { latlng },
        isVisableAddlocBtn: true
      })
    }
  }
  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
      this.setState(
        {
          garageForm: {
            ...this.state.garageForm,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
          },
          isMapModal: false
        },
        () => this.checkRequiredFields()
      )
    }
  }
onCloseModal = () => {
    this.setState({
      isMapModal: false
    })
  }
 

  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {this.props.translate('notFound')}
        </p>
      )

    if (checkPrivileges('garage')) {
      const pp = {
        ...this.props,
        garagesS: this.state.garagesS,
      }
      return (
        <div>
          <Layout
            {...pp}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            classFromChildren='has-padding'
            editCommandForm={this.editCommandForm}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            deleteItem={this.onDelete}
            {...this.state}
          >
            <div className='main-content-page'>
            {/* {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removeItem}
                title={this.props.translate('areYouWantToDelete')}
                children={this.state.deleteForm.name || this.state.deleteForm.description}
              />
            )} */}
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >
                {this.state.mode === 'create' ||
                this.state.mode === 'update' ? (
                  <CreateGarageForm
                    submitForm={this.submitForm}
                    mode={this.state.mode}
                    closeCreateFrom={this.closeCreateFrom}
                    form={this.state.garageForm}
                    handleChange={this.handleChange}
                    handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
                    isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                    translate={this.props.translate}
                    handleClickShowPostion={this.handleClickShowPostion}
                    setSelectedLocation={this.setSelectedLocation}
                    getPostion={this.getPostion}
                    onCloseModal={this.onCloseModal}
                    isVisableAddlocBtn={this.state.isVisableAddlocBtn}
                    isMapModal={this.state.isMapModal}
                    themeColors={this.props.themecolors}
                  />
                ) : null}
                {this.state.mode === '' ? (
                  <EmptyState
                    text={this.props.translate('noGargeSelected')}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}

const CreateGarageForm = ({
  form,
  handleChange,
  translate,
  handleChangeLicenseExpDate,
  handleClickShowPostion,
  submitForm,
  setSelectedLocation,
  getPostion,
  onCloseModal,
  themeColors,
  isVisableAddlocBtn,
  isMapModal,
  closeCreateFrom,
  mode,
  isVisableSubmitBtn
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('garage')}</h4>
      {isMapModal && (
          <CustomDialog
            title={translate('setLocation')}
            themecolors={themeColors}
            visable={true}
            onClose={()=>onCloseModal()}
            isVisableBtn={true}
            draggable={true}
            CancelText={translate('sharedBack')}
            headerActions={
              <Button
                size='small'
                variant='contained'
                color='secondary'
                disabled={!isVisableAddlocBtn}
                onClick={()=>setSelectedLocation()}
              >
                {' '}
                {translate('sharedAdd')}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={(latlng)=>getPostion(latlng)}
              minHeight={500}
              zoom={form.zoom || 14}
              lat={form.latitude || 0}
              lng={form.longitude || 0}
              height={'100%'}
            />
          </CustomDialog>
        )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='name'
            required
            margin='dense'
            label={translate('sharedName')}
            variant='outlined'
            fullWidth
            value={form.name}
            onChange={e => handleChange('name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='description'
            margin='dense'
            label={translate('sharedDescription')}
            variant='outlined'
            fullWidth
            value={form.description}
            onChange={e => handleChange('description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='garageNumber'
            type='number'
            margin='dense'
            label={translate('garageNumber')}
            variant='outlined'
            fullWidth
            value={form.garageNumber}
            onChange={e => handleChange('garageNumber', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='garageArea'
            margin='dense'
            label={translate('garageArea')}
            variant='outlined'
            fullWidth
            value={form.garageArea}
            onChange={e => handleChange('garageArea', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='garageManager'
            margin='dense'
            label={translate('garageManager')}
            variant='outlined'
            fullWidth
            value={form.garageManager}
            onChange={e => handleChange('garageManager', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='email'
            margin='dense'
            label={translate('userEmail')}
            variant='outlined'
            fullWidth
            value={form.email}
            onChange={e => handleChange('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='phone'
            margin='dense'
            label={translate('sharedPhone')}
            variant='outlined'
            fullWidth
            value={form.phone}
            onChange={e => handleChange('phone', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='address'
            margin='dense'
            label={translate('address')}
            variant='outlined'
            fullWidth
            value={form.address}
            onChange={e => handleChange('address', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='latitude'
            type='number'
            margin='dense'
            label={translate('positionLatitude')}
            placeholder=''
            variant='outlined'
            fullWidth
            value={form.latitude?form.latitude:''}
            onChange={e => handleChange('latitude', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='longitude'
            type='number'
            margin='dense'
            label={translate('positionLongitude')}
            placeholder=''
            variant='outlined'
            fullWidth
            value={form.longitude?form.longitude:''}
            onChange={e => handleChange('longitude', e.target.value)}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
            <Tooltip title={translate('addLocation')}>
              <IconButton onClick={()=>handleClickShowPostion()}>
                {<EditIcon />}
              </IconButton>
            </Tooltip>
        </Grid>
        {/* <Tooltip title={this.props.translate('addLocation')}>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip> */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                margin='dense'
                label={translate('created')}
                variant='outlined'
                fullWidth
                value={form.created? new Date( `${form.created}` ).toString() : null}
                onChange={handleChangeLicenseExpDate('created')}
                InputProps={{
                  classes: {
                    root: 'theme-cssOutlinedInput',
                    input: 'theme-input',
                    focused: 'theme-cssFocused',
                    notchedOutline: 'theme-notchedOutline'
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: 'theme-label',
                    focused: 'theme-label-focused',
                    shrink: 'theme-label-shrink'
                  }
                }}
              />
          </MuiPickersUtilsProvider>
        </Grid> */}

        

        {/* <Grid item xs={12}>
            <Checkbox
              checked={form.attributes.enable}
              onChange={e => handleChangeAttributes('archive', e.target.checked)}
              color='primary'
              label={translate('archive')}
              name='enable'
            />
          </Grid> */}
    
      </Grid>

      <div style={{ display: 'flex', marginTop: 10 }}>
        <div style={{ marginLeft: 'auto' }} />
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeCreateFrom}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('garageCreate') && (
          <Button
            size='small'
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === 'create' ? 'sharedCreate' : 'update')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  commands: state.commands,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  devices3: state.devices3,
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  garages:state.garages
})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Garage)))

