import React from 'react'
import Grid from '@material-ui/core/Grid'
import Radio from '../../../common/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '../../../common/TextField'
import Switch from '../../../common/Switch'
import Tooltip from '../../../common/Tooltip'
import { ReactComponent as AddNewIcon } from '../../../../assets/add-new.svg'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Collapse from '@material-ui/core/Collapse'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import { CompactPicker } from 'react-color'
import { IconButton } from '@material-ui/core'
import SingleSelect from '../../../common/SingleSelect'
import Button from '../../../common/Button'
import ReportIcon from '@material-ui/icons/Report';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ErrorIcon from '@material-ui/icons/Error';

import WarningIcon from '@material-ui/icons/Warning';
const Modal = props => {
  const paperStyle = {
    background: 'none',
    marginBottom: 5,
    paddingTop: 0,
    paddingBottom: 0
  }
  let createdCommands=[];
  props&&props.commands.map(e => {
    createdCommands.push({
      id: e.id,
      key: e.id,
      name: e.description,
      valueType: 'string',
    })}
  )
  return (
    <div style={{ padding: 20, minHeight: 270 }}>
      
      <Grid sm={5} xs={12}>
        <Paper
          className={' ' + (props.isOpenWebSection ? 'opened' : '')}
          style={paperStyle}
          elevation={0}
        >
          <h4
            className='page-title-notification'
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              padding: 0
            }}
          >
            <span>{props.translate('webPopUp')}</span>
            <FormControlLabel
              style={{ marginLeft: 'auto' }}
              control={
                <Switch
                  checked={props.isOpenWebSection}
                  onChange={() => props.handleClick('isOpenWebSection')}
                  name='checkedA'
                />
              }
              label={
                props.isOpenWebSection
                  ? props.translate('enable')
                  : props.translate('disable')
              }
              labelPlacement='start'
              classes={{ label: 'color-inherit' }}
            />
          </h4>
          <Collapse in={props.isOpenWebSection}>
            <Grid container>
              {/* <Grid item xs={12}>
                <TextField
                  id='selectSound'
                  label={props.translate('selectSound')}
                  //required={true}
                  placeholder=''
                  value={props.form.alertSound}
                  onChange={e => props.handleChange('alertSound', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  disabled={!props.isOpenWebSection}
                />
              </Grid> */}
              <Grid item xs={12}>
                <div style={{ marginBottom: 16 }}>
                  <label>{props.translate('selectColor')}</label>
                  <div
                    className='colorPicker-trigger'
                    style={{
                      background:
                        props.form && props.form.alertColor
                          ? props.form.alertColor
                          : 'red',
                      display: 'inline-block',
                      width: 18,
                      height: 18,
                      marginLeft: 10,
                      verticalAlign: 'middle',
                      position: 'relative',
                      zIndex: 9,
                      borderRadius: '50%',
                      pointerEvents: props.isOpenWebSection ? 'auto' : 'none'
                    }}
                    onClick={props.showColorPicker}
                  >
                    {props.colorPicker && (
                      <div
                        style={{ position: 'absolute', top: 0, left: '-50%' }}
                        onMouseLeave={props.hideColorPicker}
                      >
                        <CompactPicker
                          color={props.form.alertColor}
                          onChangeComplete={props.updateColor}
                          //disabled={!props.isOpenWebSection}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
      <Grid sm={5} xs={12}>
        <Paper className='' style={paperStyle} elevation={0}>
          <h4
            className='page-title-notification'
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              padding: 0
            }}
          >
            <span>{props.translate('mobileNotifications')}</span>
            <FormControlLabel
              style={{ marginLeft: 'auto' }}
              control={
                <Switch
                  checked={props.isOpenFirebaseSection}
                  onChange={() => props.handleClick('isOpenFirebaseSection')}
                  name='checkedA'
                />
              }
              label={
                props.isOpenFirebaseSection
                  ? props.translate('enable')
                  : props.translate('disable')
              }
              labelPlacement='start'
              classes={{ label: 'color-inherit' }}
            />
          </h4>
        </Paper>
      </Grid>
      
      {/* <Grid sm={4} xs={12}>
        <Paper
          className={' ' + (props.isOpenSmsSection ? 'opened' : '')}
          style={paperStyle}
          elevation={0}
        >
          <h4
            className='page-title-notification'
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              padding: 0
            }}
          >
            <span>{props.translate('SMS')}</span>
            <FormControlLabel
              style={{ marginLeft: 'auto' }}
              control={
                <Switch
                  checked={props.isOpenSmsSection}
                  onChange={() => props.handleClick('isOpenSmsSection')}
                  name='isOpenSmsSection'
                />
              }
              label={
                props.isOpenSmsSection
                  ? props.translate('enable')
                  : props.translate('disable')
              }
              labelPlacement='start'
              classes={{ label: 'color-inherit' }}
            />
          </h4>
          <Collapse in={props.isOpenSmsSection}>
            <Grid container>
              <Grid item xs={12}>
                {props.form.number.map(item => {
                  return (
                    <div style={{ display: 'flex' }} key={item.id}>
                      <TextField
                        id='number'
                        label={props.translate('commandPhone')}
                        placeholder=''
                        value={item.text || ''}
                        onChange={e => props.handleChange('number', e, item.id)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        disabled={!props.isOpenSmsSection}
                      />
                      {item.id === props.form.number.length && (
                        <Tooltip title={props.translate('addAnother')}>
                          <span>
                            <IconButton
                              color='inherit'
                              aria-label='directions'
                              onClick={e => props.onAddAnotherMail('number')}
                              disabled={!props.isOpenSmsSection}
                            >
                              <AddNewIcon
                                width={18}
                                height={18}
                                style={{ fill: 'currentColor' }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                      {item.id < props.form.number.length && (
                        <Tooltip title={props.translate('addAnother')}>
                          <span>
                            <IconButton
                              color='inherit'
                              aria-label='directions'
                              onClick={e => props.deleteMail('number', item.id)}
                              disabled={!props.isOpenSmsSection}
                            >
                              <DeleteIcon
                                width={18}
                                height={18}
                                style={{ fill: 'currentColor' }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  )
                })}
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </Grid> */}


      <Grid sm={5} xs={12}>
        <Paper
          className={' ' + (props.isOpenEmailSection ? 'opened' : '')}
          style={paperStyle}
          elevation={0}
        >
          <h4
            className='page-title-notification'
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              padding: 0
            }}
          >
            <span>{props.translate('emails')}</span>
            <FormControlLabel
              style={{ marginLeft: 'auto' }}
              control={
                <Switch
                  checked={props.isOpenEmailSection}
                  onChange={() => props.handleClick('isOpenEmailSection')}
                  name='isOpenEmailSection'
                />
              }
              label={
                props.isOpenEmailSection
                  ? props.translate('enable')
                  : props.translate('disable')
              }
              labelPlacement='start'
              classes={{ label: 'color-inherit' }}
            />
          </h4>
          <Collapse in={props.isOpenEmailSection}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl
                  component='fieldset'
                  style={{ width: 'max-content' }}
                >
                  <RadioGroup
                    row
                    aria-label='position'
                    name='position'
                    value={props.form.mailTo}
                    onChange={e => props.handleChange('mailTo', e)}
                  >
                    <FormControlLabel
                      classes={{
                        label: 'form-contol-label'
                      }}
                      value='user'
                      control={<Radio />}
                      label={props.translate('userEmail')}
                      labelPlacement='end'
                      disabled={!props.isOpenEmailSection}
                    />
                    <FormControlLabel
                      classes={{
                        label: 'form-contol-label'
                      }}
                      value='custom'
                      control={<Radio />}
                      label={props.translate('customEmails')}
                      labelPlacement='end'
                      disabled={!props.isOpenEmailSection}
                    />
                    {/* <FormControlLabel
                        classes={{
                          label: 'form-contol-label'
                        }}
                        value='both'
                        control={<Radio />}
                        label={props.translate('BOTH')}
                        labelPlacement='end'
                         disabled={!props.isOpenEmailSection}
                      /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>
          

              {props.form.mailTo !== 'user' && (
                <Grid item xs={12}>
                 {props.form.email.map((item, index) => {
                  const isValidMail = props.validMail[index];
                  return (
                    <Grid container  spacing={2} key={item.id}>
                       <Grid item xs={6} style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                      <TextField
                        id='email'
                        label={props.translate('userEmail')}
                        placeholder=''
                        value={item.text || ''}
                        onChange={(e) => props.handleChange('email', e, item.id)}
                        disabled={!props.isOpenEmailSection}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                    </Grid>
                    

                    <Grid item xs={1} style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                      {item.id === props.form.email.length && (
                        <Tooltip title={props.translate('addAnother')}>
                          <span>
                            <IconButton
                              color='inherit'
                              aria-label='directions'
                              onClick={(e) => props.onAddAnotherMail('email', index)}
                              disabled={!props.isOpenEmailSection}
                            >
                              <AddNewIcon
                                width={18}
                                height={18}
                                style={{ fill: 'currentColor' }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>



                    <Grid item xs={1} style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                      {1 < props.form.email.length && (
                        <Tooltip title={props.translate('delete')}>
                          <span>
                            <IconButton
                              color='inherit'
                              aria-label='directions'
                              onClick={(e) => props.deleteMail('email', item.id)}
                              disabled={!props.isOpenEmailSection}
                            >
                              <DeleteIcon
                                width={18}
                                height={18}
                                style={{ fill: 'currentColor' }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>


                    <Grid item xs={1} style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                      {isValidMail === true ? (
                        <Tooltip title={props.translate('alreadyVerified')}>
                          <span>
                            <IconButton
                              color='default'
                              aria-label='directions'
                              disabled={!props.isOpenEmailSection}
                              style={{ color: 'lightGreen' }}
                            >
                              <VerifiedUserIcon
                                width={30}
                                height={22}
                                // color="green"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      // ) :  isValidMail === false ? (
                        ) :  (
                        <Tooltip title={props.translate('unVerified')}>
                          <span>
                            <IconButton
                              color='default'
                              aria-label='directions'
                              disabled={!props.isOpenEmailSection}
                              // style={{ color: 'e' }}
                                // color="error"

                            >
                              <ErrorIcon
                                  width={30}
                                  height={22}
                                 color="error"
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) }
                    </Grid>

                    {props.validateSingleEmail(item.text)?
                    <Grid item xs={3} style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                      
                    {!isValidMail && (
                     <Button onClick={() => props.submitValidEmail(item.text)}>{props.translate('validate')}  </Button> 
                    )}
                    </Grid>:null}


                    </Grid>
                  );
                })}

                </Grid>
              )}
              {/* <Grid item xs={12}>
                <TextField
                  id='emailTemplate'
                  label={props.translate('emailTemplate')}
                  //required={true}
                  multiline
                  rows={5}
                  rowsMax={20}
                  placeholder=''
                  value={props.form.emailTemplate}
                  onChange={e => props.handleChange('emailTemplate', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid> */}
            </Grid>
          </Collapse>
        </Paper>
      </Grid>



      {props.expirationCommandCheck ? null :
       <Grid sm={5} xs={12}>
        <Paper
          className={' ' + (props.isOpenCommandSection ? 'opened' : '')}
          style={paperStyle}
          elevation={0}
        >
          <h4
            className='page-title-notification'
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: 0,
              padding: 0
            }}
          >
            <span>{props.translate('sharedSavedCommands')}</span>
            <FormControlLabel
              style={{ marginLeft: 'auto' }}
              control={
                <Switch
                  checked={props.isOpenCommandSection}
                  onChange={() => props.handleClick('isOpenCommandSection')}
                  name='isOpenCommandSection'
                />
              }
              label={
                props.isOpenCommandSection
                  ? props.translate('enable')
                  : props.translate('disable')
              }
              labelPlacement='start'
              classes={{ label: 'color-inherit' }}
            />
          </h4>
          <Collapse in={props.isOpenCommandSection}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  component='fieldset'
                  style={{ width: 'max-content' }}
                >
                  <RadioGroup
                    row
                    aria-label='position'
                    name='position'
                    value={props.form.commandType}
                    onChange={e => props.handleChange('commandType', e)}
                  >
                    <FormControlLabel
                      classes={{
                        label: 'form-contol-label'
                      }}
                      value='command'
                      control={<Radio />}
                      label={props.translate('sharedSavedCommands')}
                      labelPlacement='end'
                      disabled={!props.isOpenCommandSection}
                    />
                    <FormControlLabel
                      classes={{
                        label: 'form-contol-label'
                      }}
                      value='custom'
                      control={<Radio />}
                      label={props.translate('commands.custom')}
                      labelPlacement='end'
                      disabled={!props.isOpenCommandSection}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {props.form.commandType === 'command' && (
                <Grid item xs={12}>
                      <div style={{ display: 'flex' }} 
                      // key={item.id}
                      > 
                        <Grid item xs={12}>
                          <SingleSelect
                            array={createdCommands}
                            selectName='type'
                            label={'sharedType'}
                            value={
                              (props.form.commandId !== 0)
                                ? {
                                    key: props.form.commandId,
                                    id: props.form.commandId,
                                    value: props.form.commandId,
                                    label: props.form.commandName
                                  }
                                : ''
                            }
                            handleChange={
                              props.handleChange
                            }
                            canRemove={true}
                            canAssign={true}
                          />
                        </Grid>
                      </div>
                </Grid>
              )}
              {props.form.commandType === 'custom' && (
                <Grid item xs={12}>
                      <div style={{ display: 'flex' }} 
                      // key={item.id}
                      >
                        <TextField
                          id='command'
                          label={props.translate('commands.custom')}
                          placeholder=''
                          value={props.form.commandData || ''}
                          onChange={e =>
                            props.handleChange('commandData', e)
                          }
                          disabled={!props.isOpenCommandSection}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </div>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Paper>
      </Grid>
      }
      {/* <Grid item sm={6} xs={12}>
          <h4 className='page-title-notification'>{props.translate('mobile)}
           <Tooltip
              title={props.translate('openthisSectionWillBeConsiderSelection')}
            >
            <span
              style={{ verticalAlign: 'middle', display: 'inline-flex' }}
              onClick={() => props.handleClick('isOpenMobileSection')}
            >
              {props.isOpenMobileSection ? <ExpandLess /> : <ExpandMore />}
            </span>
            </Tooltip>
            </h4>
             <Collapse in={props.isOpenMobileSection} timeout='auto' unmountOnExit>
          <>
            <Grid item sm={6} xs={12}>
              <SearchDevices
                api='users'
                fill
                isClearable
                onChange={props.onChangedUsers}
                //value={props.deviceFullData}
              />
            </Grid>
          </>
           </Collapse>
        </Grid> */}
    </div>
  )
}
export default Modal
