import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import CTab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withLocalize } from 'react-localize-redux'
import {
  checkPrivileges,
  errorHandler,
  PaginationConfig,
  postionAttributesTypeComp,
  selectAll
} from '../../Helpers'
import isEqual from 'react-fast-compare'
import Notifications from 'react-notification-system-redux'
import ComputedAttributes from './Components/computedAttributes'
import ReactHtmlParser from 'react-html-parser'

/* ACTIONS */
import { addGeoFence, deleteGeofence } from '../../Actions/Devices'
import {
  getDriverDeviceWise,
  addDriverDeviceWiseSelectedList,
  driverDeviceWiseSelectedList,
  RemoveDriverDeviceWise,
  updateDriverDeviceWiseSelectedList,
  addDriverDeviceWise,
  removeDriverDeviceWiseSelectedList,

  getTrailerDeviceWise,
  addTrailerDeviceWiseSelectedList,
  trailerDeviceWiseSelectedList,
  RemoveTrailerDeviceWise,
  updateTrailerDeviceWiseSelectedList,
  addTrailerDeviceWise,
  removeTrailerDeviceWiseSelectedList,


  getVehicleDeviceWise,
  addVehicleDeviceWiseSelectedList,
  vehicleDeviceWiseSelectedList,
  RemoveVehicleDeviceWise,
  updateVehicleDeviceWiseSelectedList,
  addVehicleDeviceWise,
  removeVehicleDeviceWiseSelectedList,


  addNotification,
  updateUserDeviceWiseSelectedList,
  addUserDeviceWiseSelectedList,
  addGeofenceDeviceWiseSelectedList,
  removeGeofenceDeviceWiseSelectedList,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  notificationDeviceWiseSelectedList,
  getNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
  getGeofenceDeviceWise,
  geofenceDeviceWiseSelectedList,
  updateGeofenceDeviceWiseSelectedList,
  RemoveGeofenceDeviceWise,
  addGeofenceDeviceWise,
  addDeviceUserWise,
  RemoveDeviceUserWise,
  getDeviceUserWise,
  deviceUserWiseSelectedList,
  updateDeviceUserWiseSelectedList,
  removeDeviceUserWiseSelectedList,
  addDeviceUserWiseSelectedList,
  removeUserUsereWise
} from '../../Actions/Notifications'
import {
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  getComputedAttributeDeviceWise,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList
} from '../../Actions/ComputedAttributes'
import {
  addCommands,
  getCommandsDeviceWise,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList
} from '../../Actions/Commands'
import {
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList
} from '../../Actions/Maintenance'
import { removeDevice, addDevices } from './../../Actions/Devices'
//import { removeGroup } from './../../Actions/Groups';
import { addDriver, removeDriver } from './../../Actions/Drivers'
import { addTrailer, removeTrailer } from './../../Actions/Trailer'
import { addvehicle, removedvehicle } from './../../Actions/Vehicles'
import { garageUsersWiseSelectedList, updategarageUsersWiseSelectedList ,removedGarages, getGarages} from './../../Actions/Garages'
/* ACTIONS END */

// common sections
import Notification from './Components/notification'
import CommonTableSection from './Components/commonTableSection'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import SavedCommands from './Components/savedCommands'
import MaintenanceForm from './Components/maintenance'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { GroupsModal } from '../../Components/Groups/groupsModal'
import Loader from '../../Layout/Loader'
import instance from '../../axios'
import { areaUsersWiseSelectedList, updateAreaUsersWiseSelectedList } from '../../Actions/Areas'
import { serviceUsersWiseSelectedList, updateServiceUsersWiseSelectedList } from '../../Actions/Services'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexGrow: 1
    }
  },
  tabs: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  scrollButtons: {
    color: '#333'
  },
  indicator: {
    backgroundColor: '#333'
  }
})

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: 48,
      flexDirection: 'column',
      paddingTop: 10
    }
  },
  root: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      minWidth: 250,
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  indicator: {
    display: 'none'
  },
  scrollable: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto'
    }
  }
}))(Tabs)

function a11yProps (index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ color: 'inherit', ...other.style }}
    >
      {children}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

// --> parking form

const unitParkingAttFormat = {
  'parking.minIdleTime': 0,
  'parking.maxIdleSpeed': 0
}

const NotificationDataFormat = {
  always: '',
  attributes: {},
  calendarId: '',
  id: '',
  notificators: '',
  type: ''
}

const ComputedAttributesDataFormat = {
  description: '',
  attribute: '',
  expression: '',
  type: ''
}
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: '',
  id: '',
  description: '',
  type: 'custom',
  textChannel: ''
}
const maintenanceDataFormat = {
  attributes: {},
  name: '',
  type: '',
  start: '',
  period: ''
}

const locations = [
  '#user',
  '#device',
  '#group',
  '#driver',
  '#trailer',
  '#vehicle',
  '#garage',
  '#geofence',
  '#notification',
  '#attribute',
  '#command',
  '#maintenance'
]

class MainUnitModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      viewScreen: null,
      currentTab: 'user',
      currentId: 0,
      selecteditem: null,
      activeOperation: '',
      unitParkingAttributesform: { ...unitParkingAttFormat },
      notificationForm: { ...NotificationDataFormat },
      computedAttForm: { ...ComputedAttributesDataFormat },
      savedCommandsForm: { ...savedCommandsDataFormat },
      maintenanceForm: { ...maintenanceDataFormat },

      visableAttribute: false,
      visableNotification: false,
      visableSavedCommands: false,
      visableMaintenance: false,
      visableGeofence: false,

      visableListNotification: true,
      visableListGeofence: true,
      visableListAttribute: true,
      visableListSavedCommands: true,
      visableListMaintenance: true,

      notificationCallStatus: '',
      attributeCallStatus: '',
      commandsCallStatus: '',
      maintenanceCallStatus: '',

      paginationvehicle: { ...PaginationConfig, pagesize: 5 },
      isRecived: false,
      vehicleList: [],
      paginationdevice: { ...PaginationConfig, pagesize: 5 },
      deviceList: [],
      onDeleteConfirmationGarage:false,
      rowsPerPage: 5
    }
    this.tabChange = this.tabChange.bind(this)
    this.logo_handelChanges = this.logo_handelChanges.bind(this)
    this.isButtonVisable = this.isButtonVisable.bind(this)
    this.parkingformSubmit = this.parkingformSubmit.bind(this)
    this.onCloseC_Att_Modal = this.onCloseC_Att_Modal.bind(this)

    // notifications

    // common

    //1st
    this.onListNotification = this.onListNotification.bind(this)
    this.onListComputedAttributes = this.onListComputedAttributes.bind(this)
    this.onListSavedCommands = this.onListSavedCommands.bind(this)
    this.onListMaintenance = this.onListMaintenance.bind(this)

    // 2nd
    this.onCreateNewNotification = this.onCreateNewNotification.bind(this)
    //this.onCreateNewGeofence = this.onCreateNewGeofence.bind(this)
    this.onCreateNewAttributes = this.onCreateNewAttributes.bind(this)
    this.onCreateNewSavedCommands = this.onCreateNewSavedCommands.bind(this)
    this.onCreateNewMaintenance = this.onCreateNewMaintenance.bind(this)

    // 3rd
    this.notification_Selection = this.notification_Selection.bind(this)
    this.geofence_Selection = this.geofence_Selection.bind(this)
    this.unit_Selection = this.unit_Selection.bind(this)
    this.driver_Selection = this.driver_Selection.bind(this)
    this.trailer_Selection = this.trailer_Selection.bind(this)
    this.vehicle_Selection = this.vehicle_Selection.bind(this)
    this.computedAttributes_Selection = this.computedAttributes_Selection.bind(
      this
    )
    this.savedCommands_Selection = this.savedCommands_Selection.bind(this)
    this.maintenance_Selection = this.maintenance_Selection.bind(this)

    // 4th
    this.notificationHandle = this.notificationHandle.bind(this)
    this.attributeHandle = this.attributeHandle.bind(this)
    this.savedCommandsHandle = this.savedCommandsHandle.bind(this)
    this.maintenancesHandle = this.maintenancesHandle.bind(this)

    // 5th
    this.addNotification = this.addNotification.bind(this)
    this.addComputedAttributes = this.addComputedAttributes.bind(this)
    this.addSavedCommands = this.addSavedCommands.bind(this)
    this.addMaintenance = this.addMaintenance.bind(this)
    this.addUser = this.addUser.bind(this)

    // 6th
    this.editNotification = this.editNotification.bind(this)
    this.editComputedAttributes = this.editComputedAttributes.bind(this)
    this.editCommands = this.editCommands.bind(this)
    this.editMaintenance = this.editMaintenance.bind(this)
    this.addMaintenanceAttributes = this.addMaintenanceAttributes.bind(this)
    //garage
    this.garage_Selection  = this.garage_Selection.bind(this)
    // remove functions

    this.removeMaintenance = this.removeMaintenance.bind(this)
    this.removeUser = this.removeUser.bind(this)
    this.removeNotification = this.removeNotification.bind(this)
    this.removeGeofence = this.removeGeofence.bind(this)
    this.removeDriver = this.removeDriver.bind(this)
    this.removeTrailer = this.removeTrailer.bind(this)
    this.removeVehicle = this.removeVehicle.bind(this)
    this.removeComputedAttributes = this.removeComputedAttributes.bind(this)
    this.removeCommands = this.removeCommands.bind(this)
    this.removeDevice = this.removeDevice.bind(this)
  }

  componentWillMount () {
    if (this.props.id) {
      this.setState({ viewScreen: 'edit' })
      if (
        this.props.id &&
        parseInt(this.state.currentId) !== parseInt(this.props.id) &&
        this.props.groups.length
      ) {
        this.setState(
          {
            currentId: parseInt(this.props.id)
          },
          () => {
            if (
              this.state.currentTab === 'vehicle' ||
              this.state.currentTab === 'device'
            ) {
              this.getMoreItems()
            }

            this.getCurrentGroup(this.props.id)

            this.props.groups.map(group => {
              if (parseInt(group.id) === parseInt(this.state.currentId)) {
                this.setState({ selecteditem: group })
              }
              return null
            })
          }
        )
      }
    }
    if (this.props.hash) {
      let hash = locations.indexOf(this.props.hash)
      if (hash !== -1) {
        this.setState({ currentTab: this.props.hash.substring(1, -1) })
      }
    }
  }
  // change tab
  tabChange = (event, currentTab) => {
    this.setState({ currentTab }
    //   ,() => {
    //   if (currentTab === 'vehicle') {
    //     this.getMoreItems()
    //   }
    // }
    )
  }
  onCloseModal = () => {
    this.setState({
      activeOperation: ''
    })
  }
  addNewGroups = () => {
    this.setState({
      isaddGroupVisable: !this.state.isaddGroupVisable
    })
  }

  logo_handelChanges (item) {
    this.setState(
      {
        aOneform: {
          category: item.name
        }
      },
      () => {
        this.isButtonVisable('aFormOne')
      }
    )
  }

  // Common

  // 1st

  onListNotification () {
    this.setState({
      visableListNotification: true
    })
  }

  onListComputedAttributes () {
    this.setState({
      visableListAttribute: true
    })
  }

  onListSavedCommands () {
    this.setState({
      visableListSavedCommands: true
    })
  }
  onListMaintenance () {
    this.setState({
      visableListMaintenance: true
    })
  }

  // 2nd
  onCreateNewNotification () {
    this.props.history.push('/notificationCreate')
    this.setState({
      isAddVisableBtn: false,
      notificationCallStatus: 'POST',
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'notificationCreate'
    })
  }

  onCreateNewAttributes () {
    this.setState({
      isAddVisableBtn: false,
      attributeCallStatus: 'POST',
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'attributeCreate'
    })
  }
  onCreateNewSavedCommands () {
    this.setState({
      isAddVisableBtn: false,
      commandsCallStatus: 'POST',
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: 'sharedAdd',
      isHaveAccess: 'commandCreate'
    })
  }
  onCreateNewMaintenance () {
    this.props.history.push('/maintenanceCreate')
    // this.setState({
    //   isAddVisableBtn: false,
    //   maintenanceCallStatus: 'POST',
    //   visableMaintenance: true,
    //   maintenanceForm: { ...maintenanceDataFormat },
    //   addButtonStatus: 'sharedAdd',
    //   isHaveAccess: 'maintenanceCreate'
    // })
  }

  // 3rd
  notification_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectednotification]
    let obj = {
      groupId: this.props.id,
      notificationId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveNotificationDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                'notificationUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item))
          this.props.dispatch(addNotificationDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('notificationAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  geofence_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedgeofence]
    let obj = {
      groupId: this.props.id,
      geofenceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateGeofenceDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveGeofenceDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('geofenceUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateGeofenceDeviceWiseSelectedList(item))
          this.props.dispatch(addGeofenceDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('geofenceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  unit_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddevices]
    let obj = {
      groupId: this.props.id,
      deviceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(RemoveDeviceUserWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('trackerIsUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDeviceUserWiseSelectedList(item))
          this.props.dispatch(addDeviceUserWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('trackerIsAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  driver_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddriver]
    let obj = {
      groupId: this.props.id,
      driverId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDriverDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveDriverDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('driverUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDriverDeviceWiseSelectedList(item))
          this.props.dispatch(addDriverDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('driverAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  trailer_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedtrailer]
    let obj = {
      groupId: this.props.id,
      binderId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })

          this.props.dispatch(updateTrailerDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveTrailerDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('trailerUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateTrailerDeviceWiseSelectedList(item))
          this.props.dispatch(addTrailerDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('trailerAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  vehicle_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedvehicle]
    let obj = {
      groupId: this.props.id,
      vehicleId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateVehicleDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveVehicleDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('vehicleUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateVehicleDeviceWiseSelectedList(item))
          this.props.dispatch(addVehicleDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('vehicleAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  garage_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.selectedGarages&&this.props.selectedGarages || []
    let obj = {
      groupId: this.props.id,
      garageId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updategarageUsersWiseSelectedList(item))
           this.props.dispatch(
            Notifications.success({
              message: this.props.translate('garageUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updategarageUsersWiseSelectedList(item))
            this.props.dispatch(
            Notifications.success({
              message: this.props.translate('garageAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  area_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.selectedAreas&&this.props.selectedAreas || []
    let obj = {
      groupId: this.props.id,
      areaId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateAreaUsersWiseSelectedList(item))
           this.props.dispatch(
            Notifications.success({
              message: this.props.translate('areaUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateAreaUsersWiseSelectedList(item))
            this.props.dispatch(
            Notifications.success({
              message: this.props.translate('areaAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  service_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.selectedServices&&this.props.selectedServices || []
    let obj = {
      groupId: this.props.id,
      expenseTypeId: item.id
    }
    if (value) {
      method = 'POST'
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateServiceUsersWiseSelectedList(item))
           this.props.dispatch(
            Notifications.success({
              message: this.props.translate('serviceUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateServiceUsersWiseSelectedList(item))
            this.props.dispatch(
            Notifications.success({
              message: this.props.translate('serviceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  computedAttributes_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.computedAttributes
    let obj = {
      groupId: this.props.id,
      attributeId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
      .then(response => {
        // if (response.status === 204) {
          if (method === 'DELETE') {
            item.check = false
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = false
              }
              return null
            })
            this.props.dispatch(
              updateComputedAttributeDeviceWiseSelectedList(item)
            )
            this.props.dispatch(RemoveComputedAttributeDeviceWise(item))
            //this.props.enqueueSnackbar(this.props.translate('computedAttributeUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate(
                  'computedAttributeUnAssignedSuccessfully'
                ),
                autoDismiss: 10
              })
            )
          } else {
            item.check = true
            allData.map(dt => {
              if (dt.id === item.id) {
                dt.check = true
              }
              return null
            })
            this.props.dispatch(
              updateComputedAttributeDeviceWiseSelectedList(item)
            )
            this.props.dispatch(addComputedAttributeDeviceWise(item))
            //this.props.enqueueSnackbar(this.props.translate('computedAttributeAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate(
                  'computedAttributeAssignedSuccessfully'
                ),
                autoDismiss: 10
              })
            )
          }
        // } else {
        //   throw response
        // }
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch, this.errorCallBack)
        // if (e && e.text) {
        //   e.text().then(err => {
        //     //this.props.enqueueSnackbar(this.props.translate('computedAttributesNotValid'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        //     this.props.dispatch(
        //       Notifications.error({
        //         message: this.props.translate('computedAttributesNotValid'),
        //         autoDismiss: 10
        //       })
        //     )
        //     this.setState({ visableListAttribute: false }, () => {
        //       this.setState({ visableListAttribute: true })
        //     })
        //   })
        // }
      })
  }
  errorCallBack = (data) => {
    this.setState({ visableListAttribute: false }, () => {
      this.setState({ visableListAttribute: true })
    })
}
  savedCommands_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.commands
    let obj = {
      groupId: this.props.id,
      commandId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateCommandsDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveCommandsDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('savedCommandsUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                'savedCommandsUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateCommandsDeviceWiseSelectedList(item))
          this.props.dispatch(addCommandsDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('savedCommandsAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                'savedCommandsAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  maintenance_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = this.props.maintenance
    let obj = {
      groupId: this.props.id,
      maintenanceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveMaintenanceDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('maintenanceUnAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                'maintenanceUnAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item))
          this.props.dispatch(addMaintenanceDeviceWise(item))
          //this.props.enqueueSnackbar(this.props.translate('maintenanceAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('maintenanceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  // 4th
  notificationHandle (name, value) {
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [name]: value.id
        }
      },
      () => this.checkRequiredFields('notification')
    )
  }
  attributeHandle (name, value) {
    let selectedValue = postionAttributesTypeComp
      .filter(m => m.key === value)
      .map(m => m.valueType)
    this.setState(
      {
        computedAttForm: {
          ...this.state.computedAttForm,
          [name]: value,
          type: selectedValue[0] ? selectedValue[0] : 'string'
        }
      },
      () => this.checkRequiredFields('computedAtt')
    )
  }
  savedCommandsHandle (name, value) {
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          type: value.id
        }
      },
      () => this.checkRequiredFields('Commands')
    )
  }
  maintenancesHandle (name, value) {
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: value.id
        }
      },
      () => this.checkRequiredFields('maintenance')
    )
  }
  saveToParent = (obj) => {
    this.setState({
      maintenanceForm: { ...this.state.maintenanceForm, attributes: { ...this.state.maintenanceForm.attributes, ...obj } }
    }, () => this.checkRequiredFields('maintenance'));
  }

  // 5th
  addNotification () {
    let obj = { ...this.state.notificationForm }
    if (
      (this.state.notificationCallStatus === 'PUT' ||
        this.state.notificationCallStatus === 'POST') &&
      obj.notificators &&
      obj.notificators.length
    ) {
      let notificatorsArr = []
      obj.notificators.map(v => {
        notificators += notificatorsArr.push(v.value)
        return v
      })
      let notificators = notificatorsArr.join(',')
      obj.notificators = notificators
    }
    let params = ''
    if (
      this.state.notificationCallStatus === 'PUT' ||
      this.state.notificationCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/notifications/${params}`, {
      //   method: `${this.state.notificationCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/notifications/${params}`,
        method: `${this.state.notificationCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then( notifications => {
        // if (response.ok) {
          if (this.state.notificationCallStatus === 'DELETE') {
            this.props.dispatch(removeNotificationDeviceWiseSelectedList(obj))
            this.props.dispatch(removedNotification(obj))
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('notificationIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationNotification: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(notifications => {
              this.setState({
                visableNotification: false
              })

              if (this.state.notificationCallStatus === 'PUT') {
                let noti = { ...notifications, check }
                this.props.dispatch(
                  updateNotificationDeviceWiseSelectedList(noti)
                )
                this.props.dispatch(addNotification(noti))

                //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('notificationIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.notification_Selection(true, notifications)
                let noti = { ...notifications, check: true }
                this.props.dispatch(addNotificationDeviceWiseSelectedList(noti))
                this.props.dispatch(addNotification(noti))
                // /this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('notificationIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addGeofence () {
    let obj = this.state.geofenceForm
    let params = ''
    if (
      this.state.geofenceCallStatus === 'PUT' ||
      this.state.geofenceCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/geofences/${params}`, {
      //   method: `${this.state.geofenceCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/geofences/${params}`,
        method: `${this.state.geofenceCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then( geofences=> {
        // if (response.ok) {
          if (this.state.geofenceCallStatus === 'DELETE') {
            this.props.dispatch(removeGeofenceDeviceWiseSelectedList(obj))
            this.props.dispatch(deleteGeofence(obj))
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('geofenceDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationGeofence: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then( geofences => {
              this.setState({
                visableGeofence: false
              })

              if (this.state.geofenceCallStatus === 'PUT') {
                let noti = { ...geofences, check }
                this.props.dispatch(updateGeofenceDeviceWiseSelectedList(noti))
                this.props.dispatch(addGeoFence(noti))

                //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('geofenceUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.geofence_Selection(true, geofences)
                let noti = { ...geofences, check: true }
                this.props.dispatch(addGeofenceDeviceWiseSelectedList(noti))
                this.props.dispatch(addGeoFence(noti))
                // /this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('geofenceCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  addDevice () {
    let obj = this.state.deviceForm
    let params = ''
    if (
      this.state.deviceCallStatus === 'PUT' ||
      this.state.deviceCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/devices/${params}`, {
      //   method: `${this.state.deviceCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/devices/${params}`,
        method: `${this.state.deviceCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then( devices => {
        // if (response.ok) {
          if (this.state.deviceCallStatus === 'DELETE') {
            this.props.dispatch(removeDeviceUserWiseSelectedList(obj))
            this.props.dispatch(removeDevice(obj))
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('deviceIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationDevice: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(devices => {
              this.setState({
                visableDevice: false
              })

              if (this.state.deviceCallStatus === 'PUT') {
                let noti = { ...devices, check }
                this.props.dispatch(updateDeviceUserWiseSelectedList(noti))
                this.props.dispatch(addDevices(noti))

                //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('deviceIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.device_Selection(true, devices)
                let noti = { ...devices, check: true }
                this.props.dispatch(addDeviceUserWiseSelectedList(noti))
                this.props.dispatch(addDevices(noti))
                // /this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('deviceIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addDriver () {
    let obj = this.state.driverForm
    let params = ''
    if (
      this.state.driverCallStatus === 'PUT' ||
      this.state.driverCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/drivers/${params}`, {
      //   method: `${this.state.driverCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/drivers/${params}`,
        method: `${this.state.driverCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then(drivers => {
        // if (response.ok) {
          if (this.state.driverCallStatus === 'DELETE') {
            this.props.dispatch(removeDriverDeviceWiseSelectedList(obj))
            this.props.dispatch(removeDriver(obj))
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('driverIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationDriver: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(drivers => {
              this.setState({
                visableDriver: false
              })

              if (this.state.driverCallStatus === 'PUT') {
                let noti = { ...drivers, check }
                this.props.dispatch(updateDriverDeviceWiseSelectedList(noti))
                this.props.dispatch(addDriver(noti))

                //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('driverIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.driver_Selection(true, drivers)
                let noti = { ...drivers, check: true }
                this.props.dispatch(addDriverDeviceWiseSelectedList(noti))
                this.props.dispatch(addDriver(noti))
                // /this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('driverIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addTrailer () {
    let obj = this.state.trailerForm
    let params = ''
    if (
      this.state.trailerCallStatus === 'PUT' ||
      this.state.trailerCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/binders/${params}`, {
      //   method: `${this.state.trailerCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/binders/${params}`,
        method: `${this.state.trailerCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then(trailers => {
        // if (response.ok) {
          if (this.state.trailerCallStatus === 'DELETE') {
            this.props.dispatch(removeTrailerDeviceWiseSelectedList(obj))
            this.props.dispatch(removeTrailer(obj))
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('trailerIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationTrailer: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(trailers => {
              this.setState({
                visableTrailer: false
              })

              if (this.state.trailerCallStatus === 'PUT') {
                let noti = { ...trailers, check }
                this.props.dispatch(updateTrailerDeviceWiseSelectedList(noti))
                this.props.dispatch(addTrailer(noti))

                //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('trailerIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.trailer_Selection(true, trailers)
                let noti = { ...trailers, check: true }
                this.props.dispatch(addTrailerDeviceWiseSelectedList(noti))
                this.props.dispatch(addTrailer(noti))
                // /this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('trailerIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addVehicle () {
    let obj = this.state.vehicleForm
    let params = ''
    if (
      this.state.vehicleCallStatus === 'PUT' ||
      this.state.vehicleCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      let check
      if (obj.check !== undefined) {
        check = obj.check
        delete obj.check
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms }
        delete obj.alarms
      }
      // fetch(`/api/vehicles/${params}`, {
      //   method: `${this.state.vehicleCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/vehicles/${params}`,
        method: `${this.state.vehicleCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then(vehicles => {
        // if (response.ok) {
          if (this.state.vehicleCallStatus === 'DELETE') {
            this.props.dispatch(removeVehicleDeviceWiseSelectedList(obj))
            this.props.dispatch(removedvehicle(obj))
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('vehicleIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationVehicle: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(vehicles => {
              this.setState({
                visableVehicle: false
              })

              if (this.state.vehicleCallStatus === 'PUT') {
                let noti = { ...vehicles, check }
                this.props.dispatch(updateVehicleDeviceWiseSelectedList(noti))
                this.props.dispatch(addvehicle(noti))

                //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('vehicleIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.vehicle_Selection(true, vehicles)
                let noti = { ...vehicles, check: true }
                this.props.dispatch(addVehicleDeviceWiseSelectedList(noti))
                this.props.dispatch(addvehicle(noti))
                // /this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('vehicleIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addComputedAttributes () {
    let obj = this.state.computedAttForm
    let params = ''
    if (
      this.state.attributeCallStatus === 'PUT' ||
      this.state.attributeCallStatus === 'DELETE'
    ) {
      params = obj.id
      delete obj.deviceId
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }
      // fetch(`/api/attributes/computed/${params}`, {
      //   method: `${this.state.attributeCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/attributes/computed/${params}`,
        method: `${this.state.attributeCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
              ...obj
            }
      })
      .then(Attribute => {
        // if (response.ok) {
          if (this.state.attributeCallStatus === 'DELETE') {
            this.props.dispatch(
              removedComputedAttributeDeviceWiseSelectedList(obj)
            )
            this.props.dispatch(removedComputedAttribute(obj))
            //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('computedAttributeIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationAttributes: false
            })
          } else {
            // response.json().then(Attribute => {
              this.setState({
                visableAttribute: false
              })
              if (this.state.attributeCallStatus === 'PUT') {
                this.props.dispatch(
                  updateComputedAttributeDeviceWiseSelectedList(Attribute)
                )
                this.props.dispatch(addComputedAttribute(Attribute))
                //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('computedAttributeIsUpdated'),
                    autoDismiss: 10
                  })
                )
              } else {
                this.computedAttributes_Selection(true, Attribute)
                this.props.dispatch(
                  addComputedAttributeDeviceWiseSelectedList(Attribute)
                )
                this.props.dispatch(addComputedAttribute(Attribute))
                //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('computedAttributeIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  visableListAttribute: false
                })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addSavedCommands () {
    let {
      attributes,
      id,
      description,
      type,
      textChannel
    } = this.state.savedCommandsForm
    textChannel = textChannel === 'Yes' ? true : false
    attributes = attributes || {}
    id = id || -1
    let obj = { attributes, id, description, type, textChannel }
    obj.deviceId = 0
    let params = ''
    if (
      this.state.commandsCallStatus === 'PUT' ||
      this.state.commandsCallStatus === 'DELETE'
    ) {
      params = obj.id
    }

    if (obj) {
      // fetch(`/api/commands/${params}`, {
      //   method: `${this.state.commandsCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/commands/${params}`,
        method: `${this.state.commandsCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
              ...obj
            }
      })
      .then(commands => {
        // if (response.ok) {
          if (this.state.commandsCallStatus === 'DELETE') {
            this.props.dispatch(removedCommandsDeviceWiseSelectedList(obj))
            this.props.dispatch(removedCommands(obj))
            //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('savedCommandsIsDeleted'),
                autoDismiss: 10
              })
            )
            this.setState({
              onDeleteConfirmationCommand: false
            })
          } else {
            // response.json().then(commands => {
              this.setState({
                visableSavedCommands: false
              })
              if (this.state.commandsCallStatus === 'PUT') {
                this.props.dispatch(
                  updateCommandsDeviceWiseSelectedList(commands)
                )
                this.props.dispatch(addCommands(commands))
                //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('savedCommandsIsUpdated'),
                    autoDismiss: 10
                  })
                )
              } else {
                this.savedCommands_Selection(true, commands)
                this.props.dispatch(addCommandsDeviceWiseSelectedList(commands))
                this.props.dispatch(addCommands(commands))
                //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('savedCommandsIsCreated'),
                    autoDismiss: 10
                  })
                )

                this.setState({ visableListSavedCommands: false })
              }
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addMaintenance () {
    let obj = this.state.maintenanceForm

    if (this.state.maintenanceForm.type === 'totalDistance') {
      obj.start = parseFloat(this.state.maintenanceForm.start * 1000)
      obj.period = parseFloat(this.state.maintenanceForm.period * 1000)
    } else if (this.state.maintenanceForm.type === 'hours') {
      obj.start = parseFloat(this.state.maintenanceForm.start * (1000 * 3600))
    } else if (this.state.maintenanceForm.type === 'date') {
      obj.start = 1
    }

    let params = ''
    if (
      this.state.maintenanceCallStatus === 'PUT' ||
      this.state.maintenanceCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }
      // fetch(`/api/maintenance/${params}`, {
      //   method: `${this.state.maintenanceCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/maintenance/${params}`,
        method: `${this.state.maintenanceCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body:{
              ...obj
            }
      })
      .then(Maintenance => {
        // if (response.ok) {
          if (this.state.maintenanceCallStatus === 'DELETE') {
            this.props.dispatch(removedMaintenanceDeviceWiseSelectedList(obj))
            this.props.dispatch(removedMaintenance(obj))
            //this.props.enqueueSnackbar(this.props.translate('maintenanceIsDeleted'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('maintenanceIsDeleted'),
                autoDismiss: 10
              })
            )

            this.setState({
              onDeleteConfirmationMaintenance: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(Maintenance => {
              if (this.state.maintenanceCallStatus === 'PUT') {
                this.props.dispatch(
                  updateMaintenanceDeviceWiseSelectedList(Maintenance)
                )
                this.props.dispatch(addMaintenance(Maintenance))
                //this.props.enqueueSnackbar(this.props.translate('maintenanceIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('maintenanceIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.maintenance_Selection(true, Maintenance)
                this.props.dispatch(
                  addMaintenanceDeviceWiseSelectedList(Maintenance)
                )
                this.props.dispatch(addMaintenance(Maintenance))
                //this.props.enqueueSnackbar(this.props.translate('MaintenanceIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('MaintenanceIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: '',
                  visableListMaintenance: false
                })
              }

              this.setState({
                visableMaintenance: false,
                attributeChangesMessage: ''
              })
            // })
          }
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }
  addUser () {
    let obj = this.state.userForm
    let params = ''
    if (
      this.state.userCallStatus === 'PUT' ||
      this.state.userCallStatus === 'DELETE'
    ) {
      params = obj.id
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }
      // fetch(`/api/users/${params}`, {
      //   method: `${this.state.userCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/users/${params}`,
        method: `${this.state.userCallStatus}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        params: {
          ...obj
        }
      })
      .then(User => {
        // if (response.ok) {
          if (this.state.userCallStatus === 'DELETE') {
            this.props.dispatch(removeUserUsereWise(obj))

            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('userIsRemoved'),
                autoDismiss: 10
              })
            )

            this.setState({
              onDeleteConfirmationUser: false,
              attributeChangesMessage: ''
            })
          } else {
            // response.json().then(User => {
              if (this.state.maintenanceCallStatus === 'PUT') {
                this.props.dispatch(updateUserDeviceWiseSelectedList(User))
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('UserIsUpdated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: ''
                })
              } else {
                this.maintenance_Selection(true, User)

                this.props.dispatch(addUserDeviceWiseSelectedList(User))

                //this.props.enqueueSnackbar(this.props.translate('UserIsCreated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('UserIsCreated'),
                    autoDismiss: 10
                  })
                )
                this.setState({
                  attributeChangesMessage: '',
                  visableListUser: false
                })
              }

              this.setState({
                visableUser: false,
                attributeChangesMessage: ''
              })
            // })
          }
      //   }
      //   else{
      //     throw response
      //   }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  // 6th
  editNotification (item) {
    this.props.history.push(`/notificationUpdate/${item[0].id}`)
    let a =
      item && item[0] && item[0].notificators
        ? item[0].notificators.split(',')
        : []
    let notificators = a.length
      ? a.map(e => ({ label: this.props.translate(e), value: e }))
      : []
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      notificationCallStatus: 'PUT',
      visableNotification: true,
      notificationForm: { ...item[0], notificators },
      addButtonStatus: 'sharedUpdate',
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      attributeChangesMessage: '',
      itemAttributes: item[0].attributes,
      isHaveAccess: 'notificationUpdate'
    })
  }

  editComputedAttributes (item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      attributeCallStatus: 'PUT',
      visableAttribute: true,
      computedAttForm: {
        ...item[0],
        expression: ReactHtmlParser(item[0].expression)[0]
      },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'attributeUpdate'
    })
  }

  editCommands (item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      commandsCallStatus: 'PUT',
      visableSavedCommands: true,
      savedCommandsForm: { ...item[0] },
      addButtonStatus: 'sharedUpdate',
      isHaveAccess: 'commandUpdate'
    })
  }
  editMaintenance (item) {
    this.props.history.push(`/maintenanceUpdate/${item[0].id}`)
    // let obj = JSON.parse(JSON.stringify(item[0]))
    // if (obj.type === 'totalDistance') {
    //   obj.start = parseFloat(obj.start / 1000)
    //   obj.period = parseFloat(obj.period / 1000)
    // } else if (obj.type === 'hours') {
    //   obj.start = parseFloat(obj.start / (1000 * 3600))
    //   obj.period = parseFloat(obj.period / (1000 * 3600))
    // } else if (obj.type === 'date') {
    //   obj.start = 1
    // }

    // this.setState({
    //   isAddVisableBtn: false,
    //   selecteItem: item[0],
    //   maintenanceCallStatus: 'PUT',
    //   visableMaintenance: true,
    //   maintenanceForm: { ...obj },
    //   addButtonStatus: 'sharedUpdate',
    //   attributeChangesMessage: '',
    //   defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
    //   itemAttributes: item[0].attributes,
    //   isHaveAccess: 'maintenanceUpdate'
    // })
  }
  addMaintenanceAttributes () {
    this.setState({
      isAddAttributes: true,
      componentType: 'Maintenance',
      //defaultAttributes: JSON.parse(JSON.stringify(this.state.maintenanceForm.attributes)),
      itemAttributes: this.state.maintenanceForm.attributes
    })
  }

  notification_handleChange2 = (value, option) => {
    if (option && option.name === 'calendarId') {
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            [option.name]: value.value
          }
        },
        () => {
          this.checkRequiredFields('notification')
        }
      )
    } else if (typeof option === 'object' && Object.keys(option).length) {
      // for select
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            [option.name]: value
          }
        },
        () => {
          this.checkRequiredFields('notification')
        }
      )
    } else if (value === 'type') {
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            [value]: option
          }
        },
        () => this.checkRequiredFields('notification')
      )
    } else {
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            attributes: {
              ...this.state.notificationForm.attributes,
              [value]: option
            }
          }
        },
        () => this.checkRequiredFields('notification')
      )
    }
  }

  // direct arrow function
  notification_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('notification')
    )
  }
  geofence_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        geofenceForm: {
          ...this.state.geofenceForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('geofence')
    )
  }
  driver_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        driverForm: {
          ...this.state.driverForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('driver')
    )
  }
  trailer_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        trailerForm: {
          ...this.state.trailerForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('trailer')
    )
  }
  vehicle_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        vehicleForm: {
          ...this.state.vehicleForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('vehicle')
    )
  }
  group_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        groupForm: {
          ...this.state.groupForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('group')
    )
  }

  C_Att_handleChange = name => event => {
    const { target } = event
    this.setState(
      {
        computedAttForm: {
          ...this.state.computedAttForm,
          [name]: target.value
        }
      },
      () => this.checkRequiredFields('computedAtt')
    )
  }

  savedCommands_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (name === 'attributes') {
      let att = this.state.savedCommandsForm.attributes
      let newAtt = { [target.name]: target.value }
      att = { ...att, ...newAtt }
      value = att
      this.setState(
        {
          savedCommandsForm: {
            ...this.state.savedCommandsForm,
            [target.name]:
              target.type === 'checkbox' ? target.checked : target.value
          }
        },
        () => this.checkRequiredFields('Commands')
      )
    }
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('Commands')
    )
  }

  maintenance_handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: value
        }
      },
      () => this.checkRequiredFields('maintenance')
    )
  }

  maintenance_handleChange_attr = name => date => {
    const value =
      name === 'repeat' || name === 'status' ? date.target.checked : date
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          attributes: {
            ...this.state.maintenanceForm.attributes,
            [name]: value
          }
        }
      },
      () => this.checkRequiredFields('maintenance')
    )
  }

  // remove

  removeNotification () {
    this.setState(
      {
        notificationCallStatus: 'DELETE'
      },
      () => this.addNotification()
    )
  }
  removeGeofence () {
    this.setState(
      {
        geofenceCallStatus: 'DELETE'
      },
      () => this.addGeofence()
    )
  }
  removeDriver () {
    this.setState(
      {
        driverCallStatus: 'DELETE'
      },
      () => this.addDriver()
    )
  }
  removeTrailer () {
    this.setState(
      {
        trailerCallStatus: 'DELETE'
      },
      () => this.addTrailer()
    )
  }
  removeGarage = () => {
    this.setState(
      {
        garageCallStatus: 'DELETE'
      },
      () => this.deleteGarages()
    )
  }
  removeVehicle () {
    this.setState(
      {
        vehicleCallStatus: 'DELETE'
      },
      () => this.addVehicle()
    )
  }
  removeMaintenance () {
    this.setState(
      {
        maintenanceCallStatus: 'DELETE'
      },
      () => this.addMaintenance()
    )
  }
  removeUser () {
    this.setState(
      {
        userCallStatus: 'DELETE'
      },
      () => this.addUser()
    )
  }

  removeComputedAttributes () {
    this.setState(
      {
        attributeCallStatus: 'DELETE'
      },
      () => this.addComputedAttributes()
    )
  }

  onRemovedNotification = item => {
    this.setState({
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true
    })
  }
  onRemovedGeofence = item => {
    this.setState({
      geofenceForm: { ...item[0] },
      onDeleteConfirmationGeofence: true
    })
  }
  onRemovedDriver = item => {
    this.setState({
      driverForm: { ...item[0] },
      onDeleteConfirmationDriver: true
    })
  }
  onRemovedTrailer = item => {
    this.setState({
      trailerForm: { ...item[0] },
      onDeleteConfirmationTrailer: true
    })
  }
  onRemovedVehicle = item => {
    this.setState({
      vehicleForm: { ...item[0] },
      onDeleteConfirmationVehicle: true
    })
  }

  onRemovedGarage = item => {
    this.setState({
      garageForm: { ...item[0] },
      onDeleteConfirmationGarage: true
    })
  }

  onRemovedAttribute = item => {
    this.setState({
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true
    })
  }
  onRemovedCommand = item => {
    this.setState({
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true
    })
  }
  onRemovedMaintenance = item => {
    this.setState({
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true
    })
  }
  onRemovedUser = item => {
    this.setState({
      userForm: { ...item[0] },
      onDeleteConfirmationUser: true
    })
  }
  onRemovedDevice = item => {
    // e.preventDefault()
    this.setState({
      slectedUnits: item[0],
      onDeleteConfirmationUnits: true
    })
    return false
  }
  removeCommands () {
    this.setState(
      {
        commandsCallStatus: 'DELETE'
      },
      () => this.addSavedCommands()
    )
  }

  deleteGarages = () => {
    let id =  this.state.garageForm.id
    if (id) {
      instance({
        url: `/api/garages/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
          this.props.dispatch(removedGarages({ id:id }))
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('GaragesIsDeleted'),
              autoDismiss: 10
            })
          )
          this.fetchGarageNewLists()
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
    this.setState({
       onDeleteConfirmationGarage: false,
    })
  }

  fetchGarageNewLists = ()=>{
    instance({
      url: `/api/garages/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res => {this.props.dispatch(getGarages(res))})
    .catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  removeDevice () {
    let { slectedUnits } = this.state
    // fetch(`/api/devices/${slectedUnits.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      // if (response.status === 204) {
        this.props.dispatch(removeDevice(slectedUnits.id))
        this.props.history.push('/units')
        this.setState({
          onDeleteConfirmationUnits: false
        })
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationUser: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false,
      onDeleteConfirmation: false
    })
  }

  // comman for all
  onCloseC_Att_Modal () {
    this.setState({
      visableAttribute: false,
      visableNotification: false,
      visableGeofence: false,
      visableMaintenance: false,
      visableSavedCommands: false,
      attributeChangesMessage: '',
      defaultAttributes: {}
    })
  }

  // check for required field
  checkRequiredFields (computedAtt) {
    // changed attribute work is in pendding
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm
    } = this.state
    if (computedAtt === 'computedAtt') {
      let changed_comAt = !isEqual(selecteItem, computedAttForm)
      let { attribute, expression } = this.state.computedAttForm
      if (
        attribute &&
        expression &&
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        this.setState({
          isAddVisableBtn: true
        })
      } else {
        this.setState({
          isAddVisableBtn: false
        })
      }
    } else if (computedAtt === 'Commands') {
      let changed_comm = !isEqual(selecteItem, savedCommandsForm)
      let { type, description } = this.state.savedCommandsForm
      if (type && description && changed_comm) {
        this.setState({
          isAddVisableBtn: true
        })
      } else {
        this.setState({
          isAddVisableBtn: false
        })
      }
    } else if (computedAtt === 'notification') {
      // attChangedValue is for attributes changing value
      let attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      )
      if (attChangedValue) {
        this.setState({
          attributeChangesMessage: 'Change in attributes is pending'
        })
      } else {
        this.setState({
          attributeChangesMessage: ''
        })
      }
      let changed_noti = !isEqual(selecteItem, notificationForm)
      let { type, notificators } = this.state.notificationForm
      if (type && notificators && changed_noti) {
        this.setState({
          isAddVisableBtn: true
        })
      } else {
        this.setState({
          isAddVisableBtn: false
        })
      }
    } else if (computedAtt === 'maintenance') {
      let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes)
      if (Attvalue) {
        this.setState({
          attributeChangesMessage: 'Change in attributes is pending'
        })
      } else {
        this.setState({
          attributeChangesMessage: ''
        })
      }
      let changed_main = !isEqual(selecteItem, maintenanceForm)
      let { type, start, period, attributes } = this.state.maintenanceForm
      if (type && period && (start || attributes.startDate) && changed_main) {
        this.setState({
          isAddVisableBtn: true
        })
      } else {
        this.setState({
          isAddVisableBtn: false
        })
      }
    }
  }

  isButtonVisable (target) {
    if (target === 'parkingAttForm') {
      const { attributes } = this.state.resFormData
      let att = Object.keys(attributes).reduce((array, key) => {
        return [...array, [key, attributes[key]]]
      }, [])
      let unitParkingAttributes = []
      att.map(item => {
        if (item[0] === 'parking.minIdleTime') {
          unitParkingAttributes.push({ 'parking.minIdleTime': item[1] / 60000 })
        }
        if (item[0] === 'parking.maxIdleSpeed') {
          unitParkingAttributes.push({
            'parking.maxIdleSpeed': Math.round(item[1] * 1.852)
          })
        }
        return null
      })
      let value = {
        'parking.maxIdleSpeed': 0,
        'parking.minIdleTime': 0
      }
      unitParkingAttributes.map(item => {
        value = { ...value, ...item }
        return null
      })
      this.setState({
        isVisableParkingAttBtn: !isEqual(
          value,
          this.state.unitParkingAttributesform
        )
      })
    }
    if (target === 'aFormOne') {
      const { category } = this.state.resFormData
      this.setState({
        isVisableASOneBtn: !isEqual(category, this.state.aOneform.category)
      })
    }
  }

  handleChangeForParkingAtt = name => event => {
    const { target } = event
    this.setState(preState => ({
      ...preState,
      unitParkingAttributesform: {
        ...preState.unitParkingAttributesform,
        [name]: parseFloat(target.value)
      }
    }))
    this.setState(
      {
        isVisableParkingAttBtn: this.state.isVisableParkingAttBtn
      },
      () => {
        this.isButtonVisable('parkingAttForm')
      }
    )
  }

  parkingformSubmit () {
    let { selecteditem } = this.state
    if (selecteditem) {
      let attributes = selecteditem.attributes
      attributes = {
        ...attributes,
        'parking.minIdleTime': (
          this.state.unitParkingAttributesform['parking.minIdleTime'] *
          60 *
          1000
        ).toString(),
        'parking.maxIdleSpeed': (
          this.state.unitParkingAttributesform['parking.maxIdleSpeed'] / 1.852
        ).toString()
      }
      selecteditem.attributes = attributes
      this.setState(
        {
          selecteditem
        },
        () => this.formSubmit()
      )
    }
  }

  getCurrentGroup (id) {
    if (id) {
      // for driver
      if (checkPrivileges('driver')) {
        instance({
          url: `/api/drivers`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            groupId: id,
            all: true,
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(driver => {
              this.setState({
                mulitDriversSelect: driver,
                isDriverRecived: true
              })

              let allData = this.props.drivers
              if (driver.length) {
                allData.map(item => {
                  item.check = false
                  driver.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getDriverDeviceWise(driver))
              this.props.dispatch(driverDeviceWiseSelectedList(allData))
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      // for trailer
      if (checkPrivileges('binder')) {
        // fetch(`/api/binders?groupId=${id}&userId=${this.props.logInUsers.id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
          instance({
            url: `/api/binders`,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: {
              groupId: id,
              userId: this.props.logInUsers.id
            }
          })
        // }).then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(trailer => {
              this.setState({
                mulitTrailersSelect: trailer,
                isTrailerRecived: true
              })
              let allData = this.props.trailers
              if (trailer.length) {
                allData.map(item => {
                  item.check = false
                  trailer.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getTrailerDeviceWise(trailer))
              this.props.dispatch(trailerDeviceWiseSelectedList(allData))
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
            // for vehicle
      if (checkPrivileges('vehicle')) {
          instance({
            url: `/api/vehicles`,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params: {
              groupId: id,
              userId: this.props.logInUsers.id
            }
          })
            .then(vehicle => {
              this.setState({
                mulitVehiclesSelect: vehicle,
                isVehicleRecived: true
              })
              let allData = this.props.vehicles
              if (vehicle.length) {
                allData.map(item => {
                  item.check = false
                  vehicle.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getVehicleDeviceWise(vehicle))
              this.props.dispatch(vehicleDeviceWiseSelectedList(allData))
            })

              .catch(error => {
                // errorHandler(error, this.props.dispatch)
              })
      }

      if (checkPrivileges('garage')) {
        instance({
          url: `/api/garages`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            groupId: id,
            userId: this.props.logInUsers.id
          }
        })
          .then(garage => {
              this.setState({
                multigaragesSelect: garage,
                isgarageRecived: true
              })

              let allData = this.props.garages&&this.props.garages.data
              allData.map(item => {
                item.check = item.parent = parseInt(id) === item.parentId
                item.indeterminate = false
                if (garage.length) {
                  allData.map(item => {
                    item.check = false
                    garage.map(main => {
                      if (item.id && main.id && item.id === main.id) {
                        item.check = true
                        main.check = true
                      }
                      return null
                    })
                    return null
                  })
                  return null
                }
              })
                this.props.dispatch(garageUsersWiseSelectedList(allData))
          }).catch(error => {
            // errorHandler(error, this.props.dispatch)
          })
          // instance({
          //   url: `/api/garages/get`,
          //   method: 'GET',
          //   headers: {
          //     Accept: 'application/json',
          //     'Content-Type': 'application/json'
          //   },
          // })
          // .then(res => {
          //   dispatch(getGarages(res))
          // }).catch(error => {
          //   // errorHandler(error, this.props.dispatch)
          // })
        }

        if (checkPrivileges('area')) {
          instance({
            url: `/api/areas`,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params:{
              groupId: id,
              userId: this.props.logInUsers.id
            }
          })
        .then(area => {
            this.setState({
              multiareasSelect: area,
              isareaRecived: true
            })
            let allData = this.props.areas&&this.props.areas.data
            allData.map(item => {
              item.check = item.parent = parseInt(id) === item.parentId
              item.indeterminate = false
              if (area.length) {
                area.map(main => {
                  if (item.id && main.id && item.id === main.id) {
                    item.indeterminate = main.directAccess === false
                    item.check = main.directAccess
                    item.groupAccess = main.groupAccess
                  }
                  return null
                })
                return null
              }
            })
              this.props.dispatch(areaUsersWiseSelectedList(allData))
            }).catch(error => {})
          }

          if (checkPrivileges('service')) {
            instance({
              url: `/api/expensetypes`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              params:{
                groupId: id,
                userId: this.props.logInUsers.id
              }
            })
          .then(service => {
              this.setState({
                multiserviceSelect: service,
                isserviceRecived: true
              })
              let allData = this.props.services&&this.props.services
              allData.map(item => {
                item.check = item.parent = parseInt(id) === item.parentId
                item.indeterminate = false
                if (service.length) {
                  service.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.indeterminate = main.directAccess === false
                      item.check = main.directAccess
                      item.groupAccess = main.groupAccess
                    }
                    return null
                  })
                  return null
                }
              })
                this.props.dispatch(serviceUsersWiseSelectedList(allData))
              }).catch(error => {})
            }

        
      // for users
      // if (checkPrivileges('user')) {
      //   fetch(
      //     `/api/users/get?userId=${this.props.logInUsers.id}&groupId=${id}&all=true`,
      //     {
      //       method: 'GET',
      //       headers: {
      //         Accept: 'application/json',
      //         'Content-Type': 'application/json'
      //       }
      //     }
      //   ).then(response => {
      //     if (response.ok) {
      //       response.json().then(users => {
      //         this.setState({
      //           mulitUsersSelect: users,
      //           isUsersRecived: true
      //         })
      //         this.props.dispatch(getUserUsereWise(users.data))
      //       })
      //     }
      //   else{
      //     throw response
      //   }
      // }).catch(e => {})
      // }

      // for geofences
      if (checkPrivileges('geofence')) {
        // fetch(`/api/geofences?groupId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/geofences`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            groupId: id,
            all: true
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(geofences => {
              this.setState({
                multiGeofencesSelect: geofences,
                isGroRecived: true
              })

              let allData = this.props.geofences
              if (geofences.length) {
                allData.map(item => {
                  item.check = false
                  geofences.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getGeofenceDeviceWise(geofences))
              this.props.dispatch(geofenceDeviceWiseSelectedList(allData))
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      if (checkPrivileges('device')) {
        // fetch(`/api/devices?groupId=${id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/devices`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            groupId: id
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(devices => {
              this.setState({
                multiDevicesSelect: devices,
                isGroRecived: true
              })
              let allData = this.props.devices3

              if (devices.length) {
                allData.map(item => {
                  item.check = false
                  devices.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getDeviceUserWise(devices))
              this.props.dispatch(deviceUserWiseSelectedList(allData))
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      // for Notification
      // if (checkPrivileges('notification')) {
      //   fetch(`/api/notifications?groupId=${id}&all=true`, {
      //     method: 'GET',
      //     headers: {
      //       Accept: 'application/json',
      //       'Content-Type': 'application/json'
      //     }
      //   }).then(response => {
      //     if (response.ok) {
      //       response.json().then(notifications => {
      //         let allData = this.props.notification
      //         if (notifications.length) {
      //           allData.map(item => {
      //             item.check = false
      //             notifications.map(main => {
      //               if (item.id && main.id && item.id === main.id) {
      //                 item.check = true
      //                 main.check = true
      //               }
      //               return null
      //             })
      //             return null
      //           })
      //         } else {
      //           allData.map(n => (n.check = false))
      //         }
      //         this.props.dispatch(getNotificationDeviceWise(notifications))
      //         this.props.dispatch(notificationDeviceWiseSelectedList(allData))
      //       })
      //     }
      //     else{
      //       throw response
      //     }
      //   }).catch(e => {})
      // }

      // for ComputedAttributes
      if (checkPrivileges('attribute')) {
        // fetch(`/api/attributes/computed?groupId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/attributes/computed`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            groupId: id,
            all: true
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(attributes => {
              let allData = this.props.computedAttributes
              if (attributes.length) {
                allData.map(item => {
                  item.check = false
                  attributes.map(main => {
                    if (item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getComputedAttributeDeviceWise(attributes))
              this.props.dispatch(
                computedAttributeDeviceWiseSelectedList(allData)
              )
              this.setState({
                isComputedAttributesRecived: true
              })
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      // for SavedCommands
      if (checkPrivileges('command')) {
        // fetch(`/api/commands?groupId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/commands`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            groupId: id,
            all: true
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(commands => {
              let data = commands
              data.map(item => {
                item.textChannel = item.textChannel ? 'Yes' : 'No'
                return null
              })
              let allData = this.props.commands
              if (commands.length) {
                allData.map(item => {
                  item.check = false
                  commands.map(main => {
                    if (item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }

              this.props.dispatch(getCommandsDeviceWise(data))
              this.props.dispatch(commandsDeviceWiseSelectedList(allData))
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      // for Maintenance
      if (checkPrivileges('maintenance')) {
        // fetch(`/api/maintenance?groupId=${id}&userId=${this.props.logInUsers.id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/maintenance`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            groupId: id,
            userId: this.props.logInUsers.id,
            all: true
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(maintenance => {
              let allData = this.props.maintenance
              if (maintenance.length) {
                allData.map(item => {
                  item.check = false
                  maintenance.map(main => {
                    if (item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }

              this.props.dispatch(getMaintenanceDeviceWise(maintenance))
              this.props.dispatch(maintenanceDeviceWiseSelectedList(allData))
            })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
    }
    this.setState({
      gOneform: {},
      aOneform: {},
      resFormData: {},
      mulitDriversSelect: '',
      mulitTrailersSelect: '',
      mulitVehiclesSelect: '',
      mulitUsersSelect: '',
      multiGeofencesSelect: '',
      isDriverRecived: false,
      isTrailerRecived: false,
      isgarageRecived: false,
      isareaRecived: false,
      isVehicleRecived: false,
      isGroRecived: false,
      unitParkingAttributesform: {},
      isHaveAccess: 'attributeCreate'
    })

    let group = ''
    group = this.props.groups.filter(dev => {
      return parseInt(dev.id) === parseInt(id)
    })
    if (group[0]) {
      this.setState({
        resFormData: { ...group[0] }
      })
    }

    return { group }
  }
  componentWillReceiveProps (n) {
    if (n.id) {
      this.setState({ viewScreen: 'edit' })
      if (
        n.id && parseInt(this.state.currentId) !== parseInt(n.id) && n.groups.length 
        // && n.drivers.length && n.devices3.length && n.trailers.length && n.vehicles.length && n.geofences.length
        ) {
          this.setState(
          {
            currentId: parseInt(n.id)
          },
          () => {
            if (
              this.state.currentTab === 'vehicle' ||
              this.state.currentTab === 'device'
            ) {
              // this.getMoreItems()
            }

            this.getCurrentGroup(n.id)

            n.groups.map(group => {
              if (parseInt(group.id) === parseInt(this.state.currentId)) {
                this.setState({ selecteditem: group })
              }
              return null
            })
          }
        )
      }
    }

    if (n.addUserScreen) {
      this.setState({ viewScreen: 'add' })
    }
  }

  //new implamentation with backend pagination
  getMoreItems = () => {
    if (this.state.currentId) {
      this.setState(
        {
          isRecived: false
        },
        () => {
          let { currentTab } = this.state
          let pagItemValue = 'pagination' + currentTab
          let { page, pagesize, itemSearch } = this.state[pagItemValue]
          // apiCallLink inital for group to vichale link
          let apiCallLink = `/api/vehicles/linked?groupId=${this.state.currentId}`
          instance({
            url: `/api/vehicles/linked`,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params:{
              groupId: this.state.currentId,
              page: page,
              limit: pagesize,
              search: itemSearch
            }
          })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
              .then(items => {
                let lastPage = items.total / pagesize
                let IsFloate = this.checkFloteNumber(lastPage)
                this.setState({
                  [pagItemValue]: {
                    ...this.state[pagItemValue],
                    total: items.total,
                    lastPage: IsFloate ? parseInt(lastPage + 1) : lastPage,
                    hasNext: items.hasNext
                  },
                  [currentTab + 'List']: items.data,
                  isRecived: true
                })
            //   })
            // } else{
            //   throw response
            // }
          }).catch(error => {
            // errorHandler(error, this.props.dispatch)
          })
        }
      )
    }
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }

  handleChangeRowsPerPage = rowsPerPage => {
    this.setState({ rowsPerPage })
  }

  linkItem = (checked, item) => {
    let { currentTab } = this.state
    let method = 'DELETE'
    let value = checked
    let obj = {
      groupId: parseInt(this.props.id),
      [currentTab + 'Id']: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...obj
      }
    })
    .then(response => {
      // if (response.status === 204) {
        // this.getMoreItems()
        if (method === 'DELETE') {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                `${currentTab}UnAssignedSuccessfully`
              ),
              autoDismiss: 10
            })
          )
        } else {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                `${currentTab}AssignedSuccessfully`
              ),
              autoDismiss: 10
            })
          )
        }
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  onRemovedItem = item => {
    this.setState({
      removeItemId: item[0].id,
      removeItemName: item[0].label || item[0].name || item[0].type,
      onDeleteConfirmation: true
    })
  }
  removeItem = () => {
    let { removeItemId, currentTab } = this.state
    // fetch(`/api/${currentTab}s/${removeItemId}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/${currentTab}s/${removeItemId}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      // if (response.status === 200) {
        // this.getMoreItems()
        this.onCancel()
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate(`${currentTab}DeleteSuccessfully`),
            autoDismiss: 10
          })
        )
      // }
    }).catch(error => {errorHandler(error, this.props.dispatch)})
  }
  handleChangePage = value => {
    let { currentTab } = this.state
    let pagItemValue = 'pagination' + currentTab
    this.setState(
      {
        [pagItemValue]: {
          ...this.state[pagItemValue],
          page: value
        }
      }
      // ,() => this.getMoreItems()
    )
  }
  SearchItem = searchValue => {
    let { currentTab } = this.state
    let pagItemValue = 'pagination' + currentTab
    this.setState(
      {
        [pagItemValue]: {
          ...this.state[pagItemValue],
          page: 1,
          itemSearch: searchValue
        }
      }
      // ,() => this.getMoreItems()
    )
  }
  setSelectedItem = selecteditem =>{
    this.setState({
      selecteditem
    })
  }

  render () {
    const { currentTab, selecteditem, removeItemName } = this.state
    const { classes } = this.props
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vartical'
    const selecteddevices = [...this.props.selecteddevices]

    selecteddevices.map(n => {
      // const dev = n.id
      n.parent = n.parentId === parseInt(this.props.id)
      return null
    })

    return (
      <Fragment>
        <h4 className='tab-title'>
          {this.state.resFormData ? this.state.resFormData.name : 'Resources'}
        </h4>
        <div className={classes.root + ' main-unit-modal-bg'}>
          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeItem}
              title={this.props.translate('areYouWantToDelete')}
              children={removeItemName}
            />
          )}

          {this.state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDevice}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.slectedUnits.name}
            />
          )}
          {this.state.onDeleteConfirmationNotification && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeNotification}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                'notification.' + this.state.notificationForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationDriver && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDriver}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.driverForm.name}
            />
          )}
          {this.state.onDeleteConfirmationTrailer && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeTrailer}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.trailerForm.name}
            />
          )}
          {this.state.onDeleteConfirmationVehicle && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeVehicle}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.vehicleForm.name}
            />
          )}
          {this.state.onDeleteConfirmationGarage && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeGarage}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.garageForm.name}
            />
          )}
          {this.state.onDeleteConfirmationGeofence && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeGeofence}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.geofenceForm.name}
            />
          )}
          {this.state.onDeleteConfirmationAttributes && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeComputedAttributes}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                this.state.computedAttForm.description
              )}
            />
          )}
          {this.state.onDeleteConfirmationCommand && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeCommands}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                'commands.' + this.state.savedCommandsForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationMaintenance && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeMaintenance}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                'positiont.' + this.state.maintenanceForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationUser && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeUser}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.userForm.name}
            />
          )}
          {this.state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDevice}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.slectedUnits.name}
            />
          )}
          {this.state.onDeleteConfirmationNotification && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeNotification}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                'notification.' + this.state.notificationForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationAttributes && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeComputedAttributes}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                this.state.computedAttForm.description
              )}
            />
          )}
          {this.state.onDeleteConfirmationCommand && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeCommands}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                'commands.' + this.state.savedCommandsForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationMaintenance && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeMaintenance}
              title={this.props.translate('areYouWantToDelete')}
              children={this.props.translate(
                'positiont.' + this.state.maintenanceForm.type
              )}
            />
          )}
          <VerticalTabs
            orientation={orientation}
            variant='scrollable'
            scrollButtons='auto'
            value={currentTab}
            onChange={this.tabChange}
            aria-label='Vertical tabs example'
            className='custom-tabs'
          >
            <CTab
              value='user'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                  {this.props.translate('basic')}{' '}
                </span>
              }
              {...a11yProps('basic')}
            />
            {checkPrivileges('device') && (
              <CTab
                value='device'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sensorUnits')}{' '}
                    {/* <ArrowRightIcon className='tab_menu_icon'/>{' '} */}
                  </span>
                }
                {...a11yProps(1)}
              />
            )}
            {checkPrivileges('driver') && (
              <CTab
                value='driver'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedDrivers')}{' '}
                  </span>
                }
                {...a11yProps('driver')}
              />
            )}
            {checkPrivileges('binder') && (
              <CTab
                value='binder'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedBinder')}{' '}
                  </span>
                }
                {...a11yProps('binder')}
              />
            )}
            {checkPrivileges('vehicle') && (
              <CTab
                value='vehicle'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedVehicle')}{' '}
                  </span>
                }
                {...a11yProps('vehicle')}
              />
            )}
            {checkPrivileges('garage') && (
              <CTab
                value='garage'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedGarage')}{' '}
                  </span>
                }
                {...a11yProps('garage')}
              />
            )}
            {checkPrivileges('area') && (
              <CTab
                value='area'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedArea')}{' '}
                    {/* <ArrowRightIcon className='tab_menu_icon'/>{' '} */}
                  </span>
                }
                {...a11yProps('area')}
              />
            )}
            {checkPrivileges('service') && (
              <CTab
                value='service'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('service')}{' '}
                    {/* <ArrowRightIcon className='tab_menu_icon'/>{' '} */}
                  </span>
                }
                {...a11yProps('service')}
              />
            )}
            {checkPrivileges('geofence') && (
              <CTab
                value='geofence'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedGeofences')}{' '}
                  </span>
                }
                {...a11yProps('geofence')}
              />
            )}
            {/* temprary comment notification for Release 3.2.1 */}
            {/* {checkPrivileges('notification') && (
              <CTab
                value='notification'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedNotifications')}{' '}
                  </span>
                }
                {...a11yProps('notification')}
              />
            )} */}
            {checkPrivileges('attribute') && (
              <CTab
                value='attribute'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedComputedAttributes')}{' '}
                  </span>
                }
                {...a11yProps('attribute')}
              />
            )}
            {checkPrivileges('command') && (
              <CTab
                value='command'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedSavedCommand')}{' '}
                  </span>
                }
                {...a11yProps('command')}
              />
            )}
            {/* temprary comment maintenance for Release 3.2.1*/}
            {checkPrivileges('maintenance') && (
              <CTab
                value='maintenance'
                classes={{
                  root: 'common-tab',
                  selected: 'tab-selected',
                  wrapper: 'tab-wrapper'
                }}
                label={
                  <span className='menu_item_text'>
                    {this.props.translate('sharedMaintenance')}{' '}
                  </span>
                }
                {...a11yProps('maintenance')}
              />
            )}
          </VerticalTabs>
          {currentTab === 'user' && (
            <TabPanel
              value={currentTab}
              index={'user'}
              style={{ width: '100%' }}
            >
              {selecteditem && (
                <GroupsModal
                  {...this.props}
                  viewScreen={'edit'}
                  activeOperation={'edit'}
                  selecteditem={selecteditem}
                  setSelectedItem={this.setSelectedItem}
                />
              )}
            </TabPanel>
          )}
          {checkPrivileges('driver') && currentTab === 'driver' && (
            <TabPanel
              value={currentTab}
              index={'driver'}
              style={{ width: '100%' }}
            >
              {/* {(this.props.selecteddriver.length) ?( */}
              <CommonTableSection
                component_Selection={this.driver_Selection}
                themecolors={this.props.themecolors}
                componentData={this.props.selecteddriver}
                showCheckbox
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                onDelete={this.onRemovedDriver}
                label={this.props.translate('sharedDrivers')}
                canUpdate={false}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'driverId',
                    this.props.id,
                    this.props.selecteddriver,
                    this.props.drivers,
                    this.props.dispatch,
                    'driver',
                    this.props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sharedDrivers')
                  },
                  {
                    id: 'uniqueId',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('uniqueId')
                  }
                ]}
                canDelete={checkPrivileges('driverDelete')}
                canRemove={checkPrivileges('groupUnlinkDriver')}
                canAssign={checkPrivileges('groupLinkDriver')}
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {checkPrivileges('device') && currentTab === 'device' && (
            <TabPanel
              value={currentTab}
              index={'device'}
              style={{ width: '100%' }}
            >
              {((this.props.devices.total>0)&&!selecteddevices.length) || (this.props.devices.isFetching)?
              (<Loader />):(<CommonTableSection
              component_Selection={this.unit_Selection}
              themecolors={this.props.themecolors}
              componentData={selecteddevices}
              parent={
                selecteditem && selecteditem.id
                  ? selecteditem.id
                  : 0
              }
              showCheckbox
              onDelete={this.onRemovedDevice}
              label={this.props.translate('sensorUnits')}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              canUpdate={false}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sensorUnits')
                }
              ]}
              canDelete={checkPrivileges('deviceDelete')}
              canRemove={checkPrivileges('groupLinkDevice')}
              canAssign={checkPrivileges('groupUnlinkDevice')}
              selectAll={e =>
                selectAll(
                  e,
                  'groupId',
                  'deviceId',
                  this.props.id,
                  this.props.selecteddevices,
                  this.props.devices3,
                  this.props.dispatch,
                  'device',
                  this.props.translate
                )
              }
            />)}
            </TabPanel>
          )}
          {checkPrivileges('binder') && currentTab === 'binder' && (
            <TabPanel
              value={currentTab}
              index={'binder'}
              style={{ width: '100%' }}
            >
              {/* {(this.props.selectedtrailer.length) ?( */}
              <CommonTableSection
                component_Selection={this.trailer_Selection}
                themecolors={this.props.themecolors}
                componentData={this.props.selectedtrailer}
                showCheckbox
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                onDelete={this.onRemovedTrailer}
                label={this.props.translate('sharedBinder')}
                canUpdate={false}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'binderId',
                    this.props.id,
                    this.props.selectedtrailer,
                    this.props.trailers,
                    this.props.dispatch,
                    'binder',
                    this.props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sharedBinder')
                  },
                  {
                    id: 'uniqueId',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('BinderUniqueId')
                  }
                ]}
                canDelete={checkPrivileges('binderDelete')}
                canRemove={checkPrivileges('groupUnlinkBinder')}
                canAssign={checkPrivileges('groupLinkBinder')}
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {checkPrivileges('vehicle') && currentTab === 'vehicle' && (
            <TabPanel
              value={currentTab}
              index={'vehicle'}
              style={{ width: '100%' }}
            >
              {/* {(this.props.selectedvehicle.length) ?( */}
              <CommonTableSection
                component_Selection={this.vehicle_Selection}
                themecolors={this.props.themecolors}
                componentData={this.props.selectedvehicle}
                showCheckbox
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                onDelete={this.onRemovedVehicle}
                label={this.props.translate('sharedVehicle')}
                canUpdate={false}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'vehicleId',
                    this.props.id,
                    this.props.selectedvehicle,
                    this.props.vehicles,
                    this.props.dispatch,
                    'vehicle',
                    this.props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'label',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sharedVehicle')
                  },
                  {
                    id: 'vehicleLicensePlate',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('vehicleLicensePlate')
                  }
                ]}
                canDelete={checkPrivileges('vehicleDelete')}
                canRemove={checkPrivileges('groupUnlinkVehicle')}
                canAssign={checkPrivileges('groupLinkVehicle')}
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {/* Garage start */}
          {checkPrivileges('garage') && currentTab === 'garage' && (
              <TabPanel
                value={currentTab}
                index={'garage'}
                style={{ width: '100%' }}
              >
                <CommonTableSection
                  component_Selection={this.garage_Selection}
                  themecolors={this.props.themecolors}
                  componentData={this.props.garages?.data || []}
                  showCheckbox={true}
                  showGroupAccess
                  onDelete={this.onRemovedGarage}
                  label={this.props.translate('sharedGarage')}
                  canUpdate={false}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  parent={
                    selecteditem && selecteditem.id
                      ? selecteditem.id
                      : 0
                  }
                  selectAll={e =>
                    selectAll(
                      e,
                      'groupId',
                      'garageId',
                      this.props.id,
                      this.props.selectedGarages,
                      this.props.garages?.data || [],
                      this.props.dispatch,
                      'garage',
                      this.props.translate
                    )
                  }
                  rowDefinition={[
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate('sharedGarage')
                    }
                  ]}
                  canDelete={checkPrivileges('garageDelete')}
                  canRemove={checkPrivileges('groupUnlinkGarage')}
                  canAssign={checkPrivileges('groupLinkGarage')}
                />
              </TabPanel>
            )}
          {/* Garage end */}

          {/* Area start */}
          {checkPrivileges('area') &&
           currentTab === 'area' && (
              <TabPanel
                value={currentTab}
                index={'area'}
                style={{ width: '100%' }}
              >
                <CommonTableSection
                  component_Selection={this.area_Selection}
                  themecolors={this.props.themecolors}
                  componentData={this.props.areas?.data || []}
                  showCheckbox={true}
                  label={this.props.translate('sharedArea')}
                  canUpdate={false}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  parent={
                    selecteditem && selecteditem.id
                      ? selecteditem.id
                      : 0
                  }
                  selectAll={e =>
                    selectAll(
                      e,
                      'groupId',
                      'areaId',
                      this.props.id,
                      this.props.selectedAreas,
                      this.props.selectedAreas || [],
                      this.props.dispatch,
                      'area',
                      this.props.translate
                    )
                  }
                  rowDefinition={[
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate('sharedArea')
                    }
                  ]}
                  // canDelete={checkPrivileges('areaDelete')}
                  canRemove={checkPrivileges('groupUnlinkArea')}
                  canAssign={checkPrivileges('groupLinkArea')}
                />
              </TabPanel>
            )}
          {/* Area end */}
          
          {/* service start */}
          {checkPrivileges('service') &&
           currentTab === 'service' && (
              <TabPanel
                value={currentTab}
                index={'service'}
                style={{ width: '100%' }}
              >
                <CommonTableSection
                  component_Selection={this.service_Selection}
                  themecolors={this.props.themecolors}
                  componentData={this.props.services || []}
                  showCheckbox={true}
                  label={this.props.translate('service')}
                  canUpdate={false}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  parent={
                    selecteditem && selecteditem.id
                      ? selecteditem.id
                      : 0
                  }
                  selectAll={e =>
                    selectAll(
                      e,
                      'groupId',
                      'expenseTypeId',
                      this.props.id,
                      this.props.selectedServices,
                      this.props.selectedServices || [],
                      this.props.dispatch,
                      'service',
                      this.props.translate
                    )
                  }
                  rowDefinition={[
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate('service')
                    }
                  ]}
                  // canDelete={checkPrivileges('areaDelete')}
                  canRemove={checkPrivileges('groupUnlinkExpenseType')}
                  canAssign={checkPrivileges('groupLinkExpenseType')}
                />
              </TabPanel>
            )}
          {/* service end */}

          {checkPrivileges('geofence') && currentTab === 'geofence' && (
            <TabPanel
              value={currentTab}
              index={'geofence'}
              style={{ width: '100%' }}
            >
              {/* {(this.props.selectedgeofence.length) ?( */}
              <CommonTableSection
                component_Selection={this.geofence_Selection}
                themecolors={this.props.themecolors}
                componentData={this.props.selectedgeofence}
                showCheckbox={true}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                onDelete={this.onRemovedGeofence}
                label={this.props.translate('sharedGeofences')}
                canUpdate={false}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'geofenceId',
                    this.props.id,
                    this.props.selectedgeofence,
                    this.props.geofences,
                    this.props.dispatch,
                    'geofence',
                    this.props.translate
                  )
                }
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sharedGeofences')
                  }
                ]}
                canDelete={checkPrivileges('geofenceDelete')}
                canRemove={checkPrivileges('groupUnlinkGeofence')}
                canAssign={checkPrivileges('groupLinkGeofence')}
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {checkPrivileges('notification') && currentTab === 'notification' && (
            <TabPanel
              value={currentTab}
              index={'notification'}
              style={{ width: '100%' }}
            >
              {/* {(this.props.selectednotification.length) ?( */}
              <Notification
                onListNotification={this.onListNotification}
                onCloseC_Modal={this.onCloseC_Modal}
                onCreateNewNotification={this.onCreateNewNotification}
                notification_Selection={this.notification_Selection}
                onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                addNotification={this.addNotification}
                notification_handleChange={this.notification_handleChange}
                notification_handleChange2={this.notification_handleChange2}
                notificationHandle={this.notificationHandle}
                calendars={this.props.calendars}
                notificationDeviceWise={this.props.notificationDeviceWise}
                themecolors={this.props.themecolors}
                notificationData={this.props.selectednotification}
                visableCreateNotification={this.state.visableNotification}
                notificationForm={this.state.notificationForm}
                notificationChannel={this.props.notificationChannel}
                notificationType={this.props.notificationType}
                translate={this.props.translate}
                onEdit={this.editNotification}
                onDelete={this.onRemovedNotification}
                addButtonStatus={this.state.addButtonStatus}
                addAttributes={this.addNotificationAttributes}
                isAddVisableBtn={this.state.isAddVisableBtn}
                isHaveAccess={this.state.isHaveAccess}
                canRemove={checkPrivileges('groupUnlinkNotification')}
                canAssign={checkPrivileges('groupLinkNotification')}
                attributeChangesMessage={this.state.attributeChangesMessage}
                rowsPerPage={this.state.rowsPerPage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'notificationId',
                    this.props.id,
                    this.props.selectednotification,
                    this.props.notification,
                    this.props.dispatch,
                    'notification',
                    this.props.translate
                  )
                }
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {checkPrivileges('attribute') && currentTab === 'attribute' && (
            <TabPanel
              value={currentTab}
              index={'attribute'}
              style={{ width: '100%' }}
            >
            {/* {(this.props.selectedComputedAttributes.length) ?( */}
              <ComputedAttributes
                {...this.props}
                onListComputedAttributes={this.onListComputedAttributes}
                translate={this.props.translate}
                onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                onCloseC_Modal={this.onCloseC_Modal}
                themecolors={this.props.themecolors}
                computedAttributesDeviceWise={
                  this.props.computedAttributesDeviceWise
                }
                onCreateNewAttributes={this.onCreateNewAttributes}
                C_Att_handleChange={this.C_Att_handleChange}
                attributeHandle={this.attributeHandle}
                addComputedAttributes={this.addComputedAttributes}
                computedAttributes_handleChange={
                  this.computedAttributes_handleChange
                }
                visableAttribute={this.state.visableAttribute}
                visableListAttribute={this.state.visableListAttribute}
                computedAttributesData={this.props.selectedComputedAttributes}
                computedAttForm={this.state.computedAttForm}
                computedAttributes_Selection={this.computedAttributes_Selection}
                onEdit={this.editComputedAttributes}
                onDelete={this.onRemovedAttribute}
                addButtonStatus={this.state.addButtonStatus}
                isAddVisableBtn={this.state.isAddVisableBtn}
                isHaveAccess={this.state.isHaveAccess}
                canRemove={checkPrivileges('groupUnlinkAttribute')}
                canAssign={checkPrivileges('groupLinkAttribute')}
                rowsPerPage={this.state.rowsPerPage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'attributeId',
                    this.props.id,
                    this.props.selectedComputedAttributes,
                    this.props.computedAttributes,
                    this.props.dispatch,
                    'attribute',
                    this.props.translate
                  )
                }
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {checkPrivileges('command') && currentTab === 'command' && (
            <TabPanel
              value={currentTab}
              index={'command'}
              style={{ width: '100%' }}
            >
              {/* {(this.props.selectedcommands.length) ?( */}
              <SavedCommands
                onListSavedCommands={this.onListSavedCommands}
                translate={this.props.translate}
                onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                onCloseC_Modal={this.onCloseC_Modal}
                themecolors={this.props.themecolors}
                commandsDeviceWise={this.props.commandsDeviceWise}
                onCreateNewSavedCommands={this.onCreateNewSavedCommands}
                visableAttribute={this.state.visableSavedCommands}
                addSavedCommands={this.addSavedCommands}
                savedCommands_handleChange={this.savedCommands_handleChange}
                savedCommandsHandle={this.savedCommandsHandle}
                visableListSavedCommands={this.state.visableListSavedCommands}
                visableSavedCommands={this.state.visableSavedCommands}
                commandsData={this.props.selectedcommands}
                savedCommandsForm={this.state.savedCommandsForm}
                savedCommands_Selection={this.savedCommands_Selection}
                commandsTypes={this.props.commandsTypes}
                onEdit={this.editCommands}
                onDelete={this.onRemovedCommand}
                addButtonStatus={this.state.addButtonStatus}
                isAddVisableBtn={this.state.isAddVisableBtn}
                isHaveAccess={this.state.isHaveAccess}
                canRemove={checkPrivileges('groupUnlinkCommand')}
                canAssign={checkPrivileges('groupLinkCommand')}
                rowsPerPage={this.state.rowsPerPage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'commandId',
                    this.props.id,
                    this.props.selectedcommands,
                    this.props.commands,
                    this.props.dispatch,
                    'command',
                    this.props.translate
                  )
                }
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
          {checkPrivileges('maintenance') && currentTab === 'maintenance' && (
            <TabPanel
              value={currentTab}
              index={'maintenance'}
              style={{ width: '100%', minWidth: 0  }}
            >
              {/* {(this.props.selectedMaintenance.length) ?( */}
              <MaintenanceForm
                onListMaintenance={this.onListMaintenance}
                onCloseC_Modal={this.onCloseC_Modal}
                onCreateNewMaintenance={this.onCreateNewMaintenance}
                maintenance_Selection={this.maintenance_Selection}
                saveToParent={this.saveToParent}
                onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                addMaintenance={this.addMaintenance}
                maintenance_handleChange={this.maintenance_handleChange}
                maintenance_handleChange_attr={
                  this.maintenance_handleChange_attr
                }
                maintenancesHandle={this.maintenancesHandle}
                maintenanceDeviceWise={this.props.maintenanceDeviceWise}
                themecolors={this.props.themecolors}
                visableListMaintenance={this.state.visableListMaintenance}
                maintenanceData={this.props.selectedMaintenance}
                visableCreateMaintenance={this.state.visableMaintenance}
                maintenanceForm={this.state.maintenanceForm}
                translate={this.props.translate}
                onEdit={this.editMaintenance}
                onDelete={this.onRemovedMaintenance}
                addButtonStatus={this.state.addButtonStatus}
                addAttributes={this.addMaintenanceAttributes}
                isAddVisableBtn={this.state.isAddVisableBtn}
                isHaveAccess={this.state.isHaveAccess}
                canRemove={checkPrivileges('groupUnlinkMaintenance')}
                canAssign={checkPrivileges('groupLinkMaintenance')}
                rowsPerPage={this.state.rowsPerPage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                attributeChangesMessage={this.state.attributeChangesMessage}
                isAllowed
                selectAll={e =>
                  selectAll(
                    e,
                    'groupId',
                    'maintenanceId',
                    this.props.id,
                    this.props.selectedMaintenance,
                    this.props.maintenance,
                    this.props.dispatch,
                    'maintenance',
                    this.props.translate
                  )
                }
              />
              {/* ):<Loader />} */}
            </TabPanel>
          )}
        </div>
      </Fragment>
    )
  }
}

MainUnitModal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapState = state => ({
  devices3: state.devices3,
  devices: state.devices,
  groups: state.groups,
  drivers: state.drivers,
  trailers: state.trailers,
  vehicles: state.vehicles,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selectedtrailer: state.selectedtrailer,
  selectedvehicle: state.selectedvehicle,
  selectedgroups: state.selectedgroups,
  selecteduser: state.selecteduseruser,
  selecteddevices: state.selecteddevices,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
  logInUsers: state.logInUsers,
  selectedGarages:state.selectedGarages,
  garages: state.garages,
  selectedAreas:state.selectedAreas,
  areas: state.areas,
  selectedServices:state.selectedServices,
  services: state.services
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(
  withSnackbar(withLocalize(withStyles(styles)(MainUnitModal)))
)
