import React,{ useState } from 'react'
import { Collapse, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Paper, RadioGroup, Tooltip } from '@material-ui/core'
import { checkPrivileges, selectAll, validEmailPattern } from '../../Helpers'
import Loader from '../../Layout/Loader'
import Button from '../../Components/common/Button'
import SimpleModalWrapped from '../../Components/common/Modal'
import TextField from '../../Components/common/TextField'
import SingleSelect from '../../Components/common/SingleSelect'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import PublishIcon from '@material-ui/icons/Publish';
import SearchDevices from '../../Components/Devices/searchItems'
import AsyncSelect from '../../Components/common/asyncSelect'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormHelperText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Checkbox from '../../Components/common/Checkbox'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SettingForm } from './SettingForm'
import MenuItem from '../../Components/common/MenuItem'
import LinkIcon from '@material-ui/icons/Link';
import CustomDialog from '../../Components/common/Dialog';
import Table from '../../Components/common/tableWithBackEndPagination'
import moment from 'moment'
import instance from '../../axios'

const HelperTextStyle = {
  display:'flex',
  justifyContent:'flex-end',
  color:' white', 
  marginTop:0,
  fontSize:10
};
export const CreateExpense = ({
    expenseForm,
    handleChangeExpense,
    onChangedDevice,
    serviceExpenseCollection,
    handleChangeServices,
    drivers,
    // garageList,
    vehicles,
    dispatch,
    notificationMode,
    subTypes,
    submitExpense,
    showDialogBtn,
    showDueMaintenanceDialogFunc,
    vehicleDueMaintenance,
    showDueDialog,
    setDuePagination,
    closeCreateFrom,
    addMore,
    isVisible,
    deleteRow,
    themecolors,
    selectedUnit,
    vehicleTracker,
    uploadImage,
    handleChangeRowsPerPage,
    handleChangePage,
    translate,
    mode,
    statusTypes,
    uploadFile,
    loader,
    deleteImage,
    image64Type,
    expenseType,
    logInUser,
    dueActive,
    historyEdit,
    imagePopup,
    onCloseDueMaintenanceModel,
    saveToParent,
    completeMode,
    // areaList,
    check,
    tab,
    onRetry,
    showRetry,
    editOption
  }) => {
    // const defaultAreaOptions = areaList
    // const defaultGarageOptions = garageList
    let image =expenseForm.files && expenseForm.files.split(',')
    let imageCount = 1;
    let searchVehicle = [];
    let category=100

    const searchList = async (inputValue) => {
      searchVehicle = [];
      vehicles.map(item =>  ((item.label+' '+item.vehicleLP).toLowerCase() ).includes(inputValue.toLowerCase())? searchVehicle.push(item) : '')

      return await searchVehicle
    }

    // const searchGarageList = async (inputValue) => {
    //   if (!inputValue) {
    //     return []
    //   }
    //   return await instance({
    //     method: 'GET',
    //     url: `/api/garages/get?userId=${logInUser&&logInUser.id}&page=1&limit=50&all=true&search=${inputValue}`,
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   }).then(response => {
    //     if(response.data){
    //       return response.data.map(item => {
    //         return {
    //           id:item.id,
    //           key:item.id,
    //           name:item.name,
    //           label:item.name,
    //           uniqueId:item.id,
    //           valueType:'string'
    //         }
    //       })
    //     }
    //   }).catch(error => {
    //     // errorHandler(error, this.props.dispatch)
    //   })
    // }
    // const searchAreaList = async (inputValue) => {
    //   if (!inputValue) {
    //     return []
    //   }
  
    //   return await instance({
    //     method: 'GET',
    //     url: `/api/areas/get?&page=1&limit=10&search=${inputValue}`,
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   }).then(response => {
    //     if(response.data){
    //       return response.data.map(item => {
    //         return {
    //           key:item.id,
    //           name:item.name,
    //           label:item.name,
    //           uniqueId:item.id,
    //           valueType:'string'
    //         }
    //       })
    //     }
    //   }).catch(error => {
    //     // errorHandler(error, this.props.dispatch)
    //   })
    // }

  return (
    <div style={{ height: '100%' }}>
      
      <div style={{ background: themecolors.backgroundColor, color: themecolors.textColor, borderRadius: 6 }}>
        <SimpleModalWrapped
            // {...props}
            visable
            title={translate('sharedMaintenance')}
            modalControle={()=> closeCreateFrom( (tab ==='showMaintenance')? 'showMaintenance' :tab === 'history'? 'history' : 'due')}
            notShowAttributesBtn
            btnCloseText='sharedBack'
            content={
            <>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <TextField
                      id='name'
                      label={translate('sharedName')}
                      required
                      placeholder=''
                      value={expenseForm.name || ''}
                      onChange={e => handleChangeExpense('name', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item md={6}
                      style={{ postion: 'relative', zIndex: 99 }}>
                      <SearchDevices
                          isMulti={false}
                          api='devices'
                          name='devices'
                          isClearable
                          onChange={handleChangeExpense}
                          // value={expenseForm&&expenseForm.device}
                          value={expenseForm.device.label ? 
                            {
                              id: expenseForm.device.id,
                              value: expenseForm.device.id,
                              label: expenseForm.device.label,
                            }
                            :{
                              id: expenseForm.device.id,
                              value: expenseForm.device.id,
                              label: expenseForm.device.name,
                            }
                          }
                          placeholder={'Devices'}
                      />
                  </Grid> */}
                  <Grid item md={6}>
                    <TextField
                      id='sharedDescription'
                      label={translate('sharedDescription')}
                      type='text'
                      multiline
                      placeholder=''
                      value={expenseForm.description || ''}
                      onChange={e => handleChangeExpense('description', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      id='refNum'
                      label={translate('refNum')}
                      type='text'
                      rows={3}
                      placeholder=''
                      value={expenseForm.refNum || ''}
                      onChange={e => handleChangeExpense('refNum', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('refdate')}
                          variant='outlined'
                          fullWidth
                          format={'dd/MM/yyyy'}
                          // maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.refdate
                              ? new Date(`${expenseForm.refdate}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('refdate', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 8 , float:'right'}}>
                    <AsyncSelect
                      value={expenseForm.vehicles}
                      placeholder={translate('selectVehicle')}
                      selectName='vehicles'
                      // resultMessage={resultMessage}
                      defaultOptions={searchVehicle.length ? searchVehicle : vehicles}
                      handleChange={handleChangeExpense}
                      loadOptions={searchList}
                      isMulti={false}
                    />
                    {showDialogBtn ? (
                      <a
                        // onClick={e => this.2(e, [n])}
                        onClick={showDueMaintenanceDialogFunc}
                        // target="_blank"
                        style={{color: 'inherit', float:'inherit'}}
                      >
                        <LinkIcon
                          className='actionButton'
                          // fontSize='small'
                        />
                      </a>
                    ) : null
                    }
                  </Grid>
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 7 }}>
                    <SingleSelect
                        array={drivers}
                        selectName='driver'
                        label={translate('selectedDrivers')}
                          value={expenseForm.driver&&expenseForm.driver.id
                            ? {
                                id: expenseForm.driver.id,
                                value: expenseForm.driver.id,
                                label: expenseForm.driver.name,
                                uniqueId:expenseForm.driver.uniqueId,
                              }
                            : ''
                          }
                        handleChange={handleChangeExpense}
                        canRemove={true}
                        canAssign={true}
                      />
                  </Grid>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('serviceTime')}
                          variant='outlined'
                          fullWidth
                          required
                          format={'dd/MM/yyyy'}
                          // maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.serviceTime
                              ? new Date(`${expenseForm.serviceTime}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('serviceTime', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin='dense'
                          label={translate('expireTime')}
                          variant='outlined'
                          fullWidth
                          required
                          format={'dd/MM/yyyy'}
                          // maxDate={moment().endOf('day')}
                          value={                     
                            expenseForm && expenseForm.expireTime
                              ? new Date(`${expenseForm.expireTime}`).toString()
                              : null
                          }
                          onChange={e => handleChangeExpense('expireTime', e)}
                          InputProps={{
                            classes: {
                              root: 'theme-cssOutlinedInput',
                              error: 'theme-input-error',
                              input: 'theme-input',
                              focused: 'theme-cssFocused',
                              notchedOutline: 'theme-notchedOutline'
                            }
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'theme-label',
                              focused: 'theme-label-focused',
                              shrink: 'theme-label-shrink',
                              error: 'theme-label-error'
                            }
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  </Grid>
                  {/* <Grid item md={6} style={{ postion: 'relative', zIndex: 6 }}>
                      <AsyncSelect
                          value={expenseForm.garage&&expenseForm.garage.id
                            ? {
                                id: expenseForm.garage.id,
                                value: expenseForm.garage.id,
                                label: expenseForm.garage.name,
                                uniqueId:expenseForm.garage.uniqueId,
                              }
                            : ''
                          }
                          placeholder={translate('garage')}
                          selectName='garage'
                          defaultOptions={defaultGarageOptions}
                          handleChange={handleChangeExpense}
                          loadOptions={searchGarageList}
                          isMulti={false}
                        />
                  </Grid> */}
                  
                  <Grid item md={6} >
                    <div style={{
                        background: themecolors.themeLightColor + '40',
                        marginTop: 5,
                        padding: 10,
                        maxHeight: 575,
                        // overflow: 'auto',
                        boxSizing: 'border-box',
                        borderRadius: 10
                      }}>
                    <TextField
                      id='odometer'
                      label={translate('odometer')}
                      type='text'
                      placeholder=''
                      required
                      value={expenseForm.odometer || ''}
                      onChange={e => handleChangeExpense('odometer', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                    {/* new fields from backend */}
                     {/* <TextField
                      id='odometer'
                      label={translate('odometer')}
                      type='text'
                      placeholder=''
                      required
                      value={expenseForm.odometer || ''}
                      onChange={e => handleChangeExpense('odometer', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />*/}
                    {check ? 
                        <FormHelperText style={HelperTextStyle}>
                              {expenseForm.currentOdometer ? ('Current : '+expenseForm.currentOdometer):('Current : 0')}
                        </FormHelperText>
                       : ''}
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div style={{
                      background: themecolors.themeLightColor + '40',
                      marginTop: 5,
                      padding: 10,
                      maxHeight: 575,
                      // overflow: 'auto',
                      boxSizing: 'border-box',
                      borderRadius: 10
                      }}>   
                      <TextField
                        id='engineHours'
                        label={translate('reportEngineHours')}
                        type='text'
                        placeholder=''
                        value={expenseForm.engineHours || ''} 
                        onChange={e => handleChangeExpense('engineHours', e.target.value)}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                      />
                      {check ? 
                        <FormHelperText style={HelperTextStyle}>
                          {expenseForm.currentEngineHours ? ('Current : '+expenseForm.currentEngineHours): ('Current : 0')}
                        </FormHelperText>
                        : ''  
                      }
                    </div>
                  </Grid>
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 5 }}>                 
                      {!historyEdit ? (
                        <TextField
                          id='status'
                          select
                          label={translate('status')}
                          value={expenseForm.status&&expenseForm.status}
                          onChange={e=>handleChangeExpense('status',e )}
                          margin='dense'
                          fullWidth
                        >
                          {statusTypes.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {translate(option.name)}
                              </MenuItem>
                            ))}
                        </TextField>
                      ) : null }
                  </Grid>
                  {dueActive && (<><Grid item md={6} style={{marginLeft: 5}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <Checkbox
                        checked={expenseForm&&expenseForm.attributes&&expenseForm.attributes.updateMaintenance}
                        onChange={e => handleChangeExpense('updateMaintenance', e.target.checked)}
                        color='primary'
                        label={translate('updateMaintenance')}
                      />
                      <Tooltip 
                        classes={{
                          popper: 'menu-popper',
                          tooltip: 'menu-popper-tooltip'
                        }}
                        title={translate('updateMaintenanceTooltip')}>
                        <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
                      </Tooltip>
                    </div>
                  </Grid><Grid item md={6} style={{marginLeft: 5}}></Grid></>)}
                  {/* {checkPrivileges('area') ? 
                  <Grid item md={6} style={{ postion: 'relative', zIndex: 5 }}>                 
                     <AsyncSelect
                          value={expenseForm.areaId&&expenseForm.areaId.key
                               ? {
                                   id: expenseForm.areaId.key,
                                   value: expenseForm.areaId.key,
                                   label: expenseForm.areaId.name,
                                   uniqueId:expenseForm.areaId.uniqueId,
                                 }
                               : ''
                          }
                          placeholder={translate('Area')}
                          label={translate('Area')}
                          selectName='areaId'
                          // resultMessage={resultMessage}
                          defaultOptions={defaultAreaOptions}
                          handleChange={handleChangeExpense}
                          loadOptions={searchAreaList}
                          isMulti={false}
                        />
                  </Grid>
                  :null} */}
                  <Grid item md={12}>
                    <TextField
                      id='Address'
                      label={translate('address')}
                      type='text'
                      multiline
                      rows={3}
                      placeholder=''
                      value={expenseForm.address || ''}
                      onChange={e => handleChangeExpense('address', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                <Grid item md={12}>
                  <h4 style={{ margin: '10px 0' }} className='with-border'>
                    {translate('tags')}
                  </h4></Grid>
                  <Grid item md={4}>
                    <TextField
                      id='tag1'
                      label={
                        logInUser.attributes &&
                        logInUser.attributes.et1
                          ? logInUser.attributes.et1
                          : translate('tag_1')
                      }
                      placeholder=''
                      value={expenseForm.tag1 || ''}
                      onChange={e => handleChangeExpense('tag1', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id='tag2'
                      label={
                        logInUser.attributes &&
                        logInUser.attributes.et2
                          ? logInUser.attributes.et2
                          : translate('tag_2')
                      }
                      placeholder=''
                      value={expenseForm.tag2 || ''}
                      onChange={e => handleChangeExpense('tag2', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      id='tag3'
                      label={
                        logInUser.attributes &&
                        logInUser.attributes.et3
                          ? logInUser.attributes.et3
                          : translate('tag_3')
                      }
                      placeholder=''
                      value={expenseForm.tag3 || ''}
                      onChange={e => handleChangeExpense('tag3', e.target.value)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid>
                </Grid>

              
              </Grid>
          
              <Grid item sm={4} xs={12}>
                <Grid container spacing={0}>
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={1} >
                      {serviceExpenseCollection.map((row, index) => (
                        <Grid item md={12} xs={12} style={{
                          background: themecolors.themeLightColor + '40',
                          marginTop: 5,
                          padding: 16,
                          maxHeight: 575,
                          // overflow: 'auto',
                          boxSizing: 'border-box',
                          borderRadius: 10
                        }}>
                        <Grid container spacing={1} >
                          <Grid item md={12} xs={12} style={{ postion: 'relative', zIndex: category--}}>
                          <SingleSelect
                              // array={expenseType}
                              array={(row.allExpenseTypes&&row.allExpenseTypes.length) ? row.allExpenseTypes : (expenseType)}  
                              id='expenseTypeId'
                              selectName='expenseTypeId'
                              label={translate('sharedType')}
                              // isDisabled={dueActive || completeMode}
                              value={row.expenseTypeId ?
                              {
                                key: row.expenseTypeId.key,
                                id: row.expenseTypeId.id,
                                value: row.expenseTypeId.id,
                                label: row.expenseTypeId.name ? row.expenseTypeId.name : row.expenseTypeId.name
                              }: ''
                              }
                              index={index}
                              handleChange={handleChangeServices}
                              canRemove={true}
                              canAssign={true}
                              // index={index}
                              />
                      </Grid> 
                          <Grid item md={11} xs={12} style={{ postion: 'relative', zIndex:category}}>
                            <SingleSelect
                              id='expenseSubTypeId'
                              array={(row.selectedSubTypes&&row.selectedSubTypes.length) ? row.selectedSubTypes : (subTypes)}                  
                              selectName='expenseSubTypeId'
                              label={translate('subType')}
                              value={row.expenseSubTypeId ?
                              {
                                key: row.expenseSubTypeId.key,
                                id: row.expenseSubTypeId.id,
                                value: row.expenseSubTypeId.id,
                                label: row.expenseSubTypeId.subType ? row.expenseSubTypeId.subType : row.expenseSubTypeId.label
                              }: ''
                              }
                              handleChange={handleChangeServices}
                              canRemove={true}
                              index={index}
                              canAssign={true}
                            /> 
                          </Grid>    
                          <Grid item md={1} style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                          {serviceExpenseCollection.length > 1 && --category ? (
                            <DeleteIcon
                              onClick={e => deleteRow(row, index, e)}
                              style={{ cursor: 'pointer' }}
                              />            
                            ) 
                          : <Grid item md={2} style={{marginTop:11}}></Grid> }
                        </Grid>                      
                          <Grid item md={6}>
                            <TextField
                              id='Quantity'
                              label={translate('quantity')}
                              type='number'
                              placeholder=''
                              value={row.quantity || ''}
                              onChange={e => handleChangeServices('quantity', e.target.value, index)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                            />
                            </Grid>
                        <Grid item md={6}>
                            <TextField
                            id='Cost'
                            label={translate('cost')}
                            type='number'
                            placeholder=''
                            value={row.cost || 0}
                            onChange={e => handleChangeServices('cost', e.target.value, index)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            InputProps={{
                              style: {padding: 0},
                              endAdornment: (
                                <InputAdornment
                                  position='start'
                                  disableTypography
                                >
                                  {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                                </InputAdornment>
                              )
                            }}
                            />
                      </Grid>                                                                     
                        </Grid>  
                        </Grid>
                        ))}
                         
                    </Grid>
                  </Grid>
                <Grid item md={12} xs={12}>
                  <div style={{float:'right', marginTop:10}}>
                      {expenseForm ? (
                        <div>
                          <Grid item sm={2} xs={12} >
                            <Button
                                  // href={emptyLink}
                                  onClick={e => addMore(e)}
                                  size='small'
                                  color='inherit'
                                  style={{ minWidth: '70px' }}
                                >
                                {translate('addMore')}
                              </Button>
                          </Grid>
                        </div>
                      ) :''}
                      </div>
                </Grid>

                </Grid>
              </Grid>
            </Grid>
                        {editOption ? 
            <Grid container spacing={1} style={{marginTop:12}}>
              <Grid item sm={6} xs={12}>
                  <Tooltip title='Upload Image'>
                        <Button
                          style={{ float: 'left'}}
                          variant='contained'
                          margin='dense'
                          component="label"
                          onChange={e => uploadImage(e,'expenseCreate')}
                        >
                          <input
                            type="file"
                            hidden
                            multiple
                            accept="image/*" 
                          />
                          {translate('onlyAcceptDocFile')}
                          <PublishIcon  style={{ color: '#fff', marginLeft:15}}/>
                        </Button>
                      </Tooltip>
                </Grid>   
            </Grid> : null}
              {loader===true ? 
            <Grid container spacing={1} style={{marginTop:12}}>
                <Grid item sm={12} xs={12}><Loader defaultStyle={true}/></Grid>
              </Grid>:(
              <div style={{marginTop:12, display:'flex'}}>
                {image64Type&&image64Type.map((item,i) =>
                  item && (
                    <div> 
                      {item&&(
                        <a href={item} download target="_blank">
                          <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                        </a>
                      )}
                      <CloseIcon 
                        style={{verticalAlign:'top', marginLeft: -20, marginRight: 5}}
                        onClick={()=>deleteImage(i,image64Type,'base64')}
                      />
                      <p style={{marginLeft:20, marginTop: 0}}>File {imageCount++}</p>
                    </div>
                  )
                )}
                {image&&image.map((item,i) =>
                  item && (
                    <div> 
                      {item&&(
                      <a 
                      // href={item} 
                      // download 
                      target="_blank">
                        <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                      </a>
                      )}
                      <CloseIcon 
                        style={{verticalAlign:'top', marginLeft: -20, marginRight: 5}}
                        onClick={()=>deleteImage(i,image,'url')}
                      />
                      <p style={{marginLeft:20, marginTop: 0}}>File {imageCount++}</p>
                  </div>
                  )
                )}
              </div>
              )}
              {expenseForm.status === 'required' ? (<>
              <Grid item md={6} style={{margin: '0 0 5px 5px'}}>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <Checkbox
                        checked={expenseForm&&expenseForm.attributes&&expenseForm.attributes.sendAlert}
                        onChange={e => handleChangeExpense('sendAlert', e.target.checked)}
                        color='primary'
                        label={translate('sendAlert')}
                      />
                    </div>
              </Grid></>)
              :null}
             {expenseForm.attributes&&expenseForm.attributes.sendAlert ? (<>
                <Grid item xs={12}>
                   <SettingForm form={expenseForm&&expenseForm.attributes} mode={notificationMode} saveToParent={saveToParent} dispatch={dispatch} translate={translate} smsNotification />
                </Grid>
                </>) : null}           
             
             <hr/>
            
            <Grid container spacing={1} style={{ margin:'5px 15px 0 0' }}>
                <Grid item sm={12} xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button
                        size='small'
                        // className='button-white'
                        onClick={()=>uploadFile(mode)} 
                        disabled={isVisible}
                    >
                        {translate('submit')}{' '}
                    </Button>
                </Grid>
             </Grid>  
             {showDueDialog ? (
              <Grid container spacing={1}>
                  <CustomDialog
                    title ='Due Maintenance'
                    themecolors={themecolors}
                    visable={true}
                    onClose={onCloseDueMaintenanceModel}
                    bodyPadding={10}
                    isVisableBtn
                    noFullScreen
                  >
                    <div>
                      <Table
                          rows={vehicleDueMaintenance? vehicleDueMaintenance.data : []}
                          pagination={setDuePagination(vehicleDueMaintenance)}
                          handleChangeRowsPerPage={(n)=>handleChangeRowsPerPage(n,'dueMaintenance')}
                          handleChangePage={(n)=>handleChangePage(n,'dueMaintenance')}
                          logInUser={logInUser}
                          isEditable={false}
                          status={false}
                          completeMark={false}
                          themecolors={themecolors}
                          translate={translate}
                          hasAccessOfCreate={false}
                          hasAccessOfUpdate={false}
                          hasAccessOfDelete={false}
                          rowDefinition={[
                            {
                              id: 'id',
                              numeric: false,
                              disablePadding: false,
                              label: translate('serviceId')
                            },
                            {
                              id: 'name',
                              numeric: false,
                              disablePadding: false,
                              label: translate('serviceMaintenance')
                            },
                            {
                              id: 'odometer',
                              numeric: false,
                              disablePadding: false,
                              label: translate('totalDistance')
                            },
                            {
                              id: 'engineHours',
                              numeric: false,
                              disablePadding: false,
                              label: translate('reportEngineHours')
                            },
                            {
                              id: 'serviceTime',
                              numeric: false,
                              disablePadding: false,
                              label: translate('date')
                            }
                          ]}
                          />
                    </div>
                    
                </CustomDialog>
             </Grid>        
             ): null}         
            </>
        }
        />
        </div>
      </div>
  )
  }
   