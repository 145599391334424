import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import React, { Component } from 'react'
import { SketchPicker } from 'react-color'
import Layout from '../../Layout'
import { connect } from 'react-redux'
import RadioGroup from '@material-ui/core/RadioGroup'
import { FormControl, FormControlLabel, Radio } from '@material-ui/core'
import { logInUserInfo } from '../../Actions/Users'
import { errorHandler, fontFamilies } from '../../Helpers'
import Button from '../../Components/common/Button'
import instance from '../../axios'


class BuildTheme extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            currentTheme: {
                ...props.themecolors,
                themeLightInverse: props.themecolors.themeLightInverse,
                themeListingInverse: props.themecolors.themeListingInverse,
                themeListingColor: props.themecolors.themeListingColor
            },
            fontFamily: props.logInUser && props.logInUser.attributes && props.logInUser.attributes.fontFamily || 'ff-4'
        }
    }
    
    componentWillReceiveProps(n) {
        if(n.logInUser && n.logInUser.attributes && n.logInUser.attributes.fontFamily) {
            this.setState({
                fontFamily: n.logInUser && n.logInUser.attributes && n.logInUser.attributes.fontFamily,
                currentTheme: {
                    ...n.themecolors,
                    themeLightInverse: n.themecolors.themeLightInverse,
                    themeListingInverse: n.themecolors.themeListingInverse,
                    themeListingColor: n.themecolors.themeListingColor
                },
            })
        }
    }

    openPicker = open => {
        this.setState({open})
    }

    onColorUpdate = (key, event) => {
        this.setState({currentTheme: {...this.state.currentTheme, [key] : event.hex}, open: ''}, () => {
            console.log(this.state.currentTheme)
        })
    }

    applyTheme = () => {
        const { logInUser } = this.props
        logInUser.attributes = { ...logInUser.attributes, themeId: 'custom', customTheme: {...this.state.currentTheme, id: 'custom'} }
        // fetch(`api/users/${logInUser.id}`, {
        //     method: 'PUT',
        //     headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //     ...logInUser
        //     })
        // })
        instance({
            url: `api/users/${logInUser.id}`,
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data:{
                ...logInUser
            }
          })
        // .then(response => {
        //     if (response.ok) {
        //         response.json()
                .then(user => {
                    this.props.dispatch(logInUserInfo(user))
            //     })
            // }
            // else{
            //     throw response
            //   }
            }).catch(error => {errorHandler(error, this.props.dispatch)})
        
    }
    
    hideColorPicker = () => {
        this.setState({open: ''})
    }

    selectFamily = (event) => {
        this.setState({fontFamily: event.target.value}, () => {
            
            const { logInUser } = this.props
            logInUser.attributes = { ...logInUser.attributes, fontFamily: this.state.fontFamily }
            // fetch(`api/users/${logInUser.id}`, {
            //     method: 'PUT',
            //     headers: {
            //     Accept: 'application/json',
            //     'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify({
            //     ...logInUser
            //     })
            // })
            instance({
                url: `api/users/${logInUser.id}`,
                method: 'PUT',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                data:{
                    ...logInUser
                }
              })
            // .then(response => {
            //     if (response.ok) {
            //         response.json()
                    .then(user => {
                        this.props.dispatch(logInUserInfo(user))
                //     })
                // }
                // else{
                //     throw response
                //   }
                }).catch(error => {errorHandler(error, this.props.dispatch)})
        })
    }

    render() {
        return <Layout {...this.props} noSidebar classFromChildren={'has-padding no-sidebar'}>
            <Paper className='main-content-page theme-builder-module'>
                <h1>Theme Design</h1>

                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <h4>Theme Primary Color</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                Background
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('themeDarkColor')} style={{color: this.state.currentTheme.themeDarkColor, background: this.state.currentTheme.themeDarkColor, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'themeDarkColor' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                Text
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('themeInverse')} style={{color: this.state.currentTheme.themeInverse, background: this.state.currentTheme.themeInverse, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'themeInverse' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                        </Grid>
                        <h4>Theme Secondary Color</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                Background
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('themeLightColor')} style={{color: this.state.currentTheme.themeLightColor, background: this.state.currentTheme.themeLightColor, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'themeLightColor' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                Text
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('themeLightInverse')} style={{color: this.state.currentTheme.themeLightInverse, background: this.state.currentTheme.themeLightInverse, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'themeLightInverse' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                        </Grid>
                        <h4>Theme Listing Color</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                Background
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('themeListingColor')} style={{color: this.state.currentTheme.themeListingColor, background: this.state.currentTheme.themeListingColor, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'themeListingColor' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                Text
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('themeListingInverse')} style={{color: this.state.currentTheme.themeListingInverse, background: this.state.currentTheme.themeListingInverse, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'themeListingInverse' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                        </Grid>
                        <h4>Default Color</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                Background
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('backgroundColor')} style={{color: this.state.currentTheme.backgroundColor, background: this.state.currentTheme.backgroundColor, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'backgroundColor' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                Text
                                <span style={{display: 'inline-block', position: 'relative'}}>
                                    <a href={null} onClick={e => this.openPicker('textColor')} style={{color: this.state.currentTheme.textColor, background: this.state.currentTheme.textColor, display: 'inline-block', width: 40, height: 40, border: 'solid 1px rgba(0,0,0,0.5)', verticalAlign: 'middle' }}></a>
                                    {this.state.open === 'textColor' ? <div onMouseLeave={this.hideColorPicker} style={{ position: 'absolute', top: 0, zIndex: 3, left: '-50%' }}><SketchPicker color={this.state[this.state.open]} onChangeComplete={e => this.onColorUpdate(this.state.open, e)}></SketchPicker></div> : null}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <div style={{background: this.state.currentTheme.themeDarkColor, padding: 20, marginBottom: 20, borderRadius: 10}}>
                                <small>Fill with Theme Primary Color</small>
                                <h3 style={{marginBottom: 0, marginTop: 5, color: this.state.currentTheme.themeInverse}}>Theme Text color</h3>
                            </div>
                            
                            <div style={{background: this.state.currentTheme.themeLightColor, padding: 20, marginBottom: 20, borderRadius: 10}}>
                                <small>Fill with Theme Secondary Color</small>
                                <h3 style={{marginBottom: 0, marginTop: 5, color: this.state.currentTheme.themeLightInverse}}>Theme Secondary Text color</h3>
                            </div>

                            <div style={{background: this.state.currentTheme.themeListingColor, padding: 20, marginBottom: 20, borderRadius: 10}}>
                                <small>Fill with Theme Listing Color</small>
                                <h3 style={{marginBottom: 0, marginTop: 5, color: this.state.currentTheme.themeListingInverse}}>Theme Listing Text color</h3>
                            </div>

                            <div style={{background: this.state.currentTheme.backgroundColor, padding: 20, marginBottom: 20, borderRadius: 10}}>
                                <small>Fill with Default Background Color</small>
                                <h3 style={{marginBottom: 0, marginTop: 5, color: this.state.currentTheme.textColor}}>Default Text Color</h3>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Button onClick={this.applyTheme}>Apply Theme</Button>

                <h2>Font Family</h2>
                <FormControl component='fieldset'>
                    <RadioGroup
                        aria-label='selectedDate'
                        name='selectedDate'
                        value={this.state.fontFamily || this.props.logInUser && this.props.logInUser.attributes && this.props.logInUser.attributes.fontFamily || 'ff-4'}
                        onChange={this.selectFamily}
                        row
                    >
                        {fontFamilies.map(font => <FormControlLabel
                            key={font.value}
                            classes={{
                                label: font.value
                            }}
                            value={font.value}
                            control={<Radio  size='small' />}
                            label={font.label}
                            labelPlacement='end'
                        />)}
                        
                    </RadioGroup>
                </FormControl>

            </Paper>
        </Layout>
    }
}


const mapStateToProps = (state) => ({themecolors: state.themeColors, logInUser: state.logInUsers})
export default connect(mapStateToProps)(BuildTheme)