import React, { Component } from 'react'
import isEqual from 'react-fast-compare'
import Summery from './SummeryView'
import Details from './DetailView'
import { ReportColumns } from '../../Helpers'
import moment from "moment"
import { getDateTimeFormat } from '../../Helpers'
import { filterGreaterThan, SliderColumnFilter } from '../common/ReactBaseTable'

export default class GenericReport extends Component {
  constructor (props) {
    super(props)

    this.state = {
      reportDialog: false,
      reportColumns: {},
      columns: [],
      hiddenColumns: []
    }

    this.handleClick = this.handleClick.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  handleClick (event, name) {
    event.stopPropagation()
    this.setState({ [name]: true })
  }

  onChange (event, name) {
    const hiddenColumns = event.target.checked ? this.state.hiddenColumns.filter(f => f !== name) : this.state.hiddenColumns.concat(name);
    this.setState(
      {
        reportColumns: {
          ...this.state.reportColumns,
          [name]: {
            ...this.state.reportColumns[name],
            checked: event.target.checked
          }
        },
        hiddenColumns
      },
      () => {
        this.drawColums(this.props.table)
      }
    )
  }

  toggleFilter = event => {
    this.props.toggleFilter(event, this.drawColums, this.props.table);
  }

  onCloseModal (e, name) {
    this.setState({ [name]: false })
  }

  drawColums = table => {
    const columns = []
    let checkedList = {}
    let alreadyAssigned = false

    if (Object.keys(this.state.reportColumns).length) {
      alreadyAssigned = true
      checkedList = this.state.reportColumns
    }
      table && table.columns && table.columns.map((c, i) => {
      if (!alreadyAssigned) {
        switch (c.f) {
          case 'label':
          case 'vehicleLicensePlate':
            checkedList[c.f] = {
              checked: this.props.reportUnits > 14,
              label: c.h
            }
            break
          default:
            if (!Object.keys(this.state.reportColumns).length) {
              checkedList[c.f] = { checked: true, label: c.h }
            }
            break
        }
      }

      let width = 150

      if (ReportColumns[c.f]) {
        width = ReportColumns[c.f].width
      }

      

      const filter = {};

      if(["duration", "engineHours", "movementDuration", "idleDuration", "startOdometer", "endOdometer", "averageSpeed", "maxSpeed", "tripCount", "stopCount", "fillCount", "drainCount", "distance", "idleDurationPercentage", "movementDurationPercentage", "fillVolume", "drainVolume", "startFuelLevel", "endFuelLevel", "fuelConsumption", "spentFuel", "penalty", "periodCount", "eventCount", "visitCount", "violationCount", "count"].includes(c.f)/*  && !this.props.disableFilter */) {
        filter.Filter= SliderColumnFilter;
        filter.filter= filterGreaterThan;
      }

      columns.push({
        Header: c.h,
        id: c.f,
        accessor: d => {
          if (d && d.length) {
            let data = ""
            d.map(r => {
              if (r.f === c.f) {
                data = r;
                switch (c.f) {
                  case 'distance':
                    data = Math.round(r.v * 100) / 100
                  break
                  case 'entranceTime':
                  case 'exitTime':
                  case 'startTime':
                    case 'time':
                  case 'endTime':
                  case 'eventTime':
                  case 'serverTime':
                    data = r.r
                      ? moment(r.r).format(getDateTimeFormat())
                      : null
                    break
                  
                  case 'completedValue':
                  case 'targetValue':
                    data = r.t === 'date' ? moment(r.r).format(getDateTimeFormat()) : r.v 
                    break
                  case 'attributes':
                    data =
                      typeof r.v === 'object'
                        ? JSON.stringify(r.v)
                        : r.v
                    break
                  case 'sensorValue':
                    data =
                      typeof r.v === 'boolean'
                        ? r.v === true
                          ? 'ON'
                          : 'OFF'
                        : r.v
                    break

                  default:
                    break
                
              }
              return null
            }
            })
            if(typeof data !== 'object') {
              return data;
            } else {
              return data && data.v || "" 
            } 
          }
        },
        width,
        ...filter,
        show: checkedList[c.f].checked,
        Footer: c.f
      });

      return null
    })

    this.setState({ reportColumns: checkedList, columns })
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  componentDidMount () {
    this.setState({ reportColumns: {}, columns: [] }, () => {
      this.drawColums(this.props.table)
    })
  }

  render () {
    const { table } = this.props
    const report =
      this.props.detail === true && table.type === 'nestedTable' ? (
        <>
        <Details
          title={table.head || this.props.title}
          onRender={this.props.onRender}
          table={table}
          serverTimeZone={this.props.serverTimeZone}
          columns={this.state.columns}
          handleChange={this.onChange}
          dialogColumns={this.state.reportColumns}
          hiddenColumns={this.state.hiddenColumns}
          toggleFilter={this.toggleFilter}
          disableFilter={this.props.disableFilter}
          translate={this.props.translate}
        />
        </>
      ) : (
        <Summery
          title={table.head || this.props.title}
          onRender={this.props.onRender}
          table={table}
          serverTimeZone={this.props.serverTimeZone}
          columns={this.state.columns}
          handleChange={this.onChange}
          dialogColumns={this.state.reportColumns}
          hiddenColumns={this.state.hiddenColumns}
          toggleFilter={this.toggleFilter}
          disableFilter={this.props.disableFilter}
          translate={this.props.translate}
        />
      )

    return report
  }
}
