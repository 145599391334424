import isEqual from 'react-fast-compare'
export function notifications (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'ADD_NOTIFICATIONS':
      return state.concat(action)

    case 'ENQUEUE_SNACKBAR':
      return state.concat(action.notification)

    case 'REMOVE_SNACKBAR':
      return state.filter(notification => notification.key !== action.key)

    default:
      return state
  }
}
const defaultState = {
  notifications: []
}
// get all notification
export function notification (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_NOTIFICATIONS':
      if (!isEqual(state, action.notifications)) {
        return action.notifications
      } else {
        return state
      }
    case 'ADD_NOTIFICATION':
      return state.concat(action.notifications)
    case 'REMOVED_NOTIFICATION':
      return state.filter(m => m.id !== action.notifications.id)
    case 'UPDATE_NOTIFICATION':
      return state.map(row => {
        if (row.id === action.notifications.id) {
          return action.notifications
        } else {
          return row
        }
      })
    default:
      return state
  }
}

export function geofence (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_GEOFENCES':
      return action.geofences
    case 'ADD_GEOFENCE':
      return state.concat(action.geofences)
    case 'REMOVED_GEOFENCE':
      return state.filter(m => m.id !== action.geofences.id)
    default:
      return state
  }
}
export function notificationType (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_NOTIFICATIONS_TYPE':
      if (!isEqual(state, action.notifications)) {
        return action.notifications
      } else {
        return state
      }
    default:
      return state
  }
}
export function geofenceDeviceWise (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'ADD_GEOFENCE_DEVICE_WISE':
      return state.concat(action.geofence)

    case 'REMOVE_GEOFENCE_DEVICE_WISE':
      let data = state.filter(m => m.id !== action.geofence.id)
      return data
    case 'GET_GEOFENCES_DEVICE_WISE':
      return action.geofence
    default:
      return state
  }
}

export function selectedgeofence (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GEOFENCES_DEVICE_WISE_SELECTED_LIST':
      return action.geofences
    case 'UPDATE_GEOFENCES_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.geofence.id) {
          return m
        } else {
          return action.geofence
        }
      })
      return updatedata

    case 'ADD_GEOFENCES_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.geofence)
    case 'REMOVED_GEOFENCES_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.geofence.id)
      return rData
    default:
      return state
  }
}

export function selectedgroups (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GROUPS_DEVICE_WISE_SELECTED_LIST':
      return action.groups
    case 'UPDATE_GROUPS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.group.id) {
          return m
        } else {
          return action.group
        }
      })
      return updatedata

    case 'ADD_GROUPS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.group)
    case 'REMOVED_GROUPS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.group.id)
      return rData
    default:
      return state
  }
}

export function selecteddevices (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'DEVICE_USER_WISE_SELECTED_LIST':
      return action.device
    case 'UPDATE_DEVICE_USER_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.device.id) {
          return m
        } else {
          return action.device
        }
      })
      return updatedata

    case 'ADD_DEVICE_USER_WISE_SELECTED_LIST':
      return state.concat(action.device)
    case 'REMOVED_DEVICE_USER_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.device.id)
      return rData
    default:
      return state
  }
}

export function notificationDeviceWise (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'ADD_NOTIFICATION_DEVICE_WISE':
      return state.concat(action.notification)

    case 'REMOVE_NOTIFICATION_DEVICE_WISE':
      let data = state.filter(m => m.id !== action.notification.id)
      return data
    case 'GET_NOTIFICATIONS_DEVICE_WISE':
      return action.notification
    default:
      return state
  }
}

export function selectednotification (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST':
      return action.notifications
    case 'UPDATE_NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.notification.id) {
          return m
        } else {
          return action.notification
        }
      })
      return updatedata

    case 'ADD_NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.notification)
    case 'REMOVED_NOTIFICATIONS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.notification.id)
      return rData
    default:
      return state
  }
}
export function selecteddriver (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'DRIVERS_DEVICE_WISE_SELECTED_LIST':
      return action.drivers
    case 'UPDATE_DRIVERS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.driver.id) {
          return m
        } else {
          return action.driver
        }
      })
      return updatedata

    case 'ADD_DRIVERS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.driver)
    case 'REMOVED_DRIVERS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.driver.id)
      return rData
    default:
      return state
  }
}

export function selectedtrailer (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'TRAILERS_DEVICE_WISE_SELECTED_LIST':
    return action.trailers
    case 'UPDATE_TRAILERS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.trailer.id) {
          return m
        } else {
          return action.trailer
        }
      })
      return updatedata

    case 'ADD_TRAILERS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.trailer)
    case 'REMOVED_TRAILERS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.trailer.id)
      return rData
    default:
      return state
  }
}
export function selecteddashboard (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'DASHBOARDS_DEVICE_WISE_SELECTED_LIST':
    return action.dashboards
    case 'UPDATE_DASHBOARDS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.dashboard.id) {
          return m
        } else {
          return action.dashboard
        }
      })
      return updatedata

    case 'ADD_DASHBOARDS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.dashboard)
    case 'REMOVED_DASHBOARDS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.dashboard.id)
      return rData
    default:
      return state
  }
}
export function selectedwidget (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'WIDGETS_DEVICE_WISE_SELECTED_LIST':
    return action.widgets
    case 'UPDATE_WIDGETS_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.widget.id) {
          return m
        } else {
          return action.widget
        }
      })
      return updatedata

    case 'ADD_WIDGETS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.widget)
    case 'REMOVED_WIDGETS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.widget.id)
      return rData
    default:
      return state
  }
}
export function selectedvehicle (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'VEHICLES_DEVICE_WISE_SELECTED_LIST':
    return action.vehicles
    case 'UPDATE_VEHICLES_DEVICE_WISE_SELECTED_LIST':
      let updatedata = state.map(m => {
        if (m.id !== action.vehicle.id) {
          return m
        } else {
          return action.vehicle
        }
      })
      return updatedata

    case 'ADD_VEHICLES_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.vehicle)
    case 'REMOVED_VEHICLES_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.vehicle.id)
      return rData
    default:
      return state
  }
}
export function selecteduser (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'USERS_DEVICE_WISE_SELECTED_LIST':
      return [...action.users]
    case 'UPDATE_USERS_DEVICE_WISE_SELECTED_LIST':
      const data = [...state];
      let updatedata = data.map(m => {
        if (m.id !== action.user.id) {
          return {...m}
        } else {
          return {...action.user}
        }
      })
      return updatedata

    case 'ADD_USERS_DEVICE_WISE_SELECTED_LIST':
      return state.concat(action.user)
    case 'REMOVED_USERS_DEVICE_WISE_SELECTED_LIST':
      let rData = state.filter(m => m.id !== action.user.id)
      return rData
    default:
      return state
  }
}

export function notificationChannel (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_NOTIFICATIONS_CHANNEL':
      return action.notifications
    default:
      return state
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification
          }
        ]
      }

    case 'REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      }

    default:
      return state
  }
}

export function events (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'ADD_EVENTS':
      return action.events
    default:
      return state
  }
}

export function footerNotifications (state = [], action) {
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'ADD_FOOTER_NOTIFICATIONS':
      return state.concat(action.events)

    case 'ADD_EVENTS_BY_NODE':
      state.unshift(action.events)
      return state

    default:
      return state
  }
}

export function notificationCount (state = 0, action) {
  switch (action.type) {
    case 'RESET_APP':
      return 0

    case 'UPDATE_NOTIFICATION_COUNT':
      return action.payload

    default:
      return state
  }
}

export function notificationSettings (
  state = [
    {
      id: 1,
      label: 'overspeed',
      icon: 'overspeed',
      class: 'danger',
      notificationKey: 'overSpeed',
      checked: true
    },
    {
      id: 2,
      label: 'idling',
      icon: 'idling',
      class: 'warning',
      notificationKey: 'Idling',
      checked: true
    },
    {
      id: 3,
      label: 'notification.harshBrake',
      icon: 'harshbreak',
      class: 'danger',
      notificationKey: 'harshBreak',
      checked: false
    },
    {
      id: 4,
      label: 'moving',
      icon: 'deviceMoving',
      class: 'success',
      notificationKey: 'Moving',
      checked: false
    },
    {
      id: 5,
      label: 'towing',
      icon: 'towing',
      class: 'warning',
      notificationKey: 'Towing',
      checked: false
    },
    {
      id: 6,
      label: 'notification.deviceOnline',
      icon: 'statusOnline',
      class: 'success',
      notificationKey: 'statusOnline',
      checked: true
    },
    {
      id: 7,
      label: 'notification.deviceOffline',
      icon: 'offline',
      class: 'danger',
      notificationKey: 'statusOffline',
      checked: false
    },
    {
      id: 8,
      label: 'parking',
      icon: 'parking',
      class: 'default',
      notificationKey: 'statusParking',
      checked: true
    },
    {
      id: 9,
      label: 'notification.insideGeofence',
      icon: 'geofence',
      class: 'warning',
      notificationKey: 'geofenceEntered',
      checked: true
    },
    {
      id: 10,
      label: 'notification.lowBattery',
      icon: 'lowBattery',
      class: 'danger',
      notificationKey: 'lowBattery',
      checked: false
    },
    {
      id: 11,
      label: 'notification.powerCut',
      icon: 'powerCut',
      class: 'danger',
      notificationKey: 'powerCut',
      checked: false
    },
    {
      id: 12,
      label: 'workFlowOn',
      icon: 'workFlowOff',
      class: 'danger',
      notificationKey: 'workFlowOn',
      checked: true
    },
    {
      id: 13,
      label: 'workFlowOff',
      icon: 'workFlowOff',
      class: 'danger',
      notificationKey: 'workFlowOff',
      checked: true
    }
  ],
  action
) {
  switch (action.type) {
    case 'UPDATE_NOTIFICATION_SETTING':
      return state.map(n => {
        if (n.id === action.payload.id) {
          return { ...n, checked: action.payload.checked }
        } else {
          return n
        }
      })

    //return { ...state.Idling, ...action };
    default:
      return state
  }
}


