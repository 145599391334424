import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import Style from 'style-it'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: ownProps.themecolors || state.themeColors,
  }
}

const styles = theme => ({})

class CustomTextField extends React.Component {
  render () {
    const { classes } = this.props

    const p = { ...this.props }

    delete p.dispatch

    return (
      <Style>
        {`
					.theme-label {
						margin-top: -4px;
						font-size: 12px;
						color: ${this.props.themecolors.textColor}
					}

					.text-field-helper-text {
						color: inherit;
					}
					
					.theme-label-shrink {
						margin-top: 0
					}
					.theme-label-focused {
						margin-top: 0;
					}
					.theme-textarea {
						font-size: 14px;
						padding: 0
					}
					.theme-label-error {
						color: ${this.props.themecolors.error}
					}
					.theme-notchedOutline-multiline,
					.theme-notchedOutline {
						border-color:  ${this.props.themecolors.themeLightColor} !important;
						border-radius: 4px;
						padding: 0;
					}
					.theme-input-error .theme-notchedOutline {
						border-color:  ${this.props.themecolors.error} !important;
					}
					.theme-cssFocused .theme-notchedOutline {
						border-color:  ${this.props.themecolors.themeLightColor} !important;
					}
					.theme-input-select {
						border-color: ${this.props.themecolors.themeLightColor};
						color: ${this.props.themecolors.textColor};
						padding: 0;
						display: flex;
            height: auto
					}
					.theme-textarea,
					.theme-input {
						border-color: ${this.props.themecolors.themeLightColor};
						color: ${this.props.themecolors.textColor};
						font-size: 12px;
						padding: 5px 14px;
						min-height: 18px
					}
					.menu-list-icon {
						color: ${this.props.themecolors.textColor};
					}
					.custom-paper {
						background-color: ${this.props.themecolors.themeLightColor};
					}
				`}
        <div style={{ display: 'flex', flex: 1 }}>
          <TextField
            margin='dense'
            {...p}
            variant='outlined'
            classes={classes}
            InputProps={{
              ...this.props.InputProps,
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: this.props.multiline ? 'theme-textarea' : 'theme-input',
                focused: 'theme-cssFocused',
                multiline: 'theme-notchedOutline-multiline',
                notchedOutline: 'theme-notchedOutline'
              },
            }}
            FormHelperTextProps={{
              classes: { root: 'text-field-helper-text' }
            }}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: 'custom-menu-paper'
                }
              },
              classes: {
                icon: 'menu-list-icon'
              }
            }}
            InputLabelProps={{
              ...this.props.InputLabelProps,
              classes: {
                root: 'theme-label',
                focused: 'theme-label-focused',
                shrink: 'theme-label-shrink',
                error: 'theme-label-error'
              }
            }}
          />
        </div>
      </Style>
    )
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(withStyles(styles)(CustomTextField))
