import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '../../../common/TextField'
import Checkbox from '../../../common/Checkbox'
import { GeofencesModal } from './geofences'
import MenuItem from '../../../common/MenuItem'
import Radio from '../../../common/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchDevices from '../../../Devices/searchItems'
import Paper from '@material-ui/core/Paper'
import { Screen1 } from '../Screen1'
import Tooltip from '../../../common/Tooltip'
import Button from '../../../common/Button'
import Slider from '@material-ui/core/Slider'
import { digitalAdvTypes, notificationFunctionTypes, Types } from '.'
import "./dateTime.css";

const Modal = props => {
  const [open, setOpen] = React.useState(false)

  const paperStyle2 = {
    background: 'none',
    marginBottom: 10
  }
  const paperStyle3 = {
    background: 'none',
    marginBottom: 10
  }
  const handleChange = (name, el) => {
    const key = el.props.value
    if (name === 'notification') {
      const row = notificationFunctionTypes.find(
        e => e.key === props.form.functionalType
      )
      const currentRow = Types[props.form.functionalType].find(
        e => e.value === key
      )
      props.handleChange(
        row.field,
        currentRow.value,
        row.subField,
        currentRow.input
      )
    }
    else {
      if (props.form.functionalType !== key) {
        setOpen(true)
      } else {
        setOpen(!open)
      }

      props.handleChange('functionalType', key)
    }
  }

  const drawUnit = unit => {
    if(unit === 'kmh') {
      return props.translate('sharedKmh')
    }
    else if(unit === 'mph') {
      return props.translate('sharedMph')
    }
    else if(unit === 'kn') {
      return props.translate('sharedKn')
    }
    else {
      return 'km/h';
    }
  }

  const speedUnit = props.logInUser && props.logInUser.attributes && props.logInUser.attributes.speedUnit ? drawUnit(props.logInUser.attributes.speedUnit) : 'km/h';
  return (
    <>
      <Grid container spacing={0} style={{ minHeight: 270 }}>
        <Grid item xs={12} md>
          <div style={{ padding: 20 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={4} style={{ minWidth: 250 }}>
                <TextField
                  select
                  fullWidth
                  onChange={(e, el) => handleChange('notificationType', el)}
                  value={props.form.functionalType}
                  label={props.translate('functionalType')}
                >
                  {notificationFunctionTypes.map(row => (
                    <MenuItem key={row.key} value={row.key}>
                      {props.translate(row.key)}
                    </MenuItem>
                  ))}
                </TextField>
                {props.form.functionalType &&
                Types[props.form.functionalType] ? (
                  <TextField
                    select
                    fullWidth
                    onChange={(e, el) => handleChange('notification', el)}
                    label={props.translate('sharedNotification')}
                    value={props.form.type}
                  >
                    {Types[props.form.functionalType].map(row => (
                      <MenuItem key={row.value} value={row.value}>
                        {props.translate(row.label)}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}

                {props.form.functionalType &&
                  props.form.functionalType !== 'deviceStatus' &&
                  props.form.functionalType !== 'fuel' &&
                  props.form.type !== 'deviceMoving' &&
                  props.form.type !== 'deviceTowing' &&
                  (props.form.type ||
                    props.form.input ||
                    props.form.functionalType ===
                      'maintenanceReminder') && (
                    <>
                      {props.form.functionalType === 'movement' &&
                        props.form.type === 'deviceOverspeed' && (
                          <>
                            <h4
                              className='page-subtitle-notification'
                              style={{ marginTop: 16 }}
                            >
                              {props.translate('permittedSpeed')}
                            </h4>
                            {/* <TextField
                              id='tolerance'
                              label={
                                props.translate('tolerance') +
                                ' ' +
                                '/' +
                                (props.logInUser.attributes &&
                                props.logInUser.attributes.speedUnit
                                  ? props.translate(
                                      props.logInUser.attributes.speedUnit
                                    )
                                  : 'Km/h')
                              }
                              required
                              type='number'
                              value={props.form.tolerance || ''}
                              onChange={e => props.handleChange('tolerance', e)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                            /> */}
                            
                            

                            <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 30 }}>
                            <Slider
                              value={[props.form.minLimit, props.form.maxLimit]}
                              onChange={(e, range) => props.handleChange('speedRange', range)}
                              valueLabelDisplay='on'
                              aria-labelledby='range-slider'
                              max={500}
                              classes={{
                                root: 'theme-range-slider',
                                thumb: 'theme-range-slider-thumb',
                                track: 'theme-range-slider-track',
                                rail: 'theme-range-slider-rail',
                                valueLabel: 'theme-range-slider-label'
                              }}
                              step={5}
                            />
                          </div>
                          <Grid container spacing={2}>
                              <Grid item sm={4} xs={6}>
                                <TextField
                                id='min'
                                label={props.translate('min')}
                                placeholder=''
                                type='number'
                                value={props.form?.minLimit || ''}
                                onChange={e => props.handleChange('minLimit', e)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                              />
                              </Grid>
                              <Grid item sm={4} xs={6} style={{marginLeft: 'auto'}}>
                                <TextField
                                  id='max'
                                  label={props.translate('max')}
                                  required
                                  placeholder=''
                                  type='number'
                                  value={props.form.maxLimit || ''}
                                  onChange={e => props.handleChange('maxLimit', e)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <p style={{fontSize: '0.9em'}}>{props.translate('notificationOverSpeedMessage', {maxLimit: <strong className="text-warning">{props.form.maxLimit} {speedUnit}</strong>, minLimit: <strong className="text-warning">{props.form.minLimit} {speedUnit}</strong>})} </p>
                          </>
                        )}

                      {props.form.functionalType === 'movement' &&
                        props.form.type === 'deviceStopped' && (
                          <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate('state')}
                            </h4>
                            <TextField
                              id='threshold'
                              label={
                                props.translate('threshold') +
                                ' ' +
                                '/' +
                                props.translate('min')
                              }
                              required
                              placeholder=''
                              type='number'
                              value={props.form.threshold || ''}
                              onChange={e => props.handleChange('threshold', e)}
                              variant='outlined'
                              margin='dense'
                              helperText={props.translate('useIgnition')}
                              fullWidth
                            />

                            <Checkbox
                              label={props.translate('useIgnition')}
                              checked={props.form.useIgnition}
                              onChange={e =>
                                props.handleChange('useIgnition', e)
                              }
                              color='primary'
                            />
                          </>
                        )}
                      {props.form.functionalType === 'movement' &&
                        (props.form.type === 'deviceParking' ||
                          props.form.type === 'deviceIdling' ||
                          props.form.type === 'ecodriving') && (
                          <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate('state')}
                            </h4>
                            {props.form.type === 'deviceParking' ? (
                              <TextField
                                id='threshold'
                                label={
                                  props.translate('threshold') +
                                  ' ' +
                                  '/' +
                                  props.translate('hour')
                                }
                                required
                                type='number'
                                value={props.form.threshold !== undefined ? props.form.threshold : ''}
                                onChange={e =>
                                  props.handleChange('threshold', e)
                                }
                                variant='outlined'
                                margin='dense'
                                helperText={props.translate('setZeroValue')}
                                fullWidth
                              />
                            ) : (
                              <TextField
                                id='threshold'
                                label={
                                  props.translate('threshold') +
                                  ' ' +
                                  '/' +
                                  props.translate('min')
                                }
                                required
                                type='number'
                                value={props.form.threshold !== undefined ? props.form.threshold : ''}
                                onChange={e =>
                                  props.handleChange('threshold', e)
                                }
                                variant='outlined'
                                margin='dense'
                                helperText={props.translate('setZeroValue')}
                                fullWidth
                              />
                            )}
                          </>
                        )}
                      {props.form.functionalType === 'digital' &&
                        props.form.type && (
                          <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate(props.form.functionalType)}
                            </h4>
                            {props.form.type === 'digitalCustom' ? (
                              <TextField
                                id='input'
                                label={props.translate('input')}
                                type='text'
                                value={props.form.input || ''}
                                onChange={e =>
                                  props.handleChange('input', e.target.value)
                                }
                                variant='outlined'
                                margin='dense'
                                fullWidth
                              />
                            ) : null}

                            <TextField
                              id='logic'
                              label={props.translate('state')}
                              type='text'
                              value={props.form.logic || ''}
                              onChange={e => props.handleChange('logic', e)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              select
                            >
                              {digitalAdvTypes.map(option => (
                                <MenuItem key={option.key} value={option.key}>
                                  {props.translate(option.key)}
                                </MenuItem>
                              ))}
                            </TextField>
                          </>
                        )}
                        {props.form&&props.form.type === "parkingSecurity" ? (
                        <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate(props.form.functionalType)}
                            </h4>
                            <div>
                              <TextField
                                id="datetime-local"
                                label="Start Time"
                                type="datetime-local"
                                value={props.form.startTime}
                                defaultValue={new Date()}
                                onChange={e => props.handleChange('startTime', e)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                             />
                            </div>
                          
                            <div>
                              <TextField
                                id="datetime-local"
                                label="End Time"
                                type="datetime-local"
                                defaultValue={new Date()}
                                value={props?.form?.endTime || ''}
                                onChange={e => props.handleChange('endTime', e)}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                             />
                            </div>
                              <TextField
                                  id='fenceRadius'
                                  label={props.translate('Fence Radius') + ' (m)'}
                                  type='text'
                                  value={props.form.fenceRadius || '' }
                                  onChange={e => props.handleChange('fenceRadius', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              <TextField
                                  id='ps_plateNumber'
                                  label={props.translate('ps_plateNumber')}
                                  type='text'
                                  value={props.form.ps_plateNumber || '' }
                                  onChange={e => props.handleChange('ps_plateNumber', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              <TextField
                                  id='ps_trafficFileNumber'
                                  label={props.translate('ps_trafficFileNumber')}
                                  type='text'
                                  value={props.form.ps_trafficFileNumber || '' }
                                  onChange={e => props.handleChange('ps_trafficFileNumber', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              <TextField
                                  id='ps_brand'
                                  label={props.translate('ps_brand')}
                                  type='text'
                                  value={props.form.ps_brand || '' }
                                  onChange={e => props.handleChange('ps_brand', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              <TextField
                                  id='Model'
                                  label={props.translate('ps_model')}
                                  type='text'
                                  value={props.form.ps_model || '' }
                                  onChange={e => props.handleChange('ps_model', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              <TextField
                                  id='ps_color'
                                  label={props.translate('ps_color')}
                                  type='text'
                                  value={props.form.ps_color || '' }
                                  onChange={e => props.handleChange('ps_color', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                                <TextField
                                  select
                                  fullWidth
                                  onChange={e => props.handleChange('ps_state', e.target.value)}
                                  value={props.form.ps_state}
                                  label={props.translate('ps_state')}
                                >
                                  <MenuItem value='Abu Dhabi'>{props.translate('Abu Dhabi')}</MenuItem>
                                  <MenuItem value='Dubai'>{props.translate('Dubai')}</MenuItem>
                                  <MenuItem value='Sharjah'>{props.translate('Sharjah')}</MenuItem>
                                  <MenuItem value='Ras Al Khaimah'>{props.translate('Ras Al Khaimah')}</MenuItem>
                                  <MenuItem value='Ajman'>{props.translate('Ajman')}</MenuItem>
                                  <MenuItem value='Umm Al Quwain'>{props.translate('Umm Al Quwain')}</MenuItem>
                                  <MenuItem value='Fujairah'>{props.translate('Fujairah')}</MenuItem>
                                </TextField>
                              </>
                            ) : null}
                        {props.form&&props.form.type === "exactValue" ? (
                        <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate(props.form.functionalType)}
                            </h4>
                               <TextField
                                  id='input'
                                  label={props.translate('input')}
                                  type='text'
                                  value={props.form.input || '' }
                                  onChange={e => props.handleChange('input', e.target.value)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                                <TextField
                                  select
                                  fullWidth
                                  onChange={e => props.handleChange('valueType', e.target.value)}
                                  value={props.form.valueType}
                                  label={props.translate('valueType')}
                                >
                                  <MenuItem value='string'>{props.translate('string')}</MenuItem>
                                  <MenuItem value='number'>{props.translate('number')}</MenuItem>
                                  <MenuItem value='boolean'>{props.translate('boolean')}</MenuItem>
                                </TextField>
                                {props.form.valueType === "boolean" ? (
                                    <TextField
                                    select
                                    fullWidth
                                    onChange={e => props.handleChange('value', e.target.value)}
                                    value={props.form.value === false? 'false': props.form?.value}
                                    label={props.translate('value')}
                                  >
                                    <MenuItem value='true'>{props.translate('true')}</MenuItem>
                                    <MenuItem value='false'>{props.translate('false')}</MenuItem>
                                  </TextField>
                                ):(
                                    <TextField
                                      id='value'
                                      label={props.translate('value')}
                                      type={props.form.valueType === "number"  ? "number" : 'text'}
                                      value={props.form?.value || '' }
                                      onChange={e => props.handleChange('value', e.target.value)}
                                      variant='outlined'
                                      margin='dense'
                                      fullWidth
                                    />
                                )}
                              </>
                              
                            ) : null}

                      {props.form.functionalType === 'analog' &&
                        props.form.type && (
                          <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate(props.form.functionalType)}
                            </h4>

                            {props.form.type !== 'analogCustom' ? <TextField
                              id='index'
                              label={props.translate('index')}
                              placeholder=''
                              type='number'
                              value={props.form.index || ''}
                              onChange={e => props.handleChange('index', e)}
                              variant='outlined'
                              margin='dense'
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    disableTypography
                                    position='end'
                                    style={{
                                      color: props.themecolors.textColor,
                                      fontSize: 10
                                    }}
                                  >
                                    {props.form.input || ''}
                                  </InputAdornment>
                                )
                              }}
                            />:<TextField
                            id='input'
                            label={props.translate('input')}
                            type='text'
                            value={props.form.input || ''}
                            onChange={e =>
                              props.handleChange('input', e.target.value)
                            }
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />}
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  id='min'
                                  label={props.translate('min')}
                                  required
                                  placeholder=''
                                  type='number'
                                  value={props.form.minLimit !== undefined ? props.form.minLimit : ''}
                                  onChange={e => props.handleChange('minLimit', e)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  id='max'
                                  label={props.translate('max')}
                                  required
                                  placeholder=''
                                  type='number'
                                  value={props.form.maxLimit !== undefined ? props.form.maxLimit : ''}
                                  onChange={e => props.handleChange('maxLimit', e)}
                                  variant='outlined'
                                  margin='dense'
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <p style={{fontSize: '0.9em'}}>{props.translate('notificationAnalogMessage', {maxLimit: <strong className="text-warning">{props.form.maxLimit}</strong>, minLimit: <strong className="text-warning">{props.form.minLimit}</strong>, between: props.form.logic === 'OUT' ? ' not between ' : ' between '})} </p>
                            <Paper elevation={0} style={paperStyle2}>
                              <h5
                                className='page-subtitle-notification '
                                style={{ marginTop: 16 }}
                              >
                                {props.translate('triggerWhen')}
                              </h5>
                              <FormControl component='fieldset'>
                                <RadioGroup
                                  row
                                  aria-label='position'
                                  name='position'
                                  value={props.form.logic}
                                  onChange={e => props.handleChange('logic', e)}
                                >
                                  <FormControlLabel
                                    classes={{
                                      label: 'form-contol-label'
                                    }}
                                    value='IN'
                                    control={<Radio color='primary' />}
                                    label={props.translate('inRange')}
                                    labelPlacement='end'
                                  />
                                  <FormControlLabel
                                    classes={{
                                      label: 'form-contol-label'
                                    }}
                                    value='OUT'
                                    control={<Radio color='primary' />}
                                    label={props.translate('outRange')}
                                    labelPlacement='end'
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </>
                        )}
                      {props.form.functionalType === 'other' &&
                        props.form.input && (
                          <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ marginTop: 16 }}
                            >
                              {props.translate('other')}
                            </h4>
                            <Checkbox
                              label={props.translate('considerNull')}
                              checked={props.form.considerNull}
                              onChange={e =>
                                props.handleChange('considerNull', e)
                              }
                              color='primary'
                            />
                          </>
                        )}
                      {props.form.functionalType ===
                        'maintenanceReminder' && (
                        <>
                          <h4
                            className='page-subtitle-notification '
                            style={{ marginTop: 16 }}
                          >
                            {props.translate(
                              'notification.' + props.form.functionalType
                            )}
                          </h4>
                          <div style={{ zIndex: 3, position: 'relative' }}>
                            <SearchDevices
                              api='maintenance'
                              isMulti={false}
                              fill
                              isClearable
                              placeholder={props.translate('searchMaintenance')}
                              onChange={props.onChangedMaintence}
                              value={props.form.maintenanceData}
                            />
                          </div>
                          <TextField
                            id='minLimit'
                            label={props.translate('notifyMeOnDue') + ' '}
                            required
                            placeholder=''
                            type='number'
                            value={props.form.minLimit || ''}
                            onChange={e => props.handleChange('minLimit', e)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />

                          <TextField
                            id='maxLimit'
                            label={
                              props.translate('remindMeBefore') + ' '
                            }
                            required
                            placeholder=''
                            type='number'
                            value={props.form.maxLimit || ''}
                            onChange={e => props.handleChange('maxLimit', e)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                        </>
                      )}
                      {props.form.functionalType === 'output' && (
                        <>
                          <h4
                            className='page-subtitle-notification '
                            style={{ marginTop: 16 }}
                          >
                            {props.translate(props.form.functionalType)}
                          </h4>
                          <TextField
                            id='input'
                            label={props.translate('input')}
                            required
                            placeholder=''
                            max='10'
                            type='number'
                            value={props.form.input || ''}
                            onChange={e =>
                              props.handleChange('input', e.target.value)
                            }
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                          <FormControl component='fieldset'>
                            <RadioGroup
                              row
                              aria-label='position'
                              name='position'
                              value={props.form.logic}
                              onChange={e => props.handleChange('logic', e)}
                            >
                              <FormControlLabel
                                classes={{
                                  label: 'form-contol-label'
                                }}
                                value='ON'
                                control={<Radio color='primary' />}
                                label={props.translate('ON') + ' 0)'}
                                labelPlacement='end'
                              />
                              <FormControlLabel
                                classes={{
                                  label: 'form-contol-label'
                                }}
                                value='OFF'
                                control={<Radio color='primary' />}
                                label={props.translate('OFF') + ' 1)'}
                                labelPlacement='end'
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      )}
                      {props.form.functionalType === 'ecodriving' && (
                        <>
                          <h4
                            className='page-subtitle-notification '
                            style={{ marginTop: 16 }}
                          >
                            {props.translate(props.form.functionalType)}
                          </h4>
                          <TextField
                            id='tolerance'
                            label={
                              props.translate('tolerance') + ' g'
                            }
                            required
                            type='number'
                            value={props.form.tolerance || ''}
                            onChange={e => props.handleChange('tolerance', e)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                          <TextField
                            id='min'
                            label={
                              props.translate('minLimit') + ' g'
                            }
                            required
                            placeholder=''
                            type='number'
                            value={props.form.minLimit || ''}
                            onChange={e => props.handleChange('minLimit', e)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                          <TextField
                            id='max'
                            label={
                              props.translate('maxLimit') + ' g'
                            }
                            required
                            placeholder=''
                            type='number'
                            value={props.form.maxLimit || ''}
                            onChange={e => props.handleChange('maxLimit', e)}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                        </>
                      )}
                    </>
                  )}
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              {props.form.functionalType &&
                props.form.functionalType !== 'deviceStatus' &&
                props.form.functionalType !== 'fuel' &&
                props.form.type !== 'deviceMoving' &&
                props.form.type !== 'deviceTowing' &&
                (props.form.type ||
                  props.form.input ||
                  props.form.functionalType === 'maintenanceReminder') &&
                props.form.functionalType === 'movement' &&
                (props.form.type === 'deviceEnterGeofence' ||
                  props.form.type === 'deviceExitGeofence') && (
                  <>
                    <Grid item xs={12} md>
                      <Paper elevation={0} style={paperStyle3}>
                        <h4
                          className='page-subtitle-notification '
                          style={{ marginTop: 16 }}
                        >
                          {props.translate('Geofences')}
                        </h4>
                        <GeofencesModal
                          columns
                          showTwoInList
                          height={200}
                          logInUser={props.logInUser}
                          geoFences={props.form.geoFences}
                          geofencesHandelChange={props.geofencesHandelChange}
                          themecolors={props.themecolors}
                        />
                        {props.showLogicFields && (
                          <>
                            <h4
                              className='page-subtitle-notification '
                              style={{ paddingTop: 16 }}
                            >
                              {props.translate('logic')}
                            </h4>
                            <FormControl component='fieldset'>
                              <RadioGroup
                                row
                                aria-label='position'
                                name='position'
                                value={props.form.logic}
                                onChange={e => props.handleChange('logic', e)}
                              >
                                <FormControlLabel
                                  classes={{
                                    label: 'form-contol-label'
                                  }}
                                  value='AND'
                                  control={<Radio color='primary' />}
                                  label={props.translate('AND')}
                                  labelPlacement='end'
                                />
                                <FormControlLabel
                                  classes={{
                                    label: 'form-contol-label'
                                  }}
                                  value='OR'
                                  control={<Radio color='primary' />}
                                  label={props.translate('OR')}
                                  labelPlacement='end'
                                />
                              </RadioGroup>
                            </FormControl>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  </>
                )}
            </Grid>
          </div>
        </Grid>

        {(props.form.type || props.form.input) &&
          props.form.functionalType !== 'maintenanceReminder' && (
            <Grid item xs={12} md={3} style={{ minWidth: 250 }}>
              <div className='notification-model-common-section'>
                <Screen1 {...props} />
              </div>
            </Grid>
          )}
      </Grid>
      <div
        className='clearfix'
        style={{
          padding: '12px 20px 15px',
          borderTop: `1px solid ${props.themecolors.themeLightColor}`
        }}
      >
        {/* {props.mode && props.mode === 'update' && (
          <Button
            size='small'
            style={{ marginRight: 15 }}
            onClick={e => props.assignModal('user', props.assignData)}
          >
            {props.translate('assignUser')}{' '}
          </Button>
        )} */}

        <Tooltip title={props.translate('saveCurrentTabDataAndGotoNext')}>
          <span>
            <Button
              disabled={!props.validatedForm}
              variant='contained'
              onClick={props.next}
              style={{ float: 'right', marginTop: 4 }}
            >
              {props.translate('next')}
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  )
}
export default Modal
