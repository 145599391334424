import React from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from './Button'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors
  }
}

const modalCloseBtn = {
  position: 'absolute',
  right: 15,
  top: 15
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    background: 'none',
    color: 'inherit',
    //height: '100%',
    boxShadow: 'none'
  }
})

class SimpleModal extends React.Component {
  constructor (props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.state = {
      open: this.props.visable
    }
  }

  handleClose () {
    this.setState({ open: false })
    this.props.modalControle()
  }

  render () {
    const { classes } = this.props
    if (this.state.open) {
      return (
        <Paper
          className={classes.root}
          elevation={
            this.props.elevation !== undefined ? this.props.elevation : 2
          }
        >
          <div style={{ padding: 16 }}>
            {this.props.title && (
              <h3
                style={{ margin: '5px', marginBottom: '15px' }}
                className='with-border'
              >
                {this.props.translate(this.props.title)}
              </h3>
            )}
            {!this.props.btnCancelNotShow && (
              <Button
                style={modalCloseBtn}
                size='small'
                variant='outlined'
                onClick={this.handleClose}
              >
                {this.props.translate(
                  this.props.btnCloseText ? this.props.btnCloseText : ''
                ) || 'X'}
              </Button>
            )}
            <div>{this.props.content}</div>
            {this.props.isButtonVisable ? (
              <div
                style={{
                  borderTop: '1px outset ' + this.props.themecolors.themeLightColor,
                  paddingTop: 16,
                  marginTop: 15
                }}
                className='clearfix'
              >
                {this.props.title === 'Attributes' ? null : (
                  <div style={{ display: 'inline' }}>
                    {this.props.HaveGroupCreateAccess &&
                      this.props.addNewGroups &&
                      !this.props.notShowGroupBtn && (
                        <Button
                          onClick={this.props.addNewGroups}
                          variant='outlined'
                          style={{ marginRight: 10 }}
                        >
                          {this.props.translate('Groups')}
                        </Button>
                      )}
                    {this.props.notShowAttributesBtn ? (
                      ''
                    ) : (
                      <Button
                        className={
                          classes.button +
                          (this.props.attributeChangesMessage
                            ? ' alter'
                            : ' button-white')
                        }
                        onClick={() => this.props.addAttributes()}
                        style={{ marginRight: 10 }}
                      >
                        {this.props.translate('sharedAttribute')}
                      </Button>
                    )}
                    {this.props.showPort && (
                      <Button
                        onClick={() => this.props.showPortList()}
                        style={{ marginRight: 10 }}
                      >
                        {this.props.translate('deviceModel')}
                      </Button>
                    )}
                  </div>
                )}

                {this.props.waslFooter}
                {!this.props.hasPremissions && (
                  <Button
                    className={classes.button + ' pull-right'}
                    disabled={!this.props.isVisableBtn}
                    onClick={() => this.props.formSubmit()}
                  >
                    {' '}
                    {this.props.translate(this.props.buttonText)}
                  </Button>
                )}

                {this.props.hasPremissions && this.props.canUpdate && (
                  <Button
                    className={classes.button + ' pull-right'}
                    disabled={!this.props.isVisableBtn}
                    onClick={() => this.props.formSubmit()}
                  >
                    {' '}
                    {this.props.translate(this.props.buttonText)}
                  </Button>
                )}

                {this.props.attributeChangesMessage && (
                  <span style={{ display: 'block', padding: '10px 0' }}>
                    {' '}
                    {this.props.attributeChangesMessage}
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </Paper>
      )
    } else {
      return null
    }
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired
}

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withLocalize(withStyles(styles)(SimpleModal))

export default connect(mapStateToProps)(SimpleModalWrapped)
