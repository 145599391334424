import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import MenuItem from '../../../Components/common/MenuItem'
import { withSnackbar } from 'notistack'
import TextField from '../../../Components/common/TextField'
import Button from '../../../Components/common/Button'
import Loader from '../../../Layout/Loader'
import { EcoDrivingTable } from './drawEcoDriving'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import Notifications from 'react-notification-system-redux'
import CustomDialog from '../../../Components/common/Dialog'
import Table from '../../../Components/common/TableMultiCheckbox'
import instance from '../../../axios'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class EcoDriving extends Component {
  constructor () {
    super()
    this.state = {
      isRecived: false,
      selecteditem: '',
      currentId: 0,
      editOption: false,
      isVisibleBtn:false,
      showDialg:false,
      displayList:[],
      listData:false,
      form: {
        preset: '',
        id: '',
        attributes: {
          mode:"",
        }
      }
    }
    this.Submit = this.Submit.bind(this)
  }

  fetchEcoData = id => {
   
    instance({
      url: `/api/ecodriving/`,
      method: 'GET',
      params: {
        deviceId: id
      }
    })
        .then(comp => {
          if (comp.length) {
            let res= comp[0].attributes
            let speedExtreme2 = []
            // let speedMedium =  res.speedMedium ? res.speedMedium : []


            if (res.speedExtreme) {
              speedExtreme2 = res.speedExtreme;
              speedExtreme2[0] = parseFloat(speedExtreme2[0]) * 1.852;
              speedExtreme2[1] = parseFloat(speedExtreme2[1]) * 1.852;
            }


                // speedExtreme[0] = parseFloat(speedExtreme[0]) * 1.852;
                // speedExtreme[1] = parseFloat(speedExtreme[1]) * 1.852;

                let speedMedium2 =  []
              
  
                if (res.speedMedium) {
                  speedMedium2 = res.speedMedium;
                  speedMedium2[0] = parseFloat(speedMedium2[0]) * 1.852;
                  speedMedium2[1] = parseFloat(speedMedium2[1]) * 1.852;
                }

                // speedMedium[0] = parseFloat(speedMedium[0]) * 1.852;
                // speedMedium[1] = parseFloat(speedMedium[1]) * 1.852;





                this.setState({
                  form: {
                    attentance: true,
                    preset: comp[0].attributes.preset,
                    id: comp[0].id,
                    attributes: {
                      ...comp[0].attributes,
                      mode:comp[0]?.attributes?.mode || "",
                      // speedExtreme: speedExtreme,
                      ...(speedExtreme2.length > 0 ? { speedExtreme: speedExtreme2 } : {}),
                      ...(speedMedium2.length > 0 ?  { speedMedium: speedMedium2 } : {}),

                      // speedMedium: speedMedium,

                    }
                  },
                  editOption: true
                })
              } else {
                this.setState({
                  editOption: false,
                  form: {
                    preset: '',
                    mode: '',
                    id: '',
                    attributes: {}
                  }
                })
              }
              this.setState({
                isRecived: true
              })
    })
    .catch(error => {
      errorHandler(error,this.props.dispatch)
      // this.props.enqueueSnackbar(error.message, { autoHideDuration: 1000 })
    }) 
  }

  componentWillMount () {
    if (this.props.selectItemId !== this.state.currentId) {
      this.setState(
        {
          currentId: this.props.selectItemId
        },
        () => {
          this.fetchEcoData(this.props.selectItemId)
        }
      )
    }
  }
  componentWillReceiveProps (n) {
    if (n.selectItemId !== this.state.currentId) {
      this.setState(
        {
          currentId: n.selectItemId
        },
        () => {
          this.fetchEcoData(n.selectItemId)
        }
      )
    }
  }  


  replaceNullWithZero = (data) => {
    // console.log("data====", data);
    if (Array.isArray(data)) {
      return data.map(item => this.replaceNullWithZero(item));
    } else if (typeof data === 'object' && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        acc[key] = this.replaceNullWithZero(data[key]);
        return acc;
      }, {});
    } else {
      return data === null || Number.isNaN(data) ? 0 : data;

      // return data === null || (typeof data === 'number' && isNaN(data)) ? 0 : data;
    }
  };
  
  Submit = () => {
    this.setState(
      {
        isRecived: false
      },
      () => {
        let { editOption, form } = this.state;
        let speedExtremeUpdate = [];
        let speedMediumUpdate = [];
        let idleExtremeUpdate = [];
        let idleMediumUpdate = [];
        let option = 'POST';
        let callSufax = '';
        if (editOption) {
          option = 'PUT';
          callSufax = `/${this.props.selectItemId}`;
        }
        
        if (form && form.attributes) {
          if (form.attributes.speedExtreme) {
            speedExtremeUpdate = [...form.attributes.speedExtreme];
            speedExtremeUpdate[0] = parseFloat(speedExtremeUpdate[0]) / 1.852 || 0;
            speedExtremeUpdate[1] = parseFloat(speedExtremeUpdate[1]) / 1.852 || 0;
          }
  
          if (form.attributes.speedMedium) {
            speedMediumUpdate = [...form.attributes.speedMedium];
            speedMediumUpdate[0] = parseFloat(speedMediumUpdate[0]) / 1.852 || 0;
            speedMediumUpdate[1] = parseFloat(speedMediumUpdate[1]) / 1.852 || 0;
          }

          if (form.attributes.idleExtreme) {
            idleExtremeUpdate = [...form.attributes.idleExtreme];
            idleExtremeUpdate[0] = parseFloat(idleExtremeUpdate[0]) ||  0;
            idleExtremeUpdate[1] = parseFloat(idleExtremeUpdate[1]) ||  0;
          }

          if (form.attributes.idleMedium) {
            idleMediumUpdate = [...form.attributes.idleMedium];
            idleMediumUpdate[0] = parseFloat(idleMediumUpdate[0]) ||  0;
            idleMediumUpdate[1] = parseFloat(idleMediumUpdate[1]) ||  0;
          }

        }
  
        // Replace null values with 0 directly in the attributes
        const updatedAttributes = this.replaceNullWithZero(form.attributes);
  
        let obj = {
          id: editOption ? form.id : '',
          attributes: {
            ...updatedAttributes, // Use the updatedAttributes directly
            preset: form.preset,
            ...(speedExtremeUpdate.length > 0 ? { speedExtreme: speedExtremeUpdate } : {}),
            ...(speedMediumUpdate.length > 0 ? { speedMedium: speedMediumUpdate } : {}),
            ...(idleExtremeUpdate.length > 0 ? { idleExtreme: idleExtremeUpdate } : {}),
            ...(idleMediumUpdate.length > 0 ? { idleMedium: idleMediumUpdate } : {}),
          },
          entityId: this.props.selectItemId,
          property: 'ecoDriving',
          description: ''
        };

        instance({
          url: `/api/ecodriving${callSufax}`,
          method: `${option}`,
          data: {
            ...obj
          }
        })
          .then(comp => {
              let res= comp.attributes

              let speedExtreme2 = [];

              if (res.speedExtreme) {
                speedExtreme2 = res.speedExtreme;
                speedExtreme2[0] = parseFloat(speedExtreme2[0]) * 1.852;
                speedExtreme2[1] = parseFloat(speedExtreme2[1]) * 1.852;
              }

              let speedMedium2 =  []
              
              // speedExtreme2[0] = parseFloat(speedExtreme2[0]) * 1.852;
              // speedExtreme2[1] = parseFloat(speedExtreme2[1]) * 1.852;

              if (res.speedMedium) {
                speedMedium2 = res.speedMedium;
                speedMedium2[0] = parseFloat(speedMedium2[0]) * 1.852;
                speedMedium2[1] = parseFloat(speedMedium2[1]) * 1.852;
              }

               this.setState({
                form: {
                  id: comp.id,
                  preset: comp.attributes.preset,
                  attributes: {
                    ...comp.attributes,
                    ...(speedExtreme2.length > 0 ? { speedExtreme: speedExtreme2 } : {}),
                    ...(speedMedium2.length > 0 ? { speedMedium: speedMedium2 } : {}),
                    // speedMedium: speedMedium2
                  }
                },
                editOption: true,
                isRecived: true
              })



            if (option === 'POST') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('createdSuccessfully'),
                  autoDismiss: 10
                })
              )
              } else {
                if(Object.keys(comp.attributes).length > 1) {
                  this.props.dispatch(
                    Notifications.success({
                      message: this.props.translate('updatedSuccessfully'),
                      autoDismiss: 10
                    })
                  )
                }
                else {
                  this.DeleteSubmit();
                }                    
              }
          })
          .catch(error => {
            errorHandler(error,this.props.dispatch)
            this.setState({isRecived: true})
          }) 
      }
    )
  }

  DeleteSubmit = () => {
    let { form } = this.state
    instance({
      url: `/api/ecodriving/${form.id}`,
      method: `DELETE`
    })
      .then(response => {
        // if (response.ok) {
          this.setState({
            form: {
              mode:"",
              preset: '',
              attributes: ''
            },
            editOption: false,
            isRecived: true,
            isVisibleBtn:false
          })
          this.props.enqueueSnackbar(
            this.props.translate('attributeIsDelete'),
            { autoHideDuration: 1000 }
          )
      })
      .catch(error => {errorHandler(error,this.props.dispatch)}) 
  }

  handleChange = name => event => {
    const { target } = event
    let value = target.type === 'checkbox' ? target.checked : target.value
    if ((value === 'none' || value === '') && this.state.editOption) {
      // call delete
      this.setState(
        {
          isRecived: false
        },
        () => this.DeleteSubmit()
      )
    }
    if(name === 'mode'){
      this.setState({
        form: {
          ...this.state.form,
         attributes:{
          ...this.state.form.attributes,
          [name]:value
         }
        }
      },()=>{
        this.checkReqFields()
      })
    }
    else{
      this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
      },()=>{
        this.checkReqFields()
      })
  }
} 
  checkReqFields = ()=>{
    const {attributes} = this.state.form
    if(attributes.mode && this.state.form.preset){
      this.setState({isVisibleBtn:true})
    }
  }

  handleChangeAttributesValue = (itemKey, index, event) => {
    let { attributes } = { ...this.state.form }
    if (event) {
      const { target } = event
      let value = target.value
       attributes[itemKey][index] = value ? (value) : null
      this.setState({
        attributes: {
          ...this.state.form.attributes,
          attributes
        }
      })
    }
  }
  onDelete = Itemkey => {
    let { attributes } = { ...this.state.form }
    delete attributes[Itemkey]

    this.setState(

      {
        attributes: {
          ...this.state.form.attributes,
          attributes
        },
        isRecived: false
      },
      () => this.Submit()
    )
  }
  onUpdate = () => {
    this.setState(
      {
        isRecived: false
      },
      () => this.Submit()
    )
  }
  copyEcoDriving = ()=>{
     this.setState({
      showDialg:true,
    })
  }

  handleModelSelect = (name, event) => {
    // Update the selectedModel in state when a model is selected
    const val = event.target.value;
    this.setState({ 
      listData:val?true:false, 
      selectedModel: val
    }, ()=> {  if(val){this.getModelBasedDevices(event.target.value)}});
  }

  getModelBasedDevices = (id)=>{
    instance({
      url: `/api/devices/list?userId=${this.props?.logInUser?.id}&all=true`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params :{
        limit: -1, 
        model:id
      }
    })
        .then(res => {
          this.setState(
            {
              displayList: res.data
            }
          )
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }
  selectAllFilteredUnits = (e)=>{
    let allData = this.state.displayList
    let sensorDeviceids = [];

    allData.map(dt => {
        dt.check = e ? true : false;
        // Push the ID into the sensorDeviceids array
        if (e) {
          sensorDeviceids.push(dt.id);
      }
    });
    if(e){
      this.setState({
          // sensorDeviceids:sensorDeviceids,
          allDevices :allData
      })
    }
    else{
      // sensorDeviceids=[]
      // this.props.getIdsfromChild(sensorDeviceids)
      this.setState({
        // sensorDeviceids:[],
        allDevices :allData
    })

    }

    this.props.getIdsfromChild(sensorDeviceids)

  }
  componentDidMount() {
    // Assuming this.props?.allDevices is your array of devices
    const allDevices = this.props?.allDevices || [];

    // Create an array to store unique models
    const uniqueModels = [];

    // Create a Set to keep track of seen models
    const seenModels = new Set();

    // Iterate through each device object
    allDevices.forEach(device => {
      const model = device.model;

      // Check if the model is not in the seenModels Set
      if (!seenModels.has(model)) {
        // Add the model to the uniqueModels array
        uniqueModels.push({ model });

        // Add the model to the seenModels Set to mark it as seen
        seenModels.add(model);
      }
    });

    // Now, uniqueModels contains an array of unique models
    const options = uniqueModels.map(modelObj => ({
      value: modelObj.model,
      label: modelObj.model,
    }));

    this.setState({  uniqueModels :options })
  }
  
  render () {
    const { classes } = this.props
    const { form, editOption, isRecived } = this.state
    return (
      <div style={{ padding: 16 }}>
        {!isRecived ? (
          <Loader defaultStyle />
        ) : (
          <>
            <div className={classes.paper + ' clearfix'}>
            <Grid container spacing={1}>
              <Grid item md={3} sm={6} xs={12}>
                    <TextField
                      id='mode'
                      select
                      label={this.props.translate('mode')}
                      value={form.attributes?.mode || ''}
                      onChange={this.handleChange('mode')}
                      margin='dense'
                      fullWidth
                    >
                      <MenuItem value=''>
                        <em> {this.props.translate('none')}</em>
                      </MenuItem>
                      {modeList.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.key)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              <Grid container spacing={1}>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id='preset'
                    select
                    label={this.props.translate('preset')}
                    value={form.preset || ''}
                    onChange={this.handleChange('preset')}
                    margin='dense'
                    fullWidth
                  >
                    <MenuItem value=''>
                      <em> {this.props.translate('none')}</em>
                    </MenuItem>
                    {preset.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.key)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                 {checkPrivileges('settingCreate') && !this.state.editOption ?
                  (<Button
                    variant='contained'
                    className={classes.button}
                    style={{
                      float: 'right'
                    }}
                    onClick={() => this.Submit()}
                    disabled={!this.state.isVisibleBtn}
                  >
                    {' '}
                    {this.props.translate('sharedAdd')}
                  </Button>
                  ) : (
                    <Button
                      variant='contained'
                      className={classes.button}
                      style={{
                        float: 'right'
                      }}
                      onClick={() => this.DeleteSubmit()}
                     >
                      {' '}
                      {this.props.translate('sharedRemove')}
                    </Button>)
                }

                {this.state.editOption && this.state.form.attributes&&Object.keys(this.state.form&&this.state.form.attributes).length > 0  ?
                    <Button
                        variant='contained'
                        className={classes.button}
                        style={{
                          float: 'right'
                        }}
                        onClick={() => this.setState({showDialg:true})}
                      >
                        {' '}
                        {this.props.translate('copyEcoDriving')}
                      </Button>
                      : null
                }
              </Grid>
            </div>
            {editOption && (
              <EcoDrivingTable
                row={this.state.form.attributes}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                handleChange={this.handleChangeAttributesValue}
              />
            )}
          </>
        )}
        {this.state.showDialg && (
          <CustomDialog
            title ='Devices'
            themecolors={this.props.themecolors}
            visable={true}
            onClose={() => this.setState({showDialg:false})}
            bodyPadding={10}
            isVisableBtn
            noFullScreen
            headerActions={
              <Button
                disabled={!(this.props.sensorDeviceids && this.props.sensorDeviceids.length)}
                onClick={this.props.submitCopyEcoDriving}
              >
                {this.props.translate('copyEcoDriving')}
              </Button>
            }
              >
              <div>
                <Table
                    onSelect={this.handleModelSelect} 
                    insideDialog
                    disableHead
                    showCheckbox= {true}
                    showGroupAccess={this.props.showGroupAccess}
                    checkHandleChange={this.props.Devices_Selection|| ''}
                    rows={this.state.listData ? this.state.displayList : this.props?.allDevices || []}
                    componentData={this.state.listData ? this.state.displayList : this.props?.allDevices || []}
                    isEditable
                    canRemove={true}
                    canAssign={true}
                    className='custom-table2'
                    rowsPerPage={this.props.rowsPerPage}
                    handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                    selectAll={this.state.listData ? this.selectAllFilteredUnits : this.props.selectAllCopySensors}
                    copySensor
                    searchable
                    rowDefinition={[
                      {
                        id: 'label',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('sharedName')
                      },
                      {
                        id: 'category',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('category')
                      },
                      {
                        id: 'model',
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate('model')
                      }
                    ]}
                    />
                </div>
          </CustomDialog>
            )}
            
          {this.props.openResponseDialg && (
              <CustomDialog
                  title ='Devices'
                  themecolors={this.props.themecolors}
                  visable={true}
                  onClose={this.props.closeDialog}
                  bodyPadding={10}
                  isVisableBtn
                  noFullScreen
                    >
                    <div>
                    <Table
                        insideDialog
                        disableHead
                        // showCheckbox= {true}
                        showGroupAccess={this.props.showGroupAccess}
                        rows={this.props?.copiedData || []}
                        componentData={this.props?.copiedData || []}
                        isEditable
                        // canRemove={true}
                        // canAssign={true}
                        className='custom-table2'
                        rowsPerPage={this.props.rowsPerPage}
                        handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                        // selectAll={this.props.selectAll}
                        rowDefinition={[
                          {
                            id: 'label',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('sharedName')
                          },
                          {
                            id: 'message',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('message')
                          }
                        ]}
                        />
                      </div>
              </CustomDialog>
          )}
      </div>
    )
  }
}

const mapState = state => ({
  themecolors: state.themeColors
})

const enhance = compose(withStyles(styles), connect(mapState))

export default enhance(withSnackbar(EcoDriving))

const preset = [
  {
    key: 'bus'
  },
  {
    key: 'truck'
  },
  {
    key: 'automobile'
  }
]
const modeList = [
  {
    key: 'default'
  },
  {
    key: 'device'
  } 
]