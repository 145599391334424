import React, { Component } from 'react'
import SimpleModalWrapped from '../common/Modal'
import { withLocalize } from 'react-localize-redux'
import Grid from '@material-ui/core/Grid'
import TextField from '../common/TextField'
import Tooltip from '../common/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Attributes from '../Attributes/attributesModal'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'

const mapStateToProps = (state, ownProps) => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themeColors
  }
}
export const userTypeArray = [
  {
    key: 'trailer',
    name: 'trailer'
  },
  {
    key: 'bin',
    name: 'Bin'
  },
  {
    key: 'passenger',
    name: 'Passenger'
  }
]

const styles = theme => ({})

class addTrailer extends Component {
  constructor () {
    super()
    this.state = {
      attm_isVisable: false
    }
    this.addAttributes = this.addAttributes.bind(this)
  }

  addAttributes () {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable
    })
  }

  render () {
    const { selectedTrailers } = this.props
    const { classes } = this.props
    const { form } = this.props
    return (
      <div style={{ height: '100%' }}>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title='binder'
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.onClose}
          buttonText={this.props.buttonText}
          btnCloseText='sharedBack'
          isVisableBtn={this.props.isVisibleBtn}
          selectedItemId={selectedTrailers.uniqueId}
          attributeChangesMessage={this.props.attributeChangesMessage}
          notShowAttributesBtn
          content={
            <form autoComplete='off'>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                 <TextField
                    id='type'
                    select
                    label={this.props.translate('type')}
                    value={this.props.form.type || ''}
                    onChange={this.props.handleChange('type')}
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    required
                  >
                    {userTypeArray.length &&
                      userTypeArray.map(option => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.name)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {this.props.form.type ?  
                  (<> 
                    <Grid container spacing={1}>
                      <Grid item sm={6} xs={12}>
                          <TextField
                            id='name'
                            label={form.type==='trailer'? this.props.translate('trailerName') :form.type==='bin' ? this.props.translate('binName') :this.props.translate('passengerName')}
                            required
                            placeholder=''
                            value={this.props.form.name}
                            onChange={this.props.handleChange('name')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            id='uniqueId'
                            label={form.type==='trailer'? this.props.translate('trailerUniqueId') :form.type==='bin' ? this.props.translate('binUniqueId') :this.props.translate('passengerUniqueId')}
                            type='text'
                            required
                            placeholder=''
                            value={this.props.form.uniqueId}
                            onChange={this.props.handleChange('uniqueId')}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                          />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          id='department'
                          label={this.props.translate('sharedDescription')}
                          type='text'
                          placeholder=''
                          value={this.props.form.department}
                          onChange={this.props.handleChange('department')}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      
                      {this.props.editOption ?
                      <Grid item sm={6} xs={12}>
                        <input
                          type='file'
                          name='pic'
                          accept='image/*'
                          onChange={e => this.props.uploadImage(e)}
                        />
                        {this.props.form.trailerImg && (
                          <Tooltip
                            placement='right-end'
                            title={this.props.translate('clickForPreview')}
                          >
                            <img
                              style={{ width: 120, height: 'auto' }}
                              alt='trailerImage'
                              // src={this.props.form.trailerImg}
                              src={this.props.uploadingImage ? this.props.imagesData :  this.props.form.trailerImg || ''}

                              onClick={this.props.imagePopup}
                            />
                          </Tooltip>
                        )}
                      </Grid>:null}
                    </Grid>
                          
                    <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            format="dd/MM/yyyy"
                            margin='dense'
                            label={this.props.translate('expireDate')}
                            variant='outlined'
                            fullWidth
                            minDate={new Date()}
                            value={
                              this.props.form?.expirationTime
                                ? new Date(
                                    `${this.props.form.expirationTime}`
                                  ).toString()
                                : null
                            }
                            onChange={this.props.handleChange('expirationTime')}
                            InputProps={{
                              classes: {
                                root: 'theme-cssOutlinedInput',
                                input: 'theme-input',
                                focused: 'theme-cssFocused',
                                notchedOutline: 'theme-notchedOutline'
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: 'theme-label',
                                focused: 'theme-label-focused',
                                shrink: 'theme-label-shrink'
                              }
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      </Grid>


                    <h4 style={{ margin: '10px 0' }} className='with-border'>
                        {this.props.translate('tags')}
                   </h4>
                    <Grid container spacing={1}>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_1'
                          label={
                            this.props.logInUser.attributes &&
                            this.props.logInUser.attributes.bt1
                              ? this.props.logInUser.attributes.bt1
                              : this.props.translate('tag_1')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (this.props.form.tag_1 !== undefined
                              ? this.props.form.tag_1
                              : this.props.form.attributes.tag_1) || ''
                          }
                          onChange={e => this.props.changeAttribute2('tag_1', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_2'
                          label={
                            this.props.logInUser.attributes &&
                            this.props.logInUser.attributes.bt2
                              ? this.props.logInUser.attributes.bt2
                              : this.props.translate('tag_2')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (this.props.form.tag_2 !== undefined
                              ? this.props.form.tag_2
                              : this.props.form.attributes.tag_2) || ''
                          }
                          onChange={e => this.props.changeAttribute2('tag_2', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_3'
                          label={
                            this.props.logInUser.attributes &&
                            this.props.logInUser.attributes.bt3
                              ? this.props.logInUser.attributes.bt3
                              : this.props.translate('tag_3')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (this.props.form.tag_3 !== undefined
                              ? this.props.form.tag_3
                              : this.props.form.attributes.tag_3) || ''
                          }
                          onChange={e => this.props.changeAttribute2('tag_3', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_4'
                          label={
                            this.props.logInUser.attributes &&
                            this.props.logInUser.attributes.bt4
                              ? this.props.logInUser.attributes.bt4
                              : this.props.translate('tag_4')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (this.props.form.tag_4 !== undefined
                              ? this.props.form.tag_4
                              : this.props.form.attributes.tag_4) || ''
                          }
                          onChange={e => this.props.changeAttribute2('tag_4', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xl={3} md={4} xs={12}>
                        <TextField
                          id='tag_5'
                          label={
                            this.props.logInUser.attributes &&
                            this.props.logInUser.attributes.bt5
                              ? this.props.logInUser.attributes.bt5
                              : this.props.translate('tag_5')
                          }
                          type='text'
                          placeholder=''
                          value={
                            (this.props.form.tag_5 !== undefined
                              ? this.props.form.tag_5
                              : this.props.form.attributes.tag_5) || ''
                          }
                          onChange={e => this.props.changeAttribute2('tag_5', e)}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                        />
                      </Grid>
                    </Grid>


                  </>): null}
              </Grid>
            </form>
          }
        />
        {/* {this.state.attm_isVisable && (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType='trailer'
            isHaveAccess={this.props.isHaveAccess}
          />
        )} */}
      </div>
    )
  }
}

const AddTrailer = withStyles(styles)
export default connect(mapStateToProps)(AddTrailer(withLocalize(addTrailer)))
