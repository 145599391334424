import React, { Fragment, PureComponent } from 'react'
import Menu from './../Menu/CommonMenu'
import MenuItem from './../common/MenuItem'
import Checkbox from './../common/Checkbox'
import { withLocalize } from 'react-localize-redux'
import Icon from '@material-ui/core/Icon'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux'
import './PositionMenu.scss'
import Tooltip from '@material-ui/core/Tooltip'
import Style from 'style-it'
import { applyDevicesFilter } from '../../Actions/Devices'
import { checkPrivileges } from '../../Helpers'
class OptionMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      process: false
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  componentWillMount () {}

  showSelectedUnits (data) {
    const list = [...this.props.filterList]
    let newList = []
    if (list.includes(data)) {
      list.map(l => (l != data ? newList.push(l) : null))
    } else {
      newList = [...list].concat(data)
    }
    this.props.dispatch(applyDevicesFilter(newList))
  }

  componentWillReceiveProps (n) {}

  render () {
    const { anchorEl } = this.state
    const empty = null
    return (
      <Style>
        {`
      .main-menu-list-wrapper {
        background-color: ${this.props.themecolors.themeLightColor};
      }
      a {
        color: inherit
      }
      `}
        <div>
          <a
            href={empty}
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup='true'
            onClick={this.handleClick}
          >
            <MoreVertIcon style={{fontSize: 26, marginRight: -8, verticalAlign: 'middle'}}/>
          </a>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
             {this.props.menuSettings.map((option, index) => (
               (option.value==="workFlowOn" || option.value=== "workFlowOff") ? 
               <>
               {checkPrivileges("workflow") ? 
                 <>
                 <MenuItem
                   key={option.value}
                   selected={this.props.filterList.includes(option.value)}
                   component='label'
                   className='menu-item'
                 >
                   <div style={{ display: 'none' }}>
                     <Checkbox
                       onChange={e => this.showSelectedUnits(option.value)}
                       checked={this.props.filterList.includes(option.value)}
                       value='checkedA'
                       label={option.label}
                     />
                   </div>
                   {option.icon}
                 </MenuItem>
                 </>
               : null}
               </>
               :<>
                     <Tooltip
                        key={index}
                        title={option.label}
                        placement='right'
                        classes={{
                          popper: 'menu-popper',
                          tooltip: 'menu-popper-tooltip'
                        }}
                      > 
                      <MenuItem
                        key={option.value}
                        selected={this.props.filterList.includes(option.value)}
                        component='label'
                        className='menu-item'
                      >
                        
                            <div style={{ display: 'none' }}>
                              <Checkbox
                                onChange={e => this.showSelectedUnits(option.value)}
                                checked={this.props.filterList.includes(option.value)}
                                value='checkedA'
                                label={option.label}
                              />
                            </div>
                            {option.icon}
                      </MenuItem>
                  </Tooltip>
                  </>
             ))}
          </Menu>
        </div>
      </Style>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  filterList:
    state.filterList && state.filterList.filters ? state.filterList.filters : []
})

export default connect(mapStateToProps)(withLocalize(OptionMenu))
