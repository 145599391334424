import React from 'react'
import Autosuggest from 'react-autosuggest'
import TextField from './TextField'
import MenuItem from './MenuItem'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Paper from '@material-ui/core/Paper'

function renderInputComponent (inputProps) {
  const { inputRef = () => {}, ref, ...other } = inputProps

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        }
      }}
      {...other}
    />
  )
}

export default class Example extends React.Component {
  constructor () {
    super()

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      array: []
    }
  }
  componentWillMount () {
    let { suggestions, translate, prefix, value } = this.props
    if (suggestions) {
      if (translate) {
        this.setState({
          value,
          array: suggestions.map(s => {
            return {
              name: translate(prefix ? prefix + s : s),
              value: s
            }
          })
        })
      } else {
        this.setState({
          value,
          array: suggestions.map(s => {
            return { name: s, value: s }
          })
        })
      }
    }
  }

  componentWillReceiveProps(n) {
    //if(!isEqual(n.suggestions, this.props.suggestions)) {
      // if(n.suggestions) {
      //   this.setState({array: n.suggestions.map((s) => {return {name: s, value: s}})})
      // }
    //}
    //if(!isEqual(n.value, this.props.value)) {
      // this.setState({value: n.value || ""})
    //}
    if(n.value !== this.state.value){
      this.setState({value:n.value})
    }
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return this.state.array.filter(
      lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
    )
  }

  onChange = (event, { newValue }) => {

    if (newValue.name) {
      this.setState(
        {
          value: newValue.name
        },
        () => {
          if (this.props.handleChange) {
            this.props.handleChange(this.props.fieldName, newValue.value)
          }
        }
      )
    } else {
      this.setState(
        {
          value: newValue
        },
        () => {
          if (this.props.handleChange) {
            this.props.handleChange(this.props.fieldName, newValue)
          }
        }
      )
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query)
    const parts = parse(suggestion.name, matches)

    return (
      <MenuItem
        buttonRef={suggestion.innerRef}
        selected={isHighlighted}
        component='div'
      >
        {parts.map(part => (
          <span
            key={part.text}
            style={{ fontWeight: part.highlight ? 700 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </MenuItem>
    )
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => {
    return suggestion
  }

  drawValue = s => {
    return true
  }

  render () {
    const { value, suggestions } = this.state

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder || 'Type here...',
      value,
      label: this.props.label || 'Label',
      onChange: this.onChange
    }

    // Finally, render it!
    return (
      <Autosuggest
        renderInputComponent={renderInputComponent}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        shouldRenderSuggestions={this.drawValue}
        inputProps={inputProps}
        onChange={this.onChange}
        theme={{
          container: {
            position: 'relative'
          },
          suggestionsContainerOpen: {
            position: 'absolute',
            zIndex: 99,
            marginTop: 0,
            left: 0,
            right: 0,
            maxHeight: 250,
            overflow: 'auto'
          },
          suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none'
          },
          suggestion: {
            display: 'block'
          }
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    )
  }
}
