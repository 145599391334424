import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import { WaslShortList } from './WaslShortList'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import Loader from '../../Layout/Loader'
import isEqual from 'react-fast-compare'
import {
  removedvehicle,
  updatevehicle,
  addvehicle
} from '../../Actions/Vehicles'
import AddVehicle from './addVehicle'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import { Api, checkPrivileges, errorHandler } from '../../Helpers'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import instance from '../../axios'

const vehicleDataFormat = (data, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...data.attributes },
  deviceId: data.deviceId,
  label: data.label,
  model: data.model,
  garage: data.garage,
  vehicleType: data.vehicleType,
  vin:data.vin,
  vehicleLicensePlate: data.vehicleLicensePlate,
  expirationTime: data.expirationTime,
  areaId: data && data.areaId ? data.areaId.id : '',
})

const trasmissionList=[
  {name:'Manual'},
  {name:'Automatic'},
  {name:'Semi-Automatic'},
  {name:'CVT'},
  {name:'Other'} 
]

const formDefault = {
  label: '',
  garage: '',
  model: '',
  vehicleType: '',
  expirationTime: '',
  vehicleLicensePlate: '',
  attributes: { }//vehicleLicensePlate: ''
}

class vehicleModal extends Component {
  constructor () {
    super()
    this.state = {
      trasmissionList:trasmissionList,
      selectedVehicle: '',
      addOption: false,
      editOption: false,
      isVisableTrackerModal: false,
      isVisableUserBtn: false,
      vehicleId: '',
      vehicleTracker: '',
      form: { ...formDefault },
      vehicleStatus: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.ac_submit = this.ac_submit.bind(this)
    this.onCloseSubModal = this.onCloseSubModal.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount () {
    this.dataFormatting()
  }
  dataFormatting = () => {
    let findAreaId = this.props.areaList&&this.props.areaList.find(item => item.key === (this.props.selecteditem&&this.props.selecteditem.areaId));
    let dt = JSON.parse(JSON.stringify(this.props.selecteditem))
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(dt)
      let attributes = dt.attributes
      let trackers = this.props.trackerData
      formData = { ...formData, attributes, trackers }
      this.setState({
        selectedVehicle: formData,
        vehicleId: dt.id,
        vehicleTracker: this.props.trackerData
      })
    } else {
      if (
        this.props.activeOperation === 'edit' &&
        this.props.trackersApiResponce
      ) {
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedVehicle, dt)
        ) {
          this.setState({
            form: { 
              ...dt,
              areaId:findAreaId
            },
            vehicleId: dt.id,
            editOption: true,
            addOption: false,
            selectedVehicle: dt,
            vehicleTracker: this.props.trackerData
          })
        }
      }
      if (this.props.activeOperation === 'add' && !this.state.addOption) {
        this.setState({
          vehicleTracker: '',
          vehicleId: '',
          selectedVehicle: '',
          addOption: true,
          editOption: false,
          waslLinked:true,
          form:{...formDefault,attributes:{}}
        })
      }
    }     
  }
  setFormData = data => {
    let formData = {
      label: data.label,
      garage: data.garage,
      model: data.model,
      vehicleType: data.vehicleType,
      expirationTime: data.expirationTime,
      vehicleLicensePlate: data.vehicleLicensePlate || '',
      vin: data.attributes.vin || '',
      chassisNumber: data.attributes.chassisNumber || '',
      permittedSpeed: data.attributes.permittedSpeed || ''
    }
    return formData
  }
  ac_submit () {
    let { form } = this.state
    const obj = vehicleDataFormat(form)
    this.setState({
      isVisableUserBtn: false
    })
    // fetch('/api/vehicles', {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/vehicles`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...obj
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(data => {
            if (data.status === 'success') {
              this.props.dispatch(addvehicle(data.data))
              this.props.onCloseModal()
              this.setState({
                addOption: false,
                selectedVehicle: '',
                form: formDefault
              })

              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('vehicleIsAdded'),
                  autoDismiss: 10
                })
              )
            } else {
              if(data && data.message && data.message.includes('vehicles_deviceid')) {
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate('alreadyAttachedWithVehicle'),
                    autoDismiss: 10
                  })
                )
              } else {
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate('vehicleLicensePlateAllreadyInUsed'),
                    autoDismiss: 10
                  })
                )
              }
            }
          })
      //   } else {
      //     throw response
      //   }
      // })
      .catch(error => {errorHandler(error, this.props.dispatch)})
      // .catch(e => {
      //   this.props.dispatch(
      //     Notifications.error({
      //       message: this.props.translate('vehicleFormateIssue'),
      //       autoDismiss: 10
      //     })
      //   )
      // })
  }
  uc_submit = disableWaslCall => {
    let { form, selectedVehicle: { id } } = this.state
    const { waslEnabled } = this.props?.serversettings?.attributes || {};
    if(waslEnabled) {
      this.setState({
        waslStatus: <span className="loading-status-text"> <LoadingIndicator /> Processing</span>,
      })
    }
    this.setState({
      vehicleStatus: <span className="loading-status-text"><LoadingIndicator /> Processing</span>,
    })
    const obj = vehicleDataFormat(form, id)
    if(!obj.attributes.WASLKey) {
      delete obj.attributes.WASLKey;
    }
    this.setState({
      isVisableUserBtn: false
    })
    const data = {
      url: id ? `/api/vehicles/${id}` : `/api/vehicles/`,
      method: id ? `PUT` : `POST`,
      successMsg: id ? `vehicleIsUpdated` : `vehicleIsAdded`,
    };

    instance({
      url:data.url,
      method: data.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
    .then(d => {
      // if (d.status === 200 && d.data.status === 'success') {
        this.setState({form:{...formDefault,attributes:{}}})
        this.props.dispatch(id ? updatevehicle(d) : addvehicle(d))
        if(!disableWaslCall && waslEnabled && this.state.waslLinked) {
          this.submitWasl(obj, d);
        }
        else {
          this.handleAfterResponse();
        }
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate(data.successMsg),
            autoDismiss: 10
          })
        )
        this.onCloseSubModal()
      // }
    })
    .catch(error => {errorHandler(error, this.props.dispatch)})
  }

  handleAfterResponse = () => {
    this.setState({
      vehicleStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>
    })
  }

  onChangedDevice = item => {
    if (item) {

      const device = this.props.devices.find(d => d.id === item.id)

      this.setState(
        {
          vehicleTracker: { value: device.id, label: device.name, uniqueId: device.uniqueId },
          form: {
            ...this.state.form,
            deviceId: item.value
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          vehicleTracker: '',
          form: {
            ...this.state.form,
            deviceId: ''
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  enableAssignTrackerModal = () => {
    this.setState({
      isVisableTrackerModal: true
    })
  }
  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false
    })
  }
  onCloseSubModal () {
    this.setState({
      addOption: false,
      editOption: false
    })
    this.props.onCloseModal()
  }

  handleChangeLicenseExpDate = name => event => {
    if (name === 'expirationTime') {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString()
          }
        },
        () => this.checkRequiredFields()
      )
    } 
  }
  setExpirationTime = e => {
    e.stopPropagation()
    this.setState(
      {
        form: {
          ...this.state.form,
          expirationTime: null
        }
      },
      () => this.checkRequiredFields()
    )
  }
  handleChange (name, event) {
    const { target } = event
    if (target && target.type === 'checkbox') {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked
          }
        },
        () => this.checkRequiredFields()
      )
    } else if(name === 'areaId'){
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event
            }
          },
          () => this.checkRequiredFields()
        )
    } 
    else {
      let value = target.value.toString()
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  handleChangeForAttributesValues = (name, event, link) => {
    let { attributes } = this.state.form
    const target= name === 'trasmissionType'?event:event.target
    let value = name === 'trasmissionType' ? event :target.value.toString()
    if (value) {
      attributes[name] = value
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...attributes }
          }
        },
        () => {
          if (this.state.editOption) {
            this.setState({
              isVisableUserBtn: true
            })
          }
        }
      )
    } else {
      delete attributes[name]
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...attributes }
          }
        },
        () => {
          if (this.state.editOption) {
            this.setState({
              isVisableUserBtn: true,
              waslLinked:false
            })
          }
        }
      )
    }
  }
  checkRequiredFields () {
    let { label, vehicleLicensePlate } = this.state.form
    let value = true
    if (this.state.editOption) {
      value = !isEqual(this.state.form, this.props.selecteditem)
    }
    if (label && label.trim().length && vehicleLicensePlate && vehicleLicensePlate.trim().length && value) {
      this.setState({
        isVisableUserBtn: true
      })
    } else {
      this.setState({
        isVisableUserBtn: false
      })
    }
  }

  submitWasl = vehicle => {
    const { waslCompanyRefKey } = this.props?.serversettings?.attributes || {};
    if(waslCompanyRefKey) {

      const {number, leftLetter, rightLetter, plateType, middleLetter, sequenceNumber} = vehicle.attributes;
      const { uniqueId } = this.state.vehicleTracker;
      if(number && leftLetter && rightLetter && plateType && middleLetter && sequenceNumber && uniqueId) {
        instance.post(`${process.env.REACT_APP_WASL}/handleSocketRequest`, {  
          hook: 'registerVehicle',
          account_id: this.props.serversettings.id,
          data: {
            uniqueId,
            sequenceNumber,
            activity: 'DEFAULT',
            attributes: {
              rightLetter,
              number,
              leftLetter,
              middleLetter,
              plateType,
            }
          },
          hook_prefix: waslCompanyRefKey
        }).then(response => {
          // if(response.status === 200 && response.data && response.data.success) {
            this.setState({
              waslStatus: <span className="loading-status-text"><DoneAllIcon className="status-icon" /> Done</span>,
            })
            if(response.result && response.result.referenceKey) {
              this.handleChangeForAttributesValues('WASLKey', { target: {value: response.result.referenceKey} } );
              this.uc_submit(true);
            }
          // }
        }).catch(error => {
              let res = error?.response?.data || {}
              let check = res&&res.data&&res.data.result
              if(check&& check.referenceKey){
                    this.handleChangeForAttributesValues('WASLKey', { target: {value: check.referenceKey} } );
                    this.uc_submit(true);
              }

          this.setState({
            waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> invalid form</span>,
          })
          this.handleAfterResponse()
          
        })
      }
      else {
        this.setState({
          waslStatus: <span className="loading-status-text"><ErrorOutlineIcon className="status-icon" /> incomplete form</span>,
        })
        this.handleAfterResponse();
      }
    }
    else {
      this.handleAfterResponse();
    }
  }

  render () {
    let { selecteditem } = this.props
    
    if (
      selecteditem.id !== this.state.vehicleId &&
      this.props.trackersApiResponce
    ) {
      this.dataFormatting()
    }
    return (
      
      <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
        {this.state.addOption && (
          <AddVehicle
            {...this.state}
            dispatch={this.props.dispatch}
            logInUsers={this.props.logInUsers}
            onClose={this.onCloseSubModal}
            formSubmit={this.uc_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChangeForAttributesValues={
              this.handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={
              this.handleChangeLicenseExpDate
            }
            setExpirationTime={this.setExpirationTime}
            onChangedDevice={this.onChangedDevice}
            form={this.state.form}
            buttonText={'sharedCreate'}
            isHaveAccess={'vehicleCreate'}
            areaList={checkPrivileges('area') ?this.props.areaList : []}
          />
        )}
        {this.state.editOption && (
          <AddVehicle
            {...this.state}
            dispatch={this.props.dispatch}
            serversettings={this.props.serversettings}
            logInUsers={this.props.logInUsers}
            onClose={this.onCloseSubModal}
            formSubmit={this.uc_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChangeForAttributesValues={
              this.handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={
              this.handleChangeLicenseExpDate
            }
            setExpirationTime={this.setExpirationTime}
            onChangedDevice={this.onChangedDevice}
            buttonText={'update'}
            isHaveAccess={'vehicleUpdate'}
            areaList={checkPrivileges('area') ?this.props.areaList : []}
          />
        )}
        {this.state.selectedVehicle &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            logInUsers={this.props.logInUsers}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedVehicle}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
        {this.props.showWaslShortList && this.props.vehicleHookData ? (
          <WaslShortList
            logInUsers={this.props.logInUsers}
            currentLocation={this.props.currentLocation}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
            vehicleHookData={this.props.vehicleHookData}
            sequenceNumber={this.props.sequenceNumber}
          />
        ) : null}
      </div>
    )
  }
}

const mapState = state => ({
  logInUsers: state.logInUsers,
  serversettings: state.ServerSetting,
  themecolors: state.themeColors
})
const mapStateToProps = connect(mapState)
export const VehicleModal = mapStateToProps(
  withSnackbar(withLocalize(vehicleModal))
)

const LoadingIndicator = () => {
  return <span className="status-icon">
      <span className="loading-dot" style={{ animationDelay: '0ms' }} />
      <span className="loading-dot" style={{ animationDelay: '160ms' }} />
      <span className="loading-dot" style={{ animationDelay: '320ms' }} />
    </span>
  }