import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import { checkPrivileges, errorHandler, isOwner, selectAll } from '../../Helpers'
import Layout from './../../Layout'
import TextField from '../../Components/common/TextField'
import Checkbox from '../../Components/common/Checkbox'
import { ReactComponent as CommandIcon } from './../../assets/nav/command.svg'
import { timeZonesTypes } from '../../Components/Attributes/timezone'
import MenuItem from '../../Components/common/MenuItem'
import Button from '../../Components/common/Button'
import { addCommands, getCommands } from '../../Actions/Commands'
import Notifications from 'react-notification-system-redux'
import Grid from '@material-ui/core/Grid'
import EmptyState from '../../Components/common/EmptyState'
import Dialog from '../../Components/common/Dialog'
import ItemsSelector from '../Units/Components/itemsSelector'
import { removedUserDeviceWiseSelectedList, userDeviceWiseSelectedList } from '../../Actions/Notifications'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { removeUser } from '../../Actions/Users'
import { removeDevice } from '../../Actions/Devices'
import { removeGroup } from '../../Actions/Groups'
import SingleSelect from '../../Components/common/SingleSelect'
import instance from '../../axios'

const formType = [
  {
    key: '1',
    value: 'custom',
    label: 'Custom command ',
    valueType: 'string',
  },
  {
    key: '2',
    value: 'deviceIdentification',
    label: 'Device Identification',
    valueType: 'string',
  },
  {
    key: '3',
    value: 'singleReporting',
    label: 'Single Reporting',
    valueType: 'string',
  },
  {
    key: '4',
    value: 'periodicReporting',
    label: 'Periodic Reporting',
    valueType: 'string',
  },
  {
    key: '5',
    value: 'stopReporting',
    label: 'Stop Reporting',
    valueType: 'string',
  },
  {
    key: '6',
    value: 'engineStop',
    label: 'Engine Stop',
    valueType: 'string',
  },
  {
    key: '7',
    value: 'engineResume',
    label: 'Engine Resume',
    valueType: 'string',
  },
  {
    key: '8',
    value: 'armAlarm',
    label: 'Arm Alarm',
    valueType: 'string',
  },
  {
    key: '9',
    value: 'disarmAlarm',
    label: 'Disarm Alarm',
    valueType: 'string',
  },
  {
    key: '10',
    value: 'dismissAlarm',
    label: 'Dismiss Alarm',
    valueType: 'string',
  },
  {
    key: '11',
    value: 'setTimezone',
    label: 'Set Timezone',
    valueType: 'string',
  },
  {
    key: '12',
    value: 'requestPhoto',
    label: 'Request Photo',
    valueType: 'string',
  },
  {
    key: '13',
    value: 'powerOffDevice',
    label: 'Power Off Device',
    valueType: 'string',
  },
  {
    key: '14',
    value: 'rebootDevice',
    label: 'Reboot Device',
    valueType: 'string',
  },
  {
    key: '15',
    value: 'factoryReset',
    label: 'Factory Reset',
    valueType: 'string',
  },
  {
    key: '16',
    value: 'sendSms',
    label: 'Send SMS',
    valueType: 'string',
  },
  {
    key: '17',
    value: 'sendUssd',
    label: 'Send USSD',
    valueType: 'string',
  },
  {
    key: '18',
    value: 'sosNumber',
    label: 'Set SOS Number',
    valueType: 'string',
  },
  {
    key: '19',
    value: 'setSilenceTime',
    label: 'Set Silence Time',
    valueType: 'string',
  },
  {
    key: '20',
    value: 'setPhonebook',
    label: 'Set Phonebook',
    valueType: 'string',
  },
  {
    key: '21',
    value: 'message',
    label: 'Message',
    valueType: 'string',
  },
  {
    key: '22',
    value: 'voiceMessage',
    label: 'Voice Message',
    valueType: 'string',
  },
  {
    key: '23',
    value: 'outputControl',
    label: 'Output Control',
    valueType: 'string',
  },
  {
    key: '24',
    value: 'voiceMonitoring',
    label: 'Voice Monitoring',
    valueType: 'string',
  },
  {
    key: '25',
    value: 'setAgps',
    label: 'Set AGPS',
    valueType: 'string',
  },
  {
    key: '26',
    value: 'setIndicator',
    label: 'Set Indicator',
    valueType: 'string',
  },
  {
    key: '27',
    value: 'configuration',
    label: 'Configuration',
    valueType: 'string',
  },
  {
    key: '28',
    value: 'getVersion',
    label: 'Get Version',
    valueType: 'string',
  },
  {
    key: '29',
    value: 'updateFirmware',
    label: 'Update Firmware',
    valueType: 'string',
  },
  {
    key: '30',
    value: 'setConnection',
    label: 'Set Connection',
    valueType: 'string',
  },
  {
    key: '31',
    value: 'setOdometer',
    label: 'Set Odometer',
    valueType: 'string',
  },
  {
    key: '32',
    value: 'getModemStatus',
    label: 'Get Modem Status',
    valueType: 'string',
  },
  {
    key: '33',
    value: 'getDeviceStatus',
    label: 'Get Device Status',
    valueType: 'string',
  },
  {
    key: '34',
    value: 'setSpeedLimit',
    label: 'Set Speed Limit',
    valueType: 'string',
  },
  {
    key: '35',
    value: 'modifyPowerSaving',
    label: 'Modify Power Saving',
    valueType: 'string',
  },
  {
    key: '36',
    value: 'modifyDeepSleep',
    label: 'Modify Deep Sleep',
    valueType: 'string',
  },
  {
    key: '37',
    value: 'movementAlarm',
    label: 'Movement Alarm',
    valueType: 'string',
  },
  {
    key: '38',
    value: 'alarmBattery',
    label: 'Alarm Battery',
    valueType: 'string',
  },
  {
    key: '39',
    value: 'alarmSos',
    label: 'Alarm SOS',
    valueType: 'string',
  },
  {
    key: '40',
    value: 'alarmRemove',
    label: 'Alarm Remove',
    valueType: 'string',
  },
  {
    key: '41',
    value: 'alarmClock',
    label: 'Alarm Clock',
    valueType: 'string',
  },
  {
    key: '42',
    value: 'alarmSpeed',
    label: 'Alarm Speed',
    valueType: 'string',
  },
  {
    key: '43',
    value: 'alarmFall',
    label: 'Alarm Fall',
    valueType: 'string',
  },
  {
    key: '44',
    value: 'alarmVibration',
    label: 'Alarm Vibration',
    valueType: 'string',
  }
]
class Commands extends Component {
  constructor (props) {
    super(props)
    this.state = {
      commandForm: {},
      commandType: {},
      mode: '',
      isVisableComdBtn: true,

      model: false,
      assignMode: '',
      currentCommand: '',
      entityId: 0,

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      },
      deleteForm: {},
      uniqueModels:[],
      selectedModel:{},
      displayList:[]
    }
  }


  componentDidMount() {
    
    const allDevices = this.props?.devices || [];

    // Create an array to store unique models
    const uniqueModels = [];

    // Create a Set to keep track of seen models
    const seenModels = new Set();

    // Iterate through each device object
    allDevices.forEach(device => {
      const model = device.model;

      // Check if the model is not in the seenModels Set
      if (!seenModels.has(model)) {
        // Add the model to the uniqueModels array
        uniqueModels.push({ model });

        // Add the model to the seenModels Set to mark it as seen
        seenModels.add(model);
      }
    });

    // Now, uniqueModels contains an array of unique models
    const options = uniqueModels.map(modelObj => ({
      value: modelObj.model,
      label: modelObj.model,
    }));

    this.setState({  uniqueModels :options })
  }


  handleModelSelect = (name, event) => {
    // Update the selectedModel in state when a model is selected
    const val = event.target.value;
    this.setState({ 
      listData:val?true:false, 
      selectedModel: val
    }, ()=> {  if(val){this.getModelBasedDevices(event.target.value)}});
  };

  getModelBasedDevices = (id)=>{
    instance({
      url: `/api/devices/list?userId=${this.props?.logInUser?.id}&all=true`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params :{
        limit: -1, 
        model:id
      }
    })
        .then(res => {
          const filteredArray =  res && res.data?.map(item =>({
            ...item,
            name : item.label,
            uniqueId : item.id,
          }))
          this.setState(
            {
              displayList: filteredArray || []
            }
          )
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }


  selectAllFilteredUnits = (e)=>{
    let allData = this.state.displayList
    let sensorDeviceids = [];

    allData.map(dt => {
        dt.check = e ? true : false;
        // Push the ID into the sensorDeviceids array
        if (e) {
          sensorDeviceids.push(dt.id);
      }
    });
    if(e){
      this.setState({
          // sensorDeviceids:sensorDeviceids,
          allDevices :allData
      })
    }
    else{
      // sensorDeviceids=[]
      // this.props.getIdsfromChild(sensorDeviceids)
      this.setState({
        // sensorDeviceids:[],
        allDevices :allData
    })

    }

    this.props.getIdsfromChild(sensorDeviceids)

  }






  componentWillUnmount () {
    this.setState({
      commandForm: {},
      mode: '',
      model: false,
      assignMode: '',
      currentCommand: '',
      entityId: 0,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
          // this.fetchTypes()
        }
      )
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    )
  }

  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchData(this.props.logInUser, 1, this.state.pagesize, true)
      }
    )
  }

  fetchData = (logInUser, page, perPage, reset) => {
    let searchText = this.state.searchText
    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }

    let items = this.state.itemPagination.items
    if (reset) {
      items = []
    }
    // fetch(`/api/commands/get?userId=${logInUser.id}&all=true&page=${page}&limit=${perPage}${searchText}`)
    instance({
      url: `/api/commands/get`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        userId: logInUser.id,
        all:true,
        page: page,
        limit: perPage,
        search: this.state.searchText || ''
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(res => {
          this.setState(
            {
              itemPagination: {
                ...res,
                items: items.concat(res.data)
              },
              currentPage: res.hasNext ? res.page + 1 : res.page
            },
            () => {
              this.props.dispatch(getCommands(items.concat(res.data)))
            }
          )
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  // fetchTypes = ()=>{
  //   instance({
  //     url: `/api/commands/types`,
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then(types=>{
  //     let makeTypes = types.map((u, index) => {
  //         return {
  //             ...u,
  //             key:index+1,
  //             value: u.type,
  //             label:this.props.translate(u.type),
  //             valueType: 'string',
  //         }
  //     })
  //     this.setState({formType:makeTypes})
  //   })
  //   .catch(error => {console.log('error =', error)})
  // }

  assignModal = (assignMode, currentCommand) => {
    this.props.dispatch(userDeviceWiseSelectedList([]))
    this.setState({
      assignMode,
      currentCommand,
      selectEntity: '',
      model: true
    }, () => {
      if (checkPrivileges('user') && assignMode === 'user') {
        // fetch(`/api/users/access?commandId=${currentCommand.id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/users/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            commandId: currentCommand.id
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(users => {
              let allData = this.props.users
              if (users.length) {
                allData.map(item => {
                  item.check = false;
                  users.map(main => {

                    if (item.id && main.userId && item.id === main.userId) {
                      // for parent
                      item.check = true
                      
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }

              this.props.dispatch(userDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      if (checkPrivileges('device') && assignMode === 'unit') {
        // fetch(`/api/devices/access?commandId=${currentCommand.id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/devices/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            commandId: currentCommand.id
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(devices => {
              let allData = this.props.devices3
              if (devices.length) {
                allData.map(item => {
                  item.check = false;
                  devices.map(main => {

                    if (item.id && main.deviceId && item.id === main.deviceId) {
                      // for parent
                      item.check = true
                      
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      if (checkPrivileges('group') && assignMode === 'group') {
        // fetch(`/api/groups/access?commandId=${currentCommand.id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/groups/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            commandId: currentCommand.id
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(groups => {
              let allData = this.props.groups
              if (groups.length) {
                allData.map(item => {
                  item.check = false;
                  groups.map(main => {
                    if (item.id && main.groupId && item.id === main.groupId) {
                      // for parent
                      item.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
    })
  }

  selectEntity = e => {
    this.setState({
      selectEntity: e
    })
  }

  submitAssignModel = () => {
    if (this.state.assignMode && this.state.assignMode === 'unit') {
      let obj = {
        deviceId: this.state.selectEntity.id,
        commandId: this.state.currentCommand.id
      }
      this.assignCommand(obj)
    } else if (this.state.assignMode && this.state.assignMode === 'user') {
      let obj = {
        userId: this.state.selectEntity.id,
        commandId: this.state.currentCommand.id
      }
      this.assignCommand(obj)
    } else if (this.state.assignMode && this.state.assignMode === 'group') {
      let obj = {
        groupId: this.state.selectEntity.id,
        commandId: this.state.currentCommand.id
      }
      this.assignCommand(obj)
    }
  }

  assignCommand = obj => {
    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...obj
      }
    })
      .then(response => {
        // if (response.ok) {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(
                'savedCommandsAssignedSuccessfully'
              ),
              autoDismiss: 10
            })
          )
        // } else {
        //   throw response
        // }
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)
        // if (e && e.text) {
        //   e.text().then(err => {
        //     if (err && err.includes('Duplicate entry')) {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('alreadyAssigned'),
        //           autoDismiss: 10
        //         })
        //       )
        //     } else {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 10
        //         })
        //       )
        //     }
        //   })
        // }
      })
  }

  closeAssignModal = () => {
    this.setState({
      model: false,
      assignMode: '',
      commandTitle: '',
      currentCommand: ''
    })
  }

  openCreateFrom = () => {
    this.setState({ mode: '', commandForm: {} }, () => {
      this.setState({
        mode: 'create',
        commandForm: {
          /* temprary comment . pending from backend (1) */
          type: '',
          type: 'custom',
          textChannel: false,
          attributes: {}
        }
      })
    })
  }

  editCommandForm = commandForm => {
    // const type1 = this.state.formType && this.state.formType.find(i => i.value ===  commandForm.type)
    const type1 = (formType).find(i => i.value ===  commandForm.type)
    this.setState({ mode: '', commandForm: {} }, () => {
      this.setState({
        mode: 'update',
        commandType: commandForm,
        commandForm: {
          ...commandForm,
          attributes: { ...commandForm.attributes },
          /* temprary comment . pending from backend (2) */
          // type: type1?type1:'',
          textChannel:
            commandForm.textChannel && commandForm.textChannel !== 'No'
              ? true
              : false
        }
      })
    })
  }
  closeCreateFrom = () => {
    this.setState({
      mode: '',
      commandForm: ''
    },
    () => this.checkRequiredFields())
  }
  modeEmpty = () => {
    this.setState({
      mode: ''
    })
  }

  handleChange = (name, value) => {
    if(this.state.commandType.type === value.value){
      this.setState({
        commandForm: {
          ...this.state.commandForm,
          attributes:{...this.state.commandType.attributes},
          [name]: value
        }
      },
      () => this.checkRequiredFields())
    }else{
      this.setState({
        commandForm: {
          ...this.state.commandForm,
          attributes:{},
          [name]: value
        }
      },
      () => this.checkRequiredFields())
    }
    
  }
  checkRequiredFields = () => {
    let { description, type} = this.state.commandForm
    if (description && type) {
      this.setState({
        isVisableComdBtn: false
      })
    } else {
      this.setState({
        isVisableComdBtn: true
      })
    }
  }

  handleChangeAttributes = (name, value) => {
    if (value) {
      this.setState({
        commandForm: {
          ...this.state.commandForm,
          attributes: {
            ...this.state.commandForm.attributes,
            [name]: value
          }
        }
      },
      () => this.checkRequiredFields())
    } else {
      if (this.state.commandForm && this.state.commandForm.attributes) {
        let attributes = { ...this.state.commandForm.attributes }
        delete attributes[name]
        this.setState({
          commandForm: {
            ...this.state.commandForm,
            attributes: {
              ...attributes
            }
          }
        },
        () => this.checkRequiredFields())
      }
    }
  }

  submitForm = () => {
    let {
      attributes,
      id,
      description,
      type,
      textChannel,
      deviceId
    } = this.state.commandForm
    let obj = {
      attributes: attributes || {},
      id: id || 0,
      description,
       /* temprary comment . pending from backend (3) */
      // type: type.value,
      type,
      deviceId: deviceId || 0,
      textChannel
    }

    let params = ''
    if (this.state.mode === 'update') {
      params = id
    }
    this.setState({
      isVisableComdBtn: true
    })
    if (obj) {
      // fetch(`/api/commands/${params}`, {
      //   method: `${this.state.mode === 'update' ? 'PUT' : 'POST'}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/commands/${params}`,
        method: `${this.state.mode === 'update' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
          ...obj
        }
      })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(commands => {
            if (this.state.mode === 'update') {
              this.props.dispatch(addCommands(commands))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('savedCommandsIsUpdated'),
                  autoDismiss: 10
                })
              )
              this.closeCreateFrom()
            } else {
              this.props.dispatch(addCommands(commands))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('savedCommandsIsCreated'),
                  autoDismiss: 10
                })
              )
              this.editCommandForm(commands)
            }
        //   })
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }


  item_Selection = (checked, item) => {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteduser]
    let obj = {}
    if(this.state.assignMode === 'user') {
      obj.userId = item.id
    }
    if(this.state.assignMode === 'unit') {
      obj.deviceId = item.id
    }
    if(this.state.assignMode === 'group') {
      obj.groupId = item.id
    }
    obj.commandId = parseInt(this.state.currentCommand.id)
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
          if (method === 'DELETE') {
            item.check = false
            allData.map(dt => {
              if (dt.id === item.userId) {
                dt.check = false
              }
              return null
            })

            this.props.dispatch(
              Notifications.success({
                message: this.props.translate(this.state.assignMode === 'user' ? 'userUnAssignedSuccessfully' : this.state.assignMode === 'unit' ? 'trackerIsUnAssignedSuccessfully' : 'groupUnAssignedSuccessfully'),
                autoDismiss: 10
              })
            )
          } else {
            item.check = true
            allData.map(dt => {
              if (dt.id === item.userId) {
                dt.check = true
              }
              return null
            })
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate(this.state.assignMode === 'user' ? 'userAssignedSuccessfully' : this.state.assignMode === 'unit' ? 'trackerIsAssignedSuccessfully' : 'groupAssignedSuccessfully'),
                autoDismiss: 10
              })
            )
          }
          this.props.dispatch(userDeviceWiseSelectedList(allData))
        // } else {
        //   throw response
        // }
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch)
        // if (e && e.text) {
        //   e.text().then(err => {
        //     let a = err.split('- SecurityException').splice(0, 1)
        //     //alert(a[0])
        //     this.props.dispatch(
        //       Notifications.error({
        //         message: a[0],
        //         autoDismiss: 10
        //       })
        //     )
        //   })
        // }
      })
  }


  onRemovedItem = item => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    })
  }

  removeItem = () => {
    if(this.state.assignMode === 'unit') {

      this.removeFromDatabase();
    }
    else if(this.state.assignMode === 'user') {

      this.removeFromDatabase();

    }
    else if (this.state.assignMode === 'group') {

      this.removeFromDatabase();
    }

  }

  removeFromDatabase = () => {
    let obj = this.state.deleteForm
    let params = obj.id
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      let call;
      if(this.state.assignMode === 'user') {
        call = `/api/users/${params}`
      }
      else if(this.state.assignMode === 'unit') {
        call = `/api/devices/${params}`
      }
      else if(this.state.assignMode === 'group') {
        call = `/api/groups/${params}`
      }

      if(call) {
        // fetch(`${call}`, {
        //   method: `DELETE`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `${call}`,
          method: `DELETE`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          // if (response.ok) {
              this.props.dispatch(removedUserDeviceWiseSelectedList(obj))
              let translationKey;
              if(this.state.assignMode === 'user') {
                translationKey = `userIsRemoved`
                this.props.dispatch(removeUser(obj))
              }
              else if(this.state.assignMode === 'unit') {
                translationKey = `trackersIsDeleted`
                this.props.dispatch(removeDevice(obj.id))
              }
              else if(this.state.assignMode === 'group') {
                translationKey = `groupDeleted`
                this.props.dispatch(removeGroup(obj))
              }



              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate(translationKey),
                  autoDismiss: 10
                })
              )
              this.setState({
                onDeleteConfirmation: false
              })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {errorHandler(error, this.props.dispatch)})
      }
    }
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

 

  render () {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          -- {this.props.translate('end')} --
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {this.props.translate('notFound')}
        </p>
      )

    if (checkPrivileges('command')) {
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            classFromChildren='has-padding'
            editCommandForm={this.editCommandForm}
            searchItems={this.searchItems}
            fetchMoreItems={this.fetchMoreItems}
            modeEmpty={this.modeEmpty}
            {...this.state}
          >
            <div className='main-content-page'>
            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removeItem}
                title={this.props.translate('areYouWantToDelete')}
                children={this.state.deleteForm.name || this.state.deleteForm.description}
              />
            )}
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                }}
              >
                {this.state.mode === 'create' ||
                this.state.mode === 'update' ? (
                  <CreateCommandForm
                    // formType={this.state.formType}
                    assignModal={this.assignModal}
                    submitForm={this.submitForm}
                    mode={this.state.mode}
                    closeCreateFrom={this.closeCreateFrom}
                    handleChangeAttributes={this.handleChangeAttributes}
                    form={this.state.commandForm}
                    handleChange={this.handleChange}
                    isVisableComdBtn={this.state.isVisableComdBtn}
                    translate={this.props.translate}
                  />
                ) : null}
                {this.state.model ? (
                  <Dialog
                    isVisableBtn={true}
                    /* headerActions={
                      <Button
                        onClick={this.submitAssignModel}
                        variant='outlined'
                        size='small'
                      >
                        {this.props.translate('assignCommand')}
                      </Button>
                    } */
                    open={this.state.model}
                    onClose={this.closeAssignModal}
                    title={this.props.translate('assignCommand')}
                    disableFooter
                  >
                    <>
                    {/* {console.log("rei====",this.state.listData )} */}
                      <div style={{ padding: 16, fontWeight: 700, paddingBottom: 0 }}><CommandIcon fill="currentColor" width={20} height={20} style={{verticalAlign: 'middle', marginRight: 5}} /> {this.state.currentCommand &&
                      this.state.currentCommand.description}</div>
                      {this.state.assignMode === 'unit' ? (
                        <ItemsSelector
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        showDropDown={true}
                        selectedModel={this.state.selectedModel}
                        onSelect={this.handleModelSelect}
                        options={this.state.uniqueModels || []}

                        selectAll={e =>
                          selectAll(
                            e,
                            'deviceId',
                            'commandId',
                            this.state.currentCommand.id,
                            this.state.listData ? this.state.displayList : this.props.selecteduser,
                            this.state.listData ? this.state.displayList :  this.props.devices3,
                            this.props.dispatch,
                            'access',
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.state.listData ? this.state.displayList : this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges('deviceDelete')}
                        canRemove={checkPrivileges('deviceLinkCommand')}
                        canAssign={checkPrivileges('deviceUnlinkCommand')}
                      />
                      ) : null}

                      {this.state.assignMode === 'user' ? (
                        <ItemsSelector
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        // showDropDown={false}

                        selectAll={e =>
                          selectAll(
                            e,
                            'userId',
                            'commandId',
                            this.state.currentCommand.id,
                            this.props.selecteduser,
                            this.props.users,
                            this.props.dispatch,
                            'access',
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges('userDelete')}
                        canRemove={checkPrivileges('userLinkCommand')}
                        canAssign={checkPrivileges('userUnlinkCommand')}
                        rowDefinition={[
                          {
                            id: 'name',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('sharedName')
                          },
                          {
                            id: 'email',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('userEmail')
                          },
                          {
                            id: 'roleName',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('roleName')
                          },
                          {
                            id: 'parentName',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('parentName')
                          }
                        ]}
                      />
                      ) : null}

                      {this.state.assignMode === 'group' ? (
                        <ItemsSelector
                        // showDropDown={false}

                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        selectAll={e =>
                          selectAll(
                            e,
                            'groupId',
                            'commandId',
                            this.state.currentCommand.id,
                            this.props.selecteduser,
                            this.props.groups,
                            this.props.dispatch,
                            'access',
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges('groupDelete')}
                        canRemove={checkPrivileges('groupLinkCommand')}
                        canAssign={checkPrivileges('groupUnlinkCommand')}
                        rowDefination={[
                          {
                            id: 'name',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('sharedName')
                          },
                          {
                            id: 'uniqueId',
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate('uniqueId')
                          }
                        ]}
                      />
                      ) : null}
                    </>
                  </Dialog>
                ) : null}
                {this.state.mode === '' ? (
                  <EmptyState
                    text={this.props.translate('noCommandSelected')}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}

const CreateCommandForm = ({
  form,
  handleChange,
  translate,
  handleChangeAttributes,
  submitForm,
  closeCreateFrom,
  mode,
  assignModal,
  isVisableComdBtn
}) => {
  return (
    <div>
      <h4 className='page-title'>{translate('sharedSavedCommand')}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id='description'
            required
            margin='dense'
            label={translate('sharedDescription')}
            variant='outlined'
            fullWidth
            value={form.description}
            onChange={e => handleChange('description', e.target.value)}
          />
        </Grid>
        {/* temprary comment . pending from backend (4) */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>  <SingleSelect
            array={formType}
            selectName='type'
            label={translate('sharedType')}
            value={form.type && form.type.key?
              {
                key: form.type.key,
                id: form.type.id,
                value: form.type.value,
                label: form.type.label
              } :''
              
            }
            handleChange={handleChange}
            canRemove={true}
            canAssign={true}
            />
        </Grid> */}
        {[
          'custom',
          'silenceTime',
          'setPhonebook',
          'voiceMessage',
          'outputControl',
          'setIndicator',
          'configuration',
          'setOdometer',
          'alarmClock',
          'alarmSpeed',
          'alarmVibration',
          'setSilenceTime'
          /* temprary comment . pending from backend (5) */
        // ].includes(form.type.value) ? ( 
        ].includes(form.type) ? ( 
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='data'
              name='data'
              margin='dense'
              label={translate('commandData')}
              variant='outlined'
              fullWidth
              value={form.attributes.data || ''}
              onChange={e => handleChangeAttributes('data', e.target.value)}
            />
          </Grid>
        ) : null}

        {form.type.value === "periodicReporting" ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='frequency'
                name='frequency'
                type='number'
                margin='dense'
                label={translate('commandFrequency')}
                variant='outlined'
                fullWidth
                value={form.attributes.frequency || ''}
                onChange={e =>
                  handleChangeAttributes('frequency', e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='frequencyType'
                name='frequencyType'
                select
                value={form.attributes.frequencyType || ''}
                onChange={e =>
                  handleChangeAttributes('frequencyType', e.target.value)
                }
                fullWidth
              >
                {frequencyType.map(option => (
                  <MenuItem key={option.type} value={option.type}>
                    {option.type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </>
        ) : null}
        {form.type.value === 'setTimezone' ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='timezone'
              name='timezone'
              select
              label={translate('commandTimezone')}
              value={form.attributes.timezone || ''}
              onChange={e => handleChangeAttributes('timezone', e.target.value)}
              margin='dense'
              fullWidth
            >
              {timeZonesTypes.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : null}
        {[
          'sendSms',
          'sendUssd',
          'sosNumber',
          'outputControl',
          'message'
        ].includes(form.type.value) ? (
          <>
            {['sosNumber', 'outputControl'].includes(form.type.value) ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='index'
                  name='index'
                  type='number'
                  margin='dense'
                  label={translate('commandIndex')}
                  variant='outlined'
                  fullWidth
                  value={form.attributes.index || ''}
                  onChange={e =>
                    handleChangeAttributes('index', e.target.value)
                  }
                />
              </Grid>
            ) : null}
            {['sendSms', 'sendUssd', 'sosNumber'].includes(form.type.value) ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='phoneNumber'
                  margin='dense'
                  name='phoneNumber'
                  label={translate('commandPhone')}
                  variant='outlined'
                  fullWidth
                  value={form.attributes.phoneNumber || ''}
                  onChange={e =>
                    handleChangeAttributes('phoneNumber', e.target.value)
                  }
                />
              </Grid>
            ) : null}
            {['sendSms', 'message'].includes(form.type.value) ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  id='messages'
                  name='messages'
                  margin='dense'
                  label={translate('Messages')}
                  variant='outlined'
                  fullWidth
                  value={form.attributes.messages || ''}
                  onChange={e =>
                    handleChangeAttributes('messages', e.target.value)
                  }
                />
              </Grid>
            ) : null}
          </>
        ) : null}
        {[
          'alarmFall',
          'alarmRemove',
          'alarmBattery',
          'modeDeepSleep',
          'modePowerSaving',
          'setAgps',
          'voiceMonitoring',
          'modifyPowerSaving',
          'modifyDeepSleep',
          'alarmSos'
        ].includes(form.type.value) ? (
          <Grid item xs={12}>
            <Checkbox
              checked={form.attributes.enable}
              onChange={e => handleChangeAttributes('enable', e.target.checked)}
              color='primary'
              label={translate('commandEnable')}
              name='enable'
            />
          </Grid>
        ) : null}
        {form.type.value === 'setConnection' ? (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='server'
                name='server'
                margin='dense'
                label={translate('commandServer')}
                variant='outlined'
                fullWidth
                value={form.attributes.server || ''}
                onChange={e => handleChangeAttributes('server', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id='port'
                name='port'
                type='number'
                margin='dense'
                label={translate('commandPort')}
                variant='outlined'
                fullWidth
                value={form.attributes.port || ''}
                onChange={e => handleChangeAttributes('port', e.target.value)}
              />
            </Grid>
          </>
        ) : null}
        {form.type.value === 'movementAlarm' ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id='radius'
              name='radius'
              type='number'
              margin='dense'
              label={translate('commandRadius')}
              variant='outlined'
              fullWidth
              value={form.attributes.radius || ''}
              onChange={e => handleChangeAttributes('radius', e.target.value)}
            />
          </Grid>
        ) : null}
      </Grid>

      {/* <Checkbox
        checked={form.textChannel}
        onChange={e => handleChange('textChannel', e.target.checked)}
        color='primary'
        canAssign={true}
        canRemove={true}
        label={translate('commands.sendSms')}
      /> */}

      <div style={{ display: 'flex', marginTop: 10 }}>
        {mode !== 'create' ? (
          <>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('unit', form)}
            >
              {translate('assignUnit')}
            </Button>
            <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('user', form)}
            >
              {translate('assignUser')}
            </Button>
            {/* <Button
              size='small'
              style={{ marginRight: 15 }}
              onClick={e => assignModal('group', form)}
            >
              {translate('assignGroup')}
            </Button> */}
          </>
        ) : null}
        <div style={{ marginLeft: 'auto' }} />
        <Button
          size='small'
          style={{ marginRight: 15 }}
          className='button-white'
          onClick={closeCreateFrom}
        >
          {translate('sharedCancel')}{' '}
        </Button>
        {checkPrivileges('commandCreate') && (
          <Button
            size='small'
            onClick={submitForm}
            disabled={isVisableComdBtn}
          >
            {translate(mode === 'create' ? 'sharedCreate' : 'update')}
          </Button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  commands: state.commands,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  devices3: state.devices3,
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  devices2:state.devices2,
  devices: state.devices.data,

})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Commands)))

const frequencyType = [
  {type: 's'},
  { type: 'm' },
  { type: 'h' }
]
