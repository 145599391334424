import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

//import { NavLink } from 'react-router-dom';
import Style from 'style-it'
import { NavLink } from 'react-router-dom'
import DashboardMenu from '../../Components/Maps/DashboardMenu'
import { checkPrivileges } from '../../Helpers'
export default class Navbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: '/monitoring'
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
    this.props.history.push(newValue)
    return false
  }

  componentWillMount () {
    if (this.props.location.pathname !== this.state.value) {
      this.setState({ value: this.props.location.pathname })
    }
  }

  collapseMenu = () => {
    if (document.body.offsetWidth < 959) {
      this.props.collapseMenu()
    }
  }

  render () {
    const analytic = checkPrivileges('dashboard')&&(<DashboardMenu history={this.props.history} />) || null
    return (<div className='navbar-menu'>
        {Object.keys(this.props.items).map(name => {
          let item = this.props.items[name]
          const { icon: ItemIcon } = item;
          let navlink = null;
          if (item.checked) {
            navlink = (
              <Tooltip
                key={name}
                title={item.title}
                placement='right'
                classes={{
                  popper: 'menu-popper',
                  tooltip: 'menu-popper-tooltip'
                }}
              >
                <NavLink
                  className='navbar-menu-item'
                  onClick={this.collapseMenu}
                  to={item.href}
                  style={{position: 'relative'}}
                >
                  <ItemIcon fill='currentColor' width={20} height={20} />
                  {item.count !== undefined ? <span className="counter">{(item.count>99)?'99+':item.count}</span> : null}
                  <span className='nav-link-text'>{item.title}</span>
                </NavLink>
              </Tooltip>
            )
          }
          return name === 'dashboard' ? <div key={name}>
            {navlink}
            {analytic}
          </div> : 
          <div key={name}>{navlink}</div>
        })}
      </div>)
  }
}
