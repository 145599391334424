import isEqual from 'react-fast-compare'
import moment from "moment";
import { categoriesList } from '../../Helpers'
export const devices2 = (state = [], action) => {
  let arr = [];
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_DEVICES2':
      return action.devices

    case 'UPDATE_DEVICE':
      arr = state.map(dec => {
        if (dec.id !== action.device.id) {
          return dec
        } else {
          const { name, groupId, category} = action.device;
          return {...dec, label: name, groupId, category}
        }
      })

      return arr

    case 'REMOVE_DEVICE':
      return state.filter(dec => parseInt(dec.id) !== parseInt(action.device))

    default:
      return state
  }
}
export const groupWiseDevices = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}
    case 'SAVE_GROUP_WISE_DEVICES':
      return action.payload

    default:
      return state
  }
}
export const devices3 = (state = [], action) => {
  let arr = []
  let single = {}
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_DEVICES3':
      action.devices.map(item => {
        single = { ...item.entity, visible: true }
        arr.push(single)
        return null
      })

      if (isEqual(arr, state)) {
        return state
      }

      return arr

    case 'UPDATE_DEVICES':
      state.map(d => {
        const key = 'id'
        const found = action.devices.find(s => s && d &&  s[key] === d[key]);
        if (found) {
          arr.push({
            ...found,
            attributes: {...found.attributes, color: d.attributes.color },
            visible: d.visible !== undefined ? d.visible : true
          })
        } else {
          d.visible = d.visible !== undefined ? d.visible : true

          arr.push(d)
        }
        return null
      })
      return arr
    case 'ADD_DEVICES':
      arr = state.map(e => e.id)
      single = action.devices.filter(e => !arr.includes(e))

      return state.concat(single.map(d => ({ ...d, visible: true })))

    case 'UPDATE_DEVICE_VISIBLE':
      if (state.length) {
        const data = state.map(x => {
          if (x.id === action.payload.id) {
            return { ...x, visible: action.payload.checked }
          } else {
            return { ...x, visible: x.visible || false }
          }
        })
        return data
      }
      return state

    case 'UPDATE_DEVICES_VISIBLE':
      if (action.payload.length) {
        const visibleDevices = []
        state.map(x => {
          if (action.payload.filter(d => x.id === d).length) {
            visibleDevices.push({ ...x, visible: true })
          } else {
            visibleDevices.push({ ...x, visible: false })
          }

          return null
        })

        return visibleDevices
      } else {
        return state
      }

    case 'TOGGLE_ALL_DEVICES':
      return state.map(obj => {
        return {
          ...obj,
          visible: action.payload.checked
        }
      })
          // case 'TOGGLE_ALL_GROUP_DEVICES':
      // return state.map(obj => {
      //   return {
      //     ...obj,
      //     visible: action.payload.checked
      //   }
      // })

    // case 'TOGGLE_ALL_DEVICES':
    //   const { checked, grp, fromArray } = action.payload;
    //   if (grp ==="group") {
    //     console.log("=====1", grp);
    //     return fromArray.map((obj) => ({
    //       ...obj,
    //       visible: checked,
    //       grp,
    //     }));
    //   }else{
    //     console.log("=====2");

    //     return state.map((obj) => ({
    //       ...obj,
    //       visible: checked,
    //     }));
    //   }
      

    case 'TOGGLE_GROUP_DEVICES':
      return state.map(obj => {
        if (
          (obj.groupId === action.payload.id && action.payload.ids.includes(obj.groupId+"")) ||
          (action.payload.id === 'nogroup' && (!obj.groupId || !action.payload.ids.includes(obj.groupId+"")))
        ) {
          return {
            ...obj,
            visible: action.payload.checked
          }
        } else {
          return { ...obj, visible: obj.visible || false }
        }
      })
    case 'REMOVE_DEVICE':
      return state.filter(dec => parseInt(dec.id) !== parseInt(action.device))

    case 'UPDATE_DEVICE':
      arr = state.map(dec => {
        if (dec.id !== action.device.id) {
          return dec
        } else {
          return action.device
        }
      })

      return arr

    case 'SORT_DEVICE_TYPES':
      return action.payload

    case 'SORT_DEVICES':
      if (action.payload.sort === 'DESC') {
        arr = state.slice().sort(function (b, a) {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      } else {
        arr = state.slice().sort(function (a, b) {
          const nameA = a.name.toLowerCase()
          const nameB = b.name.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        })
      }

      return arr
    default:
      return state
  }
}
export const engineHourSummery = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}
    case 'INVALIDATE_SUMMERY':
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case 'REQUEST_SUMMERY':
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
        data: []
      })
    case 'RECEIVE_SUMMERY':
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        data: action.payload,
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}

export const devices = (state = {data : []}, action) => {
  let prevDevices = []
  switch (action.type) {
    case 'RESET_APP':
      return {data: []}
    case 'INVALIDATE_DEVICES':
      return Object.assign({}, state, {
        didInvalidate: true
      })
    case 'REQUEST_DEVICES':
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
        data: state.data || []
      })
    case 'RECEIVE_DEVICES':
      const data = action.payload.data.filter(
        dev => !state.data.map(d => d.id).includes(dev.id)
      )
      const data2 = action.payload.data.filter(
        dev => state.data.map(d => d.id).includes(dev.id)
      )
      let final = []
      if(state.data && state.data.length){
        state.data.map(d => {
          const a = (data2 || []).find(b => b.id === d.id)
          if(a && a.id){
            final.push(a)
          }else{
            final.push(d)
          }
        })
      }
      let final2 = final.concat(data)
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        hasNext: action.payload.hasNext,
        page: action.payload.page,
        lastPage: action.payload.lastPage,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
        data: action.payload.reset
          ? action.payload.data
          : final2,
        lastUpdated: action.receivedAt
      })

    case 'UPDATE_DEVICE_VISIBLE':
      if (state.data.length) {
        const data = state.data.map(x => {
          if (x.id === action.payload.id) {
            return { ...x, visible: action.payload.checked }
          } else {
            return { ...x, visible: x.visible || false }
          }
        })
        return { ...state, data }
      }
      return state

    case 'UPDATE_DEVICES_VISIBLE':
      if (action.payload.length) {
        const visibleDevices = []
        state.data.map(x => {
          if (action.payload.filter(d => x.id === d).length) {
            visibleDevices.push({ ...x, visible: true })
          } else {
            visibleDevices.push({ ...x, visible: false })
          }

          return null
        })

        return { ...state, data: visibleDevices }
      } else {
        return state
      }
    case 'REMOVE_DEVICE':
      return {
        ...state,
        data: state.data.filter(
          dec => parseInt(dec.id) !== parseInt(action.device)
        )
      }

    case 'UPDATE_DEVICE':
      return {
        ...state,
        data: state.data.map(dec => {
          if (dec.id !== action.device.id) {
            return dec
          } else {
            return action.device
          }
        })
      }

    case 'UPDATE_DEVICES':
      if (state && state.data) {
        state.data.map(d => {
          const key = 'id'
          const found = action.devices.find(s => s && d && s[key] === d[key])
          if (found) {
            prevDevices.push({
              ...found,
              attributes: {...found.attributes, color: d.attributes.color},
              visible: d.visible !== undefined ? d.visible : true
            })
          } else {
            prevDevices.push({
              ...d,
              visible: d.visible !== undefined ? d.visible : true
            })
          }
          return null
        })
      }

      return { ...state, data: prevDevices }

    case 'TOGGLE_ALL_DEVICES':
      return {
        ...state,
        data: state.data.map(obj => {
          return {
            ...obj,
            visible: action.payload.checked
          }
        })
      }

      //     case 'TOGGLE_ALL_GROUP_DEVICES':
      // return state.map(obj => {
      //   return {
      //     ...obj,
      //     visible: action.payload.checked
      //   }
      // })

    case 'TOGGLE_ALL_GROUP_DEVICES':
      const { checked, grp, fromArray } = action.payload;
      // if (grp  ==="group" && Array.isArray(fromArray)) {
        return {
          ...state,
          data: fromArray.map(obj => ({
            ...obj,
            visible: checked,
          })),
        };
      // } else {
      //   return {
      //     ...state,
      //     data: state.data.map(obj => ({
      //       ...obj,
      //       visible: checked,
      //     })),
      //   };
      // }

    case 'TOGGLE_GROUP_DEVICES':
      return {
        ...state,
        data: state.data.map(obj => {
          if (
            (obj.groupId === action.payload.id && action.payload.ids.includes(obj.groupId+"")) ||
            (action.payload.id === 'nogroup' && (!obj.groupId || !action.payload.ids.includes(obj.groupId+"")))
          ) {
            return {
              ...obj,
              visible: action.payload.checked
            }
          } else {
            return { ...obj, visible: obj.visible || false }
          }
        })
      }
    default:
      return state
  }
}

export const deviceRelatedData = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}
    case 'GET_DEVICES_DATA':
      return action.payload
    default:
      return state
  }
}

export const tags = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}
    case 'GET_TAGS':
      return action.tags
    default:
      return state
  }
}
export const driverTags = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}
    case 'GET_DRIVER_TAGS':
      return action.tags
    default:
      return state
  }
}

export const searchRecently = (state = '', action) => {
  switch (action.type) {
    case 'RESET_APP':
      return ''
    case 'SEARCH_DEVICES':
      return action.payload.device || ''
    default:
      return state
  }
}

export const deviceId = (state = 0, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return 0
    case 'ADD_DEVICE_ID':
      return action.payload.id

    case 'REMOVE_DEVICE_ID':
      return 0

    default:
      return state
  }
}

export const trackId = (state = 0, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return 0
    case 'SET_TRACK_ID':
    case 'RESET_BOUNDS':
      return action.payload || 0

    default:
      return state
  }
}

export const category = (
  state = categoriesList.map(value => ({id: value, name: value})),
  action
) => {
  return state
}
export const allNotifications = (
  state = {
    overSpeed: [],
    Idling: [],
    harshBreak: [],
    Moving: [],
    Towing: [],
    statusOnline: [],
    statusOffline: [],
    statusParking: [],
    geofenceEntered: [],
    outsideGeofence: [],
    lowBattery: [],
    gpsNotUpdated: [],
    notRegistered: [],
    stop: []
  },
  action
) => {
  switch (action.type) {
    case 'RESET_APP':
      return {
        overSpeed: [],
        Idling: [],
        harshBreak: [],
        Moving: [],
        Towing: [],
        statusOnline: [],
        statusOffline: [],
        statusParking: [],
        geofenceEntered: [],
        outsideGeofence: [],
        lowBattery: [],
        gpsNotUpdated: [],
        notRegistered: [],
        stop: []
      }
    case 'UPDATE_NOTIFICATIONS':
      return { ...state, ...action.payload }

    case 'UPDATE_NOTIFICATION_INSIDE_GEO':
      return { ...state, geofenceEntered: [...new Set(action.payload)] }
    default:
      return state
  }
}
export const filterList = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {}
    case 'UPDATE_FILTER':
      return { filters: action.payload, id: action.id }
    default:
      return state
  }
}
export const groupBy = (state = '', action) => {
  switch (action.type) {
    case 'RESET_APP':
      return ''
    case 'UPDATE_GROUP_BY':
      return action.payload
    default:
      return state
  }
}
export const filterCatList = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'UPDATE_CAT_FILTER':
      return action.payload
    default:
      return state
  }
}
export const filterTagsList = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'UPDATE_TAGS_FILTER':
      return action.payload
    default:
      return state
  }
}
export const filterDriversList = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'UPDATE_DRIVERS_FILTER':
      return action.payload
    default:
      return state
  }
}

export const positions = (state = [], action) => {
  let p = []
  switch (action.type) {
    case 'RESET_APP':
      return []

    case 'ADD_POSITIONS':
      if (
        (state.length === 0 || state.length === 1) &&
        action.positions.length > 1
      ) {
        return state.concat(action.positions)
      } else {
        return state
      }

    case 'GET_POSITIONS':
      return action.positions

    case 'UPDATE_POSITIONS':
      if (state.length === 0 && action.positions.length) {
        return state.concat(action.positions)
      } else {
        if (action.positions.length > 1) {
          state.map(d => {
            const found = action.positions.find(
              s => s && d && s.deviceId === d.deviceId
            )
            if (found) {
              p.push(found)
            } else {
              p.push(d)
            }
            return null
          })
          return p
        } else {
          const pos = action.positions[0]
          state.map(position => {
            if (
              position &&
              pos &&
              parseInt(position.deviceId) === parseInt(pos.deviceId)
            ) {
              p.push(pos)
            } else {
              p.push(position)
            }
            return ''
          })
        }
      }
      return p

    default:
      return state
  }
}


export const allComputedAttributes = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'GET_ALL_COMPUTED_ATTRIBUTES':
      return action.payload
    default:
      return state
  }
}

export const groupNames = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'UPDATE_GROUP_NAMES':
      return action.payload || state
    default:
      return state
  }
}
export const resourceGroups = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'SAVE_RESOURCE_GROUPS':
      return action.payload || {}
    default:
      return state
  }
}
export const tail = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'UPDATE_TAIL':
      return action.payload
    case 'SET_TRACK_ID':
    case 'RESET_BOUNDS':
      return [];
    default:
      return state
  }
}
export const tailSegments = (state = [], action) => {
  switch (action.type) {
    case 'RESET_APP':
      return []
    case 'UPDATE_SEGMENTS':
      return action.payload
    case 'SET_TRACK_ID':
    case 'RESET_BOUNDS':
      return []
    default:
      return state
  }
}

export const tailEnabled = (state = false, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return false
    case 'ENABLE_TAIL':
      return action.payload
    default:
      return state
  }
}

/* const config = {
  tail: false,
  cluster: false,
  tailLength: 7,
  trackId: 0
};

export const monitoringConfig = (state = config, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return config
    case 'SET_TRACK_ID':
      return {...state, trackId: action.payload}
    case 'RESET_BOUNDS':
      return {...state, trackId: 0}
    case 'ENABLE_TAIL':
      return {...state, tail: action.payload}
    case 'UPDATE_CONFIG':
      return {...state, [action.payload.option]: action.payload.value}
    default:
      return state
  }
} */

const initDashboard = [{
  id: 1,
  parent_id: 1,
  label: "My Dashbaord 1",
  expiry: moment().add(1, 'years').toISOString(),
  created: moment().toISOString(),
  attribute: {}
},
{
  id: 2,
  parent_id: 1,
  label: "My Dashbaord 2",
  expiry: moment().add(1, 'years').toISOString(),
  created: moment().toISOString(),
  attribute: {}
}]

export const dashboards = (state = {data : []}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {data: []}
    case 'REQUEST_DASHBOARDS':
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
        data: state.data || []
      })
    case 'RECEIVE_DASHBOARDS':
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        hasNext: action.payload.hasNext,
        page: action.payload.page,
        lastPage: action.payload.lastPage,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
        data: action.payload.data,
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}
export const widgets = (state = {data : []}, action) => {
  switch (action.type) {
    case 'RESET_APP':
      return {data: []}
    case 'REQUEST_WIDGETS':
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
        didInvalidate: false,
        data: state.data || []
      })
    case 'RECEIVE_WIDGETS':
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        total: action.payload.length,
        data: action.payload,
        lastUpdated: action.receivedAt
      })
    default:
      return state
  }
}