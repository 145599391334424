import React, { PureComponent } from 'react'
import './index.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faColumns,
  faTh,
  faBell,
  faComments,
  faImage,
  faTerminal
} from '@fortawesome/free-solid-svg-icons'
//import ToggleLanguage from './../ToggleLanguage'
import { connect } from 'react-redux'
import Ticker from 'react-ticker'

library.add([faColumns, faTh, faBell, faComments, faImage, faTerminal])

library.add([faColumns, faTh, faBell, faComments, faImage, faTerminal])

class Footer extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      //curTime: new Date().toLocaleTimeString()
      move: true
    }

    /*setInterval( () => {
	      this.setState({
	        curTime : new Date().toLocaleTimeString()
	      })
	    },1000);*/
  }
  play = () => {
    this.setState({ move: true })
  }
  pause = () => {
    this.setState({ move: false })
  }
  render () {
    const { attributes } = this.props.ServerSetting
    if (attributes && attributes.serviceTitle) {
      document.title = attributes.serviceTitle
    } else {
      document.title = 'En Route Technologies'
    }
    // const emptyLink = '#';
    return (
      <footer
        className='fms-footer'
        style={{
          background: this.props.themecolors.backgroundColor,
          color: this.props.themecolors.textColor
        }}
      >
        <table>
          <tbody>
            <tr>
              {/*<td className="toggleSidebar">
        				<a href={emptyLink} title="Unit Activation"><MaterialIcon size="16" icon="add_to_queue" /></a>
        			</td>
        			<td className="toggleMiniMaps">
                <a href={emptyLink} title="Units and Settings"><MaterialIcon size="16" icon="settings" /></a>
              </td>
              <td className="toggleMiniMaps">
        				<a href={emptyLink} title="Help"><MaterialIcon size="16" icon="help" /></a>
        			</td>*/}
              <td>
                &copy;{' '}
                {(attributes && attributes.footerText) || (
                  <span>enroutech.com</span>
                )}
              </td>
              {/* <td style={{ width: '8%' }}>
                <Ticker speed={10} mode='smooth' move={this.state.move}>
                  {obj => {
                    return (
                      <div
                        className='ticker-inner'
                        onMouseEnter={() => this.pause()}
                        onMouseLeave={() => this.play()}
                      >
                        {this.props.footerNotifications.map(ev => (
                          <div style={{ marginRight: 100 }}>
                            {ev.title} ({ev.name}){' '}
                          </div>
                        ))}
                      </div>
                    )
                  }}
                </Ticker>
              </td> */}
              {/* <td className="languageSection"><ToggleLanguage {...this.props} /></td> */}
              {/*<td className="activityCounterSection">
        				<Link to={'/notifications'} title="Alerts" ><FontAwesomeIcon icon={faBell} /><span className="badge">0</span></Link>
        				<a href={emptyLink} title="messages"><FontAwesomeIcon icon={faComments} /><span className="badge">0</span></a>
        				<a href={emptyLink}><FontAwesomeIcon icon={faImage} /><span className="badge">0</span></a>
        			</td>
              <td className="toggleConsole">
        				<a href={emptyLink}><FontAwesomeIcon icon={faTerminal} /></a>
        			</td>
        			<td className="dateTimeSection">{this.state.curTime}</td>*/}
            </tr>
          </tbody>
        </table>
      </footer>
    )
  }
}

const mapState = state => ({
  themecolors: state.themeColors,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  footerNotifications: state.footerNotifications
})

const mapStateToProps = connect(mapState)

export default mapStateToProps(Footer)
