import React, { Component } from 'react'
import Button from './../../../Components/common/Button'
import TextField from './../../../Components/common/SearchField'
import { GroupModal } from './../../../Components/Groups/groupModal'
import { DevicesShortList, ListRow } from './../../../Components/Devices/shortList'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import Style from 'style-it'
import Loader from './../../Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import Scrollbar from 'react-scrollbars-custom'
import { fetchMoreDevices } from '../../../Actions/Devices'
import ExportImportMenu from '../../../Components/Maps/ExportImportMenu'
import RecourcesList from './RecourcesList'
import { useSelector } from 'react-redux'
import instance from '../../../axios'

function importAll (r) {
  return r.keys().map(r)
}
const images = importAll(
  require.context('./../../../images', false, /\.(png|jpe?g|svg)$/)
)

export default class UnitsSidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      switchView: 'units',
      isPopupVisable: false,
      is_gm_Visable: false,
      isDeviceLimit: false,
      countCall: true
    }
    this.switchView = this.switchView.bind(this)
  }

  switchView (type, e) {

    this.setState({
      switchView: type,
      isChecked: true,
      sort: 'ASC',
      search: ''
    })
  }
  checkedDeviceLimit = () => {
    if (
      this.props.logInUser.deviceLimit &&
      (this.state.countCall || this.props.checkeDeviceLimit)
    ) {
      if (this.props.logInUser.deviceLimit !== -1) {
        this.setState(
          {
            countCall: false
          },
          () => {
            this.props.checkedDeviceLimit()
            // fetch('/api/devices/count')
            instance({
              url: `/api/devices/count`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            })
                .then(count => {
                  if (count === this.props.logInUser.deviceLimit) {
                    this.setState({
                      isDeviceLimit: true
                    })
                  } else {
                    this.setState({
                      isDeviceLimit: false
                    })
                  }
  
            }).catch(error => {
              // errorHandler(error, this.props.dispatch)
            })
          }
        )
      }
    }
  }
  render () {
    this.checkedDeviceLimit()
    const endMessage =
      ((this.props.devices && this.props.devices.total > 0 && this.props.switchView === 'units' )|| this.props.switchView === 'resource') ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
    return (
      <Style>
        {`
        .material-icons {vertical-align: middle; color: inherit}
      `}
        <div>
          {this.state.is_gm_Visable && (
            <GroupModal onCloseModal={this.onCloseModal} />
          )}
         <div className='section-head clearfix section-head-filter'>
            <ul className='filter-row clearfix pull-right'>
              <li>
                <TextField
                  label={this.props.view === "units" ? this.props.translate('searchUnits') :
                     this.props.view === "groups" ? this.props.translate('searchGroups') : this.props.translate('searchResources')
                  }
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.props.view === 'resource' ? this.state.search :  this.props.search} 
                  onChange={this.props.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li>
              {this.props.resourceList && this.props.view !== 'resource'?
              <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
                {checkPrivileges('itemgroupCreate') && (
                  <Button
                    size='medium'
                    style={{ marginRight: 5 }}
                    disabled={this.state.isDeviceLimit}
                    fullWidth
                    onClick={() => {
                      this.props.addResource()
                    }}
                  >
                    {' '}
                    {this.props.translate('sharedCreate')}
                  </Button>
                )}
              </li>:
              <li style={{ paddingTop: 8, paddingBottom: 4}}>{/*, paddingRight: 0 }}> */}
              {checkPrivileges('deviceCreate') && this.props.view !== 'resource' &&  (
                <Button
                  size='medium'
                  style={{ marginRight: 5 }}
                  disabled={this.state.isDeviceLimit}
                  fullWidth
                  onClick={() => {
                    this.props.addDevice()
                  }}
                >
                  {' '}
                  {this.props.translate('sharedCreate')}
                </Button>
              )}
              </li>}
              <li style={{ paddingTop: 8, paddingBottom: 4, paddingLeft: 0 }}>
              <ExportImportMenu
                  fileName = 'devices'
                  checkPrivilege = 'deviceCreate'
                  data = {this.props.devices.data}
                  themecolors = {this.props.themecolors}
                />
              </li>
            </ul>
          </div>
          {this.props.resourceList ? 
          <RecourcesList 
            ItemChild={ListRow}
            ItemChildProps={this.props}
            fromArray={this.props.devices && this.props.devices.data || []}
            {...this.props} /> :
            <div className='sidebar-devices-list with-tabs'>
              <Scrollbar
                disableTracksWidthCompensation={true}
                scrollerProps={{
                  renderer: props => {
                    const { elementRef, ...restProps } = props
                    return (
                      <div
                        {...restProps}
                        ref={elementRef}
                        id='scrollableDivDevices'
                      />
                    )
                  }
                }}
              >
              {this.props.devices.isFetching ? (
                  <Loader defaultStyle={true} />
                ) : this.props.devices ? (
                 
                  
                    <DevicesShortList
                      {...this.state}
                      removeDevice={this.props.removeDevice}
                      resetFrom={this.props.resetFrom}
                      devicesIcons={images}
                      devices={this.props.devices.data}
                      match={this.props.match}
                      isChecked={this.props.isChecked}
                      collapseSidebar={this.props.collapseSidebar}
                      translate={this.props.translate}
                      switchView={this.props.switchView}
                      itemPagination2={this.props.itemPagination2}  
                    />
                 ) : <p style={{ textAlign: 'center', color: '#ccc', fontWeight: 'bold'}}> 
                      {(this.props.devices.total && this.props.switchView === 'units') ?  ""
                        : (this.props.groups && this.props.switchView === 'resource') ? ""
                        : this.props.translate('notFound') }
                    </p>}
              </Scrollbar>
            </div>}
        </div>
      </Style>
    )
  }
}
