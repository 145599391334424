import React, { Component } from 'react'
import { connect } from 'react-redux'
import Layout from './../../Layout'
import { AcManagements } from '../../Components/AccountManagements/accountManagements'
import { withLocalize } from 'react-localize-redux'

class AccountManagements extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfoFront: {},
      userFetch: false
    }
  }

  render () {
    return (
      <Layout
        {...this.props}
        classFromChildren={'has-padding no-sidebar'}
        noSidebar
      >
        <AcManagements {...this.props} />
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withLocalize(AccountManagements))
