import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { withLocalize } from 'react-localize-redux'
import Modal from './modal'
import Button from '../../../common/Button'
import Tooltip from '../../../common/Tooltip'
import { validEmailPattern, validNumberPattern } from '../../../../Helpers'
import instance from '../../../../axios'
import Axios from 'axios'
import Notifications from 'react-notification-system-redux'



const dummyMails = ['dummy1@example.com', 'dummy2@example.com', 'dummy3@example.com'];

class screen extends React.Component {
  constructor () {
    super()
    this.state = {  
      validMail: [],   
       emailLists:[],
       originalLists:[]
    }
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }

  componentDidMount () {
    const verifiedEmailsString = this.props.logInUser&&this.props.logInUser.verifiedEmails;
    const emailArray = verifiedEmailsString&&verifiedEmailsString.split(',');
     this.setState({
        emailLists:emailArray ? emailArray : [],
        originalLists:emailArray ? emailArray : []
    })
    if(this.props.mode === 'update'){

      const isValidMails = this.state.form?.email.map((item) => emailArray?.includes(item.text));
      this.setState(
        {
          validMail: isValidMails ,
        }
      )
    }

   }
  


  componentWillMount () {
    let { wizardData } = this.props
    if (wizardData && wizardData.form3) {
      this.setState(
        {
          ...wizardData.form3
        },
        () => this.checkRequiredFields()
      )
    }




  }
  hideColorPicker = e => {
    this.setState({
      colorPicker: false
    })
  }
  showColorPicker = e => {
    this.setState({
      colorPicker: true
    })
  }
  updateColor = e => {
    this.setState(
      {
        form: {
          ...this.state.form,
          alertColor: e.hex
        }
      },
      () => {
        this.checkRequiredFields()
      }
    )
  }
  handleChange = (name, event, id) => {
    if (name === 'type') {
      this.setState({
        form: {
          ...this.state.form,
          commandId: event.id,
          commandName: event.name
        }
      },()=> this.checkRequiredFields())
    }else{
    if (
      name === 'number' ||
      name === 'web' ||
      name === 'email' ||
      name === 'mailTo' ||
      name === 'command'
    ) {
      if (name === 'email') {


        let { email } = this.state.form
        email.map(item => {
          if (item.id === id) {
            item.text = event.target.value
          }
          return null
        })

        const isValidMails = email.map((item) => this.state.originalLists?.includes(item.text));

        this.setState(
          {
            form: {
              ...this.state.form,
              email: email,
              
            },
            validMail: isValidMails ,
          },
          () => {
            this.checkRequiredFields()
          }
        )
      } else if (name === 'mailTo') {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target.value,
              email: [{ id: 1, text: '' }]
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      }
      else if (name === 'command') {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target.value
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      } else if (name === 'number') {
        let { number } = this.state.form
        number.map(item => {
          if (item.id === id) {
            item.text = event.target.value
          }
          return null
        })
        this.setState(
          {
            form: {
              ...this.state.form,
              number: number
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      } else {
        let { notificators } = this.state.form
        if (event.target.checked) {
          if (notificators.length) {
            notificators = notificators + ',' + name
          } else {
            notificators = name
          }
        } else {
          notificators = notificators.replace(name, '')
          notificators = notificators.replace(',', '')
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              notificators
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      }
    } else {
      if (
        event.target.value === '' ||
        event.target.value === null ||
        event.target.value === undefined
      ) {
        delete this.state.form[name]
        this.setState(
          {
            form: {
              ...this.state.form
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target.value
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }
  }

  onChangedUsers = item => {
    if (item) {
      this.setState(
        {
          form: {
            ...this.state.form,
            usersId: item.value
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            usersId: ''
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields () {
    let {
      isOpenEmailSection,
      isOpenCommandSection,
      isOpenSmsSection,
      isOpenWebSection,
      isOpenFirebaseSection,
      isOpenMobileSection
    } = this.state
    let { email, number, mailTo, commandType, commandData, commandName } = this.state.form

    let value = true
    if (
      (isOpenEmailSection ||
        isOpenCommandSection ||
        isOpenSmsSection ||
        isOpenWebSection ||
        isOpenFirebaseSection ||
        isOpenMobileSection) &&
      value
    ) {
      let vald = true
      if (isOpenEmailSection) {
        if (mailTo === 'user' || email[0].text) {
          vald = true
        } else {
          vald = false
        }
      }
      if (isOpenMobileSection) {
        if (number[0].text) {
          vald = true
        } else {
          vald = false
        }
      }
      if (isOpenCommandSection) {
        if (((commandType === 'command'&&commandName) || (commandType === 'custom'&&commandData) )) {
          if (isOpenEmailSection ) {
            if (mailTo === 'user' || email[0].text) {
              vald = true
            } else {
              vald = false
            }
          }else{
            vald = true
          }
        } else {
          vald = false
        }
      }
      this.setState({
        validatedForm: vald
      })
    } else {
      this.setState({
        validatedForm: false
      })
    }
  }

  deleteMail = (type, id) => {
    // frist check the formate of prevous emails
    let data = this.state.form[type]
    if (data.length === 1 && id === 1) {
      data[0].text = ''
      this.setState({
        form: {
          ...this.state.form,
          [type]: data
        }
      })
    } else {
      let newList = data.filter(itm => itm.id !== id)
      newList.map((item, ind) => {
        item.id = ind + 1
        return null
      })
      this.setState({
        form: {
          ...this.state.form,
          [type]: newList
        }
      })
    }
  }
  onChangedUsers = item => {
    let ids = []
    if (item.length) {
      item.map(item => {
        ids.push(item.id)
        return null
      })
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          usersIds: ids
        }
      },
      () => this.checkRequiredFields()
    )
  }
  handleClick = item => {
    this.setState(
      {
        [item]: !this.state[item]
      },
      () => this.checkRequiredFields()
    )
  }

  next = () => {
    let data = { ...this.state, prv: true }
    this.props.combineAllData('form3', data)
  }
  prv = () => {
    if (this.props.handleChangeTab) {
      this.props.handleChangeTab('', this.props.tabPostion - 1)
    }
  }

  onAddAnotherMail = (type,index) => {
    let { email, number } = this.state.form
    // frist check the formate of prevous emails
    let formateValidation = true
    if (type === 'email') {
      email.map(itam => {
        if (!validEmailPattern.test(itam.text)) {
          formateValidation = false
        }
        else{
          let makeData = {"email":email[index].text}
          const axiosConfig = {
            'Content-Type': 'application/json',
            'Authorization': '86a38acce978afe5072b3b3ab4872eb1:be2d7d72c966c3c92003843243753fcaf740e4eac16760f0c908c09ededf8a44'
          }
          Axios.post('https://api.clearout.io/v2/email_verify/instant', makeData, axiosConfig)
          .then(res=>{
            if(res&&res.data&&res.data.status === 'invalid'){
              formateValidation=false
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate('inValidEmail'),
                  autoDismiss: 10
                })
              )
            }
            else{
              return null
            }
          }).catch(err=>{ 
                console.log('err===', err)
              // formateValidation=false
              //   this.props.dispatch(
              //     Notifications.error({
              //       message: this.props.translate('inValidEmail'),
              //       autoDismiss: 10
              //     })
              //   )
              })
          // return null
        }
      })
      if (formateValidation) {
        email.push({ id: email.length + 1, text: '' })
        this.setState({
          form: {
            ...this.state.form,
            email: email
          }
        })
      } else {
        this.props.enqueueSnackbar(
          this.props.translate('previousEmailInvalid'),
          { autoHideDuration: 1000 }
        )
      }
    } else if (type === 'number') {
      number.map(itam => {
        if (!validNumberPattern.test(itam.text)) {
          formateValidation = false
        }
        return null
      })
      if (formateValidation) {
        number.push({ id: number.length + 1, text: '' })
        this.setState({
          form: {
            ...this.state.form,
            number: number
          }
        })
      } else {
        this.props.enqueueSnackbar(
          this.props.translate('previousNumberInvalid'),
          { autoHideDuration: 1000 }
        )
      }
    }
  }
  validateSingleEmail = (email)=>{
    const isValid = /^\S+@\S+\.\S+$/.test(email);
    return isValid
  }

  submitValidEmail = (value)=>{
    instance({
       url: `api/users/send/verificationemail`,
       method: 'POST',
       headers: {
         Accept: 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded'
       },
       data:new URLSearchParams({
           email: value 
         })
      })
     .then(response => {
       this.props.enqueueSnackbar(this.props.translate('linkSent'), { autoHideDuration: 1000 });
   }) .catch(error => {
       this.props.enqueueSnackbar(this.props.translate('error'), { autoHideDuration: 1000 });
   })
 }


 


  render () {

    return (
      <>
        <Modal
          {...this.props}
          {...this.state}
          validateSingleEmail={this.validateSingleEmail}
          validateEmail={this.validateEmail}
          submitValidEmail={this.submitValidEmail}
          validMail={this.state.validMail}
          handleClick={this.handleClick}
          handleChange={this.handleChange}
          onAddAnotherMail={this.onAddAnotherMail}
          onChangedUsers={this.onChangedUsers}
          hideColorPicker={this.hideColorPicker}
          showColorPicker={this.showColorPicker}
          updateColor={this.updateColor}
          deleteMail={this.deleteMail}
          expirationCommandCheck={this.props.wizardData?.form2?.form?.functionalType ==='expiration'?true:false}
        />
        <div
          className='clearfix'
          style={{
            padding: '12px 20px 15px',
            borderTop: `1px solid ${this.props.themecolors.themeLightColor}`
          }}
        >
          <Tooltip
            title={this.props.translate('saveCurrentTabDataAndGotoNext')}
          >
            <span>
              <Button
                disabled={!this.state.validatedForm}
                variant='contained'
                onClick={this.next}
                style={{ float: 'right' }}
              >
                {this.props.translate('next')}
              </Button>
            </span>
          </Tooltip>
          <Button variant='contained' onClick={this.prv}>
            {this.props.translate('previous')}
          </Button>
        </div>
      </>
    )
  }
}

export const ScreenTwo = connect(null, null)(withSnackbar(withLocalize(screen)))
