import React from 'react'
import Table from '../../../Components/common/TableMultiCheckbox'

export default function (props) {
  const [rowsPerPage, handleChangeRowsPerPage] = React.useState(10)
  const items = [...props.data]
  const id = props.parent ? props.parent : 0
  items.map(u => {
    u.parent = u.id === id
    if (props.roles && props.roles.length) {
      props.roles.map(r => {
        if (r.id === u.roleId) {
          u.roleName = r.name
        }
        return null
      })
    }
    return null
  })
  return (
        <Table
           showDropDown={props.showDropDown}
          selectedModel={props.selectedModel}
          onSelect={props.onSelect}
          options={props.options}

          title={props.translate('access')}
          insideDialog
          key='Notifi'
          disableHead
          showCheckbox
          
          checkHandleChange={props.item_Selection}
          rows={items}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          isEditable
          canUpdate={false}
          canDelete={props.canDelete}
          onDelete={props.onDelete}
          themecolors={props.themecolors}
          searchable
          canRemove={props.canRemove}
          selectAll={props.selectAll}
          canAssign={props.canAssign}
          className='custom-table2'
          rowDefinition={props.rowDefinition || [
            {
              id: 'name',
              numeric: false,
              disablePadding: false,
              label: props.translate('sharedName')
            },
            {
              id: 'uniqueId',
              numeric: false,
              disablePadding: false,
              label: props.translate('uniqueId')
            },
            {
              id: 'model',
              numeric: false,
              disablePadding: false,
              label: props.translate('model')
            }
          ]}
        />
  )
}
