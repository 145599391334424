import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Style from 'style-it'
import Loader from '../../Loader'
import InvoiceFilter from '../../../Components/common/WorkFlowFilter'
class WorkFlow extends Component {
  constructor (props) {
    super(props)
  }
  submitSideBarSearch = (e)=>{
    this.props.fetchData('userId',1,50,false,true,e)
  }
  render () {
    const { workFlows } = this.props
    return (
      <Style>
        {`.material-icons {vertical-align: middle; color: inherit}`}
        <div className='fms-sidebar sidebar-bg-layer'>
        <div style={{ padding: 13 }}>
       <h3
          style={{ display: 'flex', alignItems: 'center', margin: 0 }}
        >
          <span>{this.props.translate('workFlows')}</span>
          </h3>
        </div>
        {
            <InvoiceFilter
              {...this.props}
              formSubmit={this.submitSideBarSearch}
              sidebar
              invoice
              loader={this.props.loader}
            />
        }
        </div>
      </Style>
    )
  }
}

const mapState = state => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(WorkFlow)
