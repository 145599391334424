import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import {Grid, IconButton, InputAdornment, Tooltip } from '@material-ui/core'
import SimpleModalWrapped from '../common/Modal'
import MenuItem from '../common/MenuItem'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import Button from '../common/Button'
import EditIcon from '@material-ui/icons/Place'
import MarkerMap from '../common/Maps'
import CustomDialog from '../common/Dialog'
import SingleSelect from '../common/SingleSelect'
import TextField from '../../Components/common/TextField'
import instance from '../../axios'
import DateRangePicker from '../common/DateRangeHistoryPicker'
import moment from 'moment'
import { ReactComponent as WorkFlow } from './../../assets/workflow/road-arrow.svg'
import { errorHandler, setAttributeFormat } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'

const DataFormat = (data,logInUser) => {
  return {
        id: data && data.form && data.form.id ? data.form.id : 0,  
        refNum: data && data.form && data.form.refNum ? data.form.refNum : 0,
        startPoint: data && data.value==='tab2'? data?.selectedMetrixData?.startPoint || '' : data && data.form && data.form.startPoint ?  data.form.startPoint : '',
        startOutSkirts: data && data.value==='tab2'? data?.selectedMetrixData?.startOutSkirts || '' : data && data.form && data.form.startOutSkirts ?  data.form.startOutSkirts : '',
        endPoint: data && data.value==='tab2'? data?.selectedMetrixData?.endPoint || '' : data && data.form && data.form.endPoint ?  (data.form.endPoint) :'',
        endOutSkirts: data && data.value==='tab2'? data?.selectedMetrixData?.endOutSkirts || '' : data && data.form && data.form.endOutSkirts ?  (data.form.endOutSkirts) :'',
        foodCost: data && data.value==='tab2'? data?.selectedMetrixData?.foodCost || 0 : data && data.form && data.form.foodCost ?  (data.form.foodCost) :0,
        perDiemCost: data && data.value==='tab2'? data?.selectedMetrixData?.perDiemCost || 0 : data && data.form && data.form.perDiemCost ?  (data.form.perDiemCost) :0,
        foodPlusPerDiemCost: data && data.value==='tab2'? data?.selectedMetrixData?.foodPlusPerDiemCost || 0 : data && data.form && data.form.perDiemCost ?  (data.form.perDiemCost) :0,
        totalCost: data && data.value==='tab2'? data?.selectedMetrixData?.totalCost || 0 : data && data.form && data.form.totalCost ?  (data.form.totalCost) : 0,
        startLat: data&& data.form.locationFromData&&data.form.locationFromData.latitude ? data.form.locationFromData.latitude: '',
        startLng: data&& data.form.locationFromData&&data.form.locationFromData.longitude ? data.form.locationFromData.longitude: '',
        endLat: data&& data.form.locationToData&&data.form.locationToData.latitude ? data.form.locationToData.latitude: '',
        endLng: data&& data.form.locationToData&&data.form.locationToData.longitude ? data.form.locationToData.longitude: '',
        startTime:data && data.to ? data.from :'',
        endTime:data && data.from ? data.to :'',
        vehicleType: data && data.form && data.form.vehicleType ? data.form.vehicleType :'',
        interCity: data && data.value === 'tab2' ? true : false,
        deviceId: data && data.form && data.form.deviceId ? data.form.deviceId.id :'',
        cost: data && data.value==='tab2'? data?.selectedMetrixData?.cost || '' : data && data.form && data.form.cost ?  parseInt(data.form.cost) : 0,
        mileage: data && data.value==='tab2'? data?.selectedMetrixData?.mileage || '' : data && data.form && data.form.mileage ?  parseInt(data.form.mileage) : 0,
        startPositionId:data&&data.startPositionId ? data.startPositionId : '',
        endPositionId:data&&data.endPositionId ? data.endPositionId : '',
        status:data&&data.status ? data.status : '',
        attributes: data && data.form && data.form.attributes ? data.form.attributes :{},
  }
}

class WorkFlowF extends Component {
  constructor () {
    super()
    this.state = {
      isMapModal:false,
      mapForm: {
        latitude: '',
        longitude: '',
        zoom: '',
        poiLayer: '',
      },
      isMapModal: false,
      isVisableAddlocBtn: false,
      localPostionObj: '',
      isVisableAddBtn: false,
      maps: [],
      locationMode:'',
      selectedMetrixData:{},
      selectedMetrixData1:{},
      selectedDate:'',
      devicesList:[],
      form:{
        cost:'',
        mileage:'',
        refNum:'',
        vehicleType:'',
        startPoint:'',
        endPoint:'',
        startOutSkirts: '',
        endOutSkirts: '',
        deviceId:{},
        locationFromData:{},
        locationToData:{},
      },
      getDeviceList:false,
      isVisible:true,
      locationFromAddress:'',
      locationToAddress:'',
      noOfDays:""
      // value: this.props&&this.props.createMode?'tab1':'tab2',
    }
    this.showDates = this.showDates.bind(this)
  }

  componentWillMount (){
    if(this.props.editMode){
      const {formData} = this.props
      let selectedData = this.props.metrixData.filter(item=>item.id === formData.attributes.tripOutSideId)
      let timeFormat = 'HH:mm'
      if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
        timeFormat = 'hh:mm A'
      }
      var formatedDate = {
        from:moment(formData.startTime&&formData.startTime).toISOString(),
        to:moment(formData.endTime&&formData.endTime).toISOString()
      }
      // console.log("selectedData[0]======", selectedData[0]);
      this.fetchAddress('locationFrom',formData&&formData.startLat, formData&&formData.startLng)
      this.fetchAddress('locationTo',formData&&formData.endLat, formData&&formData.endLng)
       this.setState({
            value:formData.interCity ? 'tab2': 'tab1',
            form:{
              ...formData,
              cost:!formData.interCity ?formData?formData.cost: '' : '',
              mileage:!formData.interCity ?formData?formData.mileage: '': '',
              startPoint:!formData.interCity ?formData?formData.startPoint: '': '',
              endPoint:!formData.interCity ?formData?formData.endPoint: '': '',
              startOutSkirts:!formData.interCity ?formData?formData.startOutSkirts: '': '',
              endOutSkirts:!formData.interCity ?formData?formData.endOutSkirts: '': '',
              // foodCost:!formData.interCity ?formData?formData.foodCost: '': '',
              // perDiemCost:!formData.interCity ?formData?formData.perDiemCost: '': '',
              // foodPlusPerDiemCost :!formData.interCity ?formData?(formData.foodCost + formData.perDiemCost) : '': '',
              // totalCost :!formData.interCity ?formData?((formData.foodCost + formData.perDiemCost) +formData.cost ) : '': '',
              deviceId:this.props.devices.find(item => item.id === formData.deviceId),
            },
            selectedMetrixData:formData.interCity? selectedData[0] :{},
            selectedMetrixData1:formData.interCity? selectedData[0] :{},
            
            selectedDate:moment(formData.startTime).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(formData.endTime).format('YYYY-MM-DD ' + timeFormat),
          ...formatedDate
        })
    }
    else{
      this.setState({
        value:'tab1'
      })
    }
  }

  handleChange = (event, value) => {
      // this.setState({ value })
      // console.log("1=====", 1);
      if(value ==='startEndPoint'){
        this.setState({
          selectedMetrixData:event.target.value,
          selectedMetrixData1:event.target.value
          
        }, ()=>{this.checkReqFields()})
      }
      else if(value==='mileage' || value==='cost' || value ==='refNum' || value === 'vehicleType'){
        this.setState({
         form:{
            ...this.state.form,
            [value]:event.target.value
         }
        }, ()=>{this.checkReqFields()})
      }
      else if (event === 'deviceId' || event === 'startOutSkirts' || event === 'endOutSkirts' || event === 'startPoint' || event === 'endPoint' || event === 'cost'){
        this.setState({
          form:{
            ...this.state.form,
            [event]:value
          }
        }, ()=>{this.checkReqFields()})
      }
      else{
        this.setState({[value]:event.target&&event.target.value}, ()=>{this.checkReqFields()})
      }
  }

  checkReqFields = ()=>{
    const { refNum, cost, mileage,startPoint,endPoint,vehicleType } = this.state.form
    const { to, from } = this.state
    const { selectedMetrixData } = this.state
    if(this.state.value === 'tab1'){
        if(refNum&&cost&&startPoint&&endPoint&&vehicleType&& to && from){
            this.setState({isVisible:false})
        }
        else{
          this.setState({isVisible:true})
        }
    }
    else{
      if(refNum && vehicleType&& to && from){
        this.setState({isVisible:false})
        }
        else{
        this.setState({isVisible:true})

        }
    }

  }

  handleChangeTabs = (event, value) => { 
     this.setState({ value , selectedMetrixData:{}, selectedMetrixData1:{}})
  }

  handleClickShowPostion = (item,e) => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
      locationMode:item
    })
  }

  onCloseModal = () => {
    this.setState({
      isMapModal: false
    })
  }

  setSelectedLocation = () => {
    const { localPostionObj } = this.state
    if (localPostionObj) {
        if(this.state.locationMode === 'locationFrom'){
            this.setState(
              {
                form:{
                  ...this.state.form,
                  locationFromData: {
                    ...this.state.locationFromData,
                    latitude: localPostionObj.latlng.lat,
                    longitude: localPostionObj.latlng.lng,
                    zoom: localPostionObj.zoom
                  }
                },
                isMapModal: false
              }, ()=>{this.fetchAddress(this.state.locationMode,localPostionObj.latlng.lat,localPostionObj.latlng.lng)
              })
        }
        else {
            this.setState(
                { 
                  form:{
                    ...this.state.form,
                    locationToData: {
                    ...this.state.locationToData,
                    latitude: localPostionObj.latlng.lat,
                    longitude: localPostionObj.latlng.lng,
                    zoom: localPostionObj.zoom
                  }
                },
                  isMapModal: false
                },()=>{this.fetchAddress(this.state.locationMode,localPostionObj.latlng.lat,localPostionObj.latlng.lng)}
                )}
            }
    }

  fetchAddress = (mode,lat,lng)=>{
    axios.get(`http://172.105.79.247/nominatim/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=27&addressdetails=1`)
    .then(res => {
      const resp = res.data;
      if(mode === 'locationFrom') this.setState({ locationFromAddress:resp.display_name });
      if(mode === 'locationTo') this.setState({ locationToAddress:resp.display_name });
    })
    .catch(error=>{console.log('error ===', error)})

  }
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true
      })
    }
  }

  showDates (e, date) {
    
    const duration = moment.duration((moment(this.state.to)).diff(this.state.from));
    const hours = duration.asHours();
    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)
    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }


    
    const from = moment(formatedDate.from);
      const to = moment(formatedDate.to);

      // Calculate the difference in days
      const daysDifference = to.diff(from, 'days');


    let timeFormat = 'HH:mm'

    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }

    this.setState(
      {
        selectedMetrixData:{
          ...this.state.selectedMetrixData,
          foodPlusPerDiemCost:(this.state.selectedMetrixData.foodCost + this.state.selectedMetrixData.perDiemCost) *(daysDifference +1),
          totalCost:(((this.state.selectedMetrixData.foodCost + this.state.selectedMetrixData.perDiemCost) *(daysDifference +1)) + this.state.selectedMetrixData.cost)
        },
        noOfDays :daysDifference +1, 
        selectedDate:
          moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate
      },()=> this.checkReqFields())
  }

  getDevices = ()=>{
    const {from, to} = this.state
    const {vehicleType} = this.state.form
    let city = this.state&&this.state.value === 'tab1' ? true :false
    if(vehicleType &&from && to){
      instance(`/api/workflows/available/devices?startTime=${from}&endTime=${to}&interCity=${city}&category=${vehicleType}`)
        // .then(response=>{
              
        //       let findMileage = positions.filter(p=>p.deviceId === response.)
        //       this.setState({devicesList: response, getDeviceList:true})
        //     })
            .then(res => {
              let newList = res.map(item =>{ 
                return (
                    {
                      ...item,
                      mileage:this.props.positions.filter(p=> p.deviceId === item.id)
                  }
                 )
              })
            if(newList.length){
              let reslist = newList.map(item=>{
                let mil = item&&item.mileage&&item.mileage[0]&&item.mileage[0].attributes&&item.mileage[0].attributes.totalDistance ?item.mileage[0].attributes.totalDistance : 0
                return (
                 { ...item,
                  mileage:(mil / 1000).toFixed(2)
                })
              })
              this.setState({devicesList: reslist, getDeviceList:true})
            }
            else{
              this.setState({devicesList:[], getDeviceList:false})
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate('vehiclesNotAvailable'),
                  autoDismiss: 10
                })
              )

            }
        })    
        .catch(error => {
          this.setState({ getDeviceList:true})
          // errorHandler(error, this.props.dispatch)
          })
    }
  }
  isSubmit = ()=>{
    if(this.state.value==="tab2"){
        this.setState({
          form:{
            ...this.state.form,
            attributes:{
              ...this.state.form.attributes,
              tripOutSideId:this.state?.selectedMetrixData?.id|| ''
            }
          }
        }, ()=>{this.finalSubmit()})
      }
      else {
        this.finalSubmit()
      }
  }

  finalSubmit =()=>{
    // console.log("this.state=====", this.state);
    const obj = DataFormat(this.state,this.props.logInUser)
    // console.log("obj=====", obj);

    instance({
      url:!this.props.editMode ? `/api/workflows`: `/api/workflows/${this.state.form.id}`,
      method: !this.props.editMode ? 'POST': 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
    .then(response => {
            this.props.dispatch(
              Notifications.success({
                message: this.props.editMode ? this.props.translate('workFlowUpdated'): this.props.translate('workFlowCreated'),
                autoDismiss: 10
              })
            )
            this.setState({ 
              form:{},
              selectedDate:'',
              selectedMetrixData:'',
              selectedMetrixData1:{}
            }, ()=>{
              this.props.fetchMoreItems()
            })
            this.props.modelControl()
          })
      .catch(error => {errorHandler(error,this.props.dispatch)})
  }

  render () {
    const { classes } = this.props;
    const { value } = this.state
      return (
        <div style={{ height: '100%', width:'40%', marginLeft:'350px' }}>
        <div style={{ background: this.props.themecolors.backgroundColor, color: this.props.themecolors.textColor, borderRadius: 6 }}>
           <SimpleModalWrapped
               // {...props}
               visable
               title={('worKFLow')}
               modalControle={this.props.modelControl}
               notShowAttributesBtn
               btnCloseText='sharedBack'
               content={
               <>
               <Grid container spacing={2}>
                    <Grid item md={4} >
                        <strong><p style={{textAlign:'center', marginTop:'14px'}}>Reference Number</p></strong>
                    </Grid>
                    <Grid item md={8} >
                        <TextField
                          id='name'
                          label={('Reference Number')}
                          required
                          placeholder=''
                          value= {this.state.form?.refNum || ''}
                          onChange={e=>this.handleChange(e,'refNum')}
                          variant='outlined'
                          margin='dense'
                          fullWidth
                          InputProps={{
                        classes: {
                          root: 'theme-cssOutlinedInput',
                          error: 'theme-input-error',
                          input: 'theme-input',
                          focused: 'theme-cssFocused',
                          notchedOutline: 'theme-notchedOutline'
                        }
                    }}
                    InputLabelProps={{
                      ...this.props.InputLabelProps,
                      classes: {
                        root: 'theme-label',
                        focused: 'theme-label-focused',
                        shrink: 'theme-label-shrink',
                        error: 'theme-label-error'
                      }
                    }}
                      
                        />
                  </Grid>
               </Grid>
               <div style={{ paddingTop: 17 }}>
                    <AppBar
                    position='static'
                    color='inherit'
                    style={{ background: 'none', boxShadow: 'none' }}
                    >
                    <Tabs
                        value={value}
                        onChange={this.handleChangeTabs}
                        TabIndicatorProps={{
                        style: { background: this.props.themecolors.themeLightColor + '40' }
                        }}
                        scrollButtons='on'
                        classes={{
                        root: 'custom-tabs-root',
                        flexContainer: 'custom-tabs',
                        scrollable: 'custom-tabs-scrollable',
                        indicator: 'custom-indicator',
                        scrollButtons: 'scrollable-buttons'
                        }}
                    >
                        <Tab
                        value='tab1'
                        label={this.props.translate('tripInsideCity')}
                        style={{
                            background: this.state.value === 'tab1' ? this.props.themecolors.themeLightColor : this.props.themecolors.backgroundColor,
                            minHeight: '29px',
                            borderRadius: '6px 6px 0 0',
                            margin: '0px -2px 0 2px',
                            border: `1px solid ${this.props.themecolors.themeLightColor}`
                        }}
                        />
                        {this.props.logInUser && this.props.logInUser.userType !== 1 ? (
                        <Tab
                            value='tab2'
                            label={this.props.translate('tripOutSideCity')}
                            style={{
                            background: this.state.value === 'tab2' ? this.props.themecolors.themeLightColor : this.props.themecolors.backgroundColor,
                            minHeight: '29px',
                            borderRadius: '6px 6px 0 0',
                            margin: '0 2px',
                            border: `1px solid ${this.props.themecolors.themeLightColor}`    
                            }}
                        />
                        ) : null}
                    
                    </Tabs>
                    </AppBar>
                <Paper
                    style={{ 
                        padding: 16,
                        minHeight: 100,
                        background: this.props.themecolors.themeLightColor,
                        padding: 10,
                        maxHeight: 575,
                        // overflow: 'auto',
                        boxSizing: 'border-box',
                        borderRadius: 10
                       }}
                    className='custom-menu-paper'
                    square={true}
                >
                    {value === 'tab1' && (
                        <div>
                        <TripDetails 
                            translate={this.props.translate}
                            form={this.state.form||{}}
                            mode={this.state.mode}
                            handleChange={this.handleChange}
                            classes={classes}
                            handleClickShowPostion={this.handleClickShowPostion}
                            themecolors={this.props.themecolors}
                            locationFromAddress={this.state.locationFromAddress}
                            locationToAddress={this.state.locationToAddress}
                            logInUser={this.props.logInUser}
                        />
                    </div>
                    )}
                    {this.props.logInUser &&
                    this.props.logInUser.userType !== 1 &&
                    value === 'tab2' ? (
                    <div >
                        <TripOutSideDetails 
                            translate={this.props.translate}
                            locationToData={this.state.locationToData || {}}
                            mode={this.state.mode}
                            handleChange={this.handleChange}
                            classes={classes}
                            noOfDays={this.state.noOfDays}
                            handleClickShowPostion={this.handleClickShowPostion}
                            themecolors={this.props.themecolors}
                            metrixData={this.props?.metrixData || []}
                            selectedMetrixData={this.state?.selectedMetrixData || {}}
                            selectedMetrixData1={this.state?.selectedMetrixData1 || {}}
                            
                            logInUser={this.props.logInUser}
                        />
                    </div>
                    ) : null}
                </Paper>
                </div>
                  <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6} lg={6} style={{marginTop:10}}>
                          <TextField
                            id='vehicleType'
                            select
                            label={this.props.translate('vehicleType')}
                            value={this.state.form?.vehicleType || ''}
                            onChange={e=>this.handleChange(e,'vehicleType')}
                            margin='dense'
                            fullWidth
                          >
                            <MenuItem value={0}>
                              <em>None</em>
                            </MenuItem>
                            {this.props.categoriesList.map(option => (
                              <MenuItem key={option} value={option}>{this.props.translate(option)}</MenuItem>
                            ))}
                          </TextField>
                          {/* <SingleSelect
                            array={this.props?.categoriesList || []}
                            selectName='vehicleType'
                            label={this.props.translate('vehicleType')}
                              // value={this.props.form&&this.props.form.areaId&&this.props.form.areaId.key
                              //   ? {
                              //       id: this.props.form.areaId.key,
                              //       value: this.props.form.areaId.key,
                              //       label: this.props.form.areaId.name,
                              //       uniqueId:this.props.form.areaId.uniqueId,
                              //     }
                              //   : ''
                              // }
                              // value={this.props.form.vehicleType || ''}
                            // onChange={e => this.props.handleChange('vehicleType', e)}
                            handleChange={e=>this.handleChange('vehicleType',e )}
                            canRemove={true}
                            canAssign={true}
                        /> */}
                      </Grid>   
                </Grid>   
                <Grid container spacing={1}>
                    <Grid item xs={2} sm={2} md={2} lg={2} style={{marginTop:'12px',paddingLeft:'12px'}}>
                        <span>Job Time</span>
                    </Grid>   
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                    <DateRangePicker
                      fill
                      error={
                        this.props.timeError
                      }
                      selectedDate={this.state.selectedDate}
                      label={
                        this.props.translate('reportFrom') +
                        ' ' +
                        this.props.translate('reportTo')
                      }
                      onEvent={this.showDates}
                      trip
                      workFlow
                    />
                    </Grid>   
                    {/* <Grid item xs={5} sm={5} md={5} lg={5}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            error={false}
                            margin='dense'
                            label={this.props.translate('endDate')}
                            variant='outlined'
                            fullWidth
                            format='dd/MM/yyyy'
                            // value={
                            //   this.props.maintenanceForm.attributes &&
                            //   this.props.maintenanceForm.attributes.startDate
                            //     ? new Date(
                            //         `${this.props.maintenanceForm.attributes.startDate}`
                            //       ).toString()
                            //     : null
                            // }
                            onChange={e=>this.handleChange('endDate',e)}
                            InputProps={{
                            classes: {
                                root: 'theme-cssOutlinedInput',
                                error: 'theme-input-error',
                                input: 'theme-input',
                                focused: 'theme-cssFocused',
                                notchedOutline: 'theme-notchedOutline'
                            }
                            }}
                            InputLabelProps={{
                            ...this.props.InputLabelProps,
                            classes: {
                                root: 'theme-label',
                                focused: 'theme-label-focused',
                                shrink: 'theme-label-shrink',
                                error: 'theme-label-error'
                            }
                            }}
                        />
                            </MuiPickersUtilsProvider>
                    </Grid>    */}
                </Grid>
                {(<Grid container spacing={1} style={{ margin:'5px 15px 0 0' }}>
                      <Grid item sm={12} xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                      <Button
                          size='small'
                          // className='button-white'
                          onClick={()=>this.getDevices()} 
                          // disabled={this.state.isVisible}
                      >
                          {this.props.translate('getvehicles')}{' '}
                      </Button>
                  </Grid>
              </Grid>)}
              {(this.state.getDeviceList || this.props.editMode) &&
                  (<>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <SingleSelect
                          array={this.state?.devicesList || []}
                          selectName='deviceId'
                          label={this.props.translate('selectVehicle')}
                            value={this.state.form&&this.state.form.deviceId&&this.state.form.deviceId.id
                              ? {
                                  id: this.state.form.deviceId.id,
                                  value: this.state.form.deviceId.id,
                                  label: this.state.form.deviceId.name,
                                  uniqueId:this.state.form.deviceId.uniqueId,
                                }
                              : ''
                            }
                            handleChange={this.handleChange}
                          canRemove={true}
                          canAssign={true}
                          mileageCounter
                        />
                    </Grid>   
                </Grid>     
                <Grid container spacing={1} style={{ margin:'5px 15px 0 0' }}>
                      <Grid item sm={12} xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                      <Button
                          size='small'
                          // className='button-white'
                          onClick={()=>this.isSubmit()} 
                          disabled={this.state.isVisible}
                      >
                          {this.props.createMode ? this.props.translate('Create') :this.props.translate('Update') }{' '}
                      </Button>
                  </Grid>
                </Grid> </> )}
                {this.state.isMapModal && (
                <CustomDialog
                    title={this.props.translate('setLocation')}
                    themecolors={this.props.themecolors}
                    visable={true}
                    onClose={this.onCloseModal}
                    isVisableBtn={true}
                    draggable={true}
                    CancelText={this.props.translate('sharedBack')}
                    headerActions={
                    <Button
                        size='small'
                        variant='contained'
                        color='secondary'
                        disabled={!this.state.isVisableAddlocBtn}
                        onClick={() => this.setSelectedLocation()}
                    >
                        {' '}
                        {this.props.translate('sharedAdd')}
                    </Button>
                    }
                    bodyPadding={0}
                >
                    <MarkerMap
                      getPostion={this.getPostion}
                      minHeight={500}
                      zoom={this.state.mapForm.zoom || 0}
                      lat={this.state.mapForm.latitude || 0}
                      lng={this.state.mapForm.longitude || 0}
                      height={'100%'}
                    />
                </CustomDialog>
                )}
               </>}
               />
       </div>
    </div>

      )
   
  }
}

  const TripDetails = ({
    locationToData,
    handleChange,
    themecolors,
    translate,
    mode,
    classes,
    handleClickShowPostion,
    form,
    logInUser,
    locationFromAddress,
    locationToAddress
  }) =>{
    return (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                id='startPoint'
                required
                margin='dense'
                label={translate('startPoint')}
                variant='outlined'
                fullWidth
                value={form?.startPoint || ''}
                onChange={e => handleChange('startPoint',e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                locationfields={true?1:0}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                id='startOutSkirts'
                required
                margin='dense'
                label={translate('startOutSkirts')}
                variant='outlined'
                fullWidth
                value={form?.startOutSkirts || ''}
                onChange={e => handleChange('startOutSkirts',e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                locationfields={true?1:0}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                id='endPoint'
                required
                margin='dense'
                label={translate('endPoint')}
                variant='outlined'
                fullWidth
                value={form?.endPoint || ''}
                onChange={e => handleChange("endPoint",e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                locationfields={true?1:0}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                id='endOutSkirts'
                required
                margin='dense'
                label={translate('endOutSkirts')}
                variant='outlined'
                fullWidth
                value={form?.endOutSkirts || ''}
                onChange={e => handleChange("endOutSkirts",e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                locationfields={true?1:0}
              />
            </Grid>
          </Grid>
          {/* <Grid container spacing={1} style={{borderBottom: '1px solid' + themecolors.backgroundColor}}>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <TextField
                id='locationFrom'
                readOnly
                margin='dense'
                label={translate('locationFrom')}
                variant='outlined'
                fullWidth
                value={locationFromAddress?locationFromAddress:''}
                // value={form.locationFromData && form.locationFromData.latitude? `lat ${form.locationFromData.latitude} , lng ${form.locationFromData.longitude}` : ''}
                onChange={e => handleChange(e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                locationfields={true?1:0}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Tooltip title={translate('addLocation')}>
                      <IconButton onClick={e=>handleClickShowPostion('locationFrom',e)}>
                          {<EditIcon  style={{ color: themecolors.backgroundColor }}/>}
                      </IconButton>
                    </Tooltip>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <TextField
                id='locationTo'
                readOnly
                margin='dense'
                label={translate('locationTo')}
                variant='outlined'
                fullWidth
                value={locationToAddress?locationToAddress:''}
                // value={form.locationToData && form.locationToData.latitude? `lat ${form.locationToData.latitude} , lng ${form.locationToData.longitude}` : ''}
                onChange={e => handleChange("startPoint",e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
            //     InputProps={{
            //     endAdornment: (
            //         <InputAdornment position='end'>
            //             <Tooltip title={translate('addLocation')}>
            //                 <IconButton onClick={e=>handleClickShowPostion('locationTo',e)}>
            //                     {<EditIcon  style={{ color: themecolors.backgroundColor}} />}
            //                 </IconButton>
            //                 </Tooltip>
            //         </InputAdornment>
            //     )
            //  }}
             locationfields={true?1:0}
              />
            </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                  <Tooltip title={translate('addLocation')}>
                      <IconButton onClick={e=>handleClickShowPostion('locationTo',e)}>
                          {<EditIcon  style={{ color: themecolors.backgroundColor }}/>}
                      </IconButton>
                    </Tooltip>
            </Grid>
          </Grid> */}
          <Grid container spacing={1} style={{margin: '5px 0 0 0'}}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                        id='cost'
                        required
                        margin='dense'
                        label={translate('cost km')}
                        variant='outlined'
                        fullWidth
                        value={form?.cost || ''}
                        onChange={e => handleChange("cost",e.target.value)}
                        InputLabelProps={{style : {color : 'white'} }}
                        locationfields={true?1:0}
                        InputProps={{
                              style: {padding: 0},
                              endAdornment: (
                                <InputAdornment
                                  position='start'
                                  disableTypography
                                >
                                  {logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                                </InputAdornment>
                              )
                            }} 

                      />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>

                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} style={{marginTop:'12px'}}>
                    {/* <span>Mileage</span> {"     "}{"."} {'20km'} */}
                    {/* <TextField
                        id='mileage'
                        required
                        margin='dense'
                        label={translate('mileage')}
                        variant='outlined'
                        fullWidth
                        value={form?.mileage || ''}
                        onChange={e => handleChange("mileage",e.target.value)}
                        InputLabelProps={{style : {color : 'white'} }}
                        locationfields={true?1:0}
                      /> */}
                </Grid>
          </Grid>
        </div>
      )
}
  const TripOutSideDetails = ({
    form,
    handleChange,
    themecolors,
    translate,
    mode,
    classes,
    handleClickShowPostion,
    metrixData,
    selectedMetrixData,
    selectedMetrixData1,
    logInUser,
    noOfDays
    }) =>{
      return (
        <div>
          <Grid container spacing={1} style={{borderBottom: '1px solid' + themecolors.backgroundColor}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {console.log("1====",selectedMetrixData1 )}
              {console.log("metrixData====",selectedMetrixData )}
              <TextField
                id='startEndPoint'
                select
                label={translate('startEndPoints')}
                value={selectedMetrixData1 || ''}
                onChange={(e) => handleChange(e, 'startEndPoint')}
                margin='dense'
                fullWidth
                locationfields={true ? 1 : 0}
                InputProps={{
                  classes: {
                    root: 'theme-cssOutlinedInput',
                    error: 'theme-input-error',
                    input: 'theme-input',
                    focused: 'theme-cssFocused',
                    notchedOutline: 'theme-notchedOutline',
                  },
                }}
              >
                {metrixData && metrixData.length ? (
                  metrixData.map((option) => (
                    <MenuItem key={option.id} value={option}>
                      <strong>
                        {translate(option.startPoint)}{' '}
                        <span>
                          <WorkFlow
                            style={{
                              verticalAlign: 'middle',
                              width: 50,
                              height: 16,
                              fill: '#00000',
                              display: 'inline-block',
                              marginTop: 2,
                            }}
                          />
                        </span>{' '}
                        {translate(option.endPoint)}
                      </strong>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value=''>No options available</MenuItem>
                )}
              </TextField>

            </Grid>
            {/* <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                id='locationTo'
                required
                margin='dense'
                label={translate('Location To')}
                variant='outlined'
                fullWidth
                value={locationToData && locationToData.latitude? `lat ${locationToData.latitude} , lng ${locationToData.longitude}` : ''}
                onChange={e => handleChange(e.target.value)}
                InputLabelProps={{style : {color : 'white'} }}
                InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <Tooltip title={translate('addLocation')}>
                            <IconButton onClick={e=>handleClickShowPostion('locationTo',e)}>
                                {<EditIcon  style={{ color: themecolors.backgroundColor}} />}
                            </IconButton>
                            </Tooltip>
                    </InputAdornment>
                )
             }}
             locationfields={true?1:0}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={1} style={{margin: '5px 0 0 5px'}}>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                    <span>{translate('startPoint')} </span> : {"  "} {selectedMetrixData?.startPoint} {"  "}
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                <span>{translate('startOutSkirts')} </span> : {"  "} {selectedMetrixData?.startOutSkirts} {"  "}
                </Grid>
          </Grid>
          <Grid container spacing={1} style={{margin: '5px 0 0 5px'}}>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                <span>{translate('endPoint')}</span> : {"  "} {selectedMetrixData?.endPoint || ''} {"   "} 

                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                    <span>{translate('endOutSkirts')}</span> : {"  "} {selectedMetrixData?.endOutSkirts || ''} {"   "} 
                </Grid>
          </Grid>
          <Grid container spacing={1} style={{margin: '5px 0 0 5px'}}>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                    <span>{translate('perDiemCost')}</span> : {"  "} {selectedMetrixData?.perDiemCost || 0} {"   "} {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                    {/* <span>{translate('perDiemCost')}</span> : {"  "} {noOfDays ? selectedMetrixData?.perDiemCost * noOfDays : selectedMetrixData?.perDiemCost || 0} {"   "} {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'} */}
                </Grid>
               
                <Grid item xs={6} sm={6} md={5} lg={5}> 
                    <span>{translate('foodCost')}</span> : {"  "} {selectedMetrixData?.foodCost|| 0} {"   "}    {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={5}> 
                    <span>{translate('No Of Days')}</span> : {"  "} {noOfDays? noOfDays : 1} 
                </Grid>

                <Grid item xs={6} sm={6} md={5} lg={5}> 
                    <span>{translate('foodCost+PerDiem')}</span> : {"  "} {noOfDays ? ((selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost) * noOfDays) : (selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost ) || 0} {"   "}    {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                </Grid>
          </Grid>
          <Grid container spacing={1} style={{margin: '5px 0 0 5px'}}>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                    <span>{translate('Mileage')}</span> : {"  "} {selectedMetrixData?.mileage} {"  "} {"km"}
                </Grid>
                <Grid item xs={6} sm={6} md={5} lg={5}>
                    <span>{translate('cost km')}</span> : {"  "} {selectedMetrixData?.cost || 0} {"   "} {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                </Grid>
          </Grid>
          <Grid container spacing={1} style={{margin: '5px 0 0 5px'}}>
             
                <Grid item xs={6} sm={6} md={5} lg={5}>
                    <span>{translate('Total Cost')}</span> : {"  "} {(selectedMetrixData?.cost || 0) +  (noOfDays ? ((selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost) * noOfDays) : (selectedMetrixData?.foodCost + selectedMetrixData?.perDiemCost ) || 0) }   {"   "} {logInUser&&logInUser.attributes && logInUser.attributes.currencyLabel || 'AED'}
                </Grid>
          </Grid>
        </div>
      )
}
const mapState = state => ({
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  devices:state.devices?.data || [],
  positions:state.positions
})
const mapStateToProps = connect(mapState)
export const WorkFlowForm = mapStateToProps(WorkFlowF)

