import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
//import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TextField from '../../../Components/common/TextField'
import Tooltip from '../../../Components/common/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { checkPrivileges } from '../../../Helpers'
import './ecoTable.scss'

function DrawCell ({
  item,
  value,
  unit,
  translate,
  label,
  handleChange,
  index,
  themecolors
}) {
  return (
    <TableCell
      className='res-td'
      padding={'none'}
      style={{ color: 'inherit', borderColor: themecolors.themeLightColor }}
    >
      <TextField
        fullWidth
        id={item}
        label={translate(label)}
        value= { (item === 'speedMedium' || item === 'speedExtreme') && unit === 'g'
        ? value : value || ''}
        onChange={e => handleChange(item, index, e)}
        InputProps={{
          endAdornment: (
            <InputAdornment
              classes={{ root: 'text-default' }}
              disableTypography={true}
            >
              {translate(
                (item === 'speedMedium' ||
                  item === 'speedExtreme' ||
                  item === 'idleMedium' ||
                  item === 'idleExtreme') &&
                  unit === 'g'
                  ? item === 'speedMedium' || item === 'speedExtreme'
                    ? 'km/h'
                    : 'sec'
                  : unit
              )}
            </InputAdornment>
          )
        }}
      />
    </TableCell>
  )
}

export const EcoDrivingTable = props => {
  let { row } = props
  let arr = [
    { unit: 'g', label: 'min' },
    { unit: 'g', label: 'max' },
    { unit: 'points', label: 'penalty' },
    { unit: 'sec', label: 'threshold' }
  ]
  let arr2 = [
    { unit: 'key', label: 'key' },
    { unit: 'points', label: 'penalty'},
   ]
  return (
    <>
      <Table className='res-table'>
        <TableBody>
          {row &&
            Object.keys(row).map(
              item =>
                item !== 'preset' && item !== 'mode' && (
                  <>
                    <TableRow key={item} style={{borderBottom: `1px solid ${props.themecolors.themeLightColor}`}}>
                      <TableCell
                        className='res-th'
                        padding={'none'}
                        style={{
                          color: 'inherit',
                          borderColor:props.themecolors.themeLightColor
                        }}
                      >
                        {props.translate(item)}
                      </TableCell>
                        {(row.mode === 'device') && 
                          (item === "accelExtreme"
                          || item === "accelMedium"
                          || item === "brakeMedium"
                          || item === "brakeExtreme"
                          || item === "bumpMedium"
                          || item === "bumpExtreme"
                          || item === "turnMedium"
                          || item === "turnExtreme")
                          ?
                          arr2.map((obj, i) =>
                          obj.label !== 'threshold' ||
                          (obj.label === 'threshold' &&
                            item !== 'idleMedium' &&
                            item !== 'idleExtreme') ? (
                             <DrawCell
                              item={item}
                              themecolors={props.themecolors}
                              {...obj}
                              translate={props.translate}
                              value={row[item][i] || ''}
                              handleChange={props.handleChange}
                              index={i}
                            />
                          ) : (
                            <TableCell
                              className='res-td-empty'
                              style={{
                                color: 'inherit',
                                borderColor: props.themecolors.themeLightColor
                              }}
                            />
                          )
                        )
                        
                        :
                        
                        arr.map((obj, i) =>
                          obj.label !== 'threshold' ||
                          (obj.label === 'threshold' &&
                            item !== 'idleMedium' &&
                            item !== 'idleExtreme') ? (
                            <DrawCell
                              item={item}
                              themecolors={props.themecolors}
                              {...obj}
                              translate={props.translate}
                              value={row[item][i] || ''}
                              handleChange={props.handleChange}
                              index={i}
                            />
                          ) : (
                            <TableCell
                              className='res-td-empty'
                              style={{
                                color: 'inherit',
                                borderColor: props.themecolors.themeLightColor
                              }}
                            />
                          )
                        )
                      }
                      <TableCell
                        className='res-td-action'
                        padding={'none'}
                        style={{
                          color: 'inherit',
                          borderColor: props.themecolors.themeLightColor
                        }}
                      >
                        {checkPrivileges('settingUpdate') && (
                          <Tooltip title='Update'>
                            <IconButton
                              aria-label='Update'
                              onClick={() => props.onUpdate()}
                              color='inherit'
                            >
                              <SaveIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                        {checkPrivileges('settingDelete') && (
                          <Tooltip title='Delete'>
                            <IconButton
                              aria-label='Delete'
                              color='inherit'
                              onClick={() => props.onDelete(item)}
                            >
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )
            )}
        </TableBody>
      </Table>
    </>
  )
}
