import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import isEqual from 'react-fast-compare'
import { addJob, removeJob, updateJob } from './../../Actions/Jobs'
import AddJobs from './addJob'
import { errorHandler, validEmailPattern, validNumberPattern } from './../../Helpers'
import moment from 'moment'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'

const jobDataFormat = (data, attributes) => ({
  id: data.id ? data.id : 0,
  attributes: { ...attributes },
  description: data.description,
  start: data.start,
  end: data.end,
  triggerTime: data.triggerTime,
  period: data.period,
  inProgress: data.inProgress,
  days: data.period && data.period !== 'once' ? data.days.toString() : '',
  type: data.type,
  status: data.status,
  lastExecutedLog: data.lastExecutedLog,
  lastExecuted: data.lastExecuted
})

const itemGroupTypes = {
  device: 'Device',
  driver: 'Driver',
  vehicle: 'Vehicle',
}

const formDefault = {
  description: '',
  start: null,
  attributes: {
    controlDays: [1, 2, 3, 4, 5, 6, 7],
    controlTimeStart: '00:00',
    controlTimeEnd: '23:59',
    geoTimeThreshold: 1,
    attachmentType:"excel"
  },
  end: null,
  triggerTime: moment('03:30', 'HH:mm').toISOString(),
  period: 'once',
  inProgress: false,
  days: '1',
  type: 'report',
  status: true,
  lastExecutedLog: '',
  lastExecuted: '',
  email: [{ id: 1, text: '' }],
  mailTo: 'user'
}
const defaultSteps ={
  steps: [{id: 1, title: 'reportBetweenTime'}, {id: 2, title: 'selectReportTemplate'}],
  activeStep: 0,
  removedIdPropcess: '',
  selectedJobDate: 'Never End',
  reportUnits: 'device',
  reportDuration: '',
  selectedJob: '',
  validatedForm: false,
  notificationIds: [],
  types: [],
  controlDays: [1, 2, 3, 4, 5, 6, 7],
  controlTime: [0, 1439],
  sensorIds: [],
  driverIds: [],
  geofenceIds: [],
  allowFields: [],
  sensorValues: [{input: "", index: "", value: ""}],
  eventType: 'notification',
  allSensors: false,
  isSensorTracing: false,
  from: null,
  to: null,
  selectedDate: '0',
  isVisableUserBtn: false,
  isVisableTrackerModal: false,
  jobId: '',
  deviceIds: [],
  groupIds: [],
  vehicleIds: [],
  reportType: '',
}
class jobsModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // report state
      form: { ...formDefault },
      ...defaultSteps,
      addOption: false,
      editOption: false,
      dm_isVisable: false,
      
    }
    this.ad_submit = this.ad_submit.bind(this)
    this.onCloseSubModal = this.onCloseSubModal.bind(this)
    this.ud_submit = this.ud_submit.bind(this)
    this.onDeleteJob = this.onDeleteJob.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.showJobDates = this.showJobDates.bind(this)
    this.createReport = this.createReport.bind(this)
    this.reportTypeSelection = this.reportTypeSelection.bind(this)
  }

  handleChangeType = eventType => {
    this.setState(
      {
        eventType
      },
      () => {
        this.selectedNotifications([])
        this.selectedEvents([])
      }
    )
  }

  selectedNotifications = types => {
    this.setState({ types })
  }

  selectedEvents = notificationIds => {
  
    this.setState({ notificationIds })
  }

  componentWillMount () {
    const rangesFuture = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      'Next 7 Days': [
        moment().startOf('day'),
        moment()
          .add(6, 'days')
          .endOf('day')
      ],
      'Next 30 Days': [
        moment().startOf('day'),
        moment()
          .add(29, 'days')
          .endOf('day')
      ],
      'This Month': [
        moment()
          .startOf('month')
          .startOf('day'),
        moment()
          .endOf('month')
          .endOf('day')
      ],
      'Next Month': [
        moment()
          .add(1, 'month')
          .startOf('month')
          .startOf('day'),
        moment()
          .add(1, 'month')
          .endOf('month')
          .endOf('day')
      ],
      'Never End': [null, null]
    }
    const template =
      this.props.selecteditem.attributes &&
      this.props.selecteditem.attributes.rpTmplId
        ? this.props.templates.find(
            temp =>
              temp.rpTmpl.id === this.props.selecteditem.attributes.rpTmplId
          )
        : null
    // fetch group list
    if (this.props.showItemDetail) {
      this.setState({
        selectedJob: { ...this.props.selecteditem },
        jobId: this.props.selecteditem.id
      })
    } else {
      if (
        this.props.activeOperation === 'edit' &&
        !this.state.editOption &&
        template
      ) {
        const allowFields =
          template &&
          template.rpTmpl &&
          template.rpTmpl.attributes &&
          template.rpTmpl.attributes.allowFields
            ? template.rpTmpl.attributes.allowFields
            : []



        let currentSteps = this.state.steps.filter(step => step.id < 3)

        if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
          // add notification step
          currentSteps.push({id: 3, title: "selectEventOrNotification"})
        }
        else if(allowFields.some(r=> ["geofence"].includes(r))) {
          // add notification step
          currentSteps.push({id: 4, title: "selectGeofence"})
        }
        else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
          // add notification step
          currentSteps.push({id: 5, title: "selectSensor"})
        }
        
        currentSteps.push({id: 6, title: "executionSchedule"})
        
 
        this.setState({ steps: currentSteps })
        

        const ids = Object.keys(rangesFuture)
        let selectedJobDate = 'custom'
        const format = 'YYYY-MM-DD HH:mm'
        ids.map(id => {
          if (
            rangesFuture &&
            rangesFuture[id] &&
            rangesFuture[id].length === 2
          ) {
            if (
              this.props.selecteditem &&
              this.props.selecteditem.start === null &&
              this.props.selecteditem.end === null
            ) {
              selectedJobDate = 'Never End'
            } else if (
              rangesFuture[id][0] &&
              rangesFuture[id][1] &&
              rangesFuture[id][0].format(format) ===
                moment(this.props.selecteditem.start).format(format) &&
              rangesFuture[id][1].format(format) ===
                moment(this.props.selecteditem.end).format(format)
            ) {
              selectedJobDate = id
            }
          }
          return null
        })

        const {reportDuration, mailTo, emails, controlTimeStart, controlTimeEnd, sensors, notificationIds, deviceIds, driverIds, types, itemGroupIds: groupIds = [], vehicleIds, geofenceIds, sensorValues, rpTmplId} = this.props.selecteditem.attributes
        this.checkReportData(reportDuration)
        this.setState(
          {
            jobId: template && this.props.selecteditem.id,
            selectedJob: { ...this.props.selecteditem },
            editOption: true,
            addOption: false,
            dm_isVisable: true,
            isVisableUserBtn: false,
            form: {
              ...this.props.selecteditem,
              mailTo: mailTo || 'user',
              days: this.props.selecteditem.days && this.props.selecteditem.days.split(','),
              email: emails
                ? this.spiltItem(emails)
                : [{ id: 1, text: '' }]
            },
            reportUnits:
              deviceIds &&
              deviceIds.length
                ? 'device'
                : driverIds &&
                  driverIds.length
                ? 'driver'
                : groupIds &&
                  groupIds.length
                ? 'device'
                : vehicleIds &&
                  vehicleIds.length
                ? 'vehicle'
                : 'device',
            selectedJobDate,
            deviceIds: deviceIds || [],
            groupIds: groupIds || [],
            groupView: groupIds.length ? 2 : 1,
            vehicleIds: vehicleIds || [],
            driverIds: driverIds || [],
            geofenceIds: geofenceIds || [],
            sensorIds: sensors || [],
            sensorValues: sensorValues || [],
            types: types || [],
            notificationIds: notificationIds || [],
            allSensors: sensorValues && sensorValues.length === 1 && sensorValues[0] && sensorValues[0].value === 'allSensors',
            reportType: rpTmplId || '',
            allowFields,
            eventType: allowFields.includes(this.state.eventType)
              ? this.state.eventType
              : allowFields.includes('notification')
              ? 'notification'
              : allowFields.includes('eventType')
              ? 'eventType'
              : '',
            controlTime: [
              moment
                .duration(
                  controlTimeStart || '00:00'
                )
                .asMinutes(),
              moment
                .duration(
                  controlTimeEnd || '23:59'
                )
                .asMinutes()
            ]
          },
          () => {
            this.checkReportRequiredFields()
          }
        )
      }
      if (
        this.props.activeOperation === 'delete' &&
        this.state.removedIdPropcess !== this.props.selecteditem.id
      ) {
        this.setState({
          removedIdPropcess: this.props.selecteditem.id
        })
        this.onDeleteJob(this.props.selecteditem)
      }
      if (this.props.activeOperation === 'add' && !this.state.addOption) {
        this.setState(
          {
            form: { ...formDefault },
            ...defaultSteps,
            addOption: true,
            jobId: 0,
            editOption: false,
            dm_isVisable: true,
          },
          () => this.checkReportRequiredFields()
        )
      }
    }
    this.setState({
      selectedJobDate:this.props.selecteditem?.attributes?.selectedJobDate || ""
    })
  }

  componentWillReceiveProps (nextprops) {
    const rangesFuture = {
      Today: [moment().startOf('day'), moment().endOf('day')],
      'Next 7 Days': [
        moment().startOf('day'),
        moment()
          .add(6, 'days')
          .endOf('day')
      ],
      'Next 30 Days': [
        moment().startOf('day'),
        moment()
          .add(29, 'days')
          .endOf('day')
      ],
      'This Month': [
        moment()
          .startOf('month')
          .startOf('day'),
        moment()
          .endOf('month')
          .endOf('day')
      ],
      'Next Month': [
        moment()
          .add(1, 'month')
          .startOf('month')
          .startOf('day'),
        moment()
          .add(1, 'month')
          .endOf('month')
          .endOf('day')
      ],
      'Never End': [null, null]
    }

    const template = nextprops.templates.find(
      temp =>
        nextprops.selecteditem.attributes &&
        temp.rpTmpl.id === nextprops.selecteditem.attributes.rpTmplId
    )
    if (nextprops.selecteditem.id !== this.state.jobId ) { 
      if (nextprops.showItemDetail) {
        this.setState({
          selectedJob: { ...nextprops.selecteditem },
          jobId: nextprops.selecteditem.id
        })
      } else {
        if (nextprops.activeOperation === 'edit' && !this.state.editOption && template) {
          const ids = Object.keys(rangesFuture)
          const allowFields =
            template &&
            template.rpTmpl &&
            template.rpTmpl.attributes &&
            template.rpTmpl.attributes.allowFields
              ? template.rpTmpl.attributes.allowFields
              : []

              console.log(allowFields);

              let currentSteps = this.state.steps.filter(step => step.id < 3)

              if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
                // add notification step
                currentSteps.push({id: 3, title: "selectEventOrNotification"})
              }
              else if(allowFields.some(r=> ["geofence"].includes(r))) {
                // add notification step
                currentSteps.push({id: 4, title: "selectGeofence"})
              }
              else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
                // add notification step
                currentSteps.push({id: 5, title: "selectSensor"})
              }
              
              currentSteps.push({id: 6, title: "executionSchedule"})
              
      
              this.setState({ steps: currentSteps })

          let selectedJobDate = 'custom'
          const format = 'YYYY-MM-DD HH:mm'
          ids.map(id => {
            if (
              rangesFuture &&
              rangesFuture[id] &&
              rangesFuture[id].length === 2
            ) {
              if (
                nextprops.selecteditem.start === null &&
                nextprops.selecteditem.end === null
              ) {
                selectedJobDate = 'Never End'
              } else if (
                rangesFuture[id][0] &&
                rangesFuture[id][1] &&
                rangesFuture[id][0].format(format) ===
                  moment(nextprops.selecteditem.start).format(format) &&
                rangesFuture[id][1].format(format) ===
                  moment(nextprops.selecteditem.end).format(format)
              ) {
                selectedJobDate = id
              }
            }
            return null
          })

          const {reportDuration, mailTo, emails, controlTimeStart, controlTimeEnd, sensors, notificationIds, deviceIds, driverIds, types, itemGroupIds: groupIds = [], vehicleIds, geofenceIds, sensorValues, rpTmplId} = nextprops.selecteditem.attributes

          this.checkReportData(reportDuration)
          this.setState(
            {
              jobId: nextprops.selecteditem.id,
              selectedJob: { ...nextprops.selecteditem },
              editOption: true,
              addOption: false,
              dm_isVisable: true,
              isVisableUserBtn: false,

              reportUnits:
                deviceIds &&
                deviceIds.length
                  ? 'device'
                  : driverIds &&
                    driverIds.length
                  ? 'driver'
                  : groupIds &&
                    groupIds.length
                  ? 'device'
                  : vehicleIds &&
                    vehicleIds.length
                  ? 'vehicle'
                  : 'device',
              form: {
                ...nextprops.selecteditem,
                mailTo: mailTo || 'user',
                days: this.props.selecteditem.days && this.props.selecteditem.days.split(','),
                email: emails
                  ? this.spiltItem(emails)
                  : [{ id: 1, text: '' }]
              },
              selectedJobDate,
              deviceIds: deviceIds || [],
              groupIds: groupIds || [],
              groupView: groupIds.length ? 2 : 1,
              vehicleIds: vehicleIds || [],
              driverIds: driverIds || [],
              geofenceIds: geofenceIds || [],
              sensorIds: sensors || [],
              sensorValues: sensorValues || [],
              allSensors: sensorValues && sensorValues.length === 1 && sensorValues[0] && sensorValues[0].value === 'allSensors',
              reportType: rpTmplId || '',
              types: types || [],
              notificationIds: notificationIds || [],
              allowFields,
              eventType: allowFields.includes(this.state.eventType)
                ? this.state.eventType
                : allowFields.includes('notification')
                ? 'notification'
                : allowFields.includes('eventType')
                ? 'eventType'
                : '',
              controlTime: [
                moment
                  .duration(
                   controlTimeStart ||
                      '00:00'
                  )
                  .asMinutes(),
                moment
                  .duration(
                    controlTimeEnd || '23:59'
                  )
                  .asMinutes()
              ]
            },
            () => this.checkReportRequiredFields()
          )
        }
        if (nextprops.activeOperation === 'add' && !this.state.addOption) {
          this.setState(
            {
              form: { ...formDefault },
            ...defaultSteps,
            addOption: true,
            jobId: 0,
            editOption: false,
            dm_isVisable: true,
            },
            () => this.checkReportRequiredFields()
          )
        }
      }
    }
    if (
      nextprops.activeOperation === 'delete' &&
      this.state.removedIdPropcess !== nextprops.selecteditem.id
    ) {
      this.setState({
        removedIdPropcess: nextprops.selecteditem.id
      })
      this.onDeleteJob(nextprops.selecteditem)
    }
  }

  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false,
      enableImagePopup: false
    })
  }

  handleSelectedJobDate = selectedJobDate => {
    this.setState({ selectedJobDate })
  }

  checkReportData = data => {
    if (data) {
      if (
        data === 1 ||
        data === 7 ||
        data === 14 ||
        data === 30 ||
        data === 60 ||
        data === 90
      ) {
        this.setState({
          selectedDate: data.toString(),
          reportDuration: ''
        })
      } else {
        this.setState({
          selectedDate: 'custom',
          reportDuration: data.toString()
        })
      }
    }
  }

  ad_submit () {
    let isTrue = true;
    if(this.state.isSensorTracing){
      if( (this.state.selectedDate === 'custom'? (this.state.reportDuration === 1? true : false) : this.state.selectedDate <=1)){
       isTrue = true
      }else{
        isTrue = false
      }
    }
    if(isTrue){
    let attributes = this.createReport()
    let {
      form,
      selectedJobDate,
      controlTime
    } = this.state
    let { mailTo, email } = form
    if (mailTo !== 'user') {
      if (email[0].text) {
        let emailsarray = []
        email.map(em => {
          if (em.text) {
            emailsarray.push(em.text)
          }
          return null
        })
        let emails = emailsarray.join(',')
        attributes = {
          ...attributes,
          mailTo,
          emails,
          selectedJobDate,
          controlDays: form.attributes.controlDays,
          controlTimeStart: moment()
            .startOf('day')
            .add(controlTime[0], 'minutes')
            .format('HH:mm'),
          controlTimeEnd: moment()
            .startOf('day')
            .add(controlTime[1], 'minutes')
            .format('HH:mm')
        }
      }
    } else {
      attributes = {
        ...attributes,
        mailTo,
        selectedJobDate,
        controlDays: form.attributes.controlDays,
        controlTimeStart: moment()
          .startOf('day')
          .add(controlTime[0], 'minutes')
          .format('HH:mm'),
        controlTimeEnd: moment()
          .startOf('day')
          .add(controlTime[1], 'minutes')
          .format('HH:mm')
      }
    }
     const obj = jobDataFormat(form, attributes)

    instance({
      url: `/api/jobs`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
      .then(job => {
              this.props.dispatch(addJob(job))
              this.props.onCloseModal()
              this.setState({
                dm_isVisable: !this.state.dm_isVisable,
                addOption: false,
                selectedJob: '',
                form: formDefault,
                imagesData: ''
              })
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('scheduleCreated'),
                  autoDismiss: 10
                })
              )
            // } else {
            //   this.props.dispatch(
            //     Notifications.error({
            //       message: job.message,
            //       autoDismiss: 10
            //     })
            //   )
            // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)
        // if (e && e.text) {
        //   e.text().then(err => {
        //     if (err.includes('Duplicate entry')) {
        //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
        //           autoDismiss: 10
        //         })
        //       )
        //     } else {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 10
        //         })
        //       )
        //     }
        //   })
        // }
      })
    }else{
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('sensorTracingLimitError'),
          autoDismiss: 10
        })
      )
      this.setState({
        activeStep: 0
      },()=>{this.validatedCurrentStep(1, '', -1)})
    }
  }

  ud_submit () {
    let isTrue = true;
    if(this.state.isSensorTracing){
      if( (this.state.selectedDate === 'custom'? (this.state.reportDuration === 1? true : false) : this.state.selectedDate <=1)){
       isTrue = true
      }else{
        isTrue = false
      }
    }
    if(isTrue){
    let attributes = this.createReport()
    let {
      form,
      selectedJobDate,
      controlTime
    } = this.state

    let { mailTo, email } = form

    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    if (mailTo !== 'user') {
      if (email[0].text) {
        let emailsarray = []
        email.map(em => {
          if (em.text) {
            emailsarray.push(em.text)
          }
          return null
        })
        let emails = emailsarray.join(',')
        attributes = {
          ...attributes,
          mailTo,
          emails,
          selectedJobDate,
          controlDays: form.attributes.controlDays,
          controlTimeStart: moment()
            .startOf('day')
            .add(controlTime[0], 'minutes')
            .format('HH:mm'),
          controlTimeEnd: moment()
            .startOf('day')
            .add(controlTime[1], 'minutes')
            .format('HH:mm')
        }
      }
    } else {
      attributes = {
        ...attributes,
        mailTo,
        selectedJobDate,
        controlDays: form.attributes.controlDays,
        controlTimeStart: moment()
          .startOf('day')
          .add(controlTime[0], 'minutes')
          .format('HH:mm'),
        controlTimeEnd: moment()
          .startOf('day')
          .add(controlTime[1], 'minutes')
          .format('HH:mm')
      }
    }
    const obj = jobDataFormat(form, attributes)
    // fetch(`/api/jobs/${this.state.jobId}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/jobs/${this.state.jobId}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data :{
        ...obj
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(job => {
            // if (job.status === 'success') {
              this.props.dispatch(updateJob(job))
              this.props.onCloseModal()
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('scheduleUpdated'),
                  autoDismiss: 10
                })
              )
              this.setState({
                dm_isVisable: !this.state.dm_isVisable,
                selectedJob: job,
                attributeChangesMessage: '',
                isVisableUserBtn: false,
                imagesData: ''
              })
            // } else {
            //   this.props.dispatch(
            //     Notifications.error({
            //       message: job.message,
            //       autoDismiss: 10
            //     })
            //   )
            // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)
        // if (e && e.text) {
        //   e.text().then(err => {
        //     if (err.includes('Duplicate entry')) {
        //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
        //           autoDismiss: 10
        //         })
        //       )
        //     } else {
        //       this.props.dispatch(
        //         Notifications.error({
        //           message: this.props.translate('somthingWentWrongMessage'),
        //           autoDismiss: 10
        //         })
        //       )
        //     }
        //   })
        // }
      })
    }else{
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('sensorTracingLimitError'),
          autoDismiss: 10
        })
      )
      this.setState({
        activeStep: 0
      },()=>{this.validatedCurrentStep(1, '', -1)})
    }
  }

  onCloseSubModal () {
    this.setState({
      dm_isVisable: false,
      addOption: false,
      editOption: false
    })
    this.props.onCloseModal()
  }

  onDeleteJob (item) {
    // fetch(`/api/jobs/${item.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/jobs/${item.id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // if (response.status === 200) {
          this.props.dispatch(removeJob(item))
          this.props.onCloseModal()
          this.setState({
            selectedJob: ''
          })
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('scheduleDeleted'),
              autoDismiss: 10
            })
          )
          // this.props.history.push('/schedules')
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }

  isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  handleChangePeriod = value => {
    let days = '1'
    if (value === 'daily') days = ['1', '2', '3', '4', '5', '6', '7']
    this.setState(
      {
        form: {
          ...this.state.form,
          period: value,
          days
        }
      },
      () => this.checkRequiredFields()
    )
  }

  handleChange = name => event => {
     if (name === 'start' || name === 'end' || name === 'triggerTime') {
      let timezone = 'Asia/Dubai'
      if (
        this.props.ServerSetting &&
        this.props.ServerSetting.attributes &&
        this.props.ServerSetting.attributes.timezone
      ) {
        timezone = this.props.ServerSetting.attributes.timezone
      }
      if (
        this.props.logInUser &&
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
      ) {
        timezone = this.props.logInUser.attributes.timezone
      }
      moment.tz.setDefault(timezone)

      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: moment(
              moment().format('YYYY-MM-DD') + ' ' + event
            ).toISOString()
          }
        },
        () => this.checkRequiredFields()
      )
    } else if (name === 'geoTimeThreshold') {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.value
            }
          }
        }
      )
    } else if(name === 'attachmentType'){
           this.setState({
            form: {
              ...this.state.form,
              attributes:{
                ...this.state.form.attributes,
                attachmentType:event.target.value
              }
            }
          })
     }  else {
      const { target } = event
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: name === 'status' ? target.checked : target.value
          }
        },
        () =>
          name === 'description'
            ? this.checkReportRequiredFields()
            : this.checkRequiredFields()
      )
    }
  }

  handleChangeForEmails = (name, event, id) => {
    if (
      name === 'number' ||
      name === 'web' ||
      name === 'email' ||
      name === 'mailTo'
    ) {
      if (name === 'email') {
        let { email } = this.state.form
        email.map(item => {
          if (item.id === id) {
            item.text = event.target.value
          }
          return null
        })
        this.setState(
          {
            form: {
              ...this.state.form,
              email: email
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      } else if (name === 'mailTo') {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target.value,
              email: [{ id: 1, text: '' }]
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      } else if (name === 'number') {
        let { number } = this.state.form
        number.map(item => {
          if (item.id === id) {
            item.text = event.target.value
          }
          return null
        })
        this.setState(
          {
            form: {
              ...this.state.form,
              number: number
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      } else {
        let { notificators } = this.state.form
        if (event.target.checked) {
          if (notificators.length) {
            notificators = notificators + ',' + name
          } else {
            notificators = name
          }
        } else {
          notificators = notificators.replace(name, '')
          notificators = notificators.replace(',', '')
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              notificators: notificators
            }
          },
          () => {
            this.checkRequiredFields()
          }
        )
      }
    } else {
      if (
        event.target.value === '' ||
        event.target.value === null ||
        event.target.value === undefined
      ) {
        delete this.state.form[name]
        this.setState(
          {
            form: {
              ...this.state.form
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target.value
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }

  onAddAnotherMail = type => {
    let { email, number } = this.state.form
    // frist check the formate of prevous emails
    let formateValidation = true
    if (type === 'email') {
      email.map(itam => {
        if (!validEmailPattern.test(itam.text)) {
          formateValidation = false
        }
        return null
      })
      if (formateValidation) {
        email.push({ id: email.length + 1, text: '' })
        this.setState({
          form: {
            ...this.state.form,
            email: email
          }
        })
      } else {
        this.props.enqueueSnackbar(
          this.props.translate('previousEmailInvalid'),
          { autoHideDuration: 1000 }
        )
      }
    } else if (type === 'number') {
      number.map(itam => {
        if (!validNumberPattern.test(itam.text)) {
          formateValidation = false
        }
        return null
      })
      if (formateValidation) {
        number.push({ id: number.length + 1, text: '' })
        this.setState({
          form: {
            ...this.state.form,
            number: number
          }
        })
      } else {
        this.props.enqueueSnackbar(
          this.props.translate('previousNumberInvalid'),
          { autoHideDuration: 1000 }
        )
      }
    }
  }

  deleteMail = (type, id) => {
    // frist check the formate of prevous emails
    let data = this.state.form[type]
    if (data.length === 1 && id === 1) {
      data[0].text = ''
      this.setState({
        form: {
          ...this.state.form,
          [type]: data
        }
      })
    } else {
      let newList = data.filter(itm => itm.id !== id)
      newList.map((item, ind) => {
        item.id = ind + 1
        return null
      })
      this.setState({
        form: {
          ...this.state.form,
          [type]: newList
        }
      })
    }
  }

  spiltItem = item => {
    let result = []
    let res = item.split(',')
    if (res.length) {
      res.map((subitem, ind) => {
        if (subitem) {
          result.push({ id: ind + 1, text: subitem })
        }
        return null
      })
    }
    return result
  }

  showJobDates (from, to) {
    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)
    this.setState(
      {
        form: {
          ...this.state.form,
          start: from
            ? moment(from.format('YYYY-MM-DD HH:mm')).toISOString()
            : from,
          end: to ? moment(to.format('YYYY-MM-DD HH:mm')).toISOString() : to
        }
      },
      () => {
        this.checkRequiredFields()
      }
    )
  }

  onDayChange = (obj) => {
    let dayArry = []
    if ((this.state.form.days || []).includes(obj.id)) {
      dayArry = (this.state.form.days || []).filter(day => parseInt(day) !== parseInt(obj.id))
    } else {
      dayArry = (this.state.form.days || []).concat(obj.id)
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          days: dayArry
        }
      },
      () => this.checkRequiredFields()
    )
  }

  onDayChange3 = (checked, obj) => {
    let dayArry = []
    if (checked) {
      dayArry = (this.state.form.attributes.controlDays || []).concat(
        parseInt(obj.id)
      )
    } else {
      dayArry = (this.state.form.attributes.controlDays || []).filter(
        day => day !== parseInt(obj.id)
      )
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          attributes: { ...this.state.form.attributes, controlDays: dayArry }
        }
      },
      () => this.checkRequiredFields()
    )
  }

  onDayChange2 = (name, value) => {
    if (name === 'reportDuration') {
      let val = parseInt(value) || ''
      if (val && val < 1) {
        val = 1
      }
      if (val && val > 90) {
        val = 90
      }
      this.setState(
        {
          reportDuration: val
        },
        () => this.checkReportRequiredFields()
      )
    } else {
      let val = parseInt(value) || ''
      if (val && val < 1) {
        val = 1
      }
      if (val && val > 31) {
        val = 31
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            days: val
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  checkRequiredFields () {
    let {
      reportDuration,
      reportType,
      deviceIds,
      groupIds,
      vehicleIds,
      driverIds,
      selectedDate
    } = this.state
    let { description, type, triggerTime, period, days } = this.state.form
    let value = true
    let optionalPeriod = false
    if (period && period !== 'once') {
      if (days) {
        optionalPeriod = true
      } else {
        optionalPeriod = false
      }
    }
    if (period && period === 'once') {
      optionalPeriod = true
    }
    if (this.state.editOption) {
      value = !isEqual(this.state.form, this.state.selectedJob)
    }
    if (
      (this.state.editOption || this.state.validatedForm) &&
      description &&
      type &&
      triggerTime &&
      period &&
      optionalPeriod &&
      value
    ) {
      this.setState({
        isVisableUserBtn: true
      })
    } else {
      this.setState({
        isVisableUserBtn: false
      })
    }
    if (this.state.editOption && this.state.selectedJob) {
      if (
        reportType &&
        ((deviceIds && deviceIds.length) ||
          (groupIds && groupIds.length) ||
          (vehicleIds && vehicleIds.length) ||
          (driverIds && driverIds.length))
      ) {
        let newValue =
          selectedDate !== '' && selectedDate !== 'custom'
            ? selectedDate
            : reportDuration
        if (selectedDate === 'custom' && reportDuration === '') {
          this.setState({
            isVisableUserBtn: false
          })
        } else if (
          parseInt(newValue) !==
            this.state.selectedJob.attributes.reportDuration ||
          reportType !== this.state.selectedJob.attributes.rpTmplId ||
          deviceIds !== this.state.selectedJob.attributes.deviceIds ||
          groupIds !== this.state.selectedJob.attributes.groupIds ||
          vehicleIds !== this.state.selectedJob.attributes.vehicleIds ||
          driverIds !== this.state.selectedJob.attributes.driverIds ||
          value
        ) {
          this.setState({
            isVisableUserBtn: true
          })
        } else {
          this.setState({
            isVisableUserBtn: false
          })
        }
      } else {
        this.setState({
          isVisableUserBtn: false
        })
      }
      const rpId = this.state.selectedJob.attributes.rpTmplId;
      let tpl = this.props.templates.find(
        temp => temp.rpTmpl.id === rpId
      )
      tpl.rpTmplTblWrappers.map(i => {
        if(i.rpTmplTbl.rpId === 8){
          this.setState({
            isSensorTracing: true
          })
         }else{
          this.setState({
            isSensorTracing: false
          })
      }
      return null
    })

    }
  }

  selectedReportDateType = (name, event) => {
    const { target } = event
    this.setState(
      {
        [name]: target.value
      },
      () => this.checkReportRequiredFields()
    )
  }

  checkReportRequiredFields = () => {
    if (
      ((this.state.groupIds && this.state.groupIds.length) ||
        (this.state.vehicleIds && this.state.vehicleIds.length) ||
        (this.state.deviceIds && this.state.deviceIds.length) ||
        (this.state.driverIds && this.state.driverIds.length)) &&
      this.state.reportType &&
      this.state.form.description &&
      this.state.selectedDate
    ) {
      if (this.state.selectedDate === 'custom') {
        if (this.state.reportDuration) {
          this.setState({ validatedForm: true }, () =>
            this.checkRequiredFields()
          )
        } else {
          this.setState({ validatedForm: false }, () =>
            this.checkRequiredFields()
          )
        }
      } else {
        this.setState({ validatedForm: true }, () => this.checkRequiredFields())
      }
    } else {
      this.setState({ validatedForm: false }, () => this.checkRequiredFields())
    }
  }

  selectedDevices = deviceIds => {
    this.setState({ deviceIds, driverIds: [], groupIds: [], vehicleIds: [] }, () =>
      this.checkReportRequiredFields()
    )
  }

  selectedGroups = groupIds => {
    this.setState({ groupIds, driverIds: [], deviceIds: [], vehicleIds: [] }, () =>
      this.checkReportRequiredFields()
    )
  }

  selectedDrivers = driverIds => {
    this.setState({ driverIds, deviceIds: [], groupIds: [], vehicleIds: [] }, () =>
      this.checkReportRequiredFields()
    )
  }

  selectedVehicles = vehicleIds => {
    this.setState({ vehicleIds, driverIds: [], deviceIds: [], groupIds: [] }, () =>
      this.checkReportRequiredFields()
    )
  }

  
  reportTypeChange = (event) => {
    let tpl = this.props.templates.find(
      temp => temp.rpTmpl.id === event.target.value
    )
    let allowFields = []
    if (
      tpl.rpTmpl &&
      tpl.rpTmpl.attributes &&
      tpl.rpTmpl.attributes.allowFields
    ) {
      allowFields = tpl.rpTmpl.attributes.allowFields
    }
    this.setState(
      {
        reportType: event.target.value,
        reportUnits: allowFields[0],
        allowFields,
        eventType: allowFields.includes(this.state.eventType)
          ? this.state.eventType
          : allowFields.includes('notification')
          ? 'notification'
          : allowFields.includes('eventType')
          ? 'eventType'
          : ''
      },
      () => {

        let currentSteps = this.state.steps.filter(step => step.id < 3)

        if(allowFields.some(r=> ["eventType", "notification"].includes(r))) {
          // add notification step
          currentSteps.push({id: 3, title: "selectEventOrNotification"})
        }
        else if(allowFields.some(r=> ["geofence"].includes(r))) {
          // add notification step
          currentSteps.push({id: 4, title: "selectGeofence"})
        }
        else if(allowFields.some(r=> ["sensor", "digitalSensor"].includes(r))) {
          // add notification step
          currentSteps.push({id: 5, title: "selectSensor"})
        }

        currentSteps.push({id: 6, title: "executionSchedule"})
        


        this.reportTypeSelection(this.state.reportUnits)
        if (
          (this.state.groupIds.length ||
            this.state.vehicleIds.length ||
            this.state.deviceIds.length ||
            this.state.driverIds.length) &&
          this.state.reportType &&
          this.state.selectedDate
        ) {
          this.setState({ validatedForm: true, steps: currentSteps })
        } else {
          this.setState({ validatedForm: false, steps: currentSteps })
        }
      }
    )
    tpl.rpTmplTblWrappers.map(i => {
        if(i.rpTmplTbl.rpId === 8){
          this.setState({
            isSensorTracing: true
          })
         }else{
          this.setState({
            isSensorTracing: false
          })
      }
      return null
    })
  }
 
  calcTime = (date, offset) => {
    let d = new Date(date)

    let utc = d.getTime() + d.getTimezoneOffset() * 60000

    let nd = new Date(utc + 3600000 * offset)

    return nd.toISOString()
  }

  convertIdsToint = data => {
    let result = []
    if (data.length) {
      data.map(item => {
        result.push(parseInt(item))
        return null
      })
    }
    return result
  }

  createReport () {

    let form = {
      rpTmplId: this.state.reportType,
      reportDuration:
        this.state.selectedDate === 'custom'
          ? parseInt(this.state.reportDuration)
          : parseInt(this.state.selectedDate),
      deviceIds: this.convertIdsToint(this.state.deviceIds),
      itemGroupIds: this.convertIdsToint(this.state.groupIds),
      vehicleIds: this.convertIdsToint(this.state.vehicleIds),
      driverIds: this.convertIdsToint(this.state.driverIds),
      geofenceIds: this.convertIdsToint(this.state.geofenceIds),
      itemGroupType: this.state.groupView === 2 ? itemGroupTypes[this.state.reportUnits] : '',
      notificationIds: this.state.notificationIds,
      sensors: this.state.sensorIds,
      sensorValues: this.state.sensorValues,
      types: this.state.types,
      geoTimeThreshold: (this.state.form.attributes.geoTimeThreshold || 0) * 60000,
      attachmentType:this.state.form?.attributes?.attachmentType || "excel"
    }
    return form
  }

  handleTimeControl = (event, controlTime) => {
    this.setState({ controlTime })
  }

  reportTypeSelection (reportUnits) {
    this.setState({
      reportUnits
    })
  }

  goToNextStep = (step, type, index) => {
    this.validatedCurrentStep(step, type, index);
  }
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
      this.setState({activeStep: currentStep-1}) 
  }

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      // check datetime
      if(this.state.form && this.state.form.description && this.state.selectedDate) {
        validated = true
        errorMsg= null
      }
      else if(this.state.form && !this.state.form.description) {
        validated = false
        errorMsg= <span className="text-warning">Please must enter 'Report Name'</span>
      }
      else if(!this.state.selectedDate) {
        validated = false
        errorMsg= <span className="text-warning">Please select 'Report Interval' from above</span>
      }
      
    }
    else if(step === 2) {
      
      // check template and unit, group or staff selected
      if(this.state.reportType) {
        if(this.state.allowFields.some(r=> ["device", "driver", "group", "vehicle"].includes(r)) && !this.state.groupIds.length && !this.state.vehicleIds.length && !this.state.deviceIds.length && !this.state.driverIds.length) {
          validated = false
          errorMsg= <span className="text-warning">
            {this.props.translate("Please must select anything from the above list section")}</span>
        }
        else {
          validated = true
          errorMsg= null
          
        }        
        
      }
      else {
        validated = false
        errorMsg= <span className="text-warning">Please select a 'template' from above</span>
       
      }
    }
    else if(step === 3) {
      // optional step
      // allow fields containing events, types make sure select any of.
      validated = true
      errorMsg= null
    }
    else if(step === 4) {
      // allow fields containing geofence, types make sure select any of. 
      validated = false
      errorMsg= <span className="text-warning">Please select a 'geofence' in above list</span>
      if(this.state.geofenceIds.length) {
        validated = true;
        errorMsg = null;
      }
    }
    else if(step === 5) {
      // allow fields containing sensor, types make sure select any of. 
      validated = false
      errorMsg= <span className="text-warning">Please enter a 'sensor' in above form</span>
      if(this.state.sensorIds.length) {
        validated = true;
        errorMsg = null;
      }
    }

    else if (step === 6) {
      validated = false
      errorMsg = <span className="text-warning">Please enter a 'sensor' in above form</span>
      if(!this.state.form.triggerTime) {
        errorMsg = <span className="text-warning">Please select 'triggerTime' in above form</span>
      }
      else {
        validated = true
        errorMsg = null
        if(this.state.editOption) {
          this.ud_submit(type)
        }
        else {
          this.ad_submit(type)
        }
      }
    }

  
    if(validated === true) {
      this.setState({activeStep: index + 1})
    }

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      return null
    })

    this.setState({steps}) 

  }

  selectedSensors = sensorIds => {
    if (sensorIds && sensorIds.length) {
      if(sensorIds.length === 1 && sensorIds[0] && sensorIds[0].value === 'allSensors') {
        this.setState({allSensors: true})
      } else {
        this.setState({allSensors: false})
      }
      this.setState({ sensorIds: sensorIds.map(e => e.value), sensorValues: sensorIds })
    } else {
      this.setState({ sensorIds: [] })
    }
    
  }

  selectedGeofences = geofenceIds => {
    if (geofenceIds && geofenceIds.length) {
      this.setState({ geofenceIds })
    } else {
      this.setState({ geofenceIds: [] })
    }
  }
  switchGroupView = type => {
    this.setState({
      groupView: type,
      groupIds:[],
      vehicleIds: [],
      deviceIds: [],
      driverIds: []
    });
  }


  render () {
    return (
      <div style={{ height: '100%' }}>
        {this.state.dm_isVisable && this.state.addOption && (
          <AddJobs
            {...this.state}
            {...this.props}
            handleTimeControl={this.handleTimeControl}
            onClose={this.onCloseSubModal}
            formSubmit={this.ad_submit}
            handleSelectedJobDate={this.handleSelectedJobDate}
            handleChange={this.handleChange}
            handleChangePeriod={this.handleChangePeriod}
            onDayChange={this.onDayChange}
            onDayChange2={this.onDayChange2}
            onDayChange3={this.onDayChange3}
            switchGroupView={this.switchGroupView}
            buttonText='sharedCreate'
            translate={this.props.translate}
            isHaveAccess='jobCreate'
            selectedReportDateType={this.selectedReportDateType}
            showJobDates={this.showJobDates}
            selectedNotifications={this.selectedNotifications}
            selectedDevices={this.selectedDevices}
            selectedVehicles={this.selectedVehicles}
            selectedEvents={this.selectedEvents}
            selectedGroups={this.selectedGroups}
            selectedDrivers={this.selectedDrivers}
            selectedGeofences={this.selectedGeofences}
            selectedSensors={this.selectedSensors}
            reportTypeChange={this.reportTypeChange}
            createReport={this.createReport}
            reportTypeSelection={this.reportTypeSelection}
            handleChangeForEmails={this.handleChangeForEmails}
            onAddAnotherMail={this.onAddAnotherMail}
            handleChangeType={this.handleChangeType}
            deleteMail={this.deleteMail}
            goToNextStep={this.goToNextStep}
            goToBackStep={this.goToBackStep}
          />
        )}

        {this.state.selectedJob && this.state.editOption && (
          <AddJobs
            {...this.state}
            {...this.props}
            handleTimeControl={this.handleTimeControl}
            onClose={this.onCloseSubModal}
            formSubmit={this.ud_submit}
            handleSelectedJobDate={this.handleSelectedJobDate}
            handleChange={this.handleChange}
            handleChangePeriod={this.handleChangePeriod}
            onDayChange={this.onDayChange}
            onDayChange2={this.onDayChange2}
            onDayChange3={this.onDayChange3}
            switchGroupView={this.switchGroupView}
            buttonText='update'
            translate={this.props.translate}
            isHaveAccess='jobUpdate'
            selectedReportDateType={this.selectedReportDateType}
            showJobDates={this.showJobDates}
            selectedNotifications={this.selectedNotifications}
            selectedDevices={this.selectedDevices}
            selectedVehicles={this.selectedVehicles}
            selectedEvents={this.selectedEvents}
            selectedGroups={this.selectedGroups}
            selectedDrivers={this.selectedDrivers}
            selectedGeofences={this.selectedGeofences}
            selectedSensors={this.selectedSensors}
            reportTypeChange={this.reportTypeChange}
            createReport={this.createReport}
            reportTypeSelection={this.reportTypeSelection}
            handleChangeForEmails={this.handleChangeForEmails}
            onAddAnotherMail={this.onAddAnotherMail}
            handleChangeType={this.handleChangeType}
            deleteMail={this.deleteMail}
            goToNextStep={this.goToNextStep}
            goToBackStep={this.goToBackStep}
            
          />
        )}
      </div>
    )
  }
}

const mapState = state => ({
  jobs: state.jobs,
  themecolors: state.themeColors,
  templates: state.templates,
  logInUser: state.logInUsers
})
const mapStateToProps = connect(mapState)
export const JobsModal = mapStateToProps(withSnackbar(withLocalize(jobsModal)))
