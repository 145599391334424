import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import MarkerMap from '../../Components/Maps'
import CustomMarker from '../../Components/Maps/CustomMarker'
import { MapTooltip } from '../../Components/Maps/MapTooltip'
import Layout from '../../Layout'
import isEqual from 'react-fast-compare'
import Grid from '@material-ui/core/Grid'
import { DriversModal } from '../../Components/Drivers/driversModal'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import {
  Map,
  TileLayer,
  Tooltip,
  ZoomControl
} from 'react-leaflet'
import L from 'leaflet'
import { setTrackId } from '../../Actions/Devices'
import { removeDriver } from '../../Actions/Drivers'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
function importAll (r) {
  return r.keys().map(r)
}

const images = importAll(
  require.context('./../../images', false, /\.(png|jpe?g|svg)$/)
)

class Drivers extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      activeOperation: '',
      driverAddress: '',
      driverLat: '',
      driverLon: '',
      selecteditemId: '',
      trackersApiResponce: false,
      multiTrackers: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      animCount: 0,
      assigned: false,
      tracking: false,
      applied: false,
      allDriverLocation: {},
      resourceList: false,
      linkResource: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    }
    this.onCloseModal = this.onCloseModal.bind(this)
  }

  componentWillMount () {
    if (this.props.ServerSetting&&this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude
      })
    }
    if (this.props.logInUser&&this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude
      })
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.props.drivers.length &&
      this.state.initFetch === false
    ) {
      this.fetchData(this.props)
    }
  }

  componentWillUnmount () {
    this.setState({
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      activeOperation: '',
      driverAddress: '',
      driverLat: '',
      driverLon: '',
      selecteditemId: '',
      trackersApiResponce: false,
      multiTrackers: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      animCount: 0,
      allDriverLocation: {},
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }

  componentDidUpdate() {
    if(this.props.logInUser && this.props.logInUser.latitude && this.props.logInUser.longitude && this.state.assigned === false) {
      this.setState({assigned: true, lat: this.props.logInUser.latitude, lng: this.props.logInUser.longitude})
    }
  } 

  componentWillReceiveProps (n, s) {
    if (n.ServerSetting.zoom) {
      this.setState({
        zoom: n.ServerSetting.zoom,
        lat: n.ServerSetting.latitude,
        lng: n.ServerSetting.longitude
      })
    }
    if (n.logInUser.zoom) {
      this.setState({
        zoom: n.logInUser.zoom,
        lat: n.logInUser.latitude,
        lng: n.logInUser.longitude
      })
    }
    if (
      n.logInUser &&
      n.logInUser.id &&
      n.drivers.length &&
      isEqual(n.drivers,this.props.drivers) &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(n)
        }
      )
    }
    if(isEqual(n.drivers,this.props.drivers) && n.drivers.length && this.props.drivers.length)
    {
      this.fetchData(n)
    }
    if(this.props.trackId !== n.trackId) {
      this.setState({allDriverLocation: {}, animCount: 0}, () => {
       this.calculate(n);
      })
    }
    else {
      this.calculate(n);
    }

    if(this.state.showItemDetail && n.deviceRelatedData && Object.values(n.deviceRelatedData).length && n.match && n.match.params && n.match.params.id) {
      const driverLocation = Object.values(n.deviceRelatedData).find(({driverId, id}) => driverId && driverId === parseInt(n.match.params.id) && this.props.allComputedAttributes)// && this.props.allComputedAttributes.length && this.props.allComputedAttributes.includes(id))
      if(driverLocation && driverLocation.latitude && driverLocation.longitude) {
          this.setState({driverLocation: driverLocation || null}, () => {
            if(driverLocation && n.trackId > 0) {
              this.map &&
                this.map.setMaxZoom(16).fitBounds([[driverLocation.latitude, driverLocation.longitude]])
                this.map && this.map.setMaxZoom(n.mapLayer.maxZoom)
                  setTimeout(() => {
                    this.setState({applied: true, animCount: 1})
                  }, 200)
            }
          })
      }
    }

    
  }

  mapReference = el => {
    if (el) {
      this.map = el.leafletElement
    }
  }
  showResources = (type) => {
    this.setState({
      resourceList: type
    })
  }
  addResource = () =>{
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'addResource',
      selecteditem: '',
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    })
  }
  onEditResource = (item) =>{
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'editResource',
      selecteditem: item,
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    })
  }
  onLinkResource = (item) =>{
    this.setState({
      linkResource: true,
      selecteditem: item,
    },()=>{this.props.fetchNestedItems(item.id,1)})
  }
  onCloseResource = () =>{
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  }
  fetchMoreItems = () => {
    this.fetchData(this.props)
  }

  searchItems = text => {
    this.setState(
      {
        searchText: text
      },
      () => {
        this.fetchData(this.props)
      }
    )
  }

  fetchData = (nextProps) => {
    let items = nextProps.drivers.filter(row =>
      (row.id + '' + row.uniqueId + '' + row.name)
        .toLowerCase()
        .includes((this.state.searchText || '').toLowerCase())
    )

    this.setState({
      itemPagination: {
        total: items.length,
        items
      }
    })
  }

  removedItem = (item) => {
    // fetch(`/api/drivers/${item.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/drivers/${item.id}`,
      method: `DELETE`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // if (response.status === 204) {
          this.props.dispatch(removeDriver(item))
          // this.onCloseModal()
          this.fetchMoreItems()
          this.setState({
            isVisable: true,
            showItemDetail: false,
            activeOperation: '',
            selecteditem: '',
            onDeleteConfirmation: false,
            driverAddress: '',
            driverLat: '',
            driverLon: ''
          })
          //this.props.enqueueSnackbar(this.props.translate('driverIsDeleted'));
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('driverIsDeleted'),
              autoDismiss: 10
            })
          )
          this.props.history.push('/drivers')
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }

  editItem = item => {
    this.setState({
      // isVisable: true,
      isVisable: !this.state.isVisable,
      selecteditem: item,
      activeOperation: 'edit',
      driverAddress: '',
      driverLat: item.attributes.driverLat || '',
      driverLon: item.attributes.driverLon || '',
      showItemDetail: false }, () => {
      this.getMultiDevice(item.id)
    })
  }

  addItem = () => {
    this.props.history.push('/drivers')
    this.setState({
      isVisable: true,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: 'add',
      driverAddress: '',
      driverLat: '',
      driverLon: ''
    })
  }

  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false
    })
    this.props.history.push('/drivers')
  }

  selecteItem = item => {
    this.props.dispatch(setTrackId(0))
    this.setState({allDriverLocation: {},
      showItemDetail: true,
      isVisable: false,
      selecteditem: item,
      selecteditemId: item.id,
      activeOperation: 'details',
      trackersApiResponce: false,
      multiTrackers: '',
      driverLocation: null,
      animCount: 0,
      tracking: true,
      applied: false}, () => {
      this.getMultiDevice(item.id)
      this.calculate(this.props);
    })    
  }

  checkZoom = () => {
    if(this.state.applied === true) {
      console.log('disabled tracking')
      this.setState({ tracking: false, applied: false, animCount: 0 }, () => {
        this.props.dispatch(setTrackId(0))
      })
      this.setState({allDriverLocation: {}}, () => {
        this.calculate(this.props)
      })
    }
  }

  calculate = n => {
    if(n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      const list = {};
      const ids =  n.drivers.map(d => { list[d.id] = d;return d.id})
      const drivers = {};
     Object.values(n.deviceRelatedData).map(d => {
        if(ids.includes(d.driverId) && (this.props.allComputedAttributes && this.props.allComputedAttributes.length && this.props.allComputedAttributes.includes(d.id) || d.deviceAttributes.fixDriverId)) {
          drivers[d.driverId] = d; drivers[d.driverId].driver = list[d.driverId];
          drivers[d.driverId].attributes.fixDriverId = d.deviceAttributes.fixDriverId;
          if(d && d.attributes && d.attributes.trailerUniqueId) {
            drivers[d.driverId].trailer = n.trailers.find(t => {
              return parseInt(t.uniqueId) === parseInt(d.attributes.trailerUniqueId)
            })
          }
        }
        return null
      })

      this.setState({allDriverLocation: drivers})
    }
  } 

  getPostion = (address, latlng) => {
    if (address) {
      this.setState({
        driverAddress: address,
        driverLat: latlng.lat,
        driverLon: latlng.lng
      })
    }
  }

  getMultiDevice = id => {
    if (id) {
      if(this.map && this.props.deviceRelatedData && Object.values(this.props.deviceRelatedData).length) {
        const driverLocation = Object.values(this.props.deviceRelatedData).find(d => d && d.driverId === id && this.props.allComputedAttributes)
        this.setState({driverLocation: driverLocation || null, animCount: 0})
        if(driverLocation) {
          this.props.dispatch(setTrackId(driverLocation.id));
        }
        else {
          this.props.dispatch(setTrackId(0));
          this.map.setZoom(3);
        }
      }
      this.setState({
        multiTrackers: [],
        trackersApiResponce: true
      })
    }
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

  onRemovedItem = item => {
    this.setState({
      selecteditem: item,
      onDeleteConfirmation: true
    })
  }

  render () {
    let crs = {}
      if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
        crs = { crs: L.CRS.EPSG3395 }
      }

      const body = [
        <>
          <ZoomControl position={'bottomright'} />
        </>
      ]

    const position = [this.state.lat, this.state.lng]
    const thisMap = [
      <Map
        key={1}
        ref={this.mapReference}
        onZoomAnim={this.checkZoom}
        zoomControl={false}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        boundsOptions={this.setBoundOptions}
        style={{ height: this.props.height, width: this.props.width }}
        center={position}
        zoom={this.state.zoom}
        minZoom={this.state.minZoom}
        maxZoom={this.props.mapLayer.maxZoom}
        maxNativeZoom={this.props.mapLayer.maxZoom}
        {...crs}
      >
        {this.state.pointer}
        {body}

        {this.state.allDriverLocation && Object.keys(this.state.allDriverLocation).length ? 
        
        Object.values(this.state.allDriverLocation).map(row=> <CustomMarker
              key={row.id}
              position={{ lat: row.latitude, lng: row.longitude, updated: moment(row.serverTime) }}
              rotationAngle={0}
              rotationOrigin='center'
              animationTime={this.state.animCount > 0 && this.state.applied === true & this.props.trackId === row.id ? row.animationTime : 0}
              icon={L.divIcon({
                iconUrl:
                  '/assets/category/default/' +
                  (row.category || 'default') +
                  'top.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 25],
                tooltipAnchor: [0, -20],
                className: 'custom-marker',
                html: `<img
                  style="transform: rotate(${row.course}deg)"
                    src=
                      '/assets/category/default/${row.category ||
                        'default'}top.svg'
                    
                    alt=''
                  />`
              })}
              iconSize={[50, 50]}
            >
              <Tooltip direction={'top'}>
                <MapTooltip
                  themecolors={this.props.themecolors}
                  position={row}
                  device={row}
                  driver={row.driver}
                  trailer={row.trailer}
                  driver2={this.state.selecteditem}
                  logInUser={this.props.logInUser}
                  translate={this.props.translate}
                />
              </Tooltip>
            </CustomMarker>) : null}
        <TileLayer
          {...this.props.mapLayer}
          minZoom={this.state.minZoom}
        />
      </Map>
    ]
    

    return (
      <div>
        <Layout
          {...this.props}
          addDriver={this.addItem}
          editItem={this.editItem}
          removedItem={this.onRemovedItem}
          selecteItem={this.selecteItem}
          fetchMoreItems={this.fetchMoreItems}
          classFromChildren={!this.state.isVisable ? 'no-padding' : 'has-padding'}
          itemPagination={{ ...this.state.itemPagination }}
          searchItems={this.searchItems}
          allDriverLocation={this.state.allDriverLocation}
          showResources={this.showResources}
          onEditResource={this.onEditResource}
          onLinkResource={this.onLinkResource}
          addResource={this.addResource}
          resourceList={this.state.resourceList}
        >
          
          {!this.state.isVisable ? <div>
            {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
            {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['gccStreet'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['googleTerrain'].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
            {['googleSatellite'].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
            {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
            {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id) ? thisMap : null}
        </div> : null}

          {this.state.showItemDetail && (
            <DriversModal
            allDriverLocation={this.state.allDriverLocation}

              onCloseModal={this.onCloseModal}
              selecteditem={this.state.selecteditem}
              showItemDetail={this.state.showItemDetail}
              devicesIcons={images}
              trackersApiResponce={this.state.trackersApiResponce}
              multiTrackers={this.state.multiTrackers}
              currentLocation={this.state.driverLocation}
              devices={this.props.devices}
              resourceList={this.state.resourceList}
            />
          )}
          <ResourceModal
            changeResource={this.props.changeResource}
            selectedResourse={this.state.selecteditem}
            activeOperation={this.state.activeOperation}
            onCloseResource={this.onCloseResource}
            itemPagination={this.state.itemPagination&&this.state.itemPagination.items}
            assignItem={this.props.assignItem}
            unassignItem={this.props.unassignItem}
            fetchNestedItems={this.props.fetchNestedItems}
            nestedResources={this.props.nestedResources}
            translate={this.props.translate}
            linkResource={this.state.linkResource}
            themecolors={this.props.themecolors}
            itemType='Driver'
            title='sharedDriver'
          />
          {!this.state.showItemDetail ? <div className='main-content-page'>
          
            {this.state.isVisable && ['add', 'edit'].includes(this.state.activeOperation) && (
              <Grid
                container
                spacing={0}
                className='driver-page-content'
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor
                }}
              >
                <Grid item xs={12} md={7}>
                  <DriversModal
                    onCloseModal={this.onCloseModal}
                    activeOperation={this.state.activeOperation}
                    selecteditem={this.state.selecteditem}
                    selectedAddress={this.state.driverAddress}
                    driverLat={this.state.driverLat}
                    driverLon={this.state.driverLon}
                    trackersApiResponce={this.state.trackersApiResponce}
                    multiTrackers={this.state.multiTrackers}
                    resourceList={this.state.resourceList}
                    getMultiDevice={this.getMultiDevice}
                    fetchMoreItems={this.fetchMoreItems}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <MarkerMap
                    showMarker={true}
                    getPostion={this.getPostion}
                    zoom={0}
                    lat={this.state.driverLat || 0}
                    lng={this.state.driverLon || 0}
                  />
                </Grid>
              </Grid>
            )}
          </div> : null}
          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={() => this.removedItem(this.state.selecteditem)}
              title={this.props.translate('areYouWantToDelete')}
              children={this.state.selecteditem.name}
            />
          )}
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({item, deviceIds}) => {
    if(deviceIds && item.attribute === 'driverUniqueId') {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null
  })
  return {
  ServerSetting: state.ServerSetting,
  drivers: state.drivers,
  isDriverLoad: state.isDriverLoad,
  trailers: state.trailers,
  devices: state.devices,
  deviceRelatedData: state.deviceRelatedData,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  trackId: state.trackId,
  allComputedAttributes: driverIdsList,
  mapLayer: state.mapLayer
}}
export default connect(mapStateToProps)(withLocalize(withResources(Drivers, 'Driver')))