import React, { Component } from 'react'
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '../common/MenuItem'
import Checkbox from '../common/Checkbox'
import { errorHandler } from '../../Helpers';
import CustomDialog from './Dialog';
import Stepper from './Stepper'
import moment from 'moment';
import DateRangePicker from './DateRangePicker';
import TextField from './TextField';
import { Grid, FormControlLabel } from '@material-ui/core';
import Loader from '../../Layout/Loader';
import Button from './Button';
import VehicleSelector from '../Devices/DeviceSelector'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '../Menu/CommonMenu'
import CitySelector from './CitySelector';
import Scrollbar from 'react-scrollbars-custom'
import WorkFlowStatusFilter from './WorkFlowStatusFilter';
// import WorkFlow from './Components/WorkFlow'

const statusTypes = [
  {
    key: 'completed',
    name: 'Completed',
  },
  {
    key: 'inProgress',
    name: 'In Progress',
  },
  {
    key: 'cancelled',
    name: 'Cancelled',
  },
  {
    key: 'pending',
    name: 'Scheduled',
  },
]
let allStatuses= '&status=pending&status=completed&status=inprogress&status=cancelled'
export default  class MaintenanceFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openFilterModal:false,
      from: '',
      to: '',
      selectedDate: '',
      vehicleId:[],
      cityId:[],
      loader:false,
      countChecked: 0,
      anchorEl: null,
      selectedvehicleId:[],
      selectedStatusId:allStatuses,
      selectedCityId:'0',
      steps: [{id: 1, title: 'selectServiceTimeControl'}, {id: 2, title: 'selectVehicles'},{id:3, title:'tripType'}],
      activeStep: 0,
      reportType:0,
      isSubmitBtn:false,
      isValidSubmit:true
    }
  }


  onCloseDueMaintenanceModel = () =>{
    this.setState({
      openFilterModal:false,
      to:'',
      from:'',
      isSubmitBtn:false,
      selectedDate:'',
      from: '',
      to: '',
      vehicleId:[],
      cityId:[],
      countChecked: 0,
      anchorEl: null,
      activeStep:0,
    })
  }

  showDates =(e, date)=> {
    const duration = moment.duration((moment(this.state.to)).diff(this.state.from));
    const hours = duration.asHours();
    let timezone = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    var formatedDate = {
      from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
      to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
    }

    let timeFormat = 'HH:mm'

    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = 'hh:mm A'
    }

    this.setState(
      {
        selectedDate:
          moment(date.startDate).format('YYYY-MM-DD ' + timeFormat) +
          ' - ' +
          moment(date.endDate).format('YYYY-MM-DD ' + timeFormat),
        ...formatedDate
      })
  }

  handleChange =(name, e)=>{
    if(e ==='city'){
      this.setState(
        {
          cityId: name
        })
        this.setState({cityId: name },()=>{  
          let filter = '';
            Object.entries(this.state.cityId).map(([key,value])=>{
              if(value){
                filter += '&interCity=' + value
              }
            })
            this.setState({
              selectedCityId: name.length === 2 ? "0" : name[0]
            }, ()=>{this.checkReqFields()})
        })
     }
      else if(e === 'status'){
        this.setState(
          {
            statusId: name
          })
          this.setState({statusId: name },()=>{  
            let filter = '';
              Object.entries(this.state.statusId).map(([key,value])=>{
                if(value){
                  filter += '&status=' + value
                  this.setState({
                  })
                }
              })
              this.setState({
                selectedStatusId: filter
              }, ()=>{this.checkReqFields()})
          })
      }
      else{
        this.setState(
          {
              vehicleId: name
          })
          this.setState({vehicleId: name },()=>{  
            let filter = '';
              Object.entries(this.state.vehicleId).map(([key,value])=>{
                if(value){
                  filter += '&deviceId=' + value
                  this.setState({
                  })
                }
              })
              this.setState({
                selectedvehicleId: filter
              }, ()=>{this.checkReqFields()})
          })
      }
  }

  checkReqFields = ()=>{
    const {from,to,cityId,selectedvehicleId} = this.state
    if(from && to && selectedvehicleId && cityId.length){
      this.setState({isValidSubmit:false})
    }
    else{
      this.setState({isValidSubmit:true})
    }
  }

  goToNextStep = (step, type, index) => {
    if(step === 3){ 
        this.setState({
          openFilterModal:false,
        }, ()=>{
          this.props.formSubmit(this.state)
          // this.onCloseDueMaintenanceModel()
      })
    }
    else{
      this.validatedCurrentStep(step, type, index);
    }
  }
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
      this.setState({activeStep: currentStep-1}) 
  }

  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let validated = false;
    let errorMsg = null;
    if(step === 1) {
      if(this.state.to && this.state.from && this.state.selectedStatusId){
          validated = true
          errorMsg= null
        }
        else {
          validated = false
          errorMsg= <span className="text-warning">Please select 'Date and Status' from above</span>
        }
    }
    else if(step === 2) {
          validated = true
    }

    if(validated === true) {
      this.setState({activeStep: index + 1}, ()=>{
        if(this.state.activeStep===2){
          this.setState({isSubmitBtn:true})
        }})
    }

    this.state.steps.map(s => {
      if(s.id === step) {
        steps.push({...s, errorMsg})
      }
      else steps.push(s)
      
      return null
    })

    this.setState({steps})

  }

      submitDownload = (a,type,c)=>{
        let Accept;
        const { from,to,selectedCityId,selectedvehicleId, selectedStatusId} = this.state
        let accept = 'application/pdf'
        let fileName = 'workflows'
        if(type === 'downloadPdf'){
            accept='application/pdf'
        }
        else{
          accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
      let header = {
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: accept
        })
      }
      fetch(`/api/workflows/export?sample=false${selectedStatusId}${selectedvehicleId}&page=1&limit=50&from=${from}&to=${to}&interCity=${selectedCityId}`, { ...header })
      .then(response1 =>{
        if(response1.status === 200) {
        response1.blob().then(response => {
          if(type === 'downloadPdf'){
            this.saveData(
              response,
              'workFlows.pdf'
            )
          }
          else{
            this.saveData(
              response,
              'workFlows.xlsx'
            )
          }
        })
      } else {
        throw response1
      }
    })
  }

  saveData (blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        this.setState({ loading: false })
      }, 0)
    }
    this.setState({ loading: false })
  }
  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (<Grid container className='form-group sidebar-bg-layer'>
        <Grid item xs={12}>
          <DateRangePicker
            fill
            error={ this.props.timeError || false}
            selectedDate={this.state.selectedDate}
            label={
              this.props.translate('reportFrom') +
              ' ' +
              this.props.translate('reportTo')
            }
            onEvent={this.showDates}
          />
        </Grid>
        <Grid item  xs={12}>
          <WorkFlowStatusFilter
            api='status'
            fill
            hideIcons
            isClearable
            placeholder={this.props.translate('searchStatus')}
            onChange={this.handleChange}
            value={this.state?.statusId||[]}
            statusTypes={statusTypes}
            allPreSelected
          />    
        </Grid>
      </Grid>)
    case 2: 
    return (<Grid container className='form-group'>
      <Grid item  xs={12}>
          <VehicleSelector
            api='vehicle'
            fill
            hideIcons
            isClearable
            placeholder={this.props.translate('searchVehicle')}
            onChange={this.handleChange}
            value={this.state?.vehicleId||[]}
            workFlow
          />
      </Grid>
    </Grid>)
    case 3: 
      return (<Grid container className='form-group'>
       <Grid item  xs={12}>
            <CitySelector
                api='city'
                fill
                hideIcons
                isClearable
                placeholder={this.props.translate('citySelection')}
                onChange={this.handleChange}
                value={this.state?.cityId||[]}
              /></Grid>
    </Grid>)
    default:
        return null
    }
  }
  

  render () {
    const viewScreen = (
      
        <form style={{ padding: "16px 0 16px 10px" }}>
        {<>
                <Stepper 
                translate={this.props.translate}
                activeStep={this.state.activeStep} 
                reportType={this.state.reportType} 
                getStepContent={this.getStepContent} 
                steps={this.state.steps}
                handleNext={this.goToNextStep} 
                handleBack={this.goToBackStep} 
                isSubmitBtn={this.state.isSubmitBtn} 
                downloadWorkFlow
                submitDownload={this.submitDownload} 
                isValidSubmit={this.state.isValidSubmit}
                workFlow
                />
        </>
        }
      </form>
    )

    return (
      <div className='geofence-sidebar-list'>
        {!this.props.loader?(
        <Scrollbar>
          {viewScreen}
        </Scrollbar>):<Loader/>
        }
      </div>
  )
  }
}