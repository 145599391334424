import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/lib/Async'
import { geoFenceVisible, resetBounds, setTrackId, toggleAllGeofences, updateDeviceVisible } from '../../Actions/Devices';

const SearchField = React.memo((props) => {
  const { dispatch, vehicles, devices3, drivers, geofences, trailers } = props;
  const promiseOptions = async (inputValue) => {
    const vehiclesList = [...vehicles].map(({label, id:value, deviceId }) => ({label, value, deviceId, type: 'vehicle'}));
    const trailersList = [...trailers].map(({name:label, uniqueId:value }) => ({label, value, type: 'trailer'}));
    const driversList = [...drivers].map(({name:label, id:value }) => ({label, value, type: 'driver'}));
    const geofencesList = [...geofences].map(({name:label, id:value, attributes }) => ({label, value, attributes, type: 'geo'}));
    const devices3List = [...devices3].map(({name:label, id:value, attributes }) => ({label, value, attributes, type: 'unit'}));
    const list = [...vehiclesList, ...trailersList, ...driversList, ...devices3List, ...geofencesList];
    const finalList = await list.filter((i) => i.label.toLowerCase().includes((inputValue || "").toLowerCase()));  
    const options = {
      'geo' : 
      {
        label: <Translate id='sharedGeofence' />,
        options: [],
      },
      'driver' : 
      {
        label: <Translate id='Staff' />,
        options: [],
      },
      'vehicle' : 
      {
        label: <Translate id='sharedVehicle' />,
        options: [],
      },
      'trailer' : 
      {
        label: <Translate id='trailer' />,
        options: [],
      },
      'unit' : {
        label: <Translate id='trackers' />,
        options: [],
      }
    }
    finalList.map(row => {
      options[row.type].options.push(row);
    })
    return Object.values(options)
  }

  const selectOption = (item, action) => {
    if(action.action === 'select-option') {
      if(item.type === 'geo') {
        dispatch(resetBounds())
        dispatch(toggleAllGeofences(false));
        dispatch(geoFenceVisible({ visible: true, id: item.value }))
        props.updateVisible()
      }
      else if(item.type === 'driver') {
        dispatch(toggleAllGeofences(false));
        const unit = Object.values(props.deviceRelatedData).find(r => r.driverId === item.value);
        if(unit && unit.exists) {
          dispatch(updateDeviceVisible({ checked: true, id: unit.id }))
          dispatch(setTrackId(unit.id))
        }
      }
      else if(item.type === 'trailer') {
        dispatch(toggleAllGeofences(false));
        const unit = Object.values(props.deviceRelatedData).find(r => r.attributes && r.attributes.trailerUniqueId === item.value);
        if(unit && unit.exists) {
          dispatch(updateDeviceVisible({ checked: true, id: unit.id }))
          dispatch(setTrackId(unit.id))
        }
      }
      else if(item.type === 'vehicle') {
        dispatch(toggleAllGeofences(false));
        dispatch(updateDeviceVisible({ checked: true, id: item.deviceId }))
        dispatch(setTrackId(item.deviceId))
      }
      else {
        dispatch(toggleAllGeofences(false));
        if (props.deviceRelatedData && props.deviceRelatedData[item.value] && props.deviceRelatedData[item.value].exists) {
          
          dispatch(updateDeviceVisible({ checked: true, id: item.value }))
          dispatch(setTrackId(item.value))
        }
      }
    } else {
      dispatch(setTrackId(0))
      dispatch(resetBounds())
    }
  }
  
  return (<div style={{width: 280}}>
    <AsyncSelect menuPlacement="auto" DropdownIndicator={null} placeholder="Search anything" noOptionsMessage={() => 'Search here for Units or anything'} defaultOptions onChange={selectOption} isClearable styles={{
      indicatorSeparator: base => ({...base, display: 'none'}),
      dropdownIndicator: base => ({
        ...base,
        display: 'none'
      })
    }}
    
    loadOptions={promiseOptions}/>
  </div>
  );
})

const mapState = ({ vehicles, devices3, drivers, geoFence:geofences, trailers  }) => ({
  vehicles,
  devices3,
  drivers,
  geofences,
  trailers
})

const mapStateToProps = connect(mapState)
export default mapStateToProps(SearchField)