import React, { Component } from 'react'
import isEqual from 'react-fast-compare'
import { withLocalize } from 'react-localize-redux'
import { Map, TileLayer, Marker } from 'react-leaflet'
import { connect } from 'react-redux'
import L from 'leaflet'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'

let MarkerDOM
class MarkerMap extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lat: 0,
      lng: 0,
      zoom: 1,
      minZoom: 3,
      bounds: []
    }
    this.mapEvent = this.mapEvent.bind(this)
    this.myRef = this.myRef.bind(this)
    this.getGeoInfo = this.getGeoInfo.bind(this)
  }
  mapEvent (e) {
    if (this.props.getPostion) {
      this.getGeoInfo(e)
    }
  }
  myRef (el) {
    if (el) {
      this.map = el.leafletElement
    }
  }
  getGeoInfo = (e, column, ref) => {
    // fetch(
    //   `https://nominatim.openstreetmap.org/reverse?format=json&lat=${e.latlng.lat}&lon=${e.latlng.lng}&zoom=18&addressdetails=1`
    // )
    instance({
      url: `https://nominatim.openstreetmap.org/reverse`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        format: 'json',
        lat: e.latlng.lat,
        lon: e.latlng.lng,
        zoom: 18,
        addressdetails: 1
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(address => {
          this.props.getPostion(address.display_name, e.latlng)
      //   })
      // } else{
      //   throw response
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })

    // if(LCG && LCG.L && LCG.L.Control && LCG.L.Control.Geocoder){
    //   const geocoder = LCG.L.Control.Geocoder.nominatim();
    //   geocoder.reverse(e.latlng, this.map.options.crs.scale(this.map.getZoom()), results => {
    //     if(results.length){
    //       this.props.getPostion(results[0].name)
    //     }
    //   })
    // }
  }

  componentWillMount () {
    const { lat, lng, zoom } = this.props
    this.setState({
      lat: lat || 0,
      lng: lng || 0,
      zoom: zoom || 3
    })
    if (lat && lng) {
      MarkerDOM = (
        <Marker
          position={[lat || 0, lng || 0]}
          icon={L.icon({
            iconUrl: '/assets/images/location-pin.svg',
            iconSize: [79, 64],
            iconAnchor: [20, 64]
          })}
        />
      )
    } else {
      MarkerDOM = ''
    }
  }
  componentWillReceiveProps (nextProps) {
    const { lat, lng, showMarker, eventMarker } = nextProps
    this.setState({
      lat: lat || nextProps.logInUser.latitude || 0,
      lng: lng || nextProps.logInUser.longitude || 0,
      zoom: lng && lat ? nextProps.zoom : nextProps.logInUser.zoom || 3
    })
    let bounds = []
    if (nextProps.showGeofence && nextProps.groFeneList.length) {
      nextProps.groFeneList.map(g => {
        if (g.visible === true) {
          bounds.push(g.id)
        }
        return null
      })
      if (bounds) {
        this.setState({ bounds: bounds })
      }
    }

    if (lat && lng && showMarker) {
      MarkerDOM = (
        <Marker
          position={[lat || 0, lng || 0]}
          icon={L.icon({
            iconUrl: eventMarker ? eventMarker : '/assets/images/location-pin.svg',
            iconSize: [79, 64],
            iconAnchor: [20, 64]
          })}
        />
      )
    } else {
      MarkerDOM = ''
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  render () {
    let position =
      this.state.lat && this.state.lng
        ? [this.state.lat, this.state.lng]
        : [0, 0]
    let zoom = this.state.zoom

    if (this.state.lat === 0 || this.props.lat === 0) {
      if (this.props.logInUser.zoom) {
        position = [
          this.props.logInUser.latitude || 0,
          this.props.logInUser.longitude || 0
        ]
        zoom = this.props.logInUser.zoom
      } else if (this.props.ServerSetting.zoom) {
        position = [
          this.props.ServerSetting.latitude || 0,
          this.props.ServerSetting.longitude || 0
        ]
        zoom = this.props.ServerSetting.zoom
      }
    }

    let crs = {}
    if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 }
    }

    const thisMap = [
      <Map
        id='map'
        ref={this.myRef}
        style={{
          height: this.props.height || '100%',
          width: this.props.width || '100%'
        }}
        key={1}
        center={position}
        onClick={this.mapEvent}
        zoom={zoom}
        zoomControl={false}
        {...crs}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
      >
        {this.props.showGeofence && this.props.geofences}
        {MarkerDOM}
        <TileLayer
          {...this.props.mapLayer}
          maxNativeZoom={this.props.mapLayer.maxZoom}
          maxZoom={this.props.mapLayer.maxZoom}
          minZoom={this.state.minZoom}
        />
      </Map>
    ]

    return (
      <div style={{ position: 'relative' }} className={'maparea2'}>
        {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
        {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleTerrain'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleSatellite'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['gccStreet'].includes(this.props.mapLayer.id) ? thisMap : null}
        {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)
          ? thisMap
          : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mapLayer: state.mapLayer,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting
})

export default connect(mapStateToProps)(withLocalize(MarkerMap))
