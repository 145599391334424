import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EditItem } from './EditItem'
import CustomDialog from './../common/Dialog'
import { checkPrivileges, errorHandler, selectAll } from './../../Helpers'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import Loader from '../../Layout/Loader'
import isEqual from 'react-fast-compare'
// import { addDriver, removeDriver, updateDriver } from './../../Actions/Drivers'
import { addTrailer, removeTrailer, updateTrailer } from './../../Actions/Trailer'
import {
  updateDeviceUserWiseSelectedList,
  RemoveDeviceUserWise,
  addDeviceUserWise,
  deviceUserWiseSelectedList
} from './../../Actions/Notifications'
import AddTrailer from './addTrailer'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Notifications from 'react-notification-system-redux'
import CommonTableSection from './../../Pages/Units/Components/commonTableSection'
import instance from '../../axios'
import Axios from 'axios'

const trailerDataFormat = (data, extAttriubtes, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...extAttriubtes },
  name: data.name.trim(),
  description: "",
  uniqueId: data.uniqueId.trim(),
  type:data.type ? data.type:'trailer',
  expirationTime:data.expirationTime?data.expirationTime:''
})

const formDefault = {
  id:"",
  type:'',
  name: '',
  uniqueId: '',
  attributes: {},
  trailerAddress: '',
  expirationTime:''
}

class trailerModal extends Component {
    constructor () {
        super()
        this.state = {
          dm_isVisable: false,
          selectedTrailer: '',
          addOption: false,
          editOption: false,
          dr_attriubtes: '',
          isVisibleBtn: false,
          isAttChanged: false,
          trailerUniqueId: '',
          form: formDefault,
          itemAttributes: {},
          attributeChangesMessage: '',
          removedIdPropcess: '',
          imagesData: '',
          uploadingImage:false

        }
      }
      async  componentWillMount () {
        if(this.props.activeOperation === ''){
          this.setState({
            editOption: false,
            addOption: false,
          })
        }
        if (this.props.showItemDetail && this.props.trackersApiResponce) {
          let formData = this.setFormData(this.props.selecteditem)
          let attributes = this.props.selecteditem.attributes
          let trackers = this.props.multiTrackers
          formData = { ...formData, attributes, trackers }
          this.setState({
            selectedTrailer: formData,
            trailerUniqueId: this.props.selecteditem.id
          })
        } else {
          if (this.props.activeOperation === 'edit') {
            let formData =  await this.setFormData(this.props.selecteditem)
            let attributes = this.props.selecteditem.attributes
            
            formData = { ...formData, attributes }
              if (
                !this.state.editOption ||
                !isEqual(this.state.selectedTrailer, formData)
              ) {
                this.setState(
                  {
                    trailerUniqueId: this.props.selecteditem.id,
                    selectedTrailer: formData,
                    itemAttributes: this.props.selecteditem.attributes,
                    editOption: true,
                    addOption: false,
                    dm_isVisable: true,
                    attributeChangesMessage: '',
                    isVisibleBtn: false,
                    isAttChanged: false,
                    dr_attriubtes: this.props.selecteditem.attributes,
                    form: { ...formData }
                  },
                  () => this.checkRequiredFields()
                )
              }
          }
          if (this.props.activeOperation === 'add' && !this.state.addOption) {
            this.setState({
              trailerUniqueId: this.props.selecteditem.id,
              selectedTrailer: '',
              addOption: true,
              editOption: false,
              dm_isVisable: true,
              form: formDefault,
              isVisibleBtn: false,
              isAttChanged: false,
              attributeChangesMessage: ''
            })
          }
        }
        if (
          this.props.selectedAddress !== '' &&
          this.props.selectedAddress !== undefined
        ) {
          this.setState({
            form: {
              ...this.state.form,
              trailerAddress: this.props.selectedAddress
            }
          })
        }
      }

     getImage = async (id) => {
        try {
          const response = await Axios.get(`/api/media/binder/${id}`, {
            responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
          });
          const filename = id;
          const parts = filename.split('.');
          const extension = parts[parts.length - 1];
          
          
          if (response.status === 200) {
            // Convert the array buffer to base64
            const base64Image = Buffer.from(response.data, 'binary').toString('base64');

            return `data:image/${extension};base64,${base64Image}`;
          } else {
            console.error('Failed to fetch image:', response.status, response.statusText);
            return '';
          }
        } catch (error) {
          console.error('Error in getImage:', error);
          return '';
        }
      };
      componentWillReceiveProps (nextprops) {
        if (nextprops.selecteditem.id !== this.state.trailerUniqueId) {
          if (nextprops.showItemDetail && nextprops.trackersApiResponce) {
            let formData = this.setFormData(nextprops.selecteditem)
            let attributes = nextprops.selecteditem.attributes
            let trackers = nextprops.multiTrackers
            formData = { ...formData, attributes, trackers }
            this.setState({
              selectedTrailer: formData,
              trailerUniqueId: nextprops.selecteditem.id
            })
          } else {
            if (nextprops.activeOperation === 'edit') {

              let formData = this.setFormData(nextprops.selecteditem)
              let attributes = nextprops.selecteditem.attributes
              formData = { ...formData, attributes }
              if (
                !this.state.editOption ||
                !isEqual(this.state.selectedTrailer, formData)
              ) {
                this.setState(
                  {
                    trailerUniqueId: nextprops.selecteditem.id,
                    selectedTrailer: formData,
                    editOption: true,
                    addOption: false,
                    dm_isVisable: true,
                    attributeChangesMessage: '',
                    isVisibleBtn: false,
                    isAttChanged: false,
                    itemAttributes: nextprops.selecteditem.attributes,
                    dr_attriubtes: nextprops.selecteditem.attributes,
                    form: { ...formData }
                  },
                  () => this.checkRequiredFields()
                )
              }
            }
            if (nextprops.activeOperation === 'add' && !this.state.addOption) {
              this.setState(
                {
                  trailerUniqueId: nextprops.selecteditem.id,
                  selectedTrailer: '',
                  addOption: true,
                  editOption: false,
                  dm_isVisable: true,
                  form: formDefault,
                  isVisibleBtn: false,
                  isAttChanged: false,
                  attributeChangesMessage: ''
                },
                () => this.checkRequiredFields()
              )
              if (
                nextprops.selectedAddress !== '' &&
                nextprops.selectedAddress !== undefined
              ) {
                this.setState(
                  {
                    form: {
                      ...this.state.form,
                      trailerAddress: nextprops.selectedAddress
                    }
                  },
                  () => this.checkRequiredFields()
                )
              }
            }
          }
        }
        if (
          nextprops.selectedAddress !== '' &&
          nextprops.selectedAddress !== undefined
        ) {
          this.setState(
            {
              form: {
                ...this.state.form,
                trailerAddress: nextprops.selectedAddress
              }
            },
            () => this.checkRequiredFields()
          )
        }
      }

      setFormData = async (data, operation) => {
        let trailerImg;
        // Check if trailerImg is a URL
        // if (data.attributes.trailerImg && data.attributes.trailerImg.startsWith('http')) {
        //   trailerImg = data.attributes.trailerImg; // Use the URL directly
        // } else if (data.attributes.trailerImg) {
        //   // If trailerImg is an imageName, call this.getImage
        //   trailerImg = this.getImage(data.attributes.trailerImg);
        // }
        if (data.attributes.trailerImg && data.attributes.trailerImg.startsWith('http')) {
          trailerImg = data.attributes.trailerImg; // Use the URL directly
      } else if (data.attributes.trailerImg) {
          // If trailerImg is an imageName, call this.getImage and wait for the result
          try {
              trailerImg = await this.getImage(data.attributes.trailerImg);
          } catch (error) {
              console.error('Error getting image======', error);
              trailerImg = ''; // Set trailerImg to empty string in case of error
          }
      }
        let formData = {
          id: data.id,
          name: data.name,
          uniqueId: data.uniqueId,
          department: data.attributes.department || '',
          trailerImg: trailerImg || '', // Set trailerImg based on the condition
          type: data.type || '',
          expirationTime: data.expirationTime || ''
        };
        return formData;
      }
      

      submitDataPepration = () => {
        let { itemAttributes, form } = this.state
        if (form.trailerAddress) {
          let trailerAddress = form.trailerAddress
          itemAttributes = {
            ...itemAttributes,
            trailerAddress: trailerAddress
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          }
        }
        if (form.attributes.tag_1 !== undefined) {
          let tag_1 = form.attributes.tag_1
          itemAttributes = { ...itemAttributes, tag_1 }
        }
        if (form.attributes.tag_2 !== undefined) {
          let tag_2 = form.attributes.tag_2
          itemAttributes = { ...itemAttributes, tag_2 }
        }
        if (form.attributes.tag_3 !== undefined) {
          let tag_3 = form.attributes.tag_3
          itemAttributes = { ...itemAttributes, tag_3 }
        }
        if (form.attributes.tag_4 !== undefined) {
          let tag_4 = form.attributes.tag_4
          itemAttributes = { ...itemAttributes, tag_4 }
        }
        if (form.attributes.tag_5 !== undefined) {
          let tag_5 = form.attributes.tag_5
          itemAttributes = { ...itemAttributes, tag_5 }
        }
    
        if (form.email) {
          let email = form.email
          itemAttributes = { ...itemAttributes, email }
        }
        if (form.licenseClass) {
          let licenseClass = form.licenseClass
          itemAttributes = { ...itemAttributes, licenseClass }
        }
        if (form.licenseNumb) {
          let licenseNumb = form.licenseNumb
          itemAttributes = { ...itemAttributes, licenseNumb }
        }
        if (form.licenseExpDate) {
          let licenseExpDate = form.licenseExpDate
          itemAttributes = { ...itemAttributes, licenseExpDate }
        }
        if (form.phone) {
          let phone = form.phone
          itemAttributes = { ...itemAttributes, phone }
        }
        if (form.department) {
          let department = form.department
          itemAttributes = { ...itemAttributes, department }
        }
        if (this.props.trailerLat) {
          let trailerLat = this.props.trailerLat
          itemAttributes = { ...itemAttributes, trailerLat }
        }
        if (this.props.trailerLon) {
          let trailerLon = this.props.trailerLon
          itemAttributes = { ...itemAttributes, trailerLon }
        }
        if (form.trailerImg) {
          let trailerImg = form.trailerImg
          itemAttributes = { ...itemAttributes, trailerImg }
        }
        return itemAttributes
      }
      onCloseModal = () => {
        this.setState({
          enableImagePopup: false
        })
      }
    
      imageDataPrepare = () => {
        let { imagesData } = this.state

        if (imagesData) {
          // fetch('/api/uploads', {
          //   method: 'POST',
          //   headers: {
          //     Accept: 'application/json',
          //     'Content-Type': 'application/json'
          //   },
          //   body: JSON.stringify({
          //     ...imagesData
          //   })
          // })
          instance({
            url: '/api/uploads',
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data:{
              ...imagesData
            }
            })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
              .then(publicUrl => {

                // this.setState(
                //   {
                //     form: {
                //       ...this.state.form,
                //       trailerImg: publicUrl.publicUrl
                //     }
                //   },
                //   () => {
                //     if (this.state.editOption) {
                //       this.ud_submit()
                //     } else {
                //       this.ad_submit()
                //     }
                //   }
                // )
            //   })
            // } else {
            //   //this.props.enqueueSnackbar(this.props.translate('somthingWentWrongMessage'));
            //   this.props.dispatch(
            //     Notifications.error({
            //       message: this.props.translate('somthingWentWrongMessage'),
            //       autoDismiss: 10
            //     })
            //   )
            //     throw response
            //   }
            }).catch(error => {errorHandler(error, this.props.dispatch)
              // this.props.dispatch(
              //   Notifications.error({
              //     message: this.props.translate('somthingWentWrongMessage'),
              //     autoDismiss: 10
              //   })
              // )
            })
        } else {
          if (this.state.editOption) {
            this.ud_submit()
          } else {
            this.ad_submit()
          }
        }
      }
    
    
    imageDataPrepare = () => {
      const { imagesData, form } = this.state;
      if ( !process.env.REACT_APP_SERVER_LOCAL) {
        if (imagesData) {
    const config = {
      headers: {
        Accept: '*/*',
        // 'Content-Type' is set to 'multipart/form-data' for sending FormData
        'Content-Type': 'image/png'
      }
    };
    // Axios.post(`/api/uploads/binders/${form.id}`, imagesData, config)
        instance({
          url: `/api/uploads/binder/${form.id}`,
          method: 'POST',
          headers: {
             Accept: '*/*',
            'Content-Type': 'image/png'
          },
          data:{
            ...imagesData
          }
          })
      .then(response => {
        this.setState(
          {
            isVisibleBtn: false,
            form: {
              ...this.state.form,
              trailerImg: response.image ? response.image : ''
            }
          },
          () => {
            if (this.state.editOption) {
              this.ud_submit();
            } else {
              this.ad_submit();
            }
          }
        );
      })
      .catch(error => {
        console.error('Error in imageDataPrepare:', error);
        // Handle the error, display a message, etc.
      });
  } else {
    if (this.state.editOption) {
      this.ud_submit();
    } else {
      this.ad_submit();
    }
  }
} else {
  if (this.state.editOption) {
    this.ud_submit();
  } else {
    this.ad_submit();
  }
}
};
    
  
      ad_submit = () => {
        let { form } = this.state
        let preperData = {
          name: form.name,
          uniqueId: form.uniqueId,
          expirationTime:form.expirationTime?form.expirationTime:'',
          type:form.type?form.type:''
        }
        this.setState({
          isVisibleBtn: false
        })
        if (
          form.name &&
          form.name.trim() &&
          form.uniqueId &&
          form.uniqueId.trim()
        ) {
          let attriubtes = this.submitDataPepration()
          const obj = trailerDataFormat(preperData, attriubtes)
          // fetch('/api/binders', {
          //   method: 'POST',
          //   headers: {
          //     Accept: 'application/json',
          //     'Content-Type': 'application/json'
          //   }, 
          //   body: JSON.stringify({
          //     ...obj
          //   })
          // })
          instance({
            url: '/api/binders',
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data:{
              ...obj
            }
            })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(trailer => {
                  // if(trailer.status === "success"){
                    this.props.dispatch(addTrailer(trailer))
                    this.props.onCloseModal()
                    this.props.fetchMoreItems()
                    this.setState({
                      dm_isVisable: !this.state.dm_isVisable,
                      addOption: false,
                      dr_attriubtes: '',
                      selectedTrailer: '',
                      form: formDefault,
                      attributeChangesMessage: '',
                      imagesData: ''
                    })
                    //this.props.enqueueSnackbar(this.props.translate('driverIsCreated'));
                    this.props.dispatch(
                      Notifications.success({
                        message: this.props.translate('trailerIsCreated'),
                        autoDismiss: 10
                      })
                    )
                  // } else {
                  //     if (trailer.message.includes('Duplicate entry')) {
                  //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                  //       this.props.dispatch(
                  //         Notifications.error({
                  //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
                  //           autoDismiss: 10
                  //         })
                  //       )
                  //     } else {
                  //       this.props.dispatch(
                  //         Notifications.error({
                  //           message: this.props.translate('somthingWentWrongMessage'),
                  //           autoDismiss: 10
                  //         })
                  //       )
                  //     }
                  // }




              //   })
              // } else {
              //   throw response
              // }
            })
            .catch(error => {
              errorHandler(error, this.props.dispatch)
              // if (e && e.text) {
              //   e.text().then(err => {
                  // if (err.includes('Duplicate entry')) {
                  //   //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                  //   this.props.dispatch(
                  //     Notifications.error({
                  //       message: this.props.translate('uniqueIdIsAllreadyInUsed'),
                  //       autoDismiss: 10
                  //     })
                  //   )
                  // } else {
                  //   this.props.dispatch(
                  //     Notifications.error({
                  //       message: this.props.translate('somthingWentWrongMessage'),
                  //       autoDismiss: 10
                  //     })
                  //   )
                  // }
              //   })
              // }
            })
        } else {
          //this.props.enqueueSnackbar(this.props.translate('emptyField'));
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('emptyField'),
              autoDismiss: 10
            })
          )
        }
      }
      ud_submit = () => {
        const { form } = this.state
        let preperData = {
          name: form.name,
          uniqueId: form.uniqueId,
          expirationTime:form.expirationTime?form.expirationTime:'',
          type:form.type?form.type:''
        }
        this.setState({
          isVisibleBtn: false
        })
        let attriubtes = this.submitDataPepration()
        const obj = trailerDataFormat(preperData, attriubtes, this.state.trailerUniqueId)
        delete obj.attributes.lat
        delete obj.attributes.lng
        delete obj.attributes.trailerLng
    
        if (!obj.attributes.tag_1) delete obj.attributes.tag_1
        if (!obj.attributes.tag_2) delete obj.attributes.tag_2
        if (!obj.attributes.tag_3) delete obj.attributes.tag_3
        if (!obj.attributes.tag_4) delete obj.attributes.tag_4
        if (!obj.attributes.tag_5) delete obj.attributes.tag_5
    
        // fetch(`/api/binders/${this.state.trailerUniqueId}`, {
        //   method: 'PUT',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     ...obj
        //   })
        // })
        instance({
          url: `/api/binders/${this.state.trailerUniqueId}`,
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data:{
            ...obj
          }
          })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
              .then(trailer => {
                // if(trailer.status === 'success'){
                  this.props.dispatch(updateTrailer(trailer))
                  this.props.onCloseModal()
                  this.props.fetchMoreItems()
                  //this.props.enqueueSnackbar(this.props.translate('driverIsUpdated'));
                  this.props.dispatch(
                    Notifications.success({
                      message: this.props.translate('trailerIsUpdated'),
                      autoDismiss: 10
                    })
                  )
                  this.setState({
                    itemAttributes: trailer.attributes,
                    dr_attriubtes: trailer.attributes,
                    dm_isVisable: !this.state.dm_isVisable,
                    selectedTrailer: trailer,
                    attributeChangesMessage: '',
                    isVisibleBtn: false,
                    isAttChanged: false,
                    uploadingImage:false,

                    imagesData: ''
                  })
                // } else {
                //     if (trailer.message.includes('Duplicate entry')) {
                //       //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
                //       this.props.dispatch(
                //         Notifications.error({
                //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
                //           autoDismiss: 10
                //         })
                //       )
                //     } else {
                //       this.props.dispatch(
                //         Notifications.error({
                //           message: this.props.translate('somthingWentWrongMessage'),
                //           autoDismiss: 10
                //         })
                //       )
                //     }
                // }
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch(error => {
            errorHandler(error, this.props.dispatch)
            // if (e && e.text) {
            //   e.text().then(err => {
            //   if (err.includes('Duplicate entry')) {
            //     //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            //     this.props.dispatch(
            //       Notifications.error({
            //         message: this.props.translate('uniqueIdIsAllreadyInUsed'),
            //         autoDismiss: 10
            //       })
            //     )
            //   } else {
            //     this.props.dispatch(
            //       Notifications.error({
            //         message: this.props.translate('somthingWentWrongMessage'),
            //         autoDismiss: 10
            //       })
            //     )
            //   }
            //   })
            // }
          })
      }
      onCloseSubModal = () => {
        this.setState({
          dm_isVisable: false,
          addOption: false,
          editOption: false
        })
        this.props.onCloseModal()
      }
    
      changeAttribute = (name, value, selectedOption, updateAtt) => {
        if (selectedOption === 'updateAtt' || selectedOption === 'deleteAtt') {
          this.setState(
            {
              dr_attriubtes: { ...updateAtt }
            },
            () => this.checkForAttrubutesChanged(selectedOption)
          )
        } else {
          this.setState(
            {
              dr_attriubtes: {
                ...this.state.selectedTrailer.attributes,
                [name]: value
              }
            },
            () => this.checkForAttrubutesChanged()
          )
        }
      }
    
      checkForAttrubutesChanged = selectedOption => {
        let changed = false
        if (this.state.selectedTrailer) {
          changed = !isEqual(
            this.state.dr_attriubtes,
            this.state.selectedTrailer.attributes
          )
        } else {
          changed = !this.isEmpty(this.state.dr_attriubtes)
        }
        let { name, uniqueId } = this.state.form
        let selectedTrailer = {}
        if ((name && uniqueId) || (name && uniqueId && changed)) {
          selectedTrailer = this.state.dr_attriubtes
          if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
            this.setState({
              isVisibleBtn: changed,
              isAttChanged: changed,
              itemAttributes: { ...selectedTrailer }
            })
          } else {
            this.setState({
              isVisibleBtn: changed,
              isAttChanged: changed,
              itemAttributes: { ...this.state.itemAttributes, ...selectedTrailer }
            })
          }
        } else {
          selectedTrailer = this.state.dr_attriubtes
          if (selectedOption === 'deleteAtt' || selectedOption === 'updateAtt') {
            this.setState(
              {
                isVisibleBtn: false,
                isAttChanged: false,
                itemAttributes: { ...selectedTrailer }
              },
              () => this.checkRequiredFields()
            )
          } else {
            this.setState(
              {
                isVisibleBtn: false,
                isAttChanged: false,
                itemAttributes: { ...this.state.itemAttributes, ...selectedTrailer }
              },
              () => this.checkRequiredFields()
            )
          }
        }
        if (changed) {
          this.setState({
            attributeChangesMessage: 'Change in attributes is pending'
          })
        } else {
          this.setState(
            {
              attributeChangesMessage: ''
            },
            () => this.checkRequiredFields()
          )
        }
      }
      isEmpty = obj => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) return false
        }
        return true
      }
    
      handleChange = name => event => {
        const { target } = event
        if (name === 'licenseExpDate' || name === 'expirationTime') {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event.toISOString()
              }
            },
            () => this.checkRequiredFields()
          )
        } else {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: target.value
              }
            },
            () => this.checkRequiredFields()
          )
        }
      }
      changeAttribute2 = (name, event) => {
          this.setState(
            {
              form: {
                ...this.state.form,
                attributes: {
                  ...this.state.form.attributes,
                  [name]: event.target.value
                }
              }
            },
            () => this.checkRequiredFields()
          )
        
      }

      checkRequiredFields = () => {
        let { name, uniqueId } = this.state.form
        let value = !isEqual(this.state.form, this.state.selectedTrailer)
        if (name && uniqueId && value) {
          this.setState({
            isVisibleBtn: true
          })
        } else {
          this.setState({
            isVisibleBtn: this.state.isAttChanged
          })
        }
      }
      setExpirationTime = e => {
        e.stopPropagation()
        this.setState(
          {
            form: {
              ...this.state.form,
              licenseExpDate: null
            }
          },
          () => this.checkRequiredFields()
        )
      }
    
      uploadImage = async (event) => {
        let files = event.target.files
        const { form } = this.state;

        let imgData = ''
        let reader = new FileReader()
        if (files && files[0]) {
          if(process.env.REACT_APP_SERVER_LOCAL && form.id){

            const response = await fetch(`/api/uploads/binder/${form.id}`, {
              method: 'POST',
              body: files[0],
            });
            if (response.ok) {
              const responseData = await response.json(); // Parse JSON response
               const imageName = responseData.data.image;
              this.setState(
                {
                  imagesData:  URL.createObjectURL(files[0]),
                  form: {
                    ...this.state.form,
                    // driverImg: URL.createObjectURL(files[0]) ,// Use URL.createObjectURL to display the image
                    trailerImg: imageName ,
                    attributes:{
                      ...this.state.form.attributes,
                      trailerImg:imageName
                    }
                  }
                },
                () => {
                  let { name, uniqueId } = this.state.form;
                  if (name && uniqueId) {
                    this.setState({
                      isVisableUserBtn: true,
                      uploadingImage:true
                    },()=> {this.checkRequiredFields()});
                  } else {
                    this.setState({
                      isVisableUserBtn: this.state.isAttChanged,
                      uploadingImage:true

                    },()=> {this.checkRequiredFields()});
                  }
                }
              );
      
            } else {
              throw Error(await response.text());
            }
          }
        else{
          reader.readAsDataURL(files[0])
          reader.onload = e => {
            imgData = e.target.result
            if (imgData.split(':')[1].split('/')[0] === 'image') {
              let obj = {
                name: `${this.state.form.uniqueId + new Date().getTime()}`,
                contentType: `${files[0].type}`,
                oldUrl: `${this.state.form.trailerImg}`,
                data: `${imgData.split('base64')[1].substring(1)}`
              }
              this.setState(
                {
                  imagesData: obj,
                  form: {
                    ...this.state.form,
                    trailerImg: imgData
                  }
                },
                () => {
                  let { name, uniqueId } = this.state.form
                  if (name && uniqueId) {
                    this.setState({
                      isVisibleBtn: true
                    })
                  } else {
                    this.setState({
                      isVisibleBtn: this.state.isAttChanged
                    })
                  }
                }
              )
            } else {
              //this.props.enqueueSnackbar(this.props.translate('onlyAcceptImage'));
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate('onlyAcceptImage'),
                  autoDismiss: 10
                })
              )
            }
          }
        }

        } else {
          this.setState(
            {
              imagesData: '',
              form: {
                ...this.state.form,
                trailerImg: this.props.selecteditem.attributes
                  ? this.props.selecteditem.attributes.trailerImg
                  : ''
              }
            },
            () => {
              this.setState({
                isVisibleBtn: this.state.isAttChanged
              })
            }
          )
        }
      }
      imagePopup = () => {
        this.setState({
          enableImagePopup: true
        })
      }
    
      unit_Selection = (checked, item) => {
        let method = 'DELETE'
        let value = checked
        let allData = [...this.props.selecteddevices]
        let obj = {
          deviceId: item.id,
          trailerUniqueId: this.props.selecteditem.id
        }
        if (value) {
          method = 'POST'
        }
        // fetch(`/api/permissions`, {
        //   method: `${method}`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     ...obj
        //   })
        // })
        instance({
          url: '/api/permissions',
          method: `${method}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          data:{
            ...obj
          }
          })
        .then(response => {
          // if (response.status === 204) {
            if (method === 'DELETE') {
              item.check = false
              allData.map(dt => {
                if (dt.id === item.id) {
                  dt.check = false
                }
                return null
              })
              this.props.dispatch(updateDeviceUserWiseSelectedList(item))
              this.props.dispatch(RemoveDeviceUserWise(item))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('trackerIsUnAssignedSuccessfully'),
                  autoDismiss: 10
                })
              )
            } else {
              item.check = true
              allData.map(dt => {
                if (dt.id === item.id) {
                  dt.check = true
                }
                return null
              })
              this.props.dispatch(updateDeviceUserWiseSelectedList(item))
              this.props.dispatch(addDeviceUserWise(item))
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('trackerIsAssignedSuccessfully'),
                  autoDismiss: 10
                })
              )
            }
          // }
        }).catch(error => {errorHandler(error, this.props.dispatch)})
      }
      render () {
        const { selecteddevices } = this.props
        return (
          <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
            {this.state.dm_isVisable && this.state.addOption && (
              <AddTrailer
                onClose={this.onCloseSubModal}
                selectedTrailers={this.state.selectedTrailer}
                formSubmit={this.imageDataPrepare}
                changeAttribute={this.changeAttribute}
                handleChange={this.handleChange}
                changeAttribute2={this.changeAttribute2}
                form={this.state.form}
                buttonText='sharedCreate'
                translate={this.props.translate}
                isVisibleBtn={this.state.isVisibleBtn}
                itemAttributes={this.state.itemAttributes}
                attributeChangesMessage={this.state.attributeChangesMessage}
                setExpirationTime={this.setExpirationTime}
                isHaveAccess='binderCreate'
                uploadImage={this.uploadImage}
                imagePopup={this.imagePopup}
                addOption
              />
            )}
            {this.state.selectedTrailer && this.state.editOption && (
              <>
              <AddTrailer
                onClose={this.onCloseSubModal}
                selectedTrailers={this.state.selectedTrailer}
                formSubmit={this.imageDataPrepare}
                changeAttribute={this.changeAttribute}
                handleChange={this.handleChange}
                changeAttribute2={this.changeAttribute2}
                form={this.state.form}
                buttonText='update'
                translate={this.props.translate}
                isVisibleBtn={this.state.isVisibleBtn}
                itemAttributes={this.state.itemAttributes}
                attributeChangesMessage={this.state.attributeChangesMessage}
                setExpirationTime={this.setExpirationTime}
                isHaveAccess='binderUpdate'
                imagesData={this.state.imagesData}
                uploadingImage={this.state.uploadingImage}
                uploadImage={this.uploadImage}
                imagePopup={this.imagePopup}
                editOption
              />
              </>
            )}
            {this.state.selectedTrailer &&
            this.props.showItemDetail &&
            this.props.trackersApiResponce 
            ? (
              <EditItem
                devices={this.props.devices}
                currentLocation={this.props.currentLocation}
                linkedPositionHistory={this.props.linkedPositionHistory}
                unLinkedPositionHistory={this.props.unLinkedPositionHistory}
                linkedDevice={this.props.linkedDevice}
                unLinkedDevice={this.props.unLinkedDevice}
                selectedItem={this.state.selectedTrailer}
                handleClose={this.onCloseSubModal}
                themeColors={this.props.themecolors}
                devicesIcons={this.props.devicesIcons}
                showOnMap={this.props.showOnMap}
              />
            ) : !this.state.addOption && !this.state.editOption ? (
              <Loader />
            ) : null}
            {this.state.enableImagePopup && this.state.form.trailerImg && (
              <CustomDialog
                themecolors={this.props.themecolors}
                visable={true}
                onClose={this.onCloseModal}
                bodyPadding={10}
                cancelText={this.props.translate('sharedCancel')}
                noFullScreen
              >
                <div style={{ minHeight: 200, margin: 0, textAlign: 'center' }}>
                  <img
                    style={{ width: 500, height: 400 }}
                    alt='trailerImage'
                    src={this.state.form.trailerImg}
                  />
                </div>
              </CustomDialog>
            )}
          </div>
        )
      }
}

const mapState = state => ({
    devices2: state.devices2,
    drivers: state.drivers,
    trailers: state.trailers,
    devices: state.devices.data,
    themecolors: state.themeColors,
    selecteddevices: state.selecteddevices
  })


  const mapStateToProps = connect(mapState)
  export const TrailerModal = mapStateToProps(
    withSnackbar(withLocalize(trailerModal))
  )

