import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import TextField from './../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '../common/MenuItem'
import Grid from '@material-ui/core/Grid'
import Button from '../common/Button'
import isEqual from 'react-fast-compare'
import { logInUserInfo } from './../../Actions/Users'
import { timeZonesTypes } from './../Attributes/timezone'
import SingleSelect from '../common/SingleSelect'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Notifications from 'react-notification-system-redux'
import {
  checkUnitsWithStandardType,
  measurementStandard,
  currencies
} from '../Users/userDataTypes'
import { withLocalize } from 'react-localize-redux'
import instance from '../../axios'
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})
const defaultValues = {
  measurementStandard: '',
  currencyLabel: 'AED',
  'web.liveRouteLength': '',
  'web.selectZoom': '',
  'web.maxZoom': '',
  'ui.hidePositionAttributes': '',
  distanceUnit: '',
  speedUnit: '',
  volumeUnit: '',
  timezone: ''
}
class UserDefault extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      form: { attributes: { ...defaultValues } }
    }
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
  }
  componentWillMount () {
    this.formReset()
  }
  formReset () {
    this.setState({
      form: {
        ...this.state.form,
        attributes: {
          ...this.props.logInUser.attributes
        }
      },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { logInUser } = this.props
    logInUser.attributes = { ...this.state.form.attributes }
    // fetch(`api/users/${logInUser.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...logInUser
    //   })
    // })
    instance({
      url: `api/users/${logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...logInUser
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(user => {
            this.props.dispatch(logInUserInfo(user))
            let lang = user.attributes.lang
            this.props.setActiveLanguage(lang || 'en')

            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('userDefaultInfoIsUpdated'),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          // })
          this.setState({
            isVisableAddBtn: false
          })
      //   } else {
      //     throw response
      //   }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      if (name === 'measurementStandard') {
        let data = checkUnitsWithStandardType(value)
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]:
                  event.target.type === 'number' ? parseInt(value) : value,
                distanceUnit: data[0].distanceUnit.key,
                speedUnit: data[0].speedUnit.key,
                volumeUnit: data[0].volumeUnit.key
              }
            }
          },
          () => this.checkRequiredFields()
        )
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: event.target.type === 'number' ? parseInt(value) : value
              }
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }
  }

  timezone_handleChange = (name, value) => {
    if (!value || value.value === 'None') {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value || value.key
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields = () => {
    let value = !isEqual(
      this.state.form.attributes,
      this.props.logInUser.attributes
    )
    this.setState({
      isVisableAddBtn: value
    })
  }
  async searchFromZoneList (inputValue) {
    if (!inputValue) {
      return []
    }

    let result = timeZonesTypes.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    return result.map(item => {
      return { id: item.key, key: item.key, label: item.label, value: item.key }
    })
  }
  render () {
    const { languages } = this.props;
    let defaultZones = []
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
        defaultZones.push({
          id: item.key,
          key: item.key,
          label: item.label,
          value: item.key
        })
      // }
      return null
    })

    /* defaultZones.push({
      id: null,
      key: null,
      label: 'Search for more...',
      value: null,
      isDisabled: true
    }) */

    const { classes } = this.props
    const { form } = this.state
    let val =
      form && form.attributes && form.attributes.lang
        ? form.attributes.lang
        : 'en'
    let l = languages.find(e => val === e.code)
    let langValue = { ...l, id: l.code, value: l.code, label: l.name }

    let currency =
    form && form.attributes && form.attributes.currencyLabel
      ? form.attributes.currencyLabel
      : 'AED'
      let c = currencies.find(e => currency === e.value)
      if(!c){
        c = {key: 'Select Currency', value: ''}
      }
  let currencyValue = { ...c, id: c.key, value: c.key, label: c.key }
    return (
      <div>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='web.maxZoom'
              type='number'
              label={this.props.translate('attributeWebMaxZoom')}
              placeholder=''
              value={(form.attributes && form.attributes['web.maxZoom']) || ''}
              onChange={e => this.changeAttribute2('web.maxZoom', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='web.selectZoom'
              type='number'
              label={this.props.translate('attributeWebSelectZoom')}
              placeholder=''
              value={
                (form.attributes && form.attributes['web.selectZoom']) || ''
              }
              onChange={e => this.changeAttribute2('web.selectZoom', e)}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={3} sm={6}>
            <SingleSelect
              array={[]}
              async
              selectName='timezone'
              isClearable
              defaultOptions={defaultZones}
              loadOptions={this.searchFromZoneList}
              label={this.props.translate('sharedTimezone')}
              value={
                form.attributes && form.attributes.timezone
                  ? timeZonesTypes.find(t => t.key === form.attributes.timezone)
                  : ''
              }
              handleChange={this.timezone_handleChange}
              canAssign={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <SingleSelect
              array={languages.map(l => ({...l, id: l.code, key: l.code, value: l.code}))}
              selectName='lang'
              label={this.props.translate('loginLanguage')}
              value={(form.attributes && langValue) || ''}
              handleChange={this.timezone_handleChange}
              canAssign={true}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='measurementStandard'
              select
              label={this.props.translate('measurementStandard')}
              value={
                (form.attributes && form.attributes.measurementStandard) || ''
              }
              onChange={e => this.changeAttribute2('measurementStandard', e)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              margin='dense'
              fullWidth
            >
              {measurementStandard.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <SingleSelect
              array={currencies}
              selectName='currencyLabel'
              label={this.props.translate('currencyLabel')}
              value={(form.attributes && form.attributes.currencyLabel && currencyValue) || ''}
              handleChange={this.timezone_handleChange}
              canAssign={true}
            />
          </Grid>
        </Grid>

        {checkPrivileges('vehicle') ? (
          <>
            <h4 style={{ marginBottom:14,marginTop:14 }}>
              {this.props.translate('SetVehicleTagLabel')}
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='vt1'
                  label={this.props.translate('tag_1')}
                  placeholder=''
                  value={(form.attributes && form.attributes['vt1']) || ''}
                  onChange={e => this.changeAttribute2('vt1', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='vt2'
                  label={this.props.translate('tag_2')}
                  placeholder=''
                  value={(form.attributes && form.attributes['vt2']) || ''}
                  onChange={e => this.changeAttribute2('vt2', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='vt3'
                  label={this.props.translate('tag_3')}
                  placeholder=''
                  value={(form.attributes && form.attributes['vt3']) || ''}
                  onChange={e => this.changeAttribute2('vt3', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='vt4'
                  label={this.props.translate('tag_4')}
                  placeholder=''
                  value={(form.attributes && form.attributes['vt4']) || ''}
                  onChange={e => this.changeAttribute2('vt4', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='vt5'
                  label={this.props.translate('tag_5')}
                  placeholder=''
                  value={(form.attributes && form.attributes['vt5']) || ''}
                  onChange={e => this.changeAttribute2('vt5', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        {checkPrivileges('driver') ? (
          <>
            <h4 style={{ marginBottom:14,marginTop:14 }}>
              {this.props.translate('SetStaffTagLabel')}
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='st1'
                  label={this.props.translate('tag_1')}
                  placeholder=''
                  value={(form.attributes && form.attributes['st1']) || ''}
                  onChange={e => this.changeAttribute2('st1', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='st2'
                  label={this.props.translate('tag_2')}
                  placeholder=''
                  value={(form.attributes && form.attributes['st2']) || ''}
                  onChange={e => this.changeAttribute2('st2', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='st3'
                  label={this.props.translate('tag_3')}
                  placeholder=''
                  value={(form.attributes && form.attributes['st3']) || ''}
                  onChange={e => this.changeAttribute2('st3', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='st4'
                  label={this.props.translate('tag_4')}
                  placeholder=''
                  value={(form.attributes && form.attributes['st4']) || ''}
                  onChange={e => this.changeAttribute2('st4', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='st5'
                  label={this.props.translate('tag_5')}
                  placeholder=''
                  value={(form.attributes && form.attributes['st5']) || ''}
                  onChange={e => this.changeAttribute2('st5', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : null}

    {checkPrivileges('device') ? (
          <>
            <h4 style={{marginBottom:14,marginTop:14 }}>
              {this.props.translate('SetUnitsTagLabel')}
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut1'
                  label={this.props.translate('tag_1')}
                  placeholder=''
                  value={(form.attributes && form.attributes['ut1']) || ''}
                  onChange={e => this.changeAttribute2('ut1', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut2'
                  label={this.props.translate('tag_2')}
                  placeholder=''
                  value={(form.attributes && form.attributes['ut2']) || ''}
                  onChange={e => this.changeAttribute2('ut2', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut3'
                  label={this.props.translate('tag_3')}
                  placeholder=''
                  value={(form.attributes && form.attributes['ut3']) || ''}
                  onChange={e => this.changeAttribute2('ut3', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut4'
                  label={this.props.translate('tag_4')}
                  placeholder=''
                  value={(form.attributes && form.attributes['ut4']) || ''}
                  onChange={e => this.changeAttribute2('ut4', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut5'
                  label={this.props.translate('tag_5')}
                  placeholder=''
                  value={(form.attributes && form.attributes['ut5']) || ''}
                  onChange={e => this.changeAttribute2('ut5', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : null}
         {checkPrivileges('binder') ? (
          <>
            <h4 style={{ marginBottom:14,marginTop:14  }}>
              {this.props.translate('SetBindersTagLabel')}
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut1'
                  label={this.props.translate('tag_1')}
                  placeholder=''
                  value={(form.attributes && form.attributes['bt1']) || ''}
                  onChange={e => this.changeAttribute2('bt1', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut2'
                  label={this.props.translate('tag_2')}
                  placeholder=''
                  value={(form.attributes && form.attributes['bt2']) || ''}
                  onChange={e => this.changeAttribute2('bt2', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut3'
                  label={this.props.translate('tag_3')}
                  placeholder=''
                  value={(form.attributes && form.attributes['bt3']) || ''}
                  onChange={e => this.changeAttribute2('bt3', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut4'
                  label={this.props.translate('tag_4')}
                  placeholder=''
                  value={(form.attributes && form.attributes['bt4']) || ''}
                  onChange={e => this.changeAttribute2('bt4', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='ut5'
                  label={this.props.translate('tag_5')}
                  placeholder=''
                  value={(form.attributes && form.attributes['bt5']) || ''}
                  onChange={e => this.changeAttribute2('bt5', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        ) : null}
         {checkPrivileges('service') ? (
          <>
            <h4 style={{ marginBottom:14,marginTop:14}}>
              {this.props.translate('SetServicesTagLabel')}
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='et1'
                  label={this.props.translate('tag_1')}
                  placeholder=''
                  value={(form.attributes && form.attributes['et1']) || ''}
                  onChange={e => this.changeAttribute2('et1', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='et2'
                  label={this.props.translate('tag_2')}
                  placeholder=''
                  value={(form.attributes && form.attributes['et2']) || ''}
                  onChange={e => this.changeAttribute2('et2', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} sm={6}>
                <TextField
                  id='et3'
                  label={this.props.translate('tag_3')}
                  placeholder=''
                  value={(form.attributes && form.attributes['et3']) || ''}
                  onChange={e => this.changeAttribute2('et3', e)}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </Grid>
             
            </Grid>
          </>
        ) : null}

        <br />
        {checkPrivileges('userUpdate') && (
          <Fragment>
            <Button
              variant='contained'
              onClick={this.formReset}
              style={{ marginLeft: 8, marginRight: 8 }}
            >
              {' '}
              {this.props.translate('resetButton')}
            </Button>
            <Button
              variant='contained'
              disabled={!this.state.isVisableAddBtn}
              onClick={() => this.formSubmit()}
            >
              {' '}
              {this.props.translate('sharedSave')}
            </Button>
          </Fragment>
        )}
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), connect())

export default enhance(withLocalize(UserDefault))