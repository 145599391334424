import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '../../../Components/common/Button'
import Tooltip from '../../../Components/common/Tooltip'
import PublishIcon from '@material-ui/icons/Publish';
import { errorHandler } from '../../../Helpers'
import instance from '../../../axios'
export default class IconForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      category: {
        name: this.props.selectedDevice.category || 'default',
        id: this.props.selectedDevice.category || 'default'
      },
      images: [],
      applied: false,
      currentId: 0,
      // selectedImage:{},
    }
    // this.uploadInputRef = React.createRef(); // Create a ref for the file input
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange (category) {
    this.setState({ category })
    this.props.logo_handelChanges(category)
  }

  onSubmit () {
    this.props.advenceSectionOneformSubmit({
      category: this.state.category.name
    })
  }

  componentWillMount () {
    if (
      this.props.selectedDevice.id &&
      this.props.selectedDevice.id !== this.state.currentId
    ) {
      this.setState({ currentId: this.props.selectedDevice.id }, () => {
        this.setState({
          category: {
            name: this.props.selectedDevice.category,
            id: this.props.selectedDevice.category
          }
        })
      })
    }
  }

  componentWillReceiveProps (n) {
    if (n.selectedDevice.id && n.selectedDevice.id !== this.state.currentId) {
      this.setState({ currentId: n.selectedDevice.id }, () => {
        this.setState({
          category: {
            name: n.selectedDevice.category,
            id: n.selectedDevice.category
          }
        })
      })
    }
  }
  // triggerInputClick = () => {
  //    this.uploadInputRef.current.click(); // Trigger click on the file input
  // }

  // handleImageChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();
        
  //       reader.onload = (event) => {
  //         const base64DataUrl = event.target.result.split(',')[1]; // Split at ',' to get the base64 part

  //         this.setState({ selectedImage: {
  //           data: base64DataUrl,
  //           name:file.name,
  //           contentType:file.type
  //         } },()=>{
  //            this.handleImageUpload()
  //         });
  //       };
  
  //       reader.readAsDataURL(file);
  //     }
  // };

  // handleImageUpload = async () => {
  //   const { selectedImage } = this.state;

  //   if (selectedImage&&selectedImage.data) {
  //     // Create a FormData object to send the file
  //     const formData = new FormData();
  //     let obj = {
  //       name: selectedImage.name,
  //       contentType: selectedImage.contentType,
  //       data:selectedImage.data
  //   }
  //      const response = await instance({
  //       url: `/api/uploads`,
  //       method: 'POST',
  //       data: {...obj}
  //     })
  //     try {
  //       const publicUrl = response
  //          console.log('response =====', response)
  //          if(response){
  //           this.setState({
  //             selectedImage:{}
  //           })
  //          }
  //     }catch(error) {errorHandler(error,this.props.dispatch)}
  //   }
  // };
  // NCA-1802 custom unit icon stopped due to issue in file upload on server

  render () {
    const { images } = this.props

    return (
      <div>
        <div
          style={{ textAlign: 'right', padding: '16px' }}
          className='clearfix'
        >
           {/* <div style={{float: 'right'}}>{this.props.isVisablBtn && (
              <Button
                disabled={!this.props.isVisableASOneBtn}
                onClick={this.onSubmit}
                size='small'
              >
                {this.props.translate('sharedUpdate')}
              </Button>
            )}</div> */}
          <h4 className='v-tab-heading pull-left with-border'>
            {this.props.translate('icon')}
          </h4>
        </div>
        <div style={{ padding: '0 16px' }}>
          <Grid container spacing={1}>
            {images.map((item, i) => {
              return (
                <Tooltip key={i} title={this.props.translate(item.name)}>
                  <Grid
                    style={{ cursor: 'pointer' }}
                    item
                    onClick={e => this.onChange(item)}
                  >
                    <Paper
                      style={{
                        textAlign: 'center',
                        alignItems: 'center'
                      }}
                      className={
                        item.name === (this.state.category.name || 'default')
                          ? 'unit-image-active'
                          : 'unit-image'
                      }
                    >
                      <Avatar
                        alt=''
                        src={'/assets/category/default/' + item.name + '.svg'}
                        style={{
                          padding: 10,
                          margin: 'auto',
                          height: 80,
                          width: 80
                        }}
                      />
                    </Paper>
                  </Grid>
                </Tooltip>
              )
            })}
            {/* <Tooltip title={"Upload"}>
                  <Grid
                    style={{ cursor: 'pointer',padding:4}}
                    onClick={this.triggerInputClick} // Trigger file input click when Grid is clicked
                  >
                    <Paper
                      style={{
                        textAlign: 'center',
                        alignItems: 'center'
                      }}
                      className={'unit-image'}
                    >
                    <PublishIcon style={{
                          padding: 10,
                          margin: 'auto',
                          height: 80,
                          width: 80,
                          fill: '#fff' 
                        }}/>
                    </Paper>
                  <input
                      type="file"
                      accept="image/svg+xml"
                      ref={this.uploadInputRef} // Reference to the file input
                      style={{ display: 'none' }} // Hide the file input
                      onChange={this.handleImageChange}
                    />
                  </Grid>
                </Tooltip> */}
          </Grid>
            <Grid
              style={{ cursor: 'pointer', textAlign: 'right', marginTop: 10 }}
              item
              md
            >
              {this.props.isVisablBtn && (
                <Button
                  disabled={!this.props.isVisableASOneBtn}
                  onClick={this.onSubmit}
                  size='small'
                >
                  {this.props.translate('sharedUpdate')}
                </Button>
              )}
            </Grid>
        </div>
      </div>
    )
  }
}
