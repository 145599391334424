import React, { Component, Fragment } from 'react'
import { EditForm } from '../../../Components/Geofence/editForm'
import Button from '../../../Components/common/Button'
import SearchField from '../../../Components/common/SearchField'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { addcalendars } from '../../../Actions/Devices'
import isEqual from 'react-fast-compare'
import Icon from '@material-ui/core/Icon'
import { checkPrivileges, errorHandler } from '../../../Helpers'
import ConfirmDialoag from '../../../Components/common/ConfirmDialoag'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import { CheckIcon } from '../../../Components/common/CheckIcon'
import { removedGarages } from '../../../Actions/Garages'
import Notifications from 'react-notification-system-redux'
import DeleteIcon from '@material-ui/icons/Delete';
import instance from '../../../axios'
class Commands extends Component {
  constructor (props) {
    super(props)
    this.state = { searchRecently: '' }
  }

  search (e) {
    this.props.searchItems(e.target.value)
  }

  render () {
    const { garages,deleteItem} = this.props
    const commands1 =garages && garages.data && garages.data.map(g => (
      <ListRow
        key={g.id}
        item={g}
        deleteItem={deleteItem}
        editCommandForm={this.props.editCommandForm}
        // modeEmpty={this.props.modeEmpty}
      />
    ))

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className='fms-sidebar sidebar-bg-layer'>
          {checkPrivileges('garage') && (
            <div>
              <div className='section-head clearfix section-head-filter'>
                <ul className='filter-row clearfix pull-right'>
                  <li>
                    <SearchField
                      label={this.props.translate('searchGarage')}
                      fullWidth
                      onChange={e => this.search(e)}
                    />
                  </li>
                  <li style={{ paddingTop: 8, paddingBottom: 4 }}>
                    {(checkPrivileges('garageCreate')) && (
                      <Button
                        size='small'
                        onClick={e => this.props.openCreateFrom()}
                      >
                        {this.props.translate('Create')}
                      </Button>
                    )}
                  </li>
                </ul>
              </div>
              <ul className='list-view with-padding-right geofence-sidebar-list'>
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: props => {
                      const { elementRef, ...restProps } = props
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id='scrollableDiv'
                        />
                      )
                    }
                  }}
                >
                  {this.props.itemPagination ? (
                    <InfiniteScroll
                      dataLength={this.props.itemPagination.items.length}
                      next={this.props.fetchMoreItems}
                      hasMore={this.props.itemPagination.hasNext}
                      loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget='scrollableDiv'
                      endMessage={this.props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    )
  }
}

class ListRow1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      onDeleteConfirmation: false
    }
  }

  onDelete = () => {
      this.props.deleteItem(this.props.item.id)
    // if (this.props.item.id) {
    //   instance({
    //     url: `api/garages/${this.props.item.id}`,
    //     method: 'DELETE',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //   .then(response => {
    //       this.props.dispatch(removedGarages({ id: this.props.item.id }))
    //       this.props.dispatch(
    //         Notifications.success({
    //           message: this.props.translate('GaragesIsDeleted'),
    //           autoDismiss: 10
    //         })
    //       )
    //       // this.props.modeEmpty()
    //       this.setState({ item: {} })
    //       this.props.fetchGarages()
    //       this.props.closeForm()
    //   }).catch(error => {errorHandler(error, this.props.dispatch)})
    // }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
  }

  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true
    })
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }

  render () {
    return (
      <li className={'list-row clearfix'} key={this.props.item.id}>
        <label
          className='checkbox'
          onClick={e =>{
            if(checkPrivileges('garageUpdate')){
              this.props.editCommandForm(this.props.item)
            }
          }}
        >
          <span className='unit-name' style={{ fontSize: 12 }}>
            {this.props.item.name}
          </span>
        </label>
        <div className='callout-actions'>
          {(checkPrivileges('garageDelete')) && (
            <span className='action' onClick={this.onRemovedItem}>
              <DeleteIcon className='material-icons'/>
            </span>
          )}
        </div>

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.onDelete}
            title={this.props.translate('areYouWantToDelete')}
            children={this.props.item.description}
          />
        )}
      </li>
    )
  }
}

const mapState = state => ({
  garages: state.garages,
  geoFence: state.geoFence,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(withLocalize(ListRow1))
export default mapStateToProps(Commands)
