import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from './../common/MenuItem'
 import ListItemIcon from '@material-ui/core/ListItemIcon'
import Dialog from '../../Components/common/Dialog'
import EventIcon from '@material-ui/icons/Event'
import SettingsIcon from '@material-ui/icons/Settings'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import { ReactComponent as TimemachineIcon } from '../../assets/nav/time-machine.svg'
import { ReactComponent as TracksIcon } from '../../assets/nav/tracks.svg'
import { ReactComponent as CommandIcon } from '../../assets/nav/command.svg'
import { ReactComponent as StreetViewIcon } from '../../assets/nav/street-view.svg'
import { checkPrivileges, errorHandler, prepareStreeView } from '../../Helpers'
import Style from 'style-it'
   
 
export default class UnitMenu extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null,
      commandModal: false,
      list: []
    }
  }

  handleClose = () => {
    this.props.handleClose()
  }

  componentWillReceiveProps (NextProps) {
    this.setState({ anchorEl: NextProps.anchorEl })
  }

   
 

  render () {
    const styles = {
      ListItemIcon: {
        fontSize: 18,
        fill: 'currentColor'
      },
      ListItemIconWrap: {
        marginRight: 10,
        color: 'inherit',
        minWidth: 18
      }
    }
    return (
       <Style>
        {`
          .custom-menu-paper {
            background: ${this.props.themecolors.backgroundColor};
            color: ${this.props.themecolors.textColor}
          }
        `}
        <div>
          <Menu
            id={1}
            anchorEl={this.props.anchorE2}
            open={Boolean(this.props.anchorE2)}
            onClose={this.props.handleClose}
            classes={{ paper: 'custom-menu-paper' }}
          > 
              <MenuItem key={1} value={1} onClick={(e)=>this.props.createGeofence(e)}>  {this.props.translate('geoFenceCreate')} </MenuItem>
          </Menu> 
        </div>
      </Style>
    )
  }
}
