import React, { Component } from 'react'
import SearchField from './../../../Components/common/SearchField'
import { connect } from 'react-redux'
import Icon from '@material-ui/core/Icon'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { checkPrivileges } from '../../../Helpers'
import Style from 'style-it'
import Scrollbar from 'react-scrollbars-custom'
class TemplateGenerator extends Component {
  constructor (props) {
    super(props)
    this.state = { searchRecently: '' }
  }

  search (e) {
    this.setState({ searchRecently: e.target.value })
  }

  render () {
    const dev = this.props.templates.filter(
      item =>
        item.rpTmpl.name.toLowerCase().indexOf(this.state.searchRecently) !== -1
    )

    const geo = dev.map(g => (
      <ListRow
        key={g.rpTmpl.id}
        item={g}
        {...dev}
        onUpdate={this.props.updateTemplate}
        onDelete={this.props.deleteTemplate}
      />
    ))

    return (
      <Style>
        {`
			.material-icons {vertical-align: middle; color: inherit}
		`}
        <div className='fms-sidebar'>
          {checkPrivileges('geofence') && (
            <Scrollbar>
              <div>
                <div className='section-head clearfix search-form'>
                  <SearchField
                    label='Search Template'
                    fullWidth
                    onChange={e => this.search(e)}
                    style={{ marginBottom: 10 }}
                  />
                </div>
                <ul className='list-view with-padding-right'>{geo}</ul>
              </div>
            </Scrollbar>
          )}
        </div>
      </Style>
    )
  }
}

class ListRow1 extends React.PureComponent {
  render () {
    const item = this.props.item.rpTmpl

    return (
      <li className='list-row' key={item.id}>
        <span className='unit-name' style={{ fontSize: 12 }}>
          {item.name}
        </span>
        <div className='callout-actions'>
          {checkPrivileges('geofenceDelete') && (
            <span
              className='action text-danger'
              onClick={e => this.props.onDelete(item.id)}
            >
              <DeleteIcon className='material-icons'/>
            </span>
          )}
          {checkPrivileges('geofenceUpdate') && (
            <span
              className='action text-danger'
              onClick={e => this.props.onUpdate(this.props.item)}
            >
              <EditIcon className='material-icons'/>
            </span>
          )}
        </div>
      </li>
    )
  }
}

const mapState = state => ({
  geoFence: state.geoFence,
  templates: state.templates,
  themecolors: state.themeColors
})

const mapStateToProps = connect(mapState)
export const ListRow = mapStateToProps(ListRow1)
export default mapStateToProps(TemplateGenerator)
