import React, { Component } from 'react'
import { withSnackbar } from 'notistack'
import TextField from './../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '../common/Button'
import Grid from '@material-ui/core/Grid'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import Tooltip from '../common/Tooltip'
import { IconButton } from '@material-ui/core'
import { ReactComponent as AddNewIcon } from '../../assets/add-new.svg'
import { withLocalize } from 'react-localize-redux'
 
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

 class changedPasword extends Component {
    constructor () {
        super()
        this.state = {
            emailLists:[],
            originalLists:[]
        }
   }
  componentWillMount () {
    const verifiedEmailsString = this.props.logInUser&&this.props.logInUser.verifiedEmails;
    const emailArray = verifiedEmailsString&&verifiedEmailsString.split(',');
     this.setState({
        emailLists:emailArray ? emailArray : [],
        originalLists:emailArray ? emailArray : []
    })
   }
  
  errorCallBack = (data) => {
    this.setState({
     })
  }   

  handleChange = (event,index) => {
    let z = [...this.state.emailLists]
    z[index] = event.target.value
      this.setState({
        emailLists:z
      })
  }
 
  onAddAnotherMail = (e, index)=>{
    let newEmail = "";
    let updatedAr = [...this.state.emailLists, newEmail];
        this.setState({
            emailLists: updatedAr
        })  
   }
  
  addIndexToArray =(array, index)=> {
    const newArray = [...array];
    newArray.splice(index, 0, "newIndex");
    return newArray;
  }
  validateEmail = (email, index)=>{
    const isValid = /^\S+@\S+\.\S+$/.test(email);
    let z = index > (this.state.originalLists.length-1)  
    let res = z && isValid ? true : false
    return res
  }
  validateSingleEmail = (email)=>{
    const isValid = /^\S+@\S+\.\S+$/.test(email);
    return isValid
  }

  submitValidEmail = (value)=>{
     instance({
        url: `api/users/send/verificationemail`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:new URLSearchParams({
            email: value 
          })
       })
      .then(response => {
        this.props.enqueueSnackbar(this.props.translate('linkSent'), { autoHideDuration: 1000 });
    }) .catch(error => {
        this.props.enqueueSnackbar(this.props.translate('error'), { autoHideDuration: 1000 });
    })
  }
  render () {
    const { emailLists, originalLists } = this.state
      return (
      <div>
          {emailLists && emailLists.length ? emailLists.map((item, index)=>(
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} sm={6} style={{display:'flex'}}>
                    <TextField
                        id='email'
                        label={this.props.translate('email')}
                        placeholder=''
                        value={item|| ""}
                        onChange={e => this.handleChange(e,index)}
                        disabled={index < (originalLists.length) ? true : false}
                        variant='outlined'
                        margin='dense'
                        fullWidth
                        readOnly={index < (originalLists.length) ? true : false}
                      />
                </Grid>
                <Grid item xs={12} md={3} sm={6} style={{display:'flex'}}>
                        {index === (emailLists.length -1) ?
                            <Tooltip title={this.props.translate('addAnother')}>
                                <span>
                                    <IconButton
                                        color='inherit'
                                        aria-label='directions'
                                        onClick={e => this.onAddAnotherMail('email', index)}
                                         >
                                        <AddNewIcon
                                            width={18}
                                            height={18}
                                            style={{ fill: 'white' }}
                                        />
                                    </IconButton>
                                </span>
                        </Tooltip>
                         : null}
                        {this.validateEmail(item, index)
                                ? <Button onClick={() => this.submitValidEmail(item)}>{this.props.translate('validateEmail')}  </Button> 
                            :null}
                </Grid>
            </Grid>
                ))
               : <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={6} style={{display:'flex'}}>
                        <TextField
                            id='email'
                            label={this.props.translate('email')}
                            placeholder=''
                            value={this.state.singleEmail|| ""}
                            onChange={e => this.setState({singleEmail:e.target.value})}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                           />
                    </Grid>
                    <Grid item xs={12} md={3} sm={6} style={{display:'flex'}}>
                            <Tooltip title={this.props.translate('addAnother')}>
                              <span>
                                  <IconButton
                                      color='inherit'
                                      aria-label='directions'
                                      onClick={e => this.onAddAnotherMail('email')}
                                      >
                                      <AddNewIcon
                                          width={18}
                                          height={18}
                                          style={{ fill: 'white' }}
                                      />
                                  </IconButton>
                              </span>
                          </Tooltip>
                             
                            {this.validateSingleEmail(this.state.singleEmail)
                                    ? <Button onClick={() => this.submitValidEmail(this.state.singleEmail)}>{this.props.translate('validateEmail')}  </Button> 
                                :null}
                    </Grid>
               </Grid> }
        <br />
 
        {/* <Button
          variant='contained'
          // disabled={!this.state.isVisableBtn}
        //   onClick={() => this.formSubmit()}
        >
          {' '}
          {this.props.translate('changePassword')}
        </Button> */}
      </div>
    )
  }
}
const ChangedPasword = withStyles(styles)
export default ChangedPasword(withSnackbar(withLocalize(changedPasword)))
 