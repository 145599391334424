import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import MarkerMap from '../../Components/Maps'
import CustomMarker from '../../Components/Maps/CustomMarker'
import { MapTooltip } from '../../Components/Maps/MapTooltip'
import Layout from '../../Layout'
import Grid from '@material-ui/core/Grid'
import isEqual from 'react-fast-compare'
import { TrailerModal } from '../../Components/Trailer/trailerModal';
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { setTrackId } from '../../Actions/Devices'
import { removeTrailer } from '../../Actions/Trailer'
import withResources from '../HOCRecources'
import ResourceModal from '../../Components/Recources/resourceModal'
import Notifications from 'react-notification-system-redux'
import moment from 'moment'

import {
  Map,
  TileLayer,
  Tooltip,
  ZoomControl,
  LayersControl,
  Marker,
  Popup,
} from 'react-leaflet'
import { MapTileLayers } from '../../Reducers/Maps'
import L from 'leaflet'
import Style from 'style-it'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import CloseIcon from '@material-ui/icons/Close';

function importAll (r) {
  return r.keys().map(r)
}

const images = importAll(
  require.context('./../../images', false, /\.(png|jpe?g|svg)$/)
)


let res = {}
class Trailer extends Component {
    constructor (props) {
        super(props)
        this.state = {
          selecteditem: '',
          isVisable: false,
          showItemDetail: false,
          activeOperation: '',
          trailerAddress: '',
          trailerLat: '',
          trailerLon: '',
          selecteditemId: '',
          trackersApiResponce: false,
          multiTrackers: '',
          initFetch: false,
          currentPage: 1,
          pagesize: 50,
          lat: 0,
          lng: 0,
          zoom: 3,
          minZoom: 3,
          animCount: 0,
          assigned: false,
          tracking: false,
          applied: false,
          allTrailerLocation: {},
          allbindersLinkedDevices:[],
          resourceList: false,
          linkResource: false,
          trailerLocation:'',
          itemPagination: {
            items: [],
            total: 0,
            currentPage: 0,
            currentDevice: this.props.deviceId,
            hasNext: true,
            searchText: ''
          }
        }
        this.onCloseModal = this.onCloseModal.bind(this)
      }
    
      componentWillMount () {
        if (this.props.ServerSetting&&this.props.ServerSetting.zoom) {
          this.setState({
            zoom: this.props.ServerSetting.zoom,
            lat: this.props.ServerSetting.latitude,
            lng: this.props.ServerSetting.longitude
          })
        }
        if (this.props.logInUser&&this.props.logInUser.zoom) {
          this.setState({
            zoom: this.props.logInUser.zoom,
            lat: this.props.logInUser.latitude,
            lng: this.props.logInUser.longitude
          })
        }
        if (
          this.props.logInUser &&
          this.props.logInUser.id &&
          this.props.trailers.length &&
          this.state.initFetch === false
        ) {
          this.fetchData(this.props)
        }
        let binderUniqueId = 0;
        if(this.props.deviceRelatedData && Object.values(this.props.deviceRelatedData).length) {
          const list = {};
          const ids =  this.props.trailers.map(d => { list[d.uniqueId] = d;
            if(d.id === parseInt(this.props.match.params.id)){
              binderUniqueId = d.uniqueId;
            }
            return d.uniqueId})
            const trailers = {};
            const bins={}
            const passengers={}
            Object.values(this.props.deviceRelatedData).map(d => {
              const trailerUniqueId = d.attributes && d.attributes.trailerUniqueId || 0
              const binUniqueId = d.attributes && d.attributes.binUniqueId || 0
              const passengerUniqueId = d.attributes && d.attributes.passengerUniqueId || 0
              if(ids.includes(trailerUniqueId)) {
              trailers[trailerUniqueId] = d;
              trailers[trailerUniqueId].binder = list[trailerUniqueId];
            }
              if(ids.includes(binUniqueId)) {
                bins[binUniqueId] = d;
                bins[binUniqueId].binder = list[binUniqueId];
            }
            if(ids.includes(passengerUniqueId)) {
              passengers[passengerUniqueId] = d;
              passengers[passengerUniqueId].binder = list[passengerUniqueId];
          }
            return null
          })
            let obj = {...trailers,...bins,...passengers}
            this.setState({allTrailerLocation: obj})
    
        }
        if(this.state.showItemDetail && this.props.deviceRelatedData && Object.values(this.props.deviceRelatedData).length && this.props.match && this.props.match.params && this.props.match.params.id) {
          const trailerLocation = Object.values(this.props.deviceRelatedData).find(({attributes}) => attributes && attributes.trailerUniqueId && parseInt(attributes.trailerUniqueId) === parseInt(binderUniqueId))
          const binLocation = Object.values(this.props.deviceRelatedData).find(({attributes}) => attributes && attributes.binUniqueId && parseInt(attributes.trailerUniqueId) === parseInt(binderUniqueId))
          const passengerLocation = Object.values(this.props.deviceRelatedData).find(({attributes}) => attributes && attributes.trailerUniqueId && parseInt(attributes.passengerUniqueId) === parseInt(binderUniqueId))
           if(trailerLocation && trailerLocation.latitude && trailerLocation.longitude) {
            this.setState({trailerLocation: trailerLocation || null}, () => {
                if(trailerLocation && this.state.tracking === true) {
                  this.map &&
                    this.map.setMaxZoom(16).fitBounds([[trailerLocation.latitude, trailerLocation.longitude]])
                    setTimeout(() => {
                      this.map && this.map.setMaxZoom(this.props.mapLayer.maxZoom)
                      this.setState({applied: true})
                    }, 100)
                }
              })
          }
        }
      }
    
      componentWillUnmount () {
        this.setState({
          selecteditem: '',
          isVisable: false,
          showItemDetail: false,
          activeOperation: '',
          trailerAddress: '',
          trailerLat: '',
          trailerLon: '',
          selecteditemId: '',
          trackersApiResponce: false,
          multiTrackers: '',
          initFetch: false,
          currentPage: 1,
          pagesize: 50,
          animCount: 0,
          allTrailerLocation: {},
          alltrailerLocationNext:[],
          newLocation:'',
          positionLatLong:'',
          linkedPositionHistory:'',
          unLinkedPositionHistory:'',
          linkedDevice:'',
          unLinkedDevice:'',
          itemPagination: {
            items: [],
            total: 0,
            currentPage: 0,
            currentDevice: this.props.deviceId,
            hasNext: true,
            searchText: ''
          }
        })
      }
    
      componentDidUpdate() {
        if(this.props.logInUser && this.props.logInUser.latitude && this.props.logInUser.longitude && this.state.assigned === false) {
          this.setState({assigned: true, lat: this.props.logInUser.latitude, lng: this.props.logInUser.longitude})
        }
      } 
    
      componentWillReceiveProps (n, s) {
        if (n.ServerSetting.zoom) {
          this.setState({
            zoom: n.ServerSetting.zoom,
            lat: n.ServerSetting.latitude,
            lng: n.ServerSetting.longitude
          })
        }
        if (n.logInUser.zoom) {
          this.setState({
            zoom: n.logInUser.zoom,
            lat: n.logInUser.latitude,
            lng: n.logInUser.longitude
          })
        }
        if (
          n.logInUser &&
          n.logInUser.id &&
          n.trailers.length &&
          isEqual(n.trailers,this.props.trailers) &&
          this.state.initFetch === false
        ) {
          this.setState(
            {
              initFetch: true
            },
            () => {
              this.fetchData(n)
            }
          )
        }
        if(isEqual(n.trailers,this.props.trailers) && n.trailers.length && this.props.trailers.length)
        {
          this.fetchData(n)
        }
        if(this.props.trackId !== n.trackId) {
          this.setState({animCount: 0}, () => {
           this.calculate(n);
          })
        }
        else {
          this.calculate(n);
        }

        // if(this.state.showItemDetail && n.deviceRelatedData && Object.values(n.deviceRelatedData).length && n.match && n.match.params && n.match.params.id) {
        //   const driverLocation = Object.values(n.deviceRelatedData).find(({binder, id}) => binder && binder.id === parseInt(n.match.params.id) && this.props.allComputedAttributes)// && this.props.allComputedAttributes.length && this.props.allComputedAttributes.includes(id))
        //   if(driverLocation && driverLocation.latitude && driverLocation.longitude) {
        //       this.setState({trailerLocation: driverLocation || null}, () => {
        //         if(driverLocation && n.trackId > 0) {
        //           this.map &&
        //             this.map.setMaxZoom(16).fitBounds([[driverLocation.latitude, driverLocation.longitude]])
        //             this.map && this.map.setMaxZoom(n.mapLayer.maxZoom)
        //               setTimeout(() => {
        //                 this.setState({applied: true, animCount: 1})
        //               }, 200)
        //         }
        //       })
        //   }
        // }

      }
    
      mapReference = el => {
        if (el) {
          this.map = el.leafletElement
        }
      }
      showResources = (type) => {
        this.setState({
          resourceList: type
        })
      }
      addResource = () =>{
        this.setState({
          isVisable: true,
          showItemDetail: false,
          activeOperation: 'addResource',
          selecteditem: '',
          driverAddress: '',
          driverLat: '',
          driverLon: ''
        })
      }
      onEditResource = (item) =>{
        this.setState({
          isVisable: true,
          showItemDetail: false,
          activeOperation: 'editResource',
          selecteditem: item,
          driverAddress: '',
          driverLat: '',
          driverLon: ''
        })
      }
      onLinkResource = (item) =>{
        this.setState({
          linkResource: true,
          selecteditem: item,
        },()=>{this.props.fetchNestedItems(item.id,1)})
      }
      onCloseResource = () =>{
        this.setState({
          linkResource: false,
        });
        this.onCloseModal();
      }
    
      fetchMoreItems = () => {
        this.fetchData(this.props)
      }
    
      searchItems = text => {
        this.setState(
          {
            searchText: text
          },
          () => {
            this.fetchData(this.props)
          }
        )
      }
    
      fetchData = (nextProps) => {
        let items = nextProps.trailers.filter(row =>
          (row.id + '' + row.uniqueId + '' + row.name)
          .toLowerCase()
          .includes((this.state.searchText || '').toLowerCase())
          )
          this.setState({
            itemPagination: {
            total: items.length,
            items
          }
        })
      }
    
      removedItem = (item) => {
          // fetch(`/api/binders/${item.id}`, {
          //   method: 'DELETE',
          //   headers: {
          //     Accept: 'application/json',
          //     'Content-Type': 'application/json'
          //   }
          // })
          instance({
            url: `/api/binders/${item.id}`,
            method: 'DELETE'
          })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
                .then(trailer => {
                  // if(trailer.status === 'success'){
                    this.props.dispatch(removeTrailer(item))
                    // this.onCloseModal()
                    this.fetchMoreItems()
                    this.setState({
                      isVisable: true,
                      showItemDetail: false,
                      activeOperation: '',
                      selecteditem: '',
                      trailerAddress: '',
                      onDeleteConfirmation: false,
                      trailerLat: '',
                      trailerLon: ''
                    })
                    //this.props.enqueueSnackbar(this.props.translate('driverIsDeleted'));
                    this.props.dispatch(
                      Notifications.success({
                        message: this.props.translate('trailerIsDeleted'),
                        autoDismiss: 10
                      })
                    )
            })
            .catch(error => {errorHandler(error, this.props.dispatch)})
      }
    
      editItem = item => {
        this.setState({showItemDetail: false}, () => {
          this.getMultiDevice(item.id)
          this.setState({
            // isVisable: true,
            isVisable: !this.state.isVisable,
            selecteditem: item,
            activeOperation: 'edit',
            trailerAddress: '',
            trailerLat: item.attributes.trailerLat || '',
            trailerLon: item.attributes.trailerLon || ''
          })
        })
      }
    
      addItem = () => {
        this.props.history.push('/binders')
        this.setState({
          isVisable: true,
          showItemDetail: false,
          selecteditem: '',
          activeOperation: 'add',
          trailerAddress: '',
          trailerLat: '',
          trailerLon: ''
        })
      }
    
      onCloseModal = () => {
        this.setState({
          isVisable: false,
          showItemDetail: false,
          selecteditem: '',
          activeOperation: '',
          onDeleteConfirmation: false
        })
        this.props.history.push('/binders')
      }
    
      selecteItem = item => {
        this.props.dispatch(setTrackId(0))
        this.setState({positionLatLong:null}, () => {
          this.setState({
            linkedPositionHistory:'',
            unLinkedPositionHistory:'',
            unLinkedDevice:'',
            linkedDevice:'',
            showItemDetail: true,
            isVisable: false,
            selecteditem: item,
            selecteditemId: item.id,
            activeOperation: 'details',
            trackersApiResponce: false,
            multiTrackers: '',
            // trailerLocation: null,
            animCount: 0,
            tracking: true,
            applied: false,
            onDeleteConfirmation: false
          },
          ()=>{
            console.log('check ***********')
            this.getMultiDevice(item.id)
            this.calculate(this.props)
          })
          if(item.linkPositionId || item.unlinkPositionId){
                const params = new URLSearchParams()
                params.append('id', item.linkPositionId)
                params.append('id', item.unlinkPositionId)
                instance({
                  url: `/api/positions`,
                  method: `GET`,
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  params:params
                })
                .then(response=>{
                  this.setState({
                    linkedPositionHistory:response[0],
                    unLinkedPositionHistory:response[1]
                    }, ()=>{
                      let findLinkedDevice = this.props.devices&&this.props.devices.find(item=> item.id===response[0]?.deviceId)
                      let findUnLinkedDevice = this.props.devices&&this.props.devices.find(item=> item.id === response[1]?.deviceId)
                      this.setState({
                        linkedDevice:findLinkedDevice?findLinkedDevice:'',
                        unLinkedDevice:findUnLinkedDevice?findUnLinkedDevice:''
                      })
                    })
                })
                .catch(error => {errorHandler(error, this.props.dispatch)})
           }
        })
      }
      
      checkZoom = () => {
        if(this.state.applied === true) {
          this.setState({ tracking: false, applied: false, animCount: 0 }, () => {
            this.props.dispatch(setTrackId(0))
          })
           this.calculate(this.props)
          // this.setState({allTrailerLocation: {}}, () => {
          // })
        }
      }
    
      // calculate = n => {
      //   if(n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      //     const list = {};
      //     const ids =  n.trailers.map(d => { list[d.uniqueId] = d;
      //       return d.uniqueId})
      //       const trailers = {};
      //       const bins={}
      //       const passengers={}
      //       let test = []
      //     Object.values(n.deviceRelatedData).map(d => {
      //      const trailerUniqueId = d.attributes && d.attributes.trailerUniqueId || 0
      //      const binUniqueId = d.attributes && d.attributes.binUniqueId || 0
      //      const passengerUniqueId = d.attributes && d.attributes.passengerUniqueId || 0
      //      if(ids.includes(trailerUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
      //           trailers[trailerUniqueId] = d; 
      //          trailers[trailerUniqueId].binder = list[trailerUniqueId];
      //         test.push(d)
      //     }

      //       if(ids.includes(binUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
      //         bins[binUniqueId] = d; 
      //         bins[binUniqueId].binder = list[binUniqueId];
      //         test.push(d)
      //     }
      //     if(ids.includes(passengerUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
      //         passengers[passengerUniqueId] = d; 
      //         passengers[passengerUniqueId].binder = list[passengerUniqueId];
      //         test.push(d)
      //     }
      //       return null
      //     })
      //     let obj = {...trailers,...bins,...passengers}
      //     let obj2 = [];
      //     obj2.push(trailers, bins,passengers)
      //       this.setState({allTrailerLocation: obj,allbindersLinkedDevices:test}) 
      //   }   
      //     if(this.state.selecteditem.type==='trailer'){
      //       res = this.state.allbindersLinkedDevices.find(o=> o.attributes.trailerUniqueId === this.state.selecteditem.uniqueId)
      //     }
      //     if(this.state.selecteditem.type==='bin'){
      //           res = this.state.allbindersLinkedDevices.find(o=> o.attributes.binUniqueId === this.state.selecteditem.uniqueId)
      //     }
      //     if(this.state.selecteditem.type==='passenger'){
      //           res = this.state.allbindersLinkedDevices.find(o=> o.attributes.passengerUniqueId === this.state.selecteditem.uniqueId)
      //     }
      //     if(!res){
      //       this.setState({newLocation:null})
      //     }
      //     else{
      //       if(this.state.showItemDetail && n.deviceRelatedData && Object.values(n.deviceRelatedData).length && n.match && n.match.params && n.match.params.id) {
      //         // const trailerLocation = Object.values(n.deviceRelatedData).find(({id, binder}) => binder && parseInt(binder.id) === parseInt(n.match.params.id) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(id))
      //         // const binLocation = Object.values(n.deviceRelatedData).find(({id, binder}) => binder && parseInt(binder.id) === parseInt(n.match.params.id) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(id))
      //         // const passengerLocation = Object.values(n.deviceRelatedData).find(({id, binder}) => binder && parseInt(binder.id) === parseInt(n.match.params.id) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(id))
      //         if(res && res.latitude && res.longitude) {
      //             this.setState({trailerLocation: res || null, newLocation:res|| null}, () => {
      //               if(res && this.state.tracking === true) {
      //                 this.map && this.map.setMaxZoom(16).fitBounds([[res.latitude, res.longitude]])
      //                   setTimeout(() => {
      //                     this.map && this.map.setMaxZoom(n.mapLayer.maxZoom)
      //                     this.setState({applied: true, animCount: 1})
      //                   }, 200)
      //               }
      //             })
      //         }
      //       }
      //     }
      //     this.setState({allTrailerLocation: drivers})
      // } 
      
  calculate = n => {
    if(n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      const list = {};
      const ids =  n.trailers.map(d => {list[d.uniqueId] = d;return d.uniqueId})
      
      const binders = {};
      let test = []
     Object.values(n.deviceRelatedData).map(d => {
      const trailerUniqueId = d.attributes && d.attributes.trailerUniqueId || 0
      const binUniqueId = d.attributes && d.attributes.binUniqueId || 0
      const passengerUniqueId = d.attributes && d.attributes.passengerUniqueId || 0
        // if(ids.includes(trailerUniqueId) && (this.props.allComputedAttributes && this.props.allComputedAttributes.length && this.props.allComputedAttributes.includes(d.id))) {
        //   binders[d.uniqueId] = d; 
        //   binders[d.uniqueId].binder = list[trailerUniqueId];
        //   if(d && d.attributes && d.attributes.trailerUniqueId) {
        //     binders[d.uniqueId].binder = n.trailers.find(t => {
        //       test.push(d)
        //       return parseInt(t.uniqueId) === parseInt(d.attributes.trailerUniqueId)
        //     })
        //   }
        // }
            if(ids.includes(trailerUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
                binders[trailerUniqueId] = d; 
                binders[trailerUniqueId].binder = list[trailerUniqueId];
                test.push(d)
          }
            if(ids.includes(binUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
                binders[binUniqueId] = d; 
                binders[binUniqueId].binder = list[binUniqueId];
                test.push(d)
          }
          if(ids.includes(passengerUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
              binders[passengerUniqueId] = d; 
              binders[passengerUniqueId].binder = list[passengerUniqueId];
              test.push(d)
          }
      //   if(ids.includes(binUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
      //     binders[d.uniqueId] = d; 
      //     binders[d.uniqueId].binder = list[binUniqueId];
      //     if(d && d.attributes && d.attributes.binderUniqueId) {
      //       binders[d.uniqueId].binder = n.trailers.find(t => {
      //         test.push(d)
      //         return parseInt(t.uniqueId) === parseInt(d.attributes.binUniqueId)
      //       })
      //     }
      //     // test.push(d)
      //   }
      // if(ids.includes(passengerUniqueId) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(d.id)) {
      //     binders[d.uniqueId] = d; 
      //     binders[d.uniqueId].binder = list[passengerUniqueId];
      //     if(d && d.attributes && d.attributes.passengerUniqueId) {
      //       binders[d.uniqueId].binder = n.trailers.find(t => {
      //         test.push(d)
      //         return parseInt(t.uniqueId) === parseInt(d.attributes.passengerUniqueId)
      //       })
      //     }
      //     // test.push(d)
      //   }


        return null
      })
      if(this.state.selecteditem.type==='trailer'){
        res = this.state.allbindersLinkedDevices.find(o=> o.attributes.trailerUniqueId === this.state.selecteditem.uniqueId)
      }
      if(this.state.selecteditem.type==='bin'){
            res = this.state.allbindersLinkedDevices.find(o=> o.attributes.binUniqueId === this.state.selecteditem.uniqueId)
      }
      if(this.state.selecteditem.type==='passenger'){
            res = this.state.allbindersLinkedDevices.find(o=> o.attributes.passengerUniqueId === this.state.selecteditem.uniqueId)
      }
      if(!res){
         this.setState({newLocation:null})
      }
      else{
       if(this.state.showItemDetail && n.deviceRelatedData && Object.values(n.deviceRelatedData).length && n.match && n.match.params && n.match.params.id) {
          const trailerLocation = Object.values(n.deviceRelatedData).find(({id, binder}) => binder && parseInt(binder.id) === parseInt(n.match.params.id) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(id))
          const binLocation = Object.values(n.deviceRelatedData).find(({id, binder}) => binder && parseInt(binder.id) === parseInt(n.match.params.id) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(id))
          const passengerLocation = Object.values(n.deviceRelatedData).find(({id, binder}) => binder && parseInt(binder.id) === parseInt(n.match.params.id) && n.allComputedAttributes && n.allComputedAttributes.length && n.allComputedAttributes.includes(id))
          if(res && res.latitude && res.longitude) {
              this.setState({trailerLocation: res || null, newLocation:res|| null}, () => {
                if(res && this.state.tracking === true) {
                  this.map && this.map.setMaxZoom(16).fitBounds([[res.latitude, res.longitude]])
                    setTimeout(() => {
                      this.map && this.map.setMaxZoom(n.mapLayer.maxZoom)
                      this.setState({applied: true, animCount: 1})
                    }, 200)
                }
              })
          }
        }
      }
       this.setState({allbindersLinkedDevices:test})
      // this.setState({allTrailerLocation: binders,allbindersLinkedDevices:test})

    }
  } 


    
      getPostion = (address, latlng) => {
        if (address) {
          this.setState({
            trailerAddress: address,
            trailerLat: latlng.lat,
            trailerLon: latlng.lng
          })
        }
      }
    
      getMultiDevice = binderUniqueId => {
         if (binderUniqueId) {
          if(this.map && this.props.deviceRelatedData && Object.values(this.props.deviceRelatedData).length) {
             const trailerLoc = Object.values(this.props.deviceRelatedData).find(({binder, id}) => binder && binder.id && binder.id === binderUniqueId)
            if(trailerLoc) {
              this.setState({trailerLocation: trailerLoc, animCount: 0},()=>{
               })          
              this.props.dispatch(setTrackId(trailerLoc.id));
            }
            else {
              this.props.dispatch(setTrackId(0));
              this.map.setZoom(3);
            }
          }
          this.setState({
            multiTrackers: [],
            trackersApiResponce: true
          })
        }
      } 

      getPositionLatLong =(id)=>{
        if(id){
          instance({
            url: `/api/positions`,
            method: `GET`,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            params:{
              id: id
            }
          })
          .then(response=>{
            this.setState({positionLatLong:response[0]})
          })
          .catch(error => {errorHandler(error, this.props.dispatch)})
        }
      }
    
      onCancel = () => {
        this.setState({
          onDeleteConfirmation: false
        })
      }
    
      onRemovedItem = item => {
        this.setState({
          selecteditem: item,
          onDeleteConfirmation: true
        })
      }

      showOnMap= (el)=>{
        this.mapContextMenu(el)
      }

      mapContextMenu = (e) => {
        let LatLng = { 
                lat:e.latitude,
                lng:e.longitude
        }
        this.setState({ contextMarker: { position: LatLng, address: '' } })
        this.getContextMenuInfo(e);
      }

      getContextMenuInfo = (e, column, ref) => {
        fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${e.latitude}&lon=${e.longitude}&zoom=18&addressdetails=1`
        )
        .then(response => {
          if (response.ok) {
            response.json()
            .then(address => {
              this.setState({
                contextMarker: {
                  ...this.state.contextMarker&&this.state.contextMarker,
                  address: address&&address.display_name,
                  obj: address&&address.display_name
                }
              })
            })
          } else{
            throw response
          }
        }).catch(error => {
          // errorHandler(error, this.props.dispatch)
        })
      }
      
      markerClose = () =>{
        this.setState({ contextMarker: {} })
      }

      openPopup = marker => {
        if (marker && marker.leafletElement) {
          window.setTimeout(() => {
            marker.leafletElement.openPopup()
          })
        } else if (marker && marker.latlng) {
          marker.target.openPopup()
        }
      }

      render () {
      //   let iconSettings = {
      //     mapIconUrl: '<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149 178"><path fill="{mapIconColor}" stroke="#FFF" stroke-width="6" stroke-miterlimit="10" d="M126 23l-6-6A69 69 0 0 0 74 1a69 69 0 0 0-51 22A70 70 0 0 0 1 74c0 21 7 38 22 52l43 47c6 6 11 6 16 0l48-51c12-13 18-29 18-48 0-20-8-37-22-51z"/><circle fill="{mapIconColorInnerCircle}" cx="74" cy="75" r="61"/><circle fill="#FFF" cx="74" cy="75" r="{pinInnerCircleRadius}"/></svg>',
      //     mapIconColor: '#cc756b',
      //     mapIconColorInnerCircle: '#fff',
      //     pinInnerCircleRadius: 48
      // }
        const contextMarker = this.state.contextMarker&&this.state.contextMarker.position ? (
        <Marker
          ref={this.openPopup}
          onMove={this.openPopup}
          key={1222}
          position={this.state.contextMarker.position}
          className='ref-marker'
          iconSize={[25, 41]}
          icon={L.icon({
            iconUrl: '/assets/images/location-pin.svg',
            iconSize: [50, 50],
            iconAnchor: [25, 50],
            popupAnchor: [0, -50]
          })}
        >
          <Popup>
          <div className='position-box'>
              <strong>Last Location
              <CloseIcon
                size="small"
                className={'feature-close-button'}
                onClick={this.markerClose}
                style={{top: 0, right: 5}}
              />
              </strong>
              <div className='position-box-body'>
              
              <b>Address:</b>
              {this.state.contextMarker.address ? (
                this.state.contextMarker.address
              ) : (
                <span
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'top',
                    marginTop: -5,
                    marginBottom: -5,
                    color: this.props.themecolors.textColor
                  }}
                >
                  Loading...
                </span>
              )}<br /> 
              <b>Coordinates:</b> {this.state.contextMarker?.position?.lat?.toFixed(8) || ''},
                {this.state.contextMarker?.position?.lng?.toFixed(8) || ''} <br /> <br />
              </div>
            </div>
          </Popup>
        </Marker>
      ) : null

        let crs = {}
          if (['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id)) {
            crs = { crs: L.CRS.EPSG3395 }
          }
          const body = [
            <>
              <ZoomControl 
              position={'bottomright'}
               />
               {contextMarker}
            </>
          ]
    
          const position = [this.state.lat, this.state.lng]
        const thisMap = [
          <Map
            key={1}
            ref={this.mapReference}
            onZoomAnim={this.checkZoom}
            zoomControl={false}
            bounds={
              this.state.bounds && this.state.bounds.length
                ? this.state.bounds
                : null
            }
            boundsOptions={this.setBoundOptions}
            style={{ height: this.props.height, width: this.props.width }}
            center={position}
            zoom={this.state.zoom}
            minZoom={this.state.minZoom}
            maxZoom={this.props.mapLayer.maxZoom}
            maxNativeZoom={this.props.mapLayer.maxZoom}
            onContextMenu={this.mapContextMenu}
            {...crs}
          >
            {this.state.pointer}
            {body}
            {this.state.allTrailerLocation && Object.keys(this.state.allTrailerLocation).length ? 
            Object.values(this.state.allTrailerLocation).map(row=> <CustomMarker
                key={row.id}
                position={{ lat: row.latitude, lng: row.longitude, updated: moment(row.serverTime) }}
                rotationAngle={0}
                rotationOrigin='center'
                animationTime={this.state.animCount > 0 && this.state.applied === true & this.props.trackId === row.id ? row.animationTime : 0}
                icon={L.divIcon({
                  iconUrl:
                    '/assets/category/default/' +
                    (row.category || 'default') +
                    'top.svg',
                  iconSize: [50, 50],
                  iconAnchor: [25, 25],
                  tooltipAnchor: [0, -20],
                  className: 'custom-marker',
                  html: `<img
                    style="transform: rotate(${row.course}deg)"
                      src=
                        '/assets/category/default/${row.category ||
                          'default'}top.svg'
                      
                      alt=''
                    />`
                })}
                iconSize={[50, 50]}
                >
                  <Tooltip direction={'top'}>
                    <MapTooltip
                      themecolors={this.props.themecolors}
                      position={row}
                      device={row}
                      trailer={row.trailer}
                      trailer2={this.state.selecteditem}
                      logInUser={this.props.logInUser}
                      translate={this.props.translate}
                    />
                  </Tooltip>
                </CustomMarker>) : null}
            <LayersControl position="bottomright">
              {MapTileLayers.map(layer => <LayersControl.BaseLayer id={layer.id} checked={layer.id === this.props.mapLayer.id} key={layer.id} name={layer.name}>
                <TileLayer {...layer} minZoom={this.state.minZoom} />    
              </LayersControl.BaseLayer>)}
            </LayersControl> 
          </Map>
        ]
    
    
        return (
          <div>
            <Layout
              {...this.props}
              addTrailer={this.addItem}
              editItem={this.editItem}
              removedItem={this.onRemovedItem}
              selecteItem={this.selecteItem}
              fetchMoreItems={this.fetchMoreItems}
              classFromChildren={!this.state.isVisable ? 'no-padding' : 'has-padding'}
              itemPagination={{ ...this.state.itemPagination }}
              searchItems={this.searchItems}
              allTrailerLocation={this.state.allTrailerLocation}
              showResources={this.showResources}
              onEditResource={this.onEditResource}
              onLinkResource={this.onLinkResource}
              addResource={this.addResource}
              resourceList={this.state.resourceList}
            >
              <Style>{`
                .leaflet-control-layers-toggle {
                    background: url('/assets/images/maps/layers.png') no-repeat center;
                  } 
              `}</Style>
              {!this.state.isVisable ? <div>
                {['osm', ''].includes(this.props.mapLayer.id) ? thisMap : null}
                {['carto'].includes(this.props.mapLayer.id) ? thisMap : null}
                {['gccStreet'].includes(this.props.mapLayer.id) ? thisMap : null}
                {['googleTerrain'].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {['googleSatellite'].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {['googleHybrid'].includes(this.props.mapLayer.id) ? thisMap : null}
                {['googleRoad'].includes(this.props.mapLayer.id) ? thisMap : null}
                {['baidu'].includes(this.props.mapLayer.id) ? thisMap : null}
                {['yandexMap', 'yandexSat'].includes(this.props.mapLayer.id) ? thisMap : null}
                </div> : null}
                {this.state.showItemDetail && (
                  <TrailerModal
                    onCloseModal={this.onCloseModal}
                    selecteditem={this.state.selecteditem}
                    showItemDetail={this.state.showItemDetail}
                    devicesIcons={images}
                    trackersApiResponce={this.state.trackersApiResponce}
                    multiTrackers={this.state.multiTrackers}
                    currentLocation={this.state.trailerLocation}
                    linkedPositionHistory={this.state.linkedPositionHistory}
                    unLinkedPositionHistory={this.state.unLinkedPositionHistory}
                    linkedDevice={this.state.linkedDevice}
                    unLinkedDevice={this.state.unLinkedDevice}
                    devices={this.props.devices}
                    resourceList={this.state.resourceList}
                    showOnMap={this.showOnMap}
                  />
                )}
                <ResourceModal
                  changeResource={this.props.changeResource}
                  selectedResourse={this.state.selecteditem}
                  activeOperation={this.state.activeOperation}
                  onCloseResource={this.onCloseResource}
                  itemPagination={this.state.itemPagination&&this.state.itemPagination.items}
                  assignItem={this.props.assignItem}
                  unassignItem={this.props.unassignItem}
                  fetchNestedItems={this.props.fetchNestedItems}
                  nestedResources={this.props.nestedResources}
                  translate={this.props.translate}
                  linkResource={this.state.linkResource}
                  themecolors={this.props.themecolors}
                  itemType='Binder'
                  title='binder'
                />
              {!this.state.showItemDetail ? <div className='main-content-page'>
              
              {this.state.isVisable && ['add', 'edit'].includes(this.state.activeOperation) && (
                <Grid
                  container
                  spacing={0}
                  className='driver-page-content'
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor
                  }}
                >
                  <Grid item xs={12} md={7}>
                    <TrailerModal
                      onCloseModal={this.onCloseModal}
                      activeOperation={this.state.activeOperation}
                      selecteditem={this.state.selecteditem}
                      selectedAddress={this.state.trailerAddress}
                      trailerLat={this.state.trailerLat}
                      trailerLon={this.state.trailerLon}
                      trackersApiResponce={this.state.trackersApiResponce}
                      multiTrackers={this.state.multiTrackers}
                      resourceList={this.state.resourceList}
                      getMultiDevice={this.getMultiDevice}
                      fetchMoreItems={this.fetchMoreItems}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <MarkerMap
                      showMarker={true}
                      getPostion={this.getPostion}
                      zoom={0}
                      lat={this.state.trailerLat || 0}
                      lng={this.state.trailerLon || 0}
                    />
                  </Grid>
                </Grid>
                )}
              </div> : null}

                {this.state.onDeleteConfirmation && (
                  <ConfirmDialoag
                    onCancel={this.onCancel}
                    onOk={() => this.removedItem(this.state.selecteditem)}
                    title={this.props.translate('areYouWantToDelete')}
                    children={this.state.selecteditem.name}
                  ></ConfirmDialoag>
                )}
            </Layout>
          </div>
        )
      }
}

const mapStateToProps = state => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({item, deviceIds}) => {
    if((deviceIds) && (item.attribute  === 'trailerUniqueId' || item.attribute  === 'binUniqueId' || item.attribute  === 'passengerUniqueId') ) {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null
  })
  return {
  ServerSetting: state.ServerSetting,
  // drivers: state.drivers,
  trailers: state.trailers,
  isTrailerLoad: state.isTrailerLoad,
  devices: state.devices,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  deviceRelatedData: state.deviceRelatedData,
  allComputedAttributes: driverIdsList,
  trackId: state.trackId,
  mapLayer: state.mapLayer,
  devices:state.devices2
}}

  export default connect(mapStateToProps)(withLocalize(withResources(Trailer, 'Binder')))