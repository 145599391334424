import React, { Component} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { withLocalize } from 'react-localize-redux'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { checkPrivileges, errorHandler, selectAll,setAttributeFormat , getDateTimeFormat} from '../../Helpers'
import Layout from './../../Layout'
import Button from '../../Components/common/Button'
import Notifications from 'react-notification-system-redux'
import EmptyState from '../../Components/common/EmptyState'
import Dialog from '../../Components/common/Dialog'
import { getMaintenance, addMaintenance, removedMaintenance } from '../../Actions/Maintenance'
import Strings from './../../lang/en.json'
import ItemsSelector from '../Units/Components/itemsSelector'
import { ReactComponent as MaintenanceIcon } from './../../assets/nav/spanner.svg'
import { removedUserDeviceWiseSelectedList, userDeviceWiseSelectedList } from '../../Actions/Notifications'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { removeUser } from '../../Actions/Users'
import moment from 'moment'
import { removeDevice } from '../../Actions/Devices'
import CustomDialog from '../../Components/common/Dialog'
import { CreateMaintenanceForm } from './CreateMaintenanceForm'
import { MaintenanceForm } from './MaintenanceForm'
import { UpdateMaintenanceHistoryForm } from './UpdateMaintenanceHistoryForm'
import { MaintenanceComp } from './MaintenanceComp'
import { MaintenanceHistoryForm } from './MaintenanceHistoryForm'
import { CreateExpense } from './CreateExpense'
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Tooltip ,Grid} from '@material-ui/core'
import { SingleItem } from '../../Hooks/useSingleItem'
import { store } from '../../index'
import { Provider } from 'react-redux'
import instance from '../../axios'
import { addDays, toDate } from 'date-fns'
import { ServerSetting } from '../../Reducers/ServerSetting'
import Table from '../../Components/common/TableWithColors'
import Axios from 'axios'

const CancelToken = axios.CancelToken
let source
let tempFile = [];
const servicesAttributes = []
let driversList = []
// let garageList = []
let vehiclesList = []
let subTypes = []
let allSubTypes = []
let emptyLink = null

let expenseType = []
let allExpenseTypes = []
// let areaList = []
const statusTypes = [
  {
    key: 'completed',
    name: 'completed',
  },
  {
    key: 'required',
    name: 'required',
  },
]
const date = new Date()
const defaultExpenseForm = {
      name: '',
      description: '',
      odometer: '',
      serviceTime:new Date(),
      expireTime:addDays(date,7),
      quantity: '',
      cost: '',
      address: '',
      coordinates:'',
      service:'Service',
      type: '',
      driver:'Driver',
      vehicles:'',
      device:'',
	    status:'required',
      files:'',
      engineHours:'',
      selectedDateTime:'',
      currentEngineHours:0,
      currentOdometer:0,
      showStats:false,
      vehicleId:[],
      selectedvehicleId:[],
      // areaId:[],
      // selectedareaId:[],
      fromDate:'',
      loadingSidebar:false,
      toDate:'',
      attributes:{
          updateMaintenance: false,
          mileage: 0,
          hours: 0,
          date: 0,
          status: true, 
          mailTo: "", 
          email: []
      }

}
const DataFormat = (data,totalCost,totalQuantity,serviceData) => {
  return {
      service: {
          id: data && data.id ? data.id : 0,  
          updatedBy: data && data.updatedBy ? data.updatedBy : '',
          status: data && data.status ? data.status :'completed',
          name: data && data.name ? data.name : '',
          description: data && data.description ? data.description : null,
          odometer: data && data.odometer ? parseFloat(data.odometer)*1000 : '',
          // typeId: data && data.type && data.type.id ? data.type.id : '3',
          quantity: totalQuantity ? totalQuantity : '',
          cost: totalCost ? totalCost : '',
          address:data && data.address ? data.address : null,
          coordinate: data && data.coordinates ? data.coordinates : '30.00,50.00',
          vehicleId: data && data.vehicles && data.vehicles.id ? parseInt(data.vehicles.id) : '',
          // deviceId: data && data.device && data.device.id ? parseInt(data.device.id) : '',
          deviceId: data && data.deviceId || '',
          driverUniqueId:data && data.driver && data.driver.uniqueId ? data.driver.uniqueId : null,
          // garageId:data && data.garage && data.garage.id ? data.garage.id : null,
          maintenanceId: data && data.maintenanceId ? data.maintenanceId : '',
          files: data.files,
          createdTime: data && data.createdTime ? data.createdTime : '',
          serviceTime: data && data.serviceTime ? data.serviceTime : '',
          expireTime: data && data.expireTime ? data.expireTime : '',
          userId: data && data.userId ? data.userId : 1,
          engineHours: data && data.engineHours ? parseInt(data.engineHours)*(1000*3600) : 0,
          tag1: data && data.tag1 ? data.tag1 : '',
          tag2: data && data.tag2 ? data.tag2 : '',
          tag3: data && data.tag3 ? data.tag3 : '',
          refNum: data && data.refNum ? data.refNum : '',
          refdate: data && data.refdate ? data.refdate : '',
          // areaId: data && data.areaId && data.areaId.id ? data.areaId.id : data.areaId && data.areaId.key ? data.areaId.key: '',
          attributes: data && data.attributes ? {...data.attributes} : {}
      },
      serviceExpenseCollection:[...serviceData]
  }
}


class Maintenance extends Component {
  constructor (props) {
    super(props)
    this.state = {
      maintenanceForm: {},
      maintenanceHistForm: {},
      historyId: '',
      totalCount: 0,
      mode: 'due',
      tab:'due',
      isVisible: true,
      // histFormVisible: false,
      onDeleteConfirm: false,
      onDeleteExpense: false,
      analyticData: '',
      anchorEl: null,
      countChecked: 2,
      statusFilter : {required:true,scheduled:true},
      filterStatusType: [],
      filterType: 'due',
      imageFile: '',
      enableImagePopup: false,
      selectMaintenanceId: '',
      itemSearch: '',
      isDataRecieved: false,
      loader: false,
      model: false,
      assignMode: '',
      currentMaintenance: '',
      entityId: 0,
      importedData: [],
      image64Type: [],
      showColorTable:false,
      initFetch: false,
      currentPage: 1,
      currentPageHist: 1,
      pagesize: 20,
      pagesizeHist: 20,
      searchVehicleId: {id:'', label: this.props.translate('searchVehicles')},
      // searchAreaId: {id:'', label: this.props.translate('searchAreas')},
      selectedVehicleId: '',
      // selectedAreaId: '',
      selectedDate: '',
      statusMode: false,
      completeMode: false,
      showDueDialog:false,
      showDialogBtn:false,
      vehicleDueMaintenance:[],
      historyEdit:false,
      disabledSidebarBtn:true,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
      },
      itemsPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
      },
      deleteForm: {},
      expenseForm: {
       ...defaultExpenseForm
      },
      serviceExpenseCollection:[{quantity:'1',cost:'1', expenseSubTypeId:'', expenseTypeId:'', selectedSubTypes:[]}],
      resultMessage: 'No Items',
      showDueMaintenancePopUp:false,
      sideBarStatus:'',
    }
  }

  componentWillUnmount () {
    this.setState({
      maintenanceForm: {},
      mode: '',
      model: false,
      assignMode: '',
      currentMaintenance: '',
      entityId: 0,
      initFetch: false,
      currentPage: 1,
      pagesize: 20,
      importedData: [],
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
      }
    })
  }

  componentWillMount () {
    if (this.props.location.pathname === '/maintenanceCreate') {
          this.setState({ mode: 'create' })
     }
     if (this.props.location.pathname.indexOf('/maintenanceUpdate') !== -1) {
            this.openEditMaintenanceForm()
            this.getImage()
       }
    
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPage,
            this.state.pagesize
          )
        },
        // this.fetchMaintenanceHistory(
        //   this.state.currentPageHist,
        //   this.state.pagesizeHist
        // )
      )
      // this.getGarageList()
    }
    this.maintenanceAnalytic()
    this.getExpenseTypes()
    // if (checkPrivileges('area')) this.getAreaList()

  }

  getAreaList =() =>{
    instance({
      url: `/api/areas/get?&page=1&limit=10&search=`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
        .then(res => {
          // areaList = (res.data).map(item =>{ 
          //   return (
          //      {
          //       key:item.id,
          //       name:item.name,
          //       label:item.name,
          //       uniqueId:item.id,
          //       valueType:'string'
          //     }
          //    )
          // })
 
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  // getGarageList =() =>{
  //   if(this.props.logInUser&&this.props.logInUser.id){
  //       instance({
  //         url: `/api/garages/get?userId=${this.props.logInUser.id}&page=1&limit=50&all=true&search=`,
  //         method: 'GET',
  //         headers: {
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json'
  //         },
  //       })
  //           .then(res => {
  //             garageList = (res.data).map(item =>{ 
  //               return (
  //                   {
  //                   id:item.id,
  //                   key:item.id,
  //                   name:item.name,
  //                   label:item.name,
  //                   uniqueId:item.id,
  //                   valueType:'string'
  //                 }
  //               )
  //             })
    
  //       }).catch(error => {
  //         // errorHandler(error, this.props.dispatch)
  //       })
  //   }
  // }


  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          // this.fetchData(
          //   n.logInUser,
          //   this.state.currentPage,
          //   this.state.pagesize
          // )
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          )
          // this.getGarageList()
        }
        // ,
        //   this.fetchMaintenanceHistory(
        //   this.state.currentPageHist,
        //   this.state.pagesizeHist
        // )
      )
    }
  }

  openEditMaintenanceForm = () =>{
    let { maintenance } = this.props
    maintenance.map(item => {
      if (parseInt(this.props.match.params.id) === item.id) {
        this.editMaintenanceForm([item])
      }
    })
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    )
  }
  fetchMoreItemsHist = (a, b, c) => {
    this.fetchMaintenanceHistory(
      this.state.currentPageHist,
      this.state.pagesizeHist
    )
  }
  getImage = async (id) => {
    try {
      
      const response = await Axios.get(`/api/media/maintenance/${id}`, {
        responseType: 'arraybuffer', // Ensure the response is treated as an array buffer
      });
      const filename = id;
      const parts = filename.split('.');
      const extension = parts[parts.length - 1];
      
      
      if (response.status === 200) {
        // Convert the array buffer to base64
        console.log("response.data=======", response);
        const base64Image = Buffer.from(response.data, 'binary').toString('base64');

        return `data:image/${extension};base64,${base64Image}`;
      } else {
        console.error('Failed to fetch image:', response.status, response.statusText);
        return '';
      }
    } catch (error) {
      console.error('Error in getImage:', error);
      return '';
    }
  };

  fetchData = (logInUser, page, perPage, reset) => {
    source = CancelToken.source()
    let items = this.state.itemPagination&&this.state.itemPagination.items
    if (reset) {
      items = []
    }
    instance({
      method: 'GET',
      url: `/api/maintenance/get?userId=${logInUser.id}&page=${page}&limit=${perPage}&all=${true}&search=${this.state.itemSearch}`,
      cancelToken: source.token
    })
    .then(response => {
      console.log("Respo=====", response);
      // if(response && response.status === 200) {
          this.setState(
            {
              itemPagination: {
                ...response,
                items: items.concat(response&&response.data)
              },
              currentPage: response.hasNext ? response.page + 1 : response.page,
              isDataRecieved: true
            },
            () => {
              this.props.dispatch(getMaintenance(items.concat(response&&response.data)))
              
            }
          )
            if(this.state.itemSearch === ''){
              this.setState({
                savedData: {
                  ...response,
                  items: items.concat(response&&response.data)
                }
              })
            }
      // }
    }).catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  }

  fetchMaintenanceHistory = (page,perPage) =>{
    let searchVehicleid=this.state.searchVehicleId&&this.state.searchVehicleId.id?`&vehicleId=${this.state.searchVehicleId.id}`:''

    let url = this.state.vehicleId && this.state.vehicleId.length ? `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.selectedVehicleId}${this.state.filterStatusType}&from=${this.state?.fromDate ||''}&to=${this.state?.toDate||''}` 
    : (this.state.searchVehicleId&&this.state.searchVehicleId.id)  ? `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${searchVehicleid}${this.state.filterStatusType}`
    : `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.filterStatusType} `
    source = CancelToken.source()
    let items = this.state.itemsPagination.items
    instance({
      method: 'GET',
      url: url,
      cancelToken: source.token
    })
    .then(response => {
          this.setState({
            itemsPagination: {
              ...response,
              items: items.concat(response && response.data)
            },
            currentPageHist: response.hasNext ? response.page + 1 : response.page,
            isDataRecieved: true,
            loadingSidebar:false
          })
      //   }
      // }
    }).catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  }

  fetchMaintenanceHistory2 = (page,perPage) =>{
    let url = this.state.vehicleId && this.state.vehicleId.length ?  `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.selectedVehicleId}${this.state.filterStatusType}&from=${this.state?.fromDate ||''}&to=${this.state?.toDate||''}`
    : `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.filterStatusType}&from=${this.state?.fromDate ||''}&to=${this.state?.toDate||''}`
    source = CancelToken.source()
    let items = this.state.itemsPagination.items
    instance({
      method: 'GET',
      url: url,
      cancelToken: source.token
    })
    .then(response => {
          this.setState({
            itemsPagination: {
              ...response,
              items: items.concat(response && response.data)
            },
            currentPageHist: response.hasNext ? response.page + 1 : response.page,
            isDataRecieved: true,
            loadingSidebar:false
          })
      //   }
      // }
    }).catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  }
  assignModal = (assignMode, currentMaintenance) => {
    this.props.dispatch(userDeviceWiseSelectedList([]))
    this.setState({
      assignMode,
      currentMaintenance,
      selectEntity: '',
      model: true
    }, () => {
      if (checkPrivileges('user') && assignMode === 'user') {
        // fetch(`/api/users/access?maintenanceId=${currentMaintenance.id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/users/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            maintenanceId: currentMaintenance.id
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(users => {
              let allData = this.props.users
              if (users.length) {
                allData.map(item => {
                  item.check = false;
                  users.map(main => {

                    if (item.id && main.userId && item.id === main.userId) {
                      // for parent
                      item.check = true
                      
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }

              this.props.dispatch(userDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error,this.props.dispatch)
        })
      }
      if (checkPrivileges('device') && assignMode === 'unit') {
        // fetch(`/api/devices/access?maintenanceId=${currentMaintenance.id}`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/devices/access`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params: {
            maintenanceId: currentMaintenance.id
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(devices => {
              let allData = this.props.devices3
              if (devices.length) {
                allData.map(item => {
                  item.check = false;
                  devices.map(main => {

                    if (item.id && main.deviceId && item.id === main.deviceId) {
                      // for parent
                      item.check = true
                      
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData))
          //   })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {
          // errorHandler(error,this.props.dispatch)
        })
      }
      /* if (checkPrivileges('group') && assignMode === 'group') {
        fetch(`/api/groups/access?maintenanceId=${currentMaintenance.id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (response.ok) {
            response.json().then(groups => {
              let allData = this.props.groups
              if (groups.length) {
                allData.map(item => {
                  item.check = false;
                  groups.map(main => {

                    if (item.id && main.groupId && item.id === main.groupId) {
                      // for parent
                      item.check = true
                      
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData))
            })
          }
        })
      } */
    })
  }

  selectEntity = e => {
    this.setState({
      selectEntity: e
    })
  }

  submitAssignModel = () => {
    if (this.state.assignMode && this.state.assignMode === 'unit') {
      let obj = {
        deviceId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id
      }
      this.assignMaintenance(obj)
    } else if (this.state.assignMode && this.state.assignMode === 'user') {
      let obj = {
        userId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id
      }
      this.assignMaintenance(obj)
    } /* else if (this.state.assignMode && this.state.assignMode === 'group') {
      let obj = {
        groupId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id
      }
      this.assignMaintenance(obj)
    } */
  }

  assignMaintenance = obj => {
    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions/`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.ok) {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('maintenanceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        // } else {
        //   throw response
        // }
      })
      .catch(error => {errorHandler(error,this.props.dispatch)})
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       if (err && err.includes('Duplicate entry')) {
      //         this.props.dispatch(
      //           Notifications.error({
      //             message: this.props.translate('alreadyAssigned'),
      //             autoDismiss: 10
      //           })
      //         )
      //       } else {
      //         this.props.dispatch(
      //           Notifications.error({
      //             message: this.props.translate('somthingWentWrongMessage'),
      //             autoDismiss: 10
      //           })
      //         )
      //       }
      //     })
      //   }
      // })
  }

  closeAssignModal = () => {
    this.setState({
      model: false,
      assignMode: '',
      commandTitle: '',
      currentMaintenance: ''
    })
  }

  openCreateFrom = () => {
    this.setState({ mode: '', maintenanceForm: {} }, () => {
      this.setState({
        mode: 'create',
        maintenanceForm: {
          attributes: { 
            mileage: 0,
            hours: 0,
            date: 0,
            status: true, 
            mailTo: "", 
            email: []
          }
        },
        selectedUnit:null
      })
    })
    
  }

  getExpenseTypes = ()  => {
    instance({
      method: 'GET',
      url: this.props.logInUser&&this.props.logInUser.id ? `/api/expensetypes?userId=${this.props.logInUser.id}&all=${true}` :  `/api/expensetypes` ,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
      // if(response && response.data && response.data.data){
        // this.setState({allSubTypes:response})
        response&&response.map(type => {
          servicesAttributes.push(
            {
              key: type.id,
              id: type.id,
              type: type.type,
              name: type.subType,
              subType: type.subType
            }
          )
        })
        let expenseTypes = response.map(item =>{
          return (
            {
              key:item.id,
              id:item.id,
              name:item.name,
              description:item.description,
              attributes:item.attributes,
              parentId:item.parentId,
              valueType:'string'
            }
         )
        })
        expenseType = expenseTypes
      // }
    })
    .catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  
  }

  createExpense = () => {
    this.getData('vehicles', 'id', 0, 'multi', (data)=>{
      vehiclesList = (data).map(item =>{ 
        return (
              {
                id:item.id,
                label:item.label,
                value: item.id,
                vehicleLP:item.vehicleLicensePlate,
                valueType:'string'
            }
        )
      })
    })
    this.getData('drivers', 'id', 0, 'multi', (data)=>{
      driversList = (data).map(item =>{ 
        return (
               {
                key:item.id,
                name:item.name,
                uniqueId:item.uniqueId,
                valueType:'string'
              }
         )
      })
    })
    this.setState({
      mode: 'createExpense',
      image64Type: [],
      loader: false,
      selectedVehicleId: '',
      // selectedAreaId:'',
      selectedDate: '',
      expenseForm: {
          ...defaultExpenseForm,
          status:this.state.mode==="due"?"required":"completed"
        },
        serviceExpenseCollection:[{quantity:'1',cost:'1', expenseSubTypeId:'', expenseTypeId:'', selectedSubTypes:[]}],
  })
  }

  closeExpense = () => {
    this.setState({
      mode:'showHist',
      historyEdit:false
    })
  }

  getSubTypes =(id)=>{
    let res =[]
    instance({
      method: 'GET',
      url: `api/expensetypes/subtype?expenseTypeId=${id}&search=`,  
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
        response&&response.map(item => {
           res.push(
            {
              key: item.id,
              id: item.id,
              expenseTypeId : item.expenseTypeId,
              name: item.label,
              label: item.label,
              parentId: item.parentId
            }
          )
        })
        subTypes = res
        this.setState({subTypes2:res})
      // }
    })
    .catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  
  }
  addDays = (date, days)=>{
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
  handleChangeExpense = (name, value)  => {
    // if(name === 'garage') {
    //   this.setState({
    //     expenseForm: {
    //       ...this.state.expenseForm,
    //       garageId: value
    //     }
    //   },()=>{this.checkRequiredFields3()})
    // }
    if(name === 'driver'){
      this.setState({
        expenseForm: {
          ...this.state.expenseForm,
          driver: value
        }
      },()=>{this.checkRequiredFields3()})
    }
    else if(value && value.name === 'devices'){
      this.setState({
        expenseForm: {
          ...this.state.expenseForm,
          device: name
        }
      },()=>{this.checkRequiredFields3()})
    }
    else if (name === 'vehicles'){
      // let findArea = this.props.vehicles.find(item => item.id === value.id);
      // let resArea = areaList.find(item => item.key === findArea.areaId)
      this.setState({
        selectedVehicleId: value.id,
        expenseForm: {
          ...this.state.expenseForm,
          vehicles: value,
          // areaId:resArea
        }
      },()=>{
        this.checkRequiredFields3()
        this.getVehiclesData(this.state.selectedVehicleId, this.state.mode, this.state.selectedDate)
        this.showDueMaintenance(this.state.selectedVehicleId)
        this.getDriverByVehicleId(this.state.selectedVehicleId)
      })
    }

    else if(name === 'type'){
        // if(parseInt(value.id) !== 0){
        //   subTypes = (allSubTypes && allSubTypes).filter(item => item.type ===  parseInt(value.id))
          // this.setState({
          //   serviceExpenseCollection: [{quantity:'',cost:'', expenseSubTypeId:'', expenseTypeId: value.id}]
          // })
        // } 
        // else {
        //   subTypes = allSubTypes
        // }
        // this.getSubTypes(value&&value.id)
        this.setState({
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value
          },
          //  serviceExpenseCollection: [{quantity:'',cost:'', expenseSubTypeId:'', expenseTypeId: value.id}]
        },()=>{this.checkRequiredFields3()})
    }
    else if (name === 'service'){
      this.setState({
        expenseForm: {
          ...this.state.expenseForm,
          [name]: value.name
        }
      },()=>{this.checkRequiredFields3()})
    }
	else if(name === 'status'){
		 this.setState({
			expenseForm: {
			  ...this.state.expenseForm,
			  [name]: value.target.value
			}
		},()=>{this.checkRequiredFields3()})
	}
  else if(name === 'files'){
    // file.push(this.state.expenseForm.files)
    tempFile.push(value)
    this.setState({
      image64Type : tempFile
			// expenseForm: {
			//   ...this.state.expenseForm,
			//   [name]: tempFile
			// }
		},()=>{this.checkRequiredFields3()})
  } 
  else if(name === 'serviceTime'){
    let exDate=this.addDays(value,7);
    let expireTimeCal = new Date(exDate.setUTCHours(0,0,0,0));
    let today = new Date()
    var calTodayTime = new Date(today.setUTCHours(0,0,0,0));
    var calTime = new Date(value.setUTCHours(0,0,0,0));
    var time = calTime.toISOString();
    var todayTime = calTodayTime.toISOString();
    var expireTimeConv= expireTimeCal.toISOString();
    let settime= false
    if(todayTime < time){
      settime=true
    }
    let passDate = settime ? todayTime : time
    this.setState({
      selectedDate: time,
			expenseForm: {
			  ...this.state.expenseForm,
        expireTime:expireTimeConv,
			  [name]: value
			}
		},()=>{
      this.checkRequiredFields3()
      this.getVehiclesData(this.state.selectedVehicleId,this.state.mode, passDate)
    })
  }
  else if(name === 'expireTime'){
    var calTime = new Date(value.setUTCHours(0,0,0,0));
    var time = calTime.toISOString();
    this.setState({
			expenseForm: {
			  ...this.state.expenseForm,
			  [name]: time,
			}
		},()=>{
      this.checkRequiredFields3()
      // this.getVehiclesData(this.state.selectedVehicleId,this.state.mode, passDate)
    })
  }
  else if(name === 'refdate'){
    this.setState({
			expenseForm: {
			  ...this.state.expenseForm,
			  [name]: value
			}
		},()=>{
      this.checkRequiredFields3()
    })
  }
  else if(name === 'updateMaintenance'){
    this.setState({
			expenseForm: {
			  ...this.state.expenseForm,
        attributes: {
          ...this.state.expenseForm.attributes,
          [name]: value
          }
			}
		},()=>{this.checkRequiredFields3()})
  }
  else if(name === 'sendAlert'){
    this.setState({
			expenseForm: {
			  ...this.state.expenseForm,
        attributes: {
          ...this.state.expenseForm.attributes,
          [name]: value
          }
			}
		},()=>{this.checkRequiredFields3()})
  }
  // else if (name === 'areaId'){
  //   this.setState({
  //     expenseForm: {
  //       ...this.state.expenseForm,
  //       areaId: value
  //     }
  //   },()=>{this.checkRequiredFields3()})
  // }
    else {
      this.setState({
        expenseForm: {
          ...this.state.expenseForm,
          [name]: value
        }
      },()=>{this.checkRequiredFields3()})
    }
  }

  handleChangeServices = (name, value, index) =>{	
    let rows = [...this.state.serviceExpenseCollection]
    if(name === 'expenseTypeId'){
      // this.getSubTypes(value&&value.id)
      let res =[]
        instance({
          method: 'GET',
          url: `api/expensetypes/subtype?expenseTypeId=${value&&value.id}&search=`,  
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then(response => {
            response&&response.map(item => {
              res.push(
                {
                  key: item.id,
                  id: item.id,
                  expenseTypeId : item.expenseTypeId,
                  name: item.label,
                  label: item.label,
                  parentId: item.parentId
                }
              )
            })
            rows[index].selectedSubTypes=res
            rows[index].expenseTypeId= value
            this.setState({serviceExpenseCollection:rows },()=>{this.checkRequiredFields3()})
        })
     
    }
    else{
      let keys = []
      rows[index][name] = value
      rows.map(i => {
        keys.push(i.x)
        return null
      })
      let sorted_arr = keys.slice().sort()
      let results = []
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
          results.push(sorted_arr[i])
        }
      }
      let a = []
      keys.map((item, index) => {
        results.map(w => {
          if (item === w) {
            a.push(index)
          }
          return null
        })
        return null
      })
      a.map(val => {
        rows[val] = { ...rows[val] }
        return null
      })
        this.setState({ serviceExpenseCollection:rows, error: a.length > 0 ? true : false },
         ()=>{this.checkRequiredFields3()})
      
    }
  }

  getVehiclesData = (id,check,date) => {
    let url = date ? `/api/vehicles/${id}/accumulator?date=${date}`: `/api/vehicles/${id}/accumulator`
    if(id){
      instance({
        method: 'GET',
        url: url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
            if(check === 'updateHist'){
              this.setState({
                expenseForm: {
                  ...this.state.expenseForm,
                  currentEngineHours: response.hours ? (response.hours/(1000*3600)).toFixed(2)+' h' : 0, 
                  currentOdometer: response.totalDistance ? setAttributeFormat('mileage', (response.totalDistance)) : 0 , 
                },
                // mode: check
              })
            }
            else{
              this.setState({
                expenseForm: {
                  ...this.state.expenseForm,
                  engineHours: response.hours ? (response.hours/(1000*3600)).toFixed(2) +' h' : 0, 
                  odometer: response.totalDistance ? setAttributeFormat('mileage', (response.totalDistance)) : 0, 
                },
              },()=>this.checkRequiredFields3())
            }
          // }
        // }
      })
      .catch(error => {
        // errorHandler(error,this.props.dispatch)
      })
      // .catch(e => {
      //     this.props.dispatch(
      //       Notifications.error({
      //         message: this.props.translate('invalidVehicle'),
      //         autoDismiss: 10
      //       })
      //     )
      // })
    }
    
  }


  checkRequiredFields3 = () => {
    let { name, odometer, serviceTime, vehicles, type , status} = this.state.expenseForm
    let { expenseSubTypeId, cost, quantity} = this.state.serviceExpenseCollection[0]
    if (name&&odometer&&serviceTime&&vehicles&&expenseSubTypeId&&quantity&&status) {
      this.setState({
       isVisible: false
      })
    } else {
      this.setState({
       isVisible: true
      })
    }
 }

  deleteRow = (row, index, e) => {
    let rows = [...this.state.serviceExpenseCollection]
    rows.splice(index, 1)
    this.setState({ serviceExpenseCollection : rows,
      isVisible: (rows[0].expenseSubTypeId&&rows[0].cost&&rows[0].quantity)?false:true }, () => {
      this.state.serviceExpenseCollection.map((row, index) => {
      this.handleChart2('quantity', row['quantity'], index)
      this.handleChart2('cost', row['cost'], index)
      this.handleChart2('expenseSubTypeId', row['expenseSubTypeId'], index)
      this.handleChart2('selectedSubTypes', row['selectedSubTypes'], index)
        return null
      })
    })
  }

  handleChart2 = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection]
    let keys = []
    rows[index][name] = value
    rows.map(i => {
      keys.push(i.x)
      return null
    })
    let sorted_arr = keys.slice().sort()
    let results = []
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i])
      }
    }
    let a = []
    keys.map((item, index) => {
      results.map(w => {
        if (item === w) {
          a.push(index)
        }
        return null
      })
      return null
    })
    rows.map(sal => {
      sal.e = false
      return null
    })
    a.map(val => {
      rows[val] = { ...rows[val], e: true }
      return null
    })
    this.setState({ serviceExpenseCollection:rows, error: a.length > 0 ? true : false })
  }

  submitExpense = () => {
    let  expenseSubTypeId = 0, totalQuantity =0, totalCost = 0, expenseType=0;
    let newFile1=[];
    const orderNo = 1 + Math.random() * (1000 - 1);
    const newFile = (this.state.serviceExpenseCollection&&this.state.serviceExpenseCollection).map((item) => {
      if(item.cost&&item.quantity&&item.expenseSubTypeId.id){
            totalCost = totalCost + parseInt(item.cost)
            totalQuantity = totalQuantity + parseInt(item.quantity) 
            expenseSubTypeId = item.expenseSubTypeId.id || ''
            expenseType = item.expenseTypeId.id || ''
            delete item.type
            delete item.e
            delete item.selectedSubTypes
            delete item.allExpenseTypes
            return { 
              ...item, serviceId: 0, expenseSubTypeId: expenseSubTypeId,expenseTypeId:expenseType };
          }
          })
          newFile.map(item => {
            if(item){
              newFile1.push(item)
            }
          })
          this.setState({serviceExpenseCollection: newFile1 }, ()=>{
            const obj = DataFormat(this.state.expenseForm, totalCost,totalQuantity ,this.state.serviceExpenseCollection)

            instance({
              url: `/api/services`,
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              data: {
                ...obj
              }
            })
            .then(response => {
                    this.props.dispatch(
                      Notifications.success({
                        message: this.props.translate('expenseCreated'),
                        autoDismiss: 10
                      })
                    )
                  this.setState({
                    mode: this.state.dueActive ? 'history':this.state.tab === 'showMaintenance'?'showMaintenance':'due',
                    completeMode: false,
                    itemSearch:'',
                    currentPageHist: 1,
                    expenseForm:{...defaultExpenseForm},
                    serviceExpenseCollection:[{quantity:'',cost:'', expenseSubTypeId:''}]
                  },()=>{
                    this.fetchMaintenanceHistory(this.state.currentPageHist, this.state.pagesizeHist)
                    this.fetchData(this.props.logInUser,this.state.currentPage,this.state.pagesize)
                  })
              })
              .catch(error => {
                errorHandler(error,this.props.dispatch)
                this.setState({
                      mode: this.state.dueActive ? 'due':'history',
                      itemSearch:'',
                      expenseForm:{...defaultExpenseForm},
                      serviceExpenseCollection:[{quantity:'',cost:'', expenseSubTypeId:'', expenseTypeId: ''}]
                  })		
                })
            });
            this.closeAllDueMaintenance()
  }

  ud_expenseSubmit = () =>{
        let  expenseSubTypeId = 0, totalQuantity =0, totalCost = 0, expenseTypeId=0;
        let newFile1=[];
        const newFile = (this.state.serviceExpenseCollection&&this.state.serviceExpenseCollection).map((item) => {
          if(item.cost&&item.quantity&&item.expenseSubTypeId.id){
              totalCost = totalCost + parseInt(item.cost)
              totalQuantity = totalQuantity + parseInt(item.quantity) 
              expenseSubTypeId = item.expenseSubTypeId.id || ''
              expenseTypeId = item.expenseTypeId.id || ''
              delete item.selectedSubTypes
              delete item.allExpenseTypes
              delete item.type
              delete item.e
              return { 
                ...item, serviceId: this.state.expenseForm.id, expenseSubTypeId: expenseSubTypeId ,expenseTypeId:expenseTypeId };
                }
          });
          newFile.map(item => {
            if(item){
              newFile1.push(item)
            }
          })
          this.setState({serviceExpenseCollection: newFile1 }, ()=>{
          const obj = DataFormat(this.state.expenseForm, totalCost , totalQuantity ,this.state.serviceExpenseCollection)
          instance({
            url: `/api/services/${parseInt(this.state.expenseForm.id)}`,
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data: {
              ...obj
            }
          })
              .then(res => {
                  this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('expenseUpdated'),
                    autoDismiss: 10
                  })
                  )
                this.setState({
                  // mode: this.state.dueActive ? 'due':'history',
                  mode: this.state.tab,
                  itemSearch:'',
                  loader:false,
                  currentPageHist: 1,
                  expenseForm:{...defaultExpenseForm},
                  serviceExpenseCollection:[{quantity:'',cost:'', expenseSubTypeId:'',expenseTypeId:''}]
                },()=>{
                  this.fetchMaintenanceHistory(this.state.currentPageHist, this.state.pagesizeHist)
                })

            })
            .catch(error => {
              errorHandler(error,this.props.dispatch)
              this.setState({
                    mode: this.state.dueActive ? 'due':'history',
                    itemSearch:'',
                    loader:false,
                    expenseForm:{...defaultExpenseForm},
                    serviceExpenseCollection:[{quantity:'',cost:'', expenseSubTypeId:'',expenseTypeId:''}]
                })		
              })
          })
          this.getExpenseTypes()
          this.closeAllDueMaintenance()
  }

  closeAllDueMaintenance =()=>{
      this.setState({
        showDueDialog:false,
        showDialogBtn:false,
        vehicleDueMaintenance:[]
      })
    }

  addMore = () => {
      let rows = []
        rows = [...this.state.serviceExpenseCollection]

        if (rows.length) {
          let last = { ...rows[rows.length - 1] }
          rows.push({expenseSubTypeId:'', quantity:'1', cost:'1', expenseTypeId: '', selectedSubTypes:[]})
        } else {
          let last = { ...rows[rows.length - 1] }
          rows.push(last)
        }
        this.setState({ serviceExpenseCollection:rows })
  }

  imagePopup = (files) => {
      this.setState({
        maintenanceHistForm:{
          ...this.state.maintenanceHistForm,
          files
        },
        enableImagePopup: true
      })
  }

  deleteImage =(e,image, type)=> {
      // let image = this.state.maintenanceHistForm.files && this.state.maintenanceHistForm.files.split(',')
      const s = image.filter((item, index) => index !== e);
      if(type === 'url'){
        this.setState({
          maintenanceHistForm:{
            ...this.state.maintenanceHistForm,
            files: s.toString()
          },
          expenseForm:{
            ...this.state.expenseForm,
            files: s.toString()
          }
        },()=>{this.checkRequiredFields3()})
        }else if(type === 'base64'){
          this.setState({
          maintenanceHistForm:{
            ...this.state.maintenanceHistForm,
            files: s.toString()
          },
          image64Type: s
          },()=>{
            this.checkRequiredFields3()
            tempFile = s
          })
        }

    //  tempFile = s
   }


  onCloseModal = () => {
    this.setState({
      enableImagePopup: false
    })
  }

  handleClickFilter = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleCloseFilter = () => {
    this.setState({ anchorEl: null })
  }
  handleChangeFilter = (name,event) => {
    let filter = '';
    this.setState({
      statusFilter:{
        ...this.state.statusFilter,
        [name]: event.target.checked
      },
      countChecked: 0,
      currentPageHist: 1
    },
    ()=>{
      Object.entries(this.state.statusFilter).map(([key,value])=>{
        if(value){
          filter += '&status=' + key
          this.setState({
            countChecked: ++this.state.countChecked
          })
        }
      })
      this.setState({
        filterStatusType: filter
      },()=>{
        if(this.state.showStats) this.checkRequiredFieldsSidebar()
        else this.fetchMaintenanceHistory(this.state.currentPageHist, this.state.pagesizeHist)
      })
    })
  }

  switchMode =(mode, filterType) =>{
    if(filterType === 'due'){
      this.setState({
        mode: filterType,
        itemSearch:'',
        tab:'due',
        filterType,
        isDataRecieved: false,
        dueActive: false,
        currentPageHist: 1,
        itemPagination:this.state.savedData ?this.state.savedData:  this.state.itemPagination,
        searchVehicleId: {id:'', label: this.props.translate('searchVehicles')},
        // searchAreaId: {id:'', label: this.props.translate('searchAreas')}
      },()=>{
          this.fetchMaintenanceHistory(this.state.currentPageHist, this.state.pagesizeHist)
        })
      }else if(filterType === 'history'){
      this.setState({
        mode: filterType,
        itemSearch:'',
        filterType,
        tab:'history',
        isDataRecieved: false,
        dueActive: true,
        currentPageHist: 1,
        itemPagination:this.state.savedData ?this.state.savedData:  this.state.itemPagination
      },()=>{
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist)
        })
      }else{
        this.getVehiclesList()
        this.fetchData(this.props.logInUser,this.state.currentPage,this.state.pagesize, true)
        this.setState({itemSearch:'',tab:'showMaintenance',mode: filterType,dueActive: false})}
  }

  getVehiclesList = () =>{
        vehiclesList = this.props.vehicles.map(item =>{ 
      return (
            {
              id:item.id,
              label:item.label,
              value: item.id,
              vehicleLP:item.vehicleLicensePlate,
              valueType:'string'
          }
      )}
      )
  }

  editHistoryFrom = async (id,requestEdit) => {
    // const imageUrls = (id[0]?.files || '').split(',');
    // // Create an array to store image data
    // const imageData = [];
    // // Asynchronously fetch image data for each URL
    // for (const imageUrl of imageUrls) {
    //   try {
    //     const imageId = imageUrl.trim(); // Remove leading/trailing spaces
    //     const fetchedImage = await this.getImage(imageId);
    //     if (fetchedImage) {
    //       imageData.push(fetchedImage);
    //     } else {
    //       console.error(`Failed to fetch image: ${imageUrl}`);
    //     }
    //   } catch (error) {
    //     console.error(`Error fetching image ${imageUrl}:`, error);
    //   }
    // }

    Object.values(id).map(e=> {
    this.setState({ 
      // mode: '',
      // image64Type:imageData,
      expenseForm: {
           ...e,  
          //  files:imageData,
           status:requestEdit?"required":'completed'
      },
      isVisible: true,
      loader:true,
      historyEdit:requestEdit?false:true,
      historyId: e.id,
      selectedVehicleId: e.vehicleId,
      }, () => {
        this.getExpense(e.id)
        this.getVehiclesData(e.vehicleId, 'updateHist', false)
      })
    })
    this.getData('vehicles', 'id', 0, 'multi', (data)=>{
      vehiclesList = (data).map(item =>{ 
        return (
              {
                id:item.id,
                label:item.label,
                value: item.id,
                vehicleLP:item.vehicleLicensePlate,
                valueType:'string'
            }
        )
      })
    })
    this.getData('drivers', 'id', 0, 'multi', (data)=>{
      driversList = (data).map(item =>{ 
        return (
               {
                key:item.id,
                name:item.name,
                uniqueId:item.uniqueId,
                valueType:'string'
              }
         )
      })
    })
  }

  getExpense = (id) => {
    let allExpenseTypes=expenseType
    expenseType=[]
    let serverTimeZoneName = 'Asia/Dubai'
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone
    }
    instance({
      method: 'GET',
      url: `/api/services/expenses/${id}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      var tempData = []
      this.getData('devices3', 'id', this.state.expenseForm.deviceId, 'single',(data)=>{
      let device = data;
      let vehicles = this.props.vehicles.find(item => item.id === this.state.expenseForm.vehicleId);
      let driver = this.props.drivers.find(item => item.uniqueId === this.state.expenseForm.driverUniqueId);
      // let garage = garageList.find(item => item.key === this.state.expenseForm.garageId);
      // let areaId = areaList&&areaList.find(item => item.key === this.state.expenseForm.areaId);
      let selectedStatus = statusTypes.find(item => item.name === this.state.expenseForm.status);
      let serviceSubType= ''
      response.map((item) => {
        instance({
          method: 'GET',
          url: `api/expensetypes/subtype?expenseTypeId=${item.expenseTypeId}&search=`,  
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then(resp => {
            let serviceSubType= resp.find(el => el.id === item.expenseSubTypeId)
            allExpenseTypes.map(z =>{
              if(item.expenseTypeId === z.id ){
                item.expenseSubTypeId=serviceSubType
                item.expenseTypeId=z
                item.selectedSubTypes=resp
                item.allExpenseTypes=allExpenseTypes
                tempData.push(item)
                this.setState({
                  serviceExpenseCollection: tempData,
                  expenseForm:{
                    ...this.state.expenseForm,
                    serviceTime: moment(this.state.expenseForm.serviceTime).tz(serverTimeZoneName).format('YYYY-MM-DD'),
                    // type: 1,
                    device,
                    vehicles:vehicles,
                    status:selectedStatus&&selectedStatus.name,
                    // garage:garage,
                    // areaId:areaId,
                    driver:driver,
                    engineHours: this.state.expenseForm.engineHours > 0 ?(this.state.expenseForm.engineHours/(1000*3600)).toFixed(2)+' h': '0',
                    odometer: this.state.expenseForm.odometer>0? setAttributeFormat('mileage', (this.state.expenseForm.odometer)) : '0'
                  },
                    image64Type:[],
                    // serviceExpenseCollection: tempData,
                    mode: 'updateHist',
                    loader:false,
                    itemSearch:'',
                  }, ()=>{this.getExpenseTypes()})
              }
            })
        })
        });
        })
      })
      .catch(error => {
        // errorHandler(error,this.props.dispatch)
      })
  }

  editMaintenanceForm = maintenanceForm => {
    let res =[]
    let selectedVehicle = this.props.vehicles.find(item => item.id === maintenanceForm[0].vehicleId)
    // this.getSubTypes(maintenanceForm[0].attributes&&maintenanceForm[0].attributes.serviceTypeId)
    let subTypeId =maintenanceForm[0].attributes&&maintenanceForm[0].attributes.serviceTypeId
    instance({
      method: 'GET',
      url: `api/expensetypes/subtype?expenseTypeId=${subTypeId}&search=`,  
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(response => {
        response&&response.map(item => {
           res.push(
            {
              key: item.id,
              id: item.id,
              expenseTypeId : item.expenseTypeId,
              name: item.label,
              label: item.label,
              parentId: item.parentId
            }
          )
        })
        subTypes = res
        this.setState({subTypes2:res},()=>{
          let selectedServiceExpenseId = expenseType.find(item => item.id === maintenanceForm[0].attributes.serviceTypeId);
          let selectedUnit = {id:0, label:'', value:0};
          if(subTypes.length){ 
           Object.values(maintenanceForm).map(maintenanceForm1=> {
            this.setState({ mode: '', maintenanceForm1: {} }, () => {
              let obj = JSON.parse(JSON.stringify(maintenanceForm1))
            if (obj.attributes && obj.attributes.byMileage) {
              obj.attributes.mileage = parseFloat((obj.attributes.mileage / 1000).toFixed(3))
              obj.attributes.mileageInterval = parseFloat((obj.attributes.mileageInterval / 1000).toFixed(3))
              obj.attributes.reminderBeforeMileage = parseFloat((obj.attributes.reminderBeforeMileage / 1000).toFixed(3))
            } if (obj.attributes && obj.attributes.byHours) {
              obj.attributes.hours = parseFloat((obj.attributes.hours / (1000 * 3600)).toFixed(3))
              obj.attributes.hoursInterval = parseFloat((obj.attributes.hoursInterval / (1000 * 3600)).toFixed(3))
              obj.attributes.reminderBeforeHours = parseFloat((obj.attributes.reminderBeforeHours / (1000 * 3600).toFixed(3)))
            } if (obj.attributes && obj.attributes.byDays) {
              obj.attributes.date = obj.attributes.date
              obj.attributes.daysInterval = parseFloat(obj.attributes&&obj.attributes.daysInterval) || parseFloat(1)
              obj.attributes.reminderBeforeDays = parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1)
            }
            if (obj.attributes.serviceExpenseTypeId) {
              let serviceSubType= subTypes.find(el => el.id === maintenanceForm[0].attributes.serviceExpenseTypeId);
              obj.attributes.serviceTypeId = selectedServiceExpenseId
              obj.attributes.serviceExpenseTypeId = serviceSubType
              }
              selectedUnit.id = obj.deviceId
              selectedUnit.label =obj.deviceName
              selectedUnit.value = obj.deviceId
      
            delete obj.progress
            this.setState({
              mode: 'update',
              itemSearch:'',
              selectedUnit,
              maintenanceForm: {
                ...obj,
                vehicleId:selectedVehicle
              }
            })
          })
          })}
        })
      // }
    })
    .catch(error => {
      // errorHandler(error,this.props.dispatch)
    })






  }
  setPaginationHistory = user => {
    const { itemsPagination } = this.state
    if (itemsPagination && itemsPagination.data && itemsPagination.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / this.state.pagesizeHist
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pagesizeHist = this.state.pagesizeHist
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }
  setPagination = user => {
    const { itemPagination } = this.state
    if (itemPagination && itemPagination.items && itemPagination.items.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / this.state.pagesize
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pagesize = this.state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }

 setDuePagination = user => {
   const { vehicleDueMaintenance } = this.state
   if (vehicleDueMaintenance && vehicleDueMaintenance.data && vehicleDueMaintenance.data.length) {
     let nUser = { ...user }
     let lastPage = nUser.total / user.pageSize
     let IsFloate = this.checkFloteNumber(lastPage)
     // delete nUser.data
     nUser.pageSize = user.pageSize
     nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
     return nUser
   }
   return {}
 }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  handleChangeRowsPerPage =(value,mode) => {
    if(!this.state.showStats){
      if(source){
        source.cancel()
      }
      if(mode==='maintenanceHist'){
        this.setState(
          {
            pagesizeHist: value,
            currentPageHist: 1
          },
          () => {
              this.fetchMaintenanceHistory(
              this.state.currentPageHist,
              this.state.pagesizeHist)
            })
      }else if(mode==='maintenance'){
        this.setState(
          {
            pagesize: value,
            currentPage: 1,
          },
          () => {
              this.fetchData(
                this.props.logInUser,
                this.state.currentPage, 
                this.state.pagesize, 
                true)
            }
        )
      }
      else if(mode === 'dueMaintenance'){
          this.fetchDueMaintenance(this.state.selectedVehicleId,this.state.vehicleDueMaintenance.page,value)
          //  this.setState({
          //   vehicleDueMaintenance:{
          //     ...this.state.vehicleDueMaintenance,
          //     pageSize:value
          //   }
          //  })
      }
    }
    else{
      if(this.state.filterStatusType && this.state.toDate && this.state.fromDate){
        this.setState(
          {
            pagesizeHist: value,
            currentPageHist: 1
          },
          () => {
              this.fetchMaintenanceHistory(
              this.state.currentPageHist,
              this.state.pagesizeHist)
            })  }
    }
  }
  handleChangePage = (value,mode) => {
    if(source){
      source.cancel()
    }
    if(mode==='maintenanceHist'){
      this.setState(
        {
          currentPageHist: value
        },
        () => {
            this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist)
          })
    }else if(mode==='maintenance'){
      this.setState(
        {
          currentPage: value,
        },
        () => {
            this.fetchData(
              this.props.logInUser,
              this.state.currentPage, 
              this.state.pagesize, 
              true)
          }
      )
    }
  else if(mode ==='dueMaintenance'){
      this.fetchDueMaintenance(this.state.selectedVehicleId,value,this.state.vehicleDueMaintenance.pageSize)
    }
  }
  SearchItem = (searchValue,mode) => {
    if(source){
      source.cancel()
    }
    this.setState(
      {
        itemSearch: searchValue,
        currentPage: 1,
        currentPageHist: 1
      },
      () => {if(mode==='maintenanceHist'){
        this.fetchMaintenanceHistory(
        this.state.currentPageHist,
        this.state.pagesizeHist)
      }else if(mode==='maintenance'){
        this.fetchData(this.props.logInUser,this.state.currentPage, this.state.pagesize, true) 
      }}
    )
  }
  SearchVehicle = (name, value) => {
    this.setState(
      {
        searchVehicleId: value,
        currentPage: 1,
        currentPageHist: 1
      },
      () => {
        this.fetchMaintenanceHistory(
        this.state.currentPageHist,
        this.state.pagesizeHist)}
    )
  }

  SearchArea = (name, value) => {
    this.setState(
      {
        searchAreaId: value,
        currentPage: 1,
        currentPageHist: 1
      },
      () => {
        this.fetchMaintenanceHistory(
        this.state.currentPageHist,
        this.state.pagesizeHist)}
    )
  }

  closeCreateFrom = (mode) => {
      this.getExpenseTypes()
      this.setState({
        mode,
        itemSearch:'',
        // itemPagination:this.state&&this.state.savedData,
        maintenanceForm: '',
        isVisible: true,
        loader: false,
        completeMode: false,
        // histFormVisible: false,
        historyEdit:false,
        vehicleTracker: '',
        expenseForm:{...defaultExpenseForm},
        serviceExpenseCollection: [{quantity:'',cost:'', expenseSubTypeId:'', expenseTypeId: '', selectedSubTypes:[]}]
      },
      ()=>{tempFile = []})
      this.closeAllDueMaintenance()
  }

  onDeleteMaintenance = () => {
    if (this.state.itemDeleteId) {
      // fetch(`/api/maintenance/${this.state.itemDeleteId}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/maintenance/${this.state.itemDeleteId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // if (response.ok) {
          this.props.dispatch(removedMaintenance({ id: this.state.itemDeleteId }))
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('maintenanceIsDeleted'),
              autoDismiss: 10
            })
          )
          this.setState({
            onDeleteConfirm: false,
            currentPage: 1
          })
          this.closeCreateFrom('showMaintenance')
          this.maintenanceAnalytic()
          this.fetchData(this.props.logInUser,this.state.currentPage, this.state.pagesize, true)
        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error,this.props.dispatch)})
    }
  }

  onDeleteExpenseItem = () => {
    if (this.state.itemDeleteExpenseId) {
      // fetch(`/api/services/${this.state.itemDeleteExpenseId}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/services/${this.state.itemDeleteExpenseId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // if (response.ok) {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('expenseIsDeleted'),
              autoDismiss: 10
            })
          )
          this.setState({
            onDeleteConfirm: false,
            currentPage: 1,
            currentPageHist: 1,
              }, ()=>{this.fetchMaintenanceHistory(this.state.currentPageHist, this.state.pagesizeHist)})

          this.onCancelExpense()

        // }
        // else{
        //   throw response
        // }
      }).catch(error => {errorHandler(error,this.props.dispatch)})
    }
  }

  onRemovedMaintenance = (item) => {
    this.setState({
      onDeleteConfirm: true,
      itemDeleteId: item[0].id,
      itemDeleteName: item[0].name
    })
  }

  onStatusChange = (item)=> {
    this.setState({
      onStatusChange:true,
      selectedStatusChange:item,
      itemStatusChange: item.name
    })
  }
  onMarkComplete = (item)=> {
     const typeExpense = expenseType.find(obj => {return parseInt(obj.key) === parseInt(item.attributes&&item.attributes.serviceTypeId)})
     const searchedVehcile = this.props.vehicles.find(it => it.id === item.vehicleId)
     let type = ''
     if(item&&item.attributes&&item.attributes.serviceTypeId){
       let res=[]
       instance({
         method: 'GET',
         url: `api/expensetypes/subtype?expenseTypeId=${item&&item.attributes&&item.attributes.serviceTypeId}&search=`,  
         headers: {
           Accept: 'application/json',
           'Content-Type': 'application/json'
         },
       })
       .then(response => {
           response&&response.map(item => {
              res.push(
               {
                 key: item.id,
                 id: item.id,
                 expenseTypeId : item.expenseTypeId,
                 name: item.label,
                 label: item.label,
                 parentId: item.parentId
               }
             )
           })
           subTypes = res
           type = (subTypes && subTypes).find(i => i.key ===  parseInt(item.attributes&&item.attributes.serviceExpenseTypeId))
           this.setState({
             serviceExpenseCollection:[{
               attributes: {},
               cost: item.cost,
               expenseTypeId:typeExpense,
               expenseSubTypeId:type,
               id: item.id,
               quantity: 1,
               serviceId: item.id,
               allExpenseTypes:expenseType,
               selectedSubTypes:subTypes
             }]
           })
       })
       .catch(error => {
         // errorHandler(error,this.props.dispatch)
       })
    }
    this.getData('vehicles', 'id', 0, 'multi', (data)=>{
      vehiclesList = (data).map(item =>{ 
        return (
              {
                id:item.id,
                label:item.label,
                deviceId: item.deviceId,
                value: item.id,
                vehicleLP:item.vehicleLicensePlate,
                valueType:'string'
            }
        )
      })
    })
    this.getData('drivers', 'id', 0, 'multi', (data)=>{
      driversList = (data).map(item =>{ 
        return (
               {
                key:item.id,
                name:item.name,
                uniqueId:item.uniqueId,
                valueType:'string'
              }
         )
      })
    })
    this.setState({
      mode : 'createExpense',
      filterType: 'history',
      completeMode: true,
      expenseForm: {
        maintenanceId: item.id,
        attributes:{
          updateMaintenance: true
        },
        type: typeExpense,
        name: item.name,
        cost: item.cost,
        vehicles: searchedVehcile,
        address: "",
        serviceTime: moment().format('YYYY-MM-DD'),
        status: "completed",
        vehicleId: ''
      },
    }, ()=>{this.getVehiclesData(item.vehicleId, 'createExpense', false)})
 
  }
  
  onChangeStatus =() =>{
    let data = this.state.selectedStatusChange
    if(data){
      data.attributes.status = !data.attributes.status
        this.setState({  
          maintenanceForm:data,
          statusMode:true
        }, ()=>{this.submitForm()})
    }
  }

  onCompleteMark =() =>{
    let data = this.state.selectedMarkComplete
    if(data){
        this.setState({  
          maintenanceForm:data,
          statusMode:true
        }, ()=>{this.submitForm('complete')})
    }
  }

  onRemovedExpense = (item) => {
   this.setState({
    onDeleteExpense:true,
    itemDeleteExpenseId:item[0].id,
    itemDeleteExpense:item[0].name,

   })
  }  

  onCancelMaintenance = () => {
    this.setState({
      onDeleteConfirm: false
    })
  }

  onCancelStatus = () => {
    this.setState({
      onStatusChange: false
    })
  }
  onCancelMark = () => {
    this.setState({
      onMarkComplete: false
    })
  }

  onCancelExpense = () => {
    this.setState({
      onDeleteExpense: false
    })
  }

  uploadFile = async (mode) => {
    tempFile = [];
    let {image64Type} = this.state
    this.setState({loader: true})
    if(!process.env.REACT_APP_SERVER_LOCAL){
    if (image64Type && image64Type.length) {
      // image64Type.map((item,i) => 
        for(let i=0; i<image64Type.length; i++){
          var today = Math.round((new Date()).getTime() / 1000);
          const myExe = image64Type[i].split(";base64",1);
          const exe = myExe[0].replace("data:",'');
        let obj = {
            name: today,
            contentType: exe,
            data: `${image64Type[i].split('base64')[1].substring(1)}`
        }
       
        const response = await instance({
          url: `/api/uploads/maintenance/${this.state.expenseForm.id}`,
          method: 'POST',
          data: {...obj}
        })
        try {
          const publicUrl = response
            this.setState({
              expenseForm:{
                ...this.state.expenseForm,
                files: this.state.expenseForm.files ? publicUrl.publicUrl ? publicUrl.publicUrl : publicUrl.localUrl+','+this.state.expenseForm.files : publicUrl.publicUrl? publicUrl.publicUrl: publicUrl.localUrl
              }
            },()=>{
              if(i===image64Type.length-1){
                if(this.state.mode==='updateHist'){
                  this.ud_expenseSubmit()
                }else{
                  this.submitExpense()
                }
              }
            })
        }catch(error) {errorHandler(error,this.props.dispatch)}
        
      }
      }
      else{
        if(this.state.mode==='updateHist'){
          this.ud_expenseSubmit()
        }else{
          this.submitExpense()
        }
      }}
      else{
        if(this.state.mode==='updateHist'){
          this.ud_expenseSubmit()
        }else{
          this.submitExpense()
        }
      }
  }
  uploadImage = async (event, mode) => {
    // Get the current list of images
    const currentImages = this.state.expenseForm.files ? this.state.expenseForm.files.split(',') : [];
    
    // Calculate the total number of images including the current ones
    const totalImages = currentImages.length + this.state.image64Type.length;
    let imgData = ''
    let file = event.target.files
    
    // Check if the total number of images exceeds the limit
    if (totalImages + event.target.files.length <= 5) {
      if(process.env.REACT_APP_SERVER_LOCAL){
        const files = event.target.files;
        
        // Iterate over each file
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!['text/plain', 'image/png', 'image/jpeg', 'application/pdf'].includes(file.type)) {
                console.error(`File format not allowed for file ${file.name}`);
                continue; // Skip this file and proceed to the next one
            }
            // Make a separate API call for each file
            const response = await fetch(`/api/uploads/maintenance/${this.state.expenseForm.id}`, {
                method: 'POST',
                body: file,
            });

            if (response.ok) {
                // Handle success
                const responseData = await response.json();
                currentImages.push(responseData.data.image); // Add uploaded file name to the array
            } else {
                // Handle failure
                console.error(`Error uploading file ${file.name}`);
            }
        }

        // Update the state with the new list of images
        this.setState({
            expenseForm: {
                ...this.state.expenseForm,
                files: currentImages.join(','),
            }
        }, () => {
            console.log('Uploaded files:', this.state.expenseForm.files);
        });
      }else {
        if((file[0].size/1000000) <= 5){
          Object.values(file).map(item =>{
          let reader = new FileReader()
          reader.readAsDataURL(item)
          reader.onload = e => {
            imgData = e.target.result
            const myExe = imgData.split(";base64",1);
            const exe = myExe[0].replace("data:",'');
            if (exe === ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')||
                exe === ('application/vnd.openxmlformats-officedocument.wordprocessingml.document')||
                exe === ('text/plain')||
                exe === ('image/png')||
                exe === ('image/jpeg')||
                exe === ('application/pdf')) {
                let obj = {
                  name: `${item.name}`,
                  contentType: `${item.type}`,
                  // oldUrl: `${this.state.form.driverImg}`,
                  data: `${imgData.split('base64')[1].substring(1)}`
                }
                this.setState(
                  {
                    imageFile: obj,
                  },
                  () => { this.handleChangeExpense('files', imgData)}
                )
              } else {
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate('onlyAcceptImage'),
                    autoDismiss: 10
                  })
                )
              }
            }
          })
          }else{
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate('fileSize5MB'),
                autoDismiss: 10
              })
            )
          }

      }
    } else {
        this.props.dispatch(
            Notifications.error({
                message: this.props.translate('fileLimit'),
                autoDismiss: 10
            })
        );
    }
}




  // uploadImage = async (event,mode) => {
  //   let totalImages = [];
  //   totalImages = this.state.expenseForm.files&&this.state.expenseForm.files.split(',')
  //   totalImages&&totalImages.length ? totalImages=totalImages.length : totalImages = 0
    
  //   let file = event.target.files
  //   let imgData = ''
  //   if((totalImages+this.state.image64Type.length+file.length) <=  5){
  //     if (file && file[0]) {
  //       if(process.env.REACT_APP_SERVER_LOCAL){
  //         let files = event.target.files;
  //         let uploadedFiles = []; // Array to store uploaded file names

  //           // Allowed file formats
  //           const allowedFormats = [
  //             'text/plain',
  //             'image/png',
  //             'image/jpeg',
  //             'application/pdf'
  //           ];

  //           // Iterate over each file
  //           for (let i = 0; i < files.length; i++) {
  //             const file = files[i];

  //             // Check if the file format is allowed
  //             if (!allowedFormats.includes(file.type)) {
  //               console.error(`File format not allowed for file ${file.name}`);
  //               continue; // Skip this file and proceed to the next one
  //             }

  //             // Make a separate API call for each file
  //             const response = await fetch(`/api/uploads/maintenance/${this.state.expenseForm.id}`, {
  //               method: 'POST',
  //               body: file,
  //             });

  //             if (response.ok) {
  //               // Handle success
  //               const responseData = await response.json();
  //               uploadedFiles.push(responseData.data.image); // Add uploaded file name to the array
  //               const filesString = uploadedFiles.join(', ');
                
  //               this.setState({
  //                 expenseForm:{
  //                   ...this.state.expenseForm,
  //                   files:filesString,
  //                 }
  //               },()=>{console.log('Uploaded files=====', this.state.expenseForm);})

  //             } else {
  //               // Handle failure
  //               console.error(`Error uploading file ${file.name}`);
  //             }
  //           }
        

  //       }else{
  //       if((file[0].size/1000000) <= 5){
  //       Object.values(file).map(item =>{
  //       let reader = new FileReader()
  //       reader.readAsDataURL(item)
  //       reader.onload = e => {
  //         imgData = e.target.result
  //         const myExe = imgData.split(";base64",1);
  //         const exe = myExe[0].replace("data:",'');
  //         if (exe === ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')||
  //             exe === ('application/vnd.openxmlformats-officedocument.wordprocessingml.document')||
  //             exe === ('text/plain')||
  //             exe === ('image/png')||
  //             exe === ('image/jpeg')||
  //             exe === ('application/pdf')) {
  //             let obj = {
  //               name: `${item.name}`,
  //               contentType: `${item.type}`,
  //               // oldUrl: `${this.state.form.driverImg}`,
  //               data: `${imgData.split('base64')[1].substring(1)}`
  //             }
  //             this.setState(
  //               {
  //                 imageFile: obj,
  //               },
  //               () => { this.handleChangeExpense('files', imgData)}
  //             )
  //           } else {
  //             this.props.dispatch(
  //               Notifications.error({
  //                 message: this.props.translate('onlyAcceptImage'),
  //                 autoDismiss: 10
  //               })
  //             )
  //           }
  //         }
  //       })
  //       }else{
  //         this.props.dispatch(
  //           Notifications.error({
  //             message: this.props.translate('fileSize5MB'),
  //             autoDismiss: 10
  //           })
  //         )
  //       }
  //     }
  //   }

  //   }else{
  //     this.props.dispatch(
  //       Notifications.error({
  //         message: this.props.translate('fileLimit'),
  //         autoDismiss: 10
  //       })
  //     )
  //   }
  // }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader =new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error)
        };
    });
}
  handleChangeHist = (name, value) => {
    let v = value
    if (name === 'type') {
      v = value.id
      this.setState({
        maintenanceHistForm: {
          ...this.state.maintenanceHistForm,
          status: v
        }
      },()=> this.checkRequiredFields2())
    }else{
        this.setState({
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            [name]: v
          }
        },()=> {
          this.checkRequiredFields2()}
        )
  }
  }
  onChangedDevice = (name,item) => {
    if(item){
      this.getData('positions', 'vehicleId', item.id, 'single', (data)=>{
        const mileage = data&&data.attributes&&data.attributes.totalDistance ? (data.attributes.totalDistance/1000).toFixed(2) : null;
        const hours = data&&data.attributes&&data.attributes.hours ? (data.attributes.hours/(3600*1000)).toFixed(2) : null;
        this.setState({
          vehicleTracker: item,
          vehicleId: item,
          maintenanceForm: {
            ...this.state.maintenanceForm,
            deviceId:0,
            vehicleId: item,
            attributes: {
              ...this.state.maintenanceForm.attributes,
              mileage,
              hours,
              byMileage: mileage ? true : false,
              byHours: hours ? true : false,
              byDays: true,
            }
          }
        }, ()=>{this.checkRequiredFields()})
      })
      return null;
    }
  }
  checkRequiredFields2 = () => {
    const {maintenanceHistForm} = this.state
    if(maintenanceHistForm.cost+''.trim() && maintenanceHistForm.status && maintenanceHistForm.cDate){
      this.setState({
        isVisible: false 
      })
    }else{
      this.setState({
        isVisible: true
      })
    }
  }
  handleChange = (name, value) => {
    if(name === 'vehicleId'){
      if(value&&value.id){
        instance({
          method: 'GET',
          url: `/api/vehicles/${value.id}/accumulator`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          const mileage = response&&response.totalDistance ? (response.totalDistance/1000).toFixed(2) : null;
          const hours = response&&response.hours ? (response.hours/(3600*1000)).toFixed(2) : null;
          this.setState({
            vehicleTracker: value,
            vehicleId: value,
            maintenanceForm: {
              ...this.state.maintenanceForm,
              deviceId:0,
              vehicleId: value,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                mileage,
                hours,
                byMileage: mileage ? true : false,
                byHours: hours ? true : false,
                byDays: true,
              }
            }
          }, ()=>{this.checkRequiredFields()})
        })
        .catch(err =>{console.log('err ===', err)})
    }
  }
    else{
      let v = value
      this.setState({
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: v
        }
      },() => this.checkRequiredFields())
    }
    
  }
  checkRequiredFields = () => {
     let { name } = this.state.maintenanceForm
     let { date, mileage, hours, byDays, byHours, byMileage, serviceExpenseTypeId,serviceTypeId,emails, mailTo,notificators} = this.state.maintenanceForm&&this.state.maintenanceForm.attributes
    //  if ( name && (mileageInterval || hoursInterval || daysInterval) && (mileage || hours || date)) {
     if ( name && serviceExpenseTypeId && serviceTypeId && ((byMileage && mileage) || (byHours && hours) || (byDays && date))) {
       if(notificators.includes('mail') && mailTo === 'custom' && !emails){
        this.setState({
          isVisible: true
         })
       }else{
         this.setState({
          isVisible: false
         })
       }
     } else {
       this.setState({
        isVisible: true
       })
     }
  }
  getData = (collection, field, value, item, cb) =>{
      const HideMe = document.getElementById('HideMe');
      ReactDOM.render(<Provider store={store}><SingleItem collection={collection} field={field} value={value} item={item}>{
            (id) => {
              cb(id)
              return null;
            }
          }
          </SingleItem></Provider>,
          HideMe
        )
  }
  handleChangeAttributes = (name, value, checked) => {
    if (value) {
      if (name === 'byMileage' || name === 'byHours' || name === 'byDays') {
        let maintenanceForm = JSON.parse(JSON.stringify(this.state.maintenanceForm))
        maintenanceForm.attributes[name] = checked
        this.setState({maintenanceForm})
      } else if(name === 'serviceTypeId'){
        this.getSubTypes(value&&value.id)
        this.setState({
          maintenanceForm: {
            ...this.state.maintenanceForm,
            attributes: {
              ...this.state.maintenanceForm.attributes,
              serviceTypeId: value,
              expenseName: value.name
            }
          }
        },() => this.checkRequiredFields())
      }else if(name === 'serviceExpenseTypeId'){
        this.setState({
          maintenanceForm: {
            ...this.state.maintenanceForm,
            attributes: {
              ...this.state.maintenanceForm.attributes,
              serviceExpenseTypeId : value,
            }
          }
        },() => this.checkRequiredFields())
      }
      else {this.setState({
        maintenanceForm: {
          ...this.state.maintenanceForm,
          attributes: {
            ...this.state.maintenanceForm.attributes,
            [name]: value
          }
        }
      },() => this.checkRequiredFields())}
    } else {
      if (this.state.maintenanceForm && this.state.maintenanceForm.attributes) {
        let attributes = { ...this.state.maintenanceForm.attributes }
        delete attributes[name]
        this.setState({
          maintenanceForm: {
            ...this.state.maintenanceForm,
            attributes: {
              ...attributes
            }
          }
        },() => this.checkRequiredFields())
      }
    }
  }

  submitForm = (checkTrue) => {
    let obj = JSON.parse(JSON.stringify(this.state.maintenanceForm))
    if(obj.vehicleId){
      obj.vehicleId = this.state.statusMode ? obj.vehicleId : obj.vehicleId.id
      obj.deviceId = this.state.statusMode ? obj.deviceId : 0
    }
    if (obj.attributes.byMileage && !this.state.statusMode ) {
      obj.attributes.mileage = parseFloat(obj.attributes.mileage * 1000)
      obj.attributes.mileageInterval = parseFloat(obj.attributes.mileageInterval * 1000)
      obj.attributes.reminderBeforeMileage = parseFloat(obj.attributes.reminderBeforeMileage * 1000)
    } if (obj.attributes.byHours && !this.state.statusMode ) {
      obj.attributes.hours = parseFloat(obj.attributes.hours * (1000 * 3600))
      obj.attributes.hoursInterval = parseFloat(obj.attributes.hoursInterval * (1000 * 3600))
      obj.attributes.reminderBeforeHours = parseFloat(obj.attributes.reminderBeforeHours * (1000 * 3600))
    } if (obj.attributes.byDays && !this.state.statusMode ) {
      obj.attributes.date = obj.attributes.date
      obj.attributes.daysInterval = parseFloat(obj.attributes.daysInterval) || parseFloat(1)
      obj.attributes.reminderBeforeDays = parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1)
    } if (obj.attributes.serviceExpenseTypeId ) {
      obj.attributes.serviceExpenseTypeId = this.state.statusMode ? obj?.attributes?.serviceExpenseTypeId : obj?.attributes?.serviceExpenseTypeId?.id || ''
      obj.attributes.serviceTypeId = this.state.statusMode ? obj?.attributes?.serviceTypeId : obj?.attributes?.serviceTypeId?.id || ''
        }

    obj = {
      ...obj,
      type : ''
    }
    obj.cost = parseFloat(obj.cost)
    delete obj.check
    delete obj.parent
    if(!obj.attributes.repeat){
      delete obj.attributes.mileageInterval
      delete obj.attributes.hoursInterval
      delete obj.attributes.daysInterval
    }
    if(!obj.attributes.byMileage){
      delete obj.attributes.mileage
      delete obj.attributes.mileageInterval
      delete obj.attributes.reminderBeforeMileage
      obj.attributes.reminderByMileage = false
    }if(!obj.attributes.byHours){
      delete obj.attributes.hours
      delete obj.attributes.hoursInterval
      delete obj.attributes.reminderBeforeHours
      obj.attributes.reminderByHours = false
    }if(!obj.attributes.byDays){
      delete obj.attributes.date
      delete obj.attributes.daysInterval
      delete obj.attributes.reminderBeforeDays
      obj.attributes.reminderByDays = false
    }

    let params = ''
    if (this.state.mode === 'update' || this.state.statusMode) {
      params = obj.id
    }
    this.setState({
      isVisible: true
     })
    if (obj) {
      let url = checkTrue==='complete' ? `/api/maintenance/${params}/complete` : `/api/maintenance/${params}`
      instance({
        url: `${url}`,
        method: `${(this.state.statusMode)|| this.state.mode === 'update' ? 'PUT' : 'POST'}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...obj
        }
      })
      .then(maintenance=> {
        // if (response.ok) {
          if(checkTrue==='complete'){
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('maintenanceIsMarkedComplate'),
                autoDismiss: 10
              })
            )
            this.setState({
              onMarkComplete:false
            })
          }else {
            // response.json().then(maintenance => {
              this.props.dispatch(addMaintenance(maintenance))
              if (this.state.mode === 'update' || this.state.statusMode) {
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('maintenanceIsUpdated'),
                    autoDismiss: 10
                  })
                )
              } else {
                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('MaintenanceIsCreated'),
                    autoDismiss: 10
                  })
                )
              }
              this.setState({
                selectMaintenanceId: maintenance && maintenance.id,
                itemSearch: '',
                onStatusChange:false,
                onMarkComplete:false,
                statusMode: false
              }
              // ,()=>{
              //   if(this.state.vehicleId){
              //     this.maintenanceSelection()
              //   }
              // }
              )
              this.closeCreateFrom('showMaintenance')
              this.maintenanceAnalytic()
              this.fetchData(this.props.logInUser,1, this.state.pagesize, true)
            
            // })
          }
        // }else{
        //   throw response
        // }
      })
      .catch(error => {
        errorHandler(error,this.props.dispatch)
        this.setState({
          itemSearch:'',
          onStatusChange:false,
          onMarkComplete:false,
          statusMode: false
        }, ()=>{this.fetchData(this.props.logInUser,1, this.state.pagesize, true)})
      }
      )
    }
  }
  
  maintenanceSelection = () => {
    let obj = {
      deviceId: this.state.vehicleId,
      maintenanceId: this.state.selectMaintenanceId
    }
    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions/`,
      method: `POST`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
    .then(response => {
      // if (response.status === 204) {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('maintenanceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
      // }
    }).catch(error => {errorHandler(error,this.props.dispatch)})
  }
  submitHistoryForm = (e) => {
    let obj = this.state.maintenanceHistForm
    let cDate = ''
    let items = this.state.itemsPagination.items.map(row => {
      if(this.state.historyId === row.id) {
        row.attributes.cost = parseInt(obj.cost)
        row.attributes.status = obj.status
        row.attributes.cValue = obj.cValue
        row.attributes.cDate = obj.cDate
        row.attributes.updateMaintenance = obj.updateMaintenance
        row.files = (obj.files==='undefined'?'':obj.files)
      }
      cDate = moment(obj.cDate).toISOString()
      return row
    })
      this.setState({ isVisible: true })
      instance({
          method: 'GET',
          url: `/api/maintenance/history/${this.state.historyId}?cost=${obj.cost}&status=${obj.status}&cValue=${obj.cValue}&cDate=${cDate}&files=${obj.files}&updateMaintenance=${obj.updateMaintenance}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...obj
          })
        })
        .then(response => {
          // if(response && response.status === 200) {
            // if(response.data && response.data.status === 'success') {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('maintenancehistoryupdated'),
                  autoDismiss: 10
                })
              )
              this.setState({
                itemsPagination: {
                  ...this.state.itemsPagination,
                  items
                }
              }, () => {
              this.closeCreateFrom('history')
              this.maintenanceAnalytic()
            })
          // }
        // }   
    }).catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
    // .catch(e => {
    //     this.props.dispatch(
    //       Notifications.error({
    //         message: this.props.translate('somthingWentWrongMessage'),
    //         autoDismiss: 10
    //       })
    //     )
    // })
  }
  maintenanceAnalytic = () => {
    // fetch(`/api/services/analytic`, {
    //   method: 'GET',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/services/analytic`,
      method: `GET`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    // .then(response => {
    //   if (response.ok) {
    //     response.json()
        .then(data => {
          this.setState({
            analyticData: data
          })
      //   })
      // }
      // else{
      //   throw response
      // }
    }).catch(error => {
      // errorHandler(error,this.props.dispatch)
    })
  }
  item_Selection = (checked, item) => {
    let selectedUnit = item
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteduser]
    let obj = {}
    if(this.state.assignMode === 'user') {
      obj.userId = item.id
    }
    if(this.state.assignMode === 'unit') {
      obj.deviceId = item.id
    }
    /* if(this.state.assignMode === 'group') {
      obj.groupId = item.id
    } */
    obj.maintenanceId = parseInt(this.state.currentMaintenance.id)
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: {
        ...obj
      }
    })
      .then(response => {
        // if (response.status === 204) {
          if (method === 'DELETE') {
            item.check = false
            allData.map(dt => {
              if (dt.id === item.userId) {
                dt.check = false
              }
              return null
            })

            this.props.dispatch(
              Notifications.success({
                message: this.props.translate(this.state.assignMode === 'user' ? 'userUnAssignedSuccessfully' : this.state.assignMode === 'unit' ? 'trackerIsUnAssignedSuccessfully' : 'groupUnAssignedSuccessfully'),
                autoDismiss: 10
              })
            )
            if(item){
              selectedUnit.label = item.name
              selectedUnit.value = item.value
              this.setState({
                  selectedUnit,
                  vehicleTracker: {}
                },()=>{
                  this.onChangedDevice(this.state.vehicleTracker)
                })
            }
          } else {
            item.check = true
            allData.map(dt => {
              if (dt.id === item.userId) {
                dt.check = true
              }
              return null
            })
            
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate(this.state.assignMode === 'user' ? 'userAssignedSuccessfully' : this.state.assignMode === 'unit' ? 'trackerIsAssignedSuccessfully' : 'groupAssignedSuccessfully'),
                autoDismiss: 10
              })
            )
            if(item){
              selectedUnit.label = item.name
              selectedUnit.value = item.value
              this.setState({
                  selectedUnit,
                  vehicleTracker: {}
                },()=>{
                  this.onChangedDevice(this.state.selectedUnit)
                })
            }
          }

          this.props.dispatch(userDeviceWiseSelectedList(allData))

        // } else {
        //   throw response
        // }
      }).catch(error => {errorHandler(error,this.props.dispatch)})
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       let a = err.split('SecurityException').splice(0, 1)
      //       //alert(a[0])
      //       this.props.dispatch(
      //         Notifications.error({
      //           message: a[0],
      //           autoDismiss: 10
      //         })
      //       )
      //     })
      //   }
      // })
  }

  onRemovedItem = item => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true
    })
  }

  onRemovedExpenseItem= item => {
    this.setState({
      onDeleteExpenseConfirm: true
    })
  }

  removeItem = () => {
    if(this.state.assignMode === 'unit') {

      this.removeFromDatabase();
    }
    else if(this.state.assignMode === 'user') {

      this.removeFromDatabase();

    }
    /* else if (this.state.assignMode === 'group') {

      this.removeFromDatabase();
    } */

  }

  removeFromDatabase = () => {
    let obj = this.state.deleteForm
    let params = obj.id
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check
      }

      let call;
      if(this.state.assignMode === 'user') {
        call = `/api/users/${params}`
      }
      else if(this.state.assignMode === 'unit') {
        call = `/api/devices/${params}`
      }
      /* else if(this.state.assignMode === 'group') {
        call = `/api/groups/${params}`
      } */

      if(call) {
        // fetch(`${call}`, {
        //   method: `DELETE`,
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `${call}`,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          // if (response.ok) {
              this.props.dispatch(removedUserDeviceWiseSelectedList(obj))
              let translationKey;
              if(this.state.assignMode === 'user') {
                translationKey = `userIsRemoved`
                this.props.dispatch(removeUser(obj))
              }
              else if(this.state.assignMode === 'unit') {
                translationKey = `trackersIsDeleted`
                this.props.dispatch(removeDevice(obj.id))
              }
              /* else if(this.state.assignMode === 'group') {
                translationKey = `groupDeleted`
                this.props.dispatch(removeGroup(obj))
              } */
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate(translationKey),
                  autoDismiss: 10
                })
              )
              this.setState({
                onDeleteConfirmation: false
              })
          // }
          // else{
          //   throw response
          // }
        }).catch(error => {errorHandler(error,this.props.dispatch)})
      }
    }
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      onDeleteExpenseConfirm:false,
      
    })
  }

  saveToParent = (obj) => {
    this.setState({
      maintenanceForm: { ...this.state.maintenanceForm, attributes: { ...this.state.maintenanceForm.attributes, ...obj } }
    }, () => this.checkRequiredFields());
  }

  saveToExpense = (obj) => {
    this.setState({
			expenseForm: {
			  ...this.state.expenseForm,
        attributes: {...this.state.expenseForm.attributes, ...obj }
			}
		},()=>{this.checkRequiredFields3()})
  }

   fetchDueMaintenance =(id, page, pageSize) =>{
       let status = 'required'
       source = CancelToken.source()
       instance({
         method: 'GET',
         url: `/api/services/due?page=${page}&limit=${pageSize}&search=&vehicleId=${id}&status=${status}`,
         cancelToken: source.token
       })
       .then(response => {
           this.setState({
             showDialogBtn:true,
             vehicleDueMaintenance: response
           })
       }).catch(error => {
         // errorHandler(error,this.props.dispatch)
       })
     }
     getDriverByVehicleId =(id)=>{
       instance({
         method: 'GET',
         url: `/api/drivers/vehicleid/${id}`,
         cancelToken: source.token
       })
       .then(response => {
             let driver = {
               key: response.id,
               id: response.id,
               label: response.name,
               name: response.name,
               uniqueId: response.uniqueId,
               valueType: "string"
             }
             if (driver){
               this.setState({
                 expenseForm: {
                   ...this.state.expenseForm,
                   driver: driver
                 }
               },()=>{this.checkRequiredFields3()})
             }
       }).catch(error => {
         // errorHandler(error,this.props.dispatch)
       })
     }

     showDueMaintenance=(id)=>{
       this.fetchDueMaintenance(id,1,10)
     }

     showDueMaintenanceDialogFunc=()=>{
       this.setState({showDueDialog:true})   
     }
 
     onCloseDueMaintenanceModel = () =>{
       this.setState({showDueDialog:false})
     }
 
     toggleFilterForm= ()=>{
       this.setState({
         showStats:!this.state.showStats,
         statusFilter:[],
         countChecked:0,
         itemsPagination: {
          items: [],
          total: 0,
          currentPage: 0,
          hasNext: true,
        },
        vehicleId:[],
        // areaId:[],
        toDate:'',
        fromDate:'',
        filterStatusType:'',
        mode:'due'
      },()=>{
        if(!this.state.showStats) this.fetchMaintenanceHistory(1,20)
      })
     } 

     showDates =(e, date)=> {
      let timezone = 'Asia/Dubai'
      if (
        this.props.ServerSettings &&
        this.props.ServerSettings.attributes &&
        this.props.ServerSettings.attributes.timezone
      ) {
        timezone = this.props.ServerSettings.attributes.timezone
      }
      if (
        this.props.logInUser &&
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
      ) {
        timezone = this.props.logInUser.attributes.timezone
      }
      moment.tz.setDefault(timezone)
  
      var formatedDate = {
        from: moment(date.startDate.format('YYYY-MM-DD HH:mm')).toISOString(),
        to: moment(date.endDate.format('YYYY-MM-DD HH:mm')).toISOString()
      }
  
      let timeFormat = getDateTimeFormat()
  
      this.setState(
        {
          fromDate:formatedDate.from,
          toDate:formatedDate.to,
          selectedDateTime:
            moment(date.startDate).format(timeFormat) +
            ' - ' +
            moment(date.endDate).format(timeFormat),
          ...formatedDate
        }, )
    }
    
  handleChangeSidebar =(name, e)=>{
    if(name==='sideBarStatus') {
      this.setState({sideBarStatus:e.target.value},()=>{
        // let filter = this.state?.filterStatusType || ''
        let filter = ''
        if(this.state.sideBarStatus){
          filter += '&status=' + this.state.sideBarStatus
        }
      this.setState({
        filterStatusType: filter
      },()=>{ this.fetchMaintenanceHistory(1, this.state.pagesizeHist) })
    })
    }
    // else if (e === 'area'){
    //   this.setState({areaId: name },()=>{  
    //     let filter = '';
    //       Object.entries(this.state.areaId).map(([key,value])=>{
    //         if(value){
    //           filter += '&areaId=' + value
    //           this.setState({
    //             // countChecked: ++this.state.countChecked
    //           })
    //         }
    //       })
    //       this.setState({
    //         selectedAreaId: filter
    //       },()=>{
    //         // this.fetchMaintenanceHistory(1, this.state.pagesizeHist)
    //       })
    //     })
    // }
    else if(e === 'maintenance'){
      this.setState(
        {
          maintenanceId: name
        })
        this.setState({maintenanceId: name },()=>{  
          let filter = '';
            Object.entries(this.state.maintenanceId).map(([key,value])=>{
              if(value){
                filter += '&status=' + value
                this.setState({
                })
              }
            })
            this.setState({
              selectedMaintenanceId: filter
            },()=>{
              // this.checkRequiredFields()
            })
        })
    }
    else {this.setState({vehicleId: name },()=>{  
    let filter = '';
      Object.entries(this.state.vehicleId).map(([key,value])=>{
        if(value){
          filter += '&vehicleId=' + value
          this.setState({
            // countChecked: ++this.state.countChecked
          })
        }
      })
      this.setState({
        selectedVehicleId: filter
      },()=>{
        // this.fetchMaintenanceHistory(1, this.state.pagesizeHist)
      })
    })
    }
  }
  checkRequiredFieldsSidebar = ()=>{
    let {disabledSidebarBtn, sideBarStatus, toDate,fromDate } = this.state
    if(toDate && fromDate) this.setState({disabledSidebarBtn:false})
    else this.setState({disabledSidebarBtn:true})
  }

  submitSideBarSearch=(data) =>{
    this.setState({
      loadingSidebar:true,
      // areaId:data.areaId,
      vehicleId:data.vehicleId,
      // selectedAreaId:data.selectedAreaId,
      selectedVehicleId:data.selectedvehicleId,
      filterStatusType:data.selectedMaintenanceId,
      toDate:data.to,
      fromDate:data.from
    },()=>{this.fetchMaintenanceHistory2(1, this.state.pagesizeHist)})
    
  }
  importFunc = (res) => {
    let arr = [];
    let data =
      res &&
      res.map((item) => {
        return {
          ...item.service,
          expenseCollection: [item.serviceExpenseCollection],
        };
      });
    this.setState({
      importedData: data,
      importedReceivedData: res,
      showColorTable: true,
    });
  };

  onCloseColorModel = () => {
    this.setState({ showColorTable: false });
  };


  SubmitImport = (e) => {
    e.preventDefault();
    const objFor = (
      this.state.importedReceivedData && this.state.importedReceivedData
    ).map((item) => {
      item.serviceExpenseCollection.map((it) => {
        delete it?.attributes?.invalid;
        return { ...it };
      });
      delete item?.service?.attributes?.invalid;
      return { ...item };
    });
    instance({
      url: `/api/services/bulk`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: objFor,
    })
      .then((res) => {
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("importedSuccessfully"),
            autoDismiss: 10,
          })
        );
        this.onCloseColorModel();
      })
      .catch((err) => {
        console.log("err ===", err);
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate(err),
            autoDismiss: 10,
          })
        );
      });
  };

  render () { 
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          -- {this.props.translate('end')} --{' '}
        </p>
      ) : (
        <p style={{ textAlign: 'center', color: '#ccc' }}>
          {' '}
          {this.props.translate('notFound')}{' '}
        </p>
      )
      let image = this.state.maintenanceHistForm.files && this.state.maintenanceHistForm.files.split(',')

    if (checkPrivileges('maintenance')) {
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            createExpense= {this.createExpense}
            editHistoryFrom={this.editHistoryFrom}
            closeCreateFrom={this.closeCreateFrom}
            classFromChildren='has-padding'
            editMaintenanceForm={this.editMaintenanceForm}
            fetchMoreItems={this.fetchMoreItems}
            fetchMoreItemsHist={this.fetchMoreItemsHist}
            searchItems={this.searchItems}
            toggleFilterForm={this.toggleFilterForm}
            showDates={this.showDates}
            handleChangeSidebar={this.handleChangeSidebar}
            handleChangeFilter={this.handleChangeFilter}
            handleClickFilter={this.handleClickFilter}
            handleCloseFilter={this.handleCloseFilter}
            submitSideBarSearch={this.submitSideBarSearch}
            {...this.state}
          >
            <div className='main-content-page'>
              <div id="HideMe" />
              {/* <SingleItem collection="devices3" field="id" value={1536}>{
                (item) => {
                  console.log(item);
                  return null;
                }
              }
              </SingleItem> */}
              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.removeItem}
                  title={this.props.translate('areYouWantToDelete')}
                  children={this.state.deleteForm.name || this.state.deleteForm.description}
                />
              )}
              {this.state.onDeleteConfirm && (
                <ConfirmDialoag
                  onCancel={this.onCancelMaintenance}
                  onOk={this.onDeleteMaintenance}
                  title={this.props.translate('areYouWantToDelete')}
                  children={this.state.itemDeleteName}
                />
              )}
              {this.state.onStatusChange && (
                <ConfirmDialoag
                  onCancel={this.onCancelStatus}
                  onOk={this.onChangeStatus}
                  OkText={'Yes'}
                  CancelText={'No'}
                  title={this.props.translate('areYouWantChangeStatus')}
                  children={this.state.itemStatusChange}
                />
              )}
              {this.state.onMarkComplete && (
                <ConfirmDialoag
                  onCancel={this.onCancelMark}
                  onOk={this.onCompleteMark}
                  OkText={'Yes'}
                  CancelText={'No'}
                  title={this.props.translate('Do you want to Mark complete this maintenance')}
                  children={this.state.itemMarkComplete}
                />
              )}

              {this.state.onDeleteExpense && (
                <ConfirmDialoag
                  onCancel={this.onCancelExpense}
                  onOk={this.onDeleteExpenseItem}
                  title={this.props.translate('areYouWantToDelete')}
                  children={this.state.itemDeleteExpense}
                />
              )}
            {this.state.enableImagePopup && (
                <CustomDialog
                  title={this.props.translate('Images')}
                  themecolors={this.props.themecolors}
                  visable={true}
                  onClose={this.onCloseModal}
                  bodyPadding={10}
                  cancelText={this.props.translate('sharedCancel')}
                  noFullScreen
                >
                  { this.state.maintenanceHistForm&&this.state.maintenanceHistForm.files ?(<div style={{ display: 'flex',flexWrap: 'wrap',}}>
                    <ImageList cols={2.5} style={{flexWrap: 'nowrap'}}>
                    {image.map((item, i) =>
                      item && (<ImageListItem key={item} style={{height: 'auto', width:'auto'}}>
                        <a href={item} download target="_blank">
                          <Tooltip 
                            classes={{
                            popper: 'menu-popper',
                            tooltip: 'menu-popper-tooltip'
                          }}
                          title={this.props.translate('DOWNLOAD')}>
                            <FileCopyIcon style={{width: 80, height: 80, color: '#ffffff'}}/>
                          </Tooltip>
                        </a>
                        <p style={{textAlign: 'center', marginTop: 0}}>File {i+1}</p>
                        </ImageListItem>))}
                      </ImageList>
                  </div>) : <h3 style={{display: 'flex',justifyContent: 'center'}}>No Image Selected</h3>}
                </CustomDialog>
              )}
             {this.state.showColorTable && (
                <Grid container spacing={1}>
                  <CustomDialog
                    title="Imported Services"
                    themecolors={this.props.themecolors}
                    visable={true}
                    onClose={this.onCloseColorModel}
                    bodyPadding={10}
                    isVisableBtn
                    noFullScreen
                    maxWidth={500}
                    importServiceTbl
                    headerActions={
                      <Button
                        disabled={this.state.importedData.length ? false : true}
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={(e) => this.SubmitImport(e)}
                      >
                        {" "}
                        {this.props.translate("sharedAdd")}
                      </Button>
                    }
                  >
                    <div>
                      <Table
                        rows={this.state.importedData || []}
                        pagination={this.setDuePagination(this.state.importedData || [])}
                        handleChangeRowsPerPage={(n) =>  this.handleChangeRowsPerPage(n, "dueMaintenance") }
                        handleChangePage={(n) =>  this.handleChangePage(n, "dueMaintenance") }
                        logInUser={this.props.logInUser}
                        isEditable={false}
                        status={false}
                        completeMark={false}
                        themecolors={this.props.themecolors}
                        translate={this.props.translate}
                        hasAccessOfCreate={false}
                        hasAccessOfUpdate={false}
                        hasAccessOfDelete={false}
                        vehicles={this.props.vehicles}
                        rowDefinition={[
                          {
                            id: "name",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("service"),
                          },
                          {
                            id: "description",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("description"),
                          },
                          {
                            id: "refNum",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("refNum"),
                          },
                          {
                            id: "vehicleLicensePlate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("vehicleLicensePlate"),
                          },
                          {
                            id: "driverUniqueId",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("driverUniqueId"),
                          },
                          {
                            id: "odometer",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalDistance"),
                          },
                          {
                            id: "engineHours",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("reportEngineHours"),
                          },
                          {
                            id: "serviceTime",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("serviceTime"),
                          },
                          {
                            id: "cost",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("cost"),
                          },
                          {
                            id: "status",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("status"),
                          },
                          {
                            id: "expenseCollection",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("expenseCollection"),
                          },
                        ]}
                      />
                    </div>
                  </CustomDialog>
                </Grid>
              )}
              {this.state.mode === 'create' ||
                this.state.mode === 'update' ? (
                    <div
                      style={{
                        background: this.props.themecolors.backgroundColor,
                        color: this.props.themecolors.textColor,
                        borderRadius: 6,
                        padding: 16
                      }}
                    >
                     
                    <CreateMaintenanceForm
                      vehicleTracker={this.state.vehicleTracker}
                      selectedUnit={this.state.selectedUnit}
                      mode={this.state.mode}
                      form={this.state.maintenanceForm}
                      isVisible={this.state.isVisible}
                      servicesAttributes={expenseType}
                      subTypes = {this.state.subTypes2 || []}
                      translate={this.props.translate}
                      themecolors={this.props.themecolors}
                      logInUser={this.props.logInUser}
                      dispatch={this.props.dispatch}
                      saveToParent={this.saveToParent}
                      assignModal={this.assignModal}
                      submitForm={this.submitForm}
                      closeCreateFrom={this.closeCreateFrom}
                      handleChangeAttributes={this.handleChangeAttributes}
                      handleChange={this.handleChange}
                      onChangedDevice={this.onChangedDevice}
                      uploadImage={this.uploadImage}
                      imagePopup={this.imagePopup}
                      vehicles = {vehiclesList}
                    /></div>
                ) : null}
              {this.state.mode === 'updateHist' ? (
                <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16
                  }}
                  >
                   
                    <CreateExpense
                         editOption

                      expenseForm={this.state.expenseForm}
                      vehicleTracker={this.state.vehicleTracker}
                      selectedUnit={this.state.selectedUnit}
                      isVisible={this.state.isVisible}
                      serviceExpenseCollection = {this.state.serviceExpenseCollection}
                      mode={this.state.dueActive}
                      image64Type={this.state.image64Type}
                      dueActive={this.state.dueActive}
                      loader={this.state.loader}
                      drivers = {driversList}
                      // garageList = {garageList}
                      statusTypes = {statusTypes}
                      notificationMode = {this.state.mode ==='createExpense' ? "create":"update"}
                      saveToParent={this.saveToExpense}
                      dispatch={this.props.dispatch}
                      vehicles = {vehiclesList}
                      subTypes = {subTypes}
                      expenseType={expenseType&&expenseType.length?expenseType:allExpenseTypes}
                      themecolors={this.props.themecolors}
                      translate={this.props.translate}
                      logInUser={this.props.logInUser}
                      handleChangeExpense= {this.handleChangeExpense}
                      onChangedDevice={this.onChangedDevice}
                      closeExpense= {this.closeExpense}
                      closeCreateFrom={this.closeCreateFrom}
                      submitExpense= {this.ud_expenseSubmit}
                      addMore = {this.addMore}
                      uploadImage={this.uploadImage}
                      handleChangeServices={this.handleChangeServices}
                      deleteRow= {this.deleteRow}
                      imagePopup={this.imagePopup}
                      uploadFile= {this.uploadFile}
                      deleteImage={this.deleteImage}
                      showDueMaintenance={this.showDueMaintenance}
                      showDialogBtn={this.state.showDialogBtn}
                      vehicleDueMaintenance={this.state.vehicleDueMaintenance}
                      showDueDialog={this.state.showDueDialog}
                      showDueMaintenanceDialogFunc={this.showDueMaintenanceDialogFunc}
                      onCloseDueMaintenanceModel={this.onCloseDueMaintenanceModel}
                      handleChangePage={this.handleChangePage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      setDuePagination={this.setDuePagination}
                      historyEdit={this.state.historyEdit}
                      // areaList={checkPrivileges('area') ?areaList : []}
                      tab={this.state.tab}
                      check      
                      />
                  </div>
                ) : null}
                
                {this.state.mode === 'showMaintenance' && !this.state.showStats ? (
                  <div
                    style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                  >
                    <MaintenanceForm
                      data={this.state.itemPagination}
                      dueActive={this.state.dueActive}
                      isDataRecieved={this.state.isDataRecieved}
                      mode={this.state.mode}
                      translate={this.props.translate}
                      themecolors={this.props.themecolors}
                      logInUser={this.props.logInUser}
                      setPagination={this.setPagination}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      handleChangePage={this.handleChangePage}
                      SearchItem={this.SearchItem}
                      openCreateFrom={this.openCreateFrom}
                      onRemove={this.onRemovedMaintenance}
                      onStatusChange = {this.onStatusChange}
                      onMarkComplete = {this.onMarkComplete}
                      switchMode={this.switchMode}
                      editMaintenanceForm={this.editMaintenanceForm}
                      vehicles={this.props.vehicles}
                    />
                  </div>
                ) : null}
                
                {(this.state.mode === ('history') || this.state.mode === ('due')) && !this.state.showStats ? (
                  <div
                  style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  paddingLeft: 0,
                  paddingRight: 0
                  }}
                >
                  <MaintenanceHistoryForm
                    data={this.state.itemsPagination}
                    dueActive={this.state.dueActive}
                    isDataRecieved={this.state.isDataRecieved}
                    countChecked={this.state.countChecked}
                    statusFilter={this.state.statusFilter}
                    anchorEl={this.state.anchorEl}
                    mode={this.state.mode}
                    expenseForm={this.state.expenseForm}
                    searchVehicleId={this.state.searchVehicleId}
                    // searchAreaId={this.state.searchAreaId}
                    // vehicles={vehiclesList}
                    // histFormVisible={this.state.histFormVisible}
                    translate={this.props.translate}
                    vehicles={this.props.vehicles}
                    // areas={this.props.areas}
                    deviceRelatedData={this.props.deviceRelatedData}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    setPagination={this.setPaginationHistory}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    SearchItem={this.SearchItem}
                    SearchVehicle={this.SearchVehicle}
                    // SearchArea={this.SearchArea}
                    openCreateFrom={this.openCreateFrom}
                    handleChangeFilter={this.handleChangeFilter}
                    handleClickFilter={this.handleClickFilter}
                    handleCloseFilter={this.handleCloseFilter}
                    imagePopup={this.imagePopup}
                    switchMode={this.switchMode}
                    onRemove={this.onRemovedExpense}
                    editHistoryFrom={this.editHistoryFrom}
                    createExpense= {this.createExpense}
                    importFunc={this.importFunc}
                  /></div>
                ) : null}

                  {this.state.mode === 'createExpense' ? (
                    <div style={{ height: '100%', position: 'relative', zIndex: 9999 }}>
                      <CreateExpense
                          expenseForm= {this.state.expenseForm}
                          vehicleTracker={this.state.vehicleTracker}
                          selectedUnit={this.state.selectedUnit}
                          isVisible={this.state.isVisible}
                          serviceExpenseCollection = {this.state.serviceExpenseCollection}
                          mode={this.state.dueActive}
                          image64Type={this.state.image64Type}
                          loader={this.state.loader}
                          dueActive={this.state.dueActive}
                          drivers = {driversList}
                          // garageList = {garageList}
                          statusTypes = {statusTypes}
                          notificationMode = {this.state.mode ==='createExpense' ? "create":"update"}
                          saveToParent={this.saveToExpense}
                          dispatch={this.props.dispatch}
                          vehicles = {vehiclesList}
                          subTypes = {subTypes}
                          expenseType={expenseType&&expenseType.length ?expenseType :allExpenseTypes}
                          themecolors={this.props.themecolors}
                          translate={this.props.translate}
                          logInUser={this.props.logInUser}
                          completeMode={this.state.completeMode}
                          handleChangeExpense= {this.handleChangeExpense}
                          onChangedDevice={this.onChangedDevice}
                          closeExpense= {this.closeExpense}
                          closeCreateFrom={this.closeCreateFrom}
                          submitExpense= {this.submitExpense}
                          addMore = {this.addMore}
                          uploadImage={this.uploadImage}
                          handleChangeServices={this.handleChangeServices}
                          deleteRow= {this.deleteRow}
                          imagePopup={this.imagePopup}
                          uploadFile= {this.uploadFile}
                          deleteImage={this.deleteImage}
                          showDueMaintenance={this.showDueMaintenance}
                          showDialogBtn={this.state.showDialogBtn}
                          vehicleDueMaintenance={this.state.vehicleDueMaintenance}
                          showDueDialog={this.state.showDueDialog}
                          showDueMaintenanceDialogFunc={this.showDueMaintenanceDialogFunc}
                          onCloseDueMaintenanceModel={this.onCloseDueMaintenanceModel}
                          handleChangePage={this.handleChangePage}
                          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                          setDuePagination={this.setDuePagination}
                          check={false}
                          // areaList={checkPrivileges('area') ?areaList : []}
                          tab={this.state.tab}
                      />
                      </div>
                    ) : null}
                  {this.state.showStats ? (
                    <div
                    style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    paddingLeft: 0,
                    paddingRight: 0
                    }}
                  >
                  <MaintenanceComp
                    data={this.state.itemsPagination}
                    dueActive={this.state.dueActive}
                    isDataRecieved={this.state.isDataRecieved}
                    countChecked={this.state.countChecked}
                    statusFilter={this.state.statusFilter}
                    anchorEl={this.state.anchorEl}
                    mode={this.state.mode}
                    expenseForm={this.state.expenseForm}
                    searchVehicleId={this.state.searchVehicleId}
                    // searchAreaId={this.state.searchAreaId}
                    // vehicles={vehiclesList}
                    // histFormVisible={this.state.histFormVisible}
                    translate={this.props.translate}
                    vehicles={this.props.vehicles}
                    // areas={this.props.areas}
                    deviceRelatedData={this.props.deviceRelatedData}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    setPagination={this.setPaginationHistory}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    SearchItem={this.SearchItem}
                    SearchVehicle={this.SearchVehicle}
                    // SearchArea={this.SearchArea}
                    openCreateFrom={this.openCreateFrom}
                    handleChangeFilter={this.handleChangeFilter}
                    handleClickFilter={this.handleClickFilter}
                    handleCloseFilter={this.handleCloseFilter}
                    imagePopup={this.imagePopup}
                    switchMode={this.switchMode}
                    onRemove={this.onRemovedExpense}
                    editHistoryFrom={this.editHistoryFrom}
                    createExpense= {this.createExpense}
                  /></div>
                ) : null}
                
                {this.state.model ? (
                  <Dialog
                  isVisableBtn={true}
                  headerActions={
                    <Button
                      onClick={this.submitAssignModel}
                      variant='outlined'
                      size='small'
                    >
                      {this.props.translate('assignMaintenance')}
                    </Button>
                  }
                  open={this.state.model}
                  onClose={this.closeAssignModal}
                  title={this.props.translate('assignMaintenance')}
                  disableFooter
                >
                  <>
                  <div style={{ padding: 16, fontWeight: 700, paddingBottom: 0 }}><MaintenanceIcon fill="currentColor" width={20} height={20} style={{verticalAlign: 'middle', marginRight: 5}} /> {this.state.currentMaintenance &&
                    this.state.currentMaintenance.name}</div>
                    {this.state.assignMode === 'unit' ? (
                      <ItemsSelector
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      rowsPerPage={this.state.rowsPerPage}
                      selectAll={e =>
                        selectAll(
                          e,
                          'deviceId',
                          'maintenanceId',
                          this.state.currentMaintenance.id,
                          this.props.selecteduser,
                          this.props.devices3,
                          this.props.dispatch,
                          'access',
                          this.props.translate
                        )
                      }
                      parent={0}
                      item_Selection={this.item_Selection}
                      themecolors={this.props.themecolors}
                      data={this.props.selecteduser}
                      translate={this.props.translate}
                      onDelete={this.onRemovedItem}
                      canDelete={checkPrivileges('deviceDelete')}
                      canRemove={checkPrivileges('deviceLinkMaintenance')}
                      canAssign={checkPrivileges('deviceUnlinkMaintenance')}
                    />
                    ) : null}

                    {this.state.assignMode === 'user' ? (
                      <ItemsSelector
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      rowsPerPage={this.state.rowsPerPage}
                      selectAll={e =>
                        selectAll(
                          e,
                          'userId',
                          'maintenanceId',
                          this.state.currentMaintenance.id,
                          this.props.selecteduser,
                          this.props.users,
                          this.props.dispatch,
                          'access',
                          this.props.translate
                        )
                      }
                      parent={0}
                      item_Selection={this.item_Selection}
                      themecolors={this.props.themecolors}
                      data={this.props.selecteduser}
                      translate={this.props.translate}
                      onDelete={this.onRemovedItem}
                      canDelete={checkPrivileges('userDelete')}
                      canRemove={checkPrivileges('userLinkMaintenance')}
                      canAssign={checkPrivileges('userUnlinkMaintenance')}
                      rowDefinition={[
                        {
                          id: 'name',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('sharedName')
                        },
                        {
                          id: 'email',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('userEmail')
                        },
                        {
                          id: 'roleName',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('roleName')
                        },
                        {
                          id: 'parentName',
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate('parentName')
                        }
                      ]}
                    />
                    ) : null}
                  </>
                </Dialog>
                ) : null}
                {this.state.mode === '' ? (
                  <EmptyState
                    text={this.props.translate('noMaintenanceSelected')}
                  />
                ) : null}
            </div>
          </Layout>
        </div>
      )
    } else {
      return null
    }
  }
}
const mapStateToProps = state => ({
  maintenance: state.maintenance,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  roles: state.roles,
  deviceRelatedData: state.deviceRelatedData,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  drivers: state.drivers,
  vehicles: state.vehicles,
  ServerSettings:state.ServerSetting,
  // areas:state.areas
})

export default connect(mapStateToProps)(withSnackbar(withLocalize(Maintenance)))