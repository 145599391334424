import React from 'react'
import Marker from './VehicleMarker2'
const areCoordinatesDistantEnough = (coord_one, coord_two) => {
  return (
    JSON.stringify(coord_one.lat.toString().substring(8, 3)) !==
    JSON.stringify(coord_two.lat.toString().substring(8, 3))
  )
}

const animateMarker = (leafletElement, coordFrom, coordTo) => {

  leafletElement.setLatLng(coordTo)
}

class CustomMarker2 extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      position: props.position
    }
  }

  componentWillReceiveProps (nextProps) {
    try {
      if (
        areCoordinatesDistantEnough(nextProps.position, this.props.position) &&
        this.ref &&
        this.ref.leafletElement
      ) {
        animateMarker(
          this.ref.leafletElement,
          this.props.position,
          nextProps.position
        )

        if(nextProps.tracking) {
          this.ref && this.ref.leafletElement._map && this.ref.leafletElement._map.setView(nextProps.position, this.ref.leafletElement._map.getZoom(), {
            animate: true,
            duration: 0.5
          })
        }

        return false
      }
    } catch (ERR) {
      console.error(ERR)
      return false
    }
  }

  makeRef = e => {
    this.ref = e
    if (this.ref && this.ref.leafletElement) {
      this.ref.leafletElement.setLatLng(this.props.position)
    }
  }

  render () {
    return (
      <Marker
        {...this.props}
        ref={this.makeRef}
        position={this.state.position}
      />
    )
  }
}
export default CustomMarker2
