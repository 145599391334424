import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Layout from '../../Layout';
import { WorkFlowForm } from '../../Components/WorkFlow/WorkFlowForm';
import instance from '../../axios';
import Table from '../../Components/common/tableWithBackEndPagination'
import { checkPrivileges, errorHandler } from '../../Helpers';
import Notifications from 'react-notification-system-redux'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag';
import Button from '../../Components/common/Button';
import axios from 'axios'
import { FormControlLabel, Grid } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Checkbox from '../../Components/common/Checkbox'
import MenuItem from '../../Components/common/MenuItem'
import Menu from '../../Components/Menu/CommonMenu'
import ExportImportMenu from '../../Components/Maps/ExportImportMenu';
const CancelToken = axios.CancelToken
let source=''
class WorkFlow extends Component {
    constructor (props) {
        super(props)
        this.state = {
          mode: '',
          initFetch: false,
          metrixData: [],
          categoriesList:[],
          workFlows:[],
          initFetch:false,
          showTable:true,
          showForm:false,
          onDeleteConfirmation:false,
          selectedItem:'',
          currentPage:1,
          pagesize:20,
          countChecked: 0,
          anchorEl: null,
          statusFilter : [],
          filterStatusType:'',
          itemPagination: {
            items: [],
            total: 0,
            currentPage: 1,
            hasNext: true,
            searchText: ''
          },
        }
      }
  componentWillMount () {
        this.getMetrix()
        this.fetchCategories()
        if (
          this.props.logInUser &&
          this.props.logInUser.id &&
          this.state.initFetch === false
        ) {
          this.setState(
            {
              initFetch: true
            },
            () => {
              this.fetchData(
                this.props.logInUser,
                this.state.currentPage,
                this.state.pagesize
              )
              // this.fetchTypes()
            }
          )
        }
    }
   
    componentWillReceiveProps (n) {
      if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
        this.setState(
          {
            initFetch: true
          },
          () => {
            this.fetchData(
              n.logInUser,
              this.state.currentPage,
              this.state.pagesize
            )
            // this.fetchTypes()
          }
        )
      }
    }
    fetchMoreItems = (a, b, c) => {
      this.fetchData(
        this.props.logInUser,
        this.state.currentPage,
        this.state.pagesize
      )
    }

    fetchData = (userId, page, perPage, reset,sidebar,sidebarData) => {  
      source = CancelToken.source()
      const query = 'all=true'
      let searchText = this.state.searchText
  
      if (searchText) {
        searchText = '&search=' + searchText
      } else {
        searchText = ''
      }
      let items = this.state.itemPagination.items
      if (reset) {
        this.setState(
          {
            itemPagination: { ...this.state.itemPagination, items: [] }
          },
          () => {
            items = []
          }
        )
      }
      let sidebarURL = ''
      if(sidebar){
        let city= parseInt(sidebarData.selectedCityId)
        sidebarURL = `/api/workflows/get?${sidebarData.selectedStatusId}${sidebarData.selectedvehicleId}&page=1&limit=50&from=${sidebarData.from}&to=${sidebarData.to}&interCity=${city}`
        }
      instance({
        url: sidebar ? sidebarURL : `/api/workflows/get?${query}&page=${page}&limit=${perPage}${searchText}${this.state.filterStatusType}`,
        method: 'GET',
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        this.setState({workFlows:response})
      }).catch(error => {
        // errorHandler(error,this.props.dispatch)
      })
    }
    
    getMetrix = () => {
      instance({
        url: `/api/workflows/matrix/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      })
          .then(res => {
            this.setState({metrixData:res})
   
      }).catch(error => {
        console.log('error ===', error)
      })
    }
    fetchCategories = ()=>{
      //(/api/devices/categories
      instance(`/api/devices/categories`)
              .then(response=>{
                this.setState({categoriesList: response?.categories})
              })
          .catch(error => {
            // errorHandler(error, this.props.dispatch)
            })
    }
    editWorkFlow = (item) =>{
      this.setState({
        form:item&&item[0],
        form2:item&&item[0],
        mode:'edit',
        showTable:false,
        showForm:true,
      })
    }
 openCreateTypeFrom= () =>{
    this.setState({
      mode:'create',
      showTable:false,
      showForm:true,
    })
}
 modelControl = () =>{
    this.setState({
      mode:'',
      showTable:true,
      showForm:false,
    })
}
 
  handleChangeRowsPerPage = (value) => {
    if(source){
      source.cancel()
    }
      this.setState(
        {
          pagesize: value,
          currentPage: 1
        },
        () => {
            this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize)
          })
  }

  handleChangePage = (value)=>{
    if(source){
      source.cancel()
    }
      this.setState(
        {
          currentPage: value
        },
        () => {
            this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            )
          })
  }

  setPagination = user => {
    const { workFlows } = this.state
    if (workFlows && workFlows.data && workFlows.data.length) {
      let nUser = { ...user }
      let lastPage = nUser.total / this.state.pagesize
      let IsFloate = this.checkFloteNumber(lastPage)
      delete nUser.data
      nUser.pageSize = this.state.pagesize
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
      return nUser
    }
    return {}
  }

  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }

  onRemovedItem = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      selectedItem:item&&item[0]
    })
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      selectedItem:''
    })
  }

  onDelete = () => {
    if (this.state.selectedItem&&this.state.selectedItem.id) {
      instance({
        url: `/api/workflows//${this.state.selectedItem.id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        // if (response.ok) {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('savedWorkFlowIsDeleted'),
              autoDismiss: 10
            })
          )
          this.setState({onDeleteConfirmation:false,selectedItem:''}, ()=>{this.fetchMoreItems()})
      }).catch(error => {errorHandler(error, this.props.dispatch)})
    }
  }

  cancelWorkFlow= (e)=>{
    instance({
      url: `api/workflows/cancel/${e.id}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
        .then(response => {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('workFlowCanceled'),
              autoDismiss: 10
            })
          )
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
    }).catch(error => {
      console.log('error ===', error)
    })
  }

  finishWorkFlow = (e)=>{
    instance({
      url: `api/workflows/finish/${e.id}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })
        .then(response => {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate('workFlowFinished'),
              autoDismiss: 10
            })
          )
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          )
    }).catch(error => {
      console.log('error ===', error)
    })
  }
  
  searchItems =(e)=>{
    if(source){
      source.cancel()
    }
    this.setState(
      {
        searchText: e
      },
      () => {
          this.fetchData(
          this.props.logInUser,
          this.state.currentPage,
          this.state.pagesize,
          )
        })
  }
  handleClickFilter = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleCloseFilter = () => {
    this.setState({ anchorEl: null })
  }
  handleChangeFilter = (name,event) => {
    let filter = '';
    this.setState({
      statusFilter:{
        ...this.state.statusFilter,
        [name]: event.target.checked
      },
      countChecked: 0,
      currentPageHist: 1
    },
    ()=>{
      Object.entries(this.state.statusFilter).map(([key,value])=>{
        if(value){
          filter += '&status=' + key
          this.setState({
            countChecked: ++this.state.countChecked
          })
        }
      })
      this.setState({
        filterStatusType: filter
      },()=>{
        this.fetchData(
          this.props.logInUser,
          this.state.currentPage,
          this.state.pagesize
        )
      })
    })
  }

render() {
    const endMessage =(
      <p style={{ textAlign: 'center', color: '#ccc' }}>
        -- {this.props.translate('end')} --
      </p>
    )
         return (
            <div>
              <Layout
                endMessage={endMessage}
                classFromChildren='has-padding'
                editWorkFlow={this.editWorkFlow}
                fetchMoreItems={this.fetchMoreItems}
                itemPagination={{ ...this.state.itemPagination }}
                fetchData={this.fetchData}
                {...this.props}
                {...this.state}
              >
                {this.state.showTable &&
                  (<div className='main-content-page'>
                  <div
                    style={{
                      background: this.props.themecolors.backgroundColor,
                      color: this.props.themecolors.textColor,
                      borderRadius: 6,
                      padding: 16
                    }}
                  >
                    {this.state.showTable ?(
                      <Table
                          rows={this.state?.workFlows?.data||[]}
                          pagination={this.setPagination(this.state?.workFlows||{})}
                          handleChangeRowsPerPage={(n)=>this.handleChangeRowsPerPage(n,'workFlows')}
                          handleChangePage={(n)=>this.handleChangePage(n,'workFlows')}
                          logInUser={this.props.logInUser}
                          isEditable={true}
                          status={false}
                          completeMark={false}
                          themecolors={this.props.themecolors}
                          translate={this.props.translate}
                          isCancel
                          isFinish
                          searchable
                          workFlowSearch
                          workFlowEdit
                          SearchItem={this.searchItems}
                          cancelWorkFlow={this.cancelWorkFlow}
                          finishWorkFlow={this.finishWorkFlow}
                          device2={this.props.devices2}
                          // hasAccessOfCreate={checkPrivileges('workflowCreate')}
                          hasAccessOfUpdate={checkPrivileges('workflowUpdate')}
                          hasAccessOfDelete={checkPrivileges('workflowDelete')}
                          onDelete={this.onRemovedItem}
                          onEdit={this.editWorkFlow}
                          workFlowStatus
                          createButton={
                          checkPrivileges('workflowCreate') && (
                            <div style={{ marginLeft: 10 }}>
                              <Button onClick={this.openCreateTypeFrom}>
                                {this.props.translate('Create')}
                              </Button>
                            </div>
                          )
                        }
                        isFilter={
                          (<Filter
                          countChecked={this.state.countChecked}
                          anchorEl={this.state.anchorEl}
                          handleChangeFilter={this.handleChangeFilter}
                          handleClickFilter={this.handleClickFilter}
                          handleCloseFilter={this.handleCloseFilter}
                          translate={this.props.translate}
                          statusFilter={this.state.statusFilter}
                          />)
                        }
                          rowDefinition={[
                            {
                              id: 'refNum',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('refNum')
                            },
                            {
                              // id: 'vehicleType',
                              id: 'deviceIdWorkFLow',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('Vehicle')
                            },
                            {
                              id: 'startPoint',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('startPoint')
                            },
                            {
                              id: 'startOutSkirts',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('startOutSkirts')
                            },
                            {
                              id: 'endPoint',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('endPoint')
                            },
                            {
                              id: 'endOutSkirts',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('endOutSkirts')
                            },
                            {
                              id: 'perDiemCost',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('perDiemCost')
                            },
                            {
                              id: 'foodCost',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('foodCost')
                            },
                            {
                              id: 'cost',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('cost km')
                            },
                            {
                              id: 'foodPlusPerDiemCost',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('foodPlusPerDiemCost')
                            },  {
                              id: 'totalCost',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('totalCost')
                            },
                            {
                              id: 'mileage',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('Mileage km')
                            },
                            {
                              id: 'status',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('status')
                            },
                            {
                              id: 'interCity',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('tripType')
                            },
                            {
                              id: 'startTime',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('startTime')
                            },
                            {
                              id: 'endTime',
                              numeric: false,
                              disablePadding: false,
                              label: this.props.translate('endTime')
                            }
                          ]}
                          />
                      ):null }
                      
                    {/* {this.state.mode === '' ? (
                      <EmptyState
                        text={this.props.translate('noCommandSelected')}
                      />
                    ) : null} */}
                  </div>
                </div>)
                }
            
                {this.state.onDeleteConfirmation && (
                  <ConfirmDialoag
                    onCancel={this.onCancel}
                    onOk={this.onDelete}
                    title={this.props.translate('areYouWantToDelete')}
                    // children={this.props.item.description}
                  />
                )}

                {this.state.mode ==='create' ? (
                    <WorkFlowForm 
                        themecolors={this.props.themecolors}
                        modelControl={this.modelControl}
                        translate={this.props.translate}
                        metrixData={this.state?.metrixData || []}
                        categoriesList={this.state?.categoriesList || []}
                        fetchMoreItems={this.fetchMoreItems}
                        createMode
                     />
                ):null}
                {this.state.mode ==='edit' ? (
                    <WorkFlowForm 
                        formData = {this.state.form}
                        themecolors={this.props.themecolors}
                        modelControl={this.modelControl}
                        translate={this.props.translate}
                        metrixData={this.state?.metrixData || []}
                        categoriesList={this.state?.categoriesList || []}
                        fetchMoreItems={this.fetchMoreItems}
                        editMode
                     />
                ):null}

              </Layout>
            </div>
          )
    }
  }
  const mapStateToProps = state => ({
    themecolors: state.themeColors,
    logInUser: state.logInUsers,
    devices2: state.devices2
  })
  
  export default connect(mapStateToProps)(withSnackbar(withLocalize(WorkFlow)))

  const Filter = (props) => {
    const html = <div>
    <a
    href={null}
    aria-owns={props.anchorEl ? 'simple-menu' : undefined}
    aria-haspopup='true'
    onClick={props.handleClickFilter}
  >
    <p className='v-tab-heading pull-left' style={{width: 100}}>{props.translate('selected')}{": "}{props.countChecked}</p>
    <ArrowDropDownIcon
    style={{color: '#FFFFFF'}}
    />
  </a>
    <Menu
    id='simple-menu'
    anchorEl={props.anchorEl}
    open={Boolean(props.anchorEl)}
    onClose={props.handleCloseFilter}
  >
    {/* <MenuItem component={FormControlLabel} style={{margin: 0, paddingTop: 0, paddingBottom: 0}}
        control={<Checkbox 
          name='Completed'
          checked={props.statusFilter.history}
          onChange={e=>props.handleChangeFilter('history',e)} 
          canAssign
          canRemove
        />}
        label={props.translate('completed')}
      /> */}
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Pending' 
          checked={props.statusFilter.pending}
          onChange={e=>props.handleChangeFilter('pending',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('scheduled')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Cancelled' 
          checked={props.statusFilter.cancelled}
          onChange={e=>props.handleChangeFilter('cancelled',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('cancelled')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='inprogress' 
          checked={props.statusFilter.inprogress}
          onChange={e=>props.handleChangeFilter('inprogress',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('inProgress')}</span>}
      />
      <MenuItem component={FormControlLabel} style={{margin: 0}}
        control={<Checkbox 
          name='Completed' 
          checked={props.statusFilter.completed}
          onChange={e=>props.handleChangeFilter('completed',e)} 
          canAssign
          canRemove
        />}
        label={<span style={{marginLeft: 8}}>{props.translate('completed')}</span>}
      />
  </Menu></div>
  return <div className="v-filter-field">{html}</div>
  }