import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
//import TextField from './../common/TextField';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import isEqual from 'react-fast-compare'
//import InputAdornment from '@material-ui/core/InputAdornment'
import RefreshIcon from '@material-ui/icons/Refresh'
//import IconButton from '@material-ui/core/IconButton';
import Tooltip from '../common/Tooltip'
//import CrossIcons from '@material-ui/icons/Remove'
import Paper from '@material-ui/core/Paper'
//import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
//import DateFnsUtils from '@date-io/date-fns';
import { withSnackbar } from 'notistack'
import Button from '../common/Button'
import { checkPrivileges, errorHandler } from '../../Helpers'
import Style from 'style-it'
import { logInUserInfo } from './../../Actions/Users'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import instance from '../../axios'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
const CancelToken = axios.CancelToken
let source

//let themecolors;
const mapStateToProps = (state, ownProps) => {
  // themecolors = state.themeColors;
  return {
    themecolors: state.themeColors
  }
}
const styles = theme => ({})

class Permissions extends Component {
  constructor () {
    super()
    this.state = {
      isVisableAddBtn: false,
      isLoadUnit: true,
      isLoadUser: true,
      deviceCount: {
        limit: 0,
        remaningLimit: 0
      },
      userCount: {
        limit: 0,
        remaningLimit: 0
      },
      form: {
        readonly: '',
        deviceReadonly: '',
        limitCommands: '',
        deviceLimit: '',
        userLimit: '',
        token: '',
        expirationTime: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.generateToken = this.generateToken.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
  }
  componentWillUmount () {
    if (source) {
      source.cancel()
    }
  }
  componentWillMount () {
    source = CancelToken.source()

    if (this.props.logInUser) {
      instance({
        method: 'GET',
        url: `/api/devices/count?userId=${this.props.logInUser.id}`,
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        // if (response.status === 200) {
          this.setState({
            deviceCount: response
          })
        // }
        this.setState({
          isLoadUnit: false
        })
      }).catch(error => {
        // errorHandler(error, this.props.dispatch, this.errorCallBack)
        this.setState({
          isLoadUnit: false
        })
      })
      instance({
        method: 'GET',
        url: `/api/users/count?userId=${this.props.logInUser.id}`,
        cancelToken: source.token,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        // if (response.status === 200) {
          this.setState({
            userCount: response
          })
        // }
        this.setState({
          isLoadUser: false
        })
      }).catch(error => {
        // errorHandler(error, this.props.dispatch, this.errorCallBack)
        this.setState({
          isLoadUser: false
        })
      })
    }
    this.setState({
      form: { ...this.props.logInUser }
    })
  }
//   errorCallBack = (data) => {
//     this.setState({
//       isLoadUnit: false,
//       isLoadUser: false
//     })
// }
  formReset = () => {
    this.setState({
      form: { ...this.props.logInUser },
      isVisableAddBtn: false
    })
  }
  formSubmit () {
    let { logInUser } = this.props
    const { form } = this.state
    logInUser = { ...form }
    // fetch(`api/users/${logInUser.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...logInUser
    //   })
    // })
    instance({
      url: `api/users/${logInUser.id}`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
        ...logInUser
      }
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(user => {
            this.props.dispatch(logInUserInfo(user))
            //this.props.enqueueSnackbar(this.props.translate('permissionsInfoIsUpdated'), { autoHideDuration: 1000 });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('permissionsInfoIsUpdated'),
                autoDismiss: 10
              })
            )
          // })
          this.setState({
            isVisableAddBtn: false
          })
      //   } else {
      //     throw response
      //   }
      })
      .catch(error => {errorHandler(error, this.props.dispatch)})
  }
  handleChange = name => event => {
    if (name === 'expirationTime') {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString()
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      const { target } = event
      let value = target.type === 'checkbox' ? target.checked : target.value
      if (target.type === 'number') {
        value = parseInt(value)
        if (value === 0) {
          value = ''
        }
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  copyTokenToClipboard = () => {
    const { form } = this.state;
    const hostUrl = this.props.ServerSetting?.attributes?.hostUrl || '';
    const token = form.token;

    const tokenUrl = `${hostUrl}/loginasuser?&${btoa(token)}`;

    // Create a temporary textarea element to copy the token URL to clipboard
    const tempTextarea = document.createElement('textarea');
    tempTextarea.value = tokenUrl;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    // Show a notification indicating that the token URL has been copied to clipboard
    this.props.enqueueSnackbar('Token URL copied to clipboard', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  generateToken () {
    let symbols =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let i,
      newToken = ''
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken
        }
      },
      () => this.checkRequiredFields()
    )
  }
  setExpirationTime = e => {
    e.stopPropagation()
    this.setState(
      {
        form: {
          ...this.state.form,
          expirationTime: null
        }
      },
      () => this.checkRequiredFields()
    )
  }
  checkRequiredFields = () => {
    let value = !isEqual(this.state.form, this.props.logInUser)
    this.setState({
      isVisableAddBtn: value
    })
  }

  render () {
    const { logInUser } = this.props
    return (
      <Style>
        {`
          .row-value {
              font-size: 14px;
          }
          .row-label {
              font-size: 10px;
              font-weight: bold;
              min-width: 100px;
              display: inline-block;
          }
			`}

        <div>
          <Grid container spacing={2}>
            {logInUser.userType === -1 &&
              renderOwnerField(
                this.state,
                this.props,
                this.handleChange,
                this.setExpirationTime,
                this.generateToken,
                this.copyTokenToClipboard
              )}
            {logInUser.userType !== -1 &&
              renderAdminField(
                this.state,
                this.props,
                this.handleChange,
                this.generateToken,
                this.copyTokenToClipboard
              )}
          </Grid>
          <br />
          {checkPrivileges('userUpdate') && (
            <Fragment>
              <Button
                variant='contained'
                onClick={this.formReset}
                style={{ marginRight: 8 }}
              >
                {' '}
                {this.props.translate('resetButton')}
              </Button>
              <Button
                variant='contained'
                disabled={!this.state.isVisableAddBtn}
                onClick={() => this.formSubmit()}
              >
                {' '}
                {this.props.translate('sharedSave')}
              </Button>
            </Fragment>
          )}
        </div>
      </Style>
    )
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(withSnackbar(Permissions))
)

function renderOwnerField (
  state,
  props,
  handleChange,
  setExpirationTime,
  generateToken,
  copyTokenToClipboard
) {
  // const { classes } = props

  const selectMe = element => {
    var doc = document,
      text = element.target,
      range,
      selection
    if (doc.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(text)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(text)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className='row-label'>
              {props.translate('userExpirationTime')}
            </span>
            <span className='row-value'>
              {state.form.expirationTime
                ? new Date(`${state.form.expirationTime}`).toString()
                : null}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className='row-label'>
              {props.translate('userDeviceLimit')}
            </span>
            {state.isLoadUnit && <span className='row-value'>...</span>}
            {!state.isLoadUnit && <span className='row-value'>
              { state.form.deviceLimit === -1
                ? props.translate('unlimited')
                : state.form.deviceLimit}
            </span>}
          </Grid>
          <Grid item xs={12}>
            <span className='row-label'>
              {props.translate('userUserLimit')}
            </span>
            {state.isLoadUser && <span className='row-value'>...</span>}
            {!state.isLoadUser && <span className='row-value'>
              {state.form.userLimit === -1
                ? props.translate('unlimited')
                : state.form.userLimit}
            </span>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className='license-card clearfix'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label style={{ fontSize: 10 }}>
                {props.translate('userToken')}
              </label>
              <Tooltip title={props.translate('getToken')}>
                <Button
                  className='pull-right button-white'
                  onClick={generateToken}
                >
                  {<RefreshIcon />}
                </Button>
              </Tooltip>
              <Tooltip title={props.translate('copyLink')}>
                <Button
                style={{marginRight:"5px"}}
                  className='pull-right button-white'
                  onClick={copyTokenToClipboard}
                >
                  {<FileCopyOutlinedIcon />}
                </Button>
              </Tooltip>
              <p onClick={e => selectMe(e)}>
                <code style={{ wordBreak: 'break-all' }}>
                  {state.form.token &&
                    props.ServerSetting &&
                    props.ServerSetting.attributes &&
                    (props.ServerSetting.attributes.hostUrl +
                      '/loginasuser?&' +
                      btoa(state.form.token) ||
                      '')}
                </code>
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Fragment>
  )
}

function renderAdminField (state, props, handleChange, generateToken, copyTokenToClipboard) {
  // const { classes } = props
  const selectMe = element => {
    var doc = document,
      text = element.target,
      range,
      selection
    if (doc.body.createTextRange) {
      range = document.body.createTextRange()
      range.moveToElementText(text)
      range.select()
    } else if (window.getSelection) {
      selection = window.getSelection()
      range = document.createRange()
      range.selectNodeContents(text)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
      <Grid item xs={12} >
        <div style={{display:'flex', flexWrap:'wrap'}}>     
          <div style={{padding:15, borderRadius:10}}>
                <strong>{props.translate('units')}</strong><br />
                <div style={{marginTop:4}}>
                   <p style={{fontSize:11}}> {props.translate('childLimit')}</p>
                </div>
                <div style={{display:'flex',marginTop:8}} >
                <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.reserveLimit: <>...</>}</p>
                      <span>{props.translate('childReserveLimit')}{'  '}</span>
                  </div>
                <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.reserveLimit - state.deviceCount.childUnitCount: <>...</>}</p>
                      <span>{props.translate('childRemainigLimit')}{'  '}</span>
                  </div>
                </div>
                <div>
                   <p style={{fontSize:11}}>{props.translate('created')}</p>
                </div>
              <div style={{display:'flex', marginTop:8}}>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.unitCount: <>...</>}</p>
                      <span>{props.translate('totalOwnCreatedUnits')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.childUnitCount: <>...</>}</p>
                      <span>{props.translate('totalChildUnitsCreated')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                    <p>{!state.isLoadUnit ?state.deviceCount.totalCount : <>...</>}</p>
                    <span>{props.translate('usersUnitsTotal')}{'  '}</span>
                  </div>
              </div>

              {/* <div style={{display:'flex'}}>
              <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.activeUnitCount: <>...</>}</p>
                      <span>{props.translate('totalOwnCreatedActiveUnits')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.childActiveUnitCount: <>...</>}</p>
                      <span>{props.translate('totalChildCreatedActiveUnits')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?(state.deviceCount.childActiveUnitCount+state.deviceCount.activeUnitCount): <>...</>}</p>
                      <span>{props.translate('totalIntegrated')}{'  '}</span>
                  </div>
              </div> */}
               <div>
                  <p style={{fontSize:11}}>{props.translate('totalLimit')}</p>
                </div>
              <div style={{display:'flex',marginTop:8}} >
                <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.limit: <>...</>}</p>
                      <span>{props.translate('usersUnitsLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.reserveLimit: <>...</> }</p>
                      <span>{props.translate('reserveUnitsLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUnit ?state.deviceCount.remaningLimit: <>...</>}</p>
                      <span>{props.translate('remainingUnitsLimit')}{'  '}</span>
                  </div>
                </div>
            </div>

           <div style={{padding:15, borderRadius:10}}>
                <strong>{props.translate('Users')}</strong>
                <div style={{marginTop:4}}>
                   <p style={{fontSize:11}}> {props.translate('childLimit')}</p>
                </div>
                <div style={{display:'flex'}} >
                  <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.reserveLimit: <>...</>}</p>
                      <span>{props.translate('childReserveLimit')}{'  '}</span>
                  </div>
                </div>
                <div>
                   <p style={{fontSize:11}}>{props.translate('created')}</p>
                </div>
              <div style={{display:'flex' ,marginTop:8}}> 
                  <div className='stat-block' >
                      <p>{!state.isLoadUser  ? state.userCount.childCount : <>...</>}</p>
                      <span>{props.translate('childCount')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUser  ? state.userCount.grandChildCount : <>...</>}</p>
                      <span>{props.translate('grandChildCount')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                    <p>{!state.isLoadUser ? state.userCount.totalCount : <>...</> }</p>
                    <span>{props.translate('usersAccountTotal')}{'  '}</span>
                  </div>
              </div>
              <div>
                   <p style={{fontSize:11}}>{props.translate('totalLimit')}</p>
                </div>
              <div style={{display:'flex'}} >
                <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.limit : <>...</>}</p>
                      <span>{props.translate('usersAccountLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.reserveLimit+state.userCount.childCount  : <>...</>}</p>
                      <span>{props.translate('reserveLimit')}{'  '}</span>
                  </div>
                  <div className='stat-block' >
                      <p>{!state.isLoadUser ? state.userCount.remaningLimit: <>...</>}</p>
                      <span>{props.translate('remainingLimit')}{'  '}</span>
                  </div>
                </div>
            </div>   
            </div>
        </Grid>
       </Grid>
      
      <Grid item xs={12} md={6}>
        <div
          className='license-card clearfix'
          style={{
            padding: 16,
            color: 'inherit',
            borderRadius: 0,
            boxShadow: 'none'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label style={{ fontSize: 10 }}>
                {props.translate('userToken')}
              </label>
              <Tooltip title={props.translate('getToken')}>
                <Button
                  className='pull-right button-white'
                  onClick={generateToken}
                >
                  {<RefreshIcon />}
                </Button>
              </Tooltip>
              {/* copyTokenToClipboard */}
              <Tooltip title={props.translate('copyLink')}>
                <Button
                     style={{marginRight:"5px"}}
                  className='pull-right button-white'
                  onClick={copyTokenToClipboard}
                >
                  {<FileCopyOutlinedIcon />}
                </Button>
              </Tooltip>
              <p onClick={e => selectMe(e)}>
                <code style={{ wordBreak: 'break-all' }}>
                  {state.form.token &&
                    props.ServerSetting &&
                    props.ServerSetting.attributes &&
                    (props.ServerSetting.attributes.hostUrl +
                      '/loginasuser?&' +
                      btoa(state.form.token) ||
                      '')}
                </code>
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Fragment>
  )
}

