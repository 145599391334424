import React, { Component } from 'react'
import { withSnackbar } from 'notistack'
import TextField from './../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '../common/Button'
import Grid from '@material-ui/core/Grid'
import Notifications from 'react-notification-system-redux'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import { FormHelperText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class changedPasword extends Component {
  constructor () {
    super()
    this.state = {
      currentPawsErrorMgs: false,
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: '',
        currentPassword: ''
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)
    this.formReset = this.formReset.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  formReset () {
    this.setState({
      currentPawsErrorMgs: false,
      nPawsErrorMgs: false,
      form: {
        newPassword: '',
        reapetNewPassword: '',
        currentPassword: ''
      }
    })
  }

  goToLogoutpage = () => {
    localStorage.removeItem('backtoAdmin')
    this.props.history.push('/logout')
  }
  formSubmit () {
    const { logInUser } = this.props
    let { currentPassword, newPassword, reapetNewPassword } = this.state.form
    if (newPassword.trim().length > 0 && reapetNewPassword.trim().length > 0) {
      if (newPassword === reapetNewPassword) {
        this.setState({
          nPawsErrorMgs: false
        })
      } else {
        this.setState({
          nPawsErrorMgs: true
        })
      }
    } else {
      this.setState({
        nPawsErrorMgs: true
      })
    }
    if (
      currentPassword.trim().length > 0 &&
      newPassword.trim().length > 0 &&
      reapetNewPassword.trim().length > 0 &&
      newPassword === reapetNewPassword
    ) {
      instance({
        url: `api/users/${logInUser.id}/password`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:(
          `cpass=${this.state.form.currentPassword}&npass=${this.state.form.newPassword}`
        )
      })
        .then(response => {
          // if (response.ok) {
            //this.props.enqueueSnackbar(this.props.translate('passwordIsChanged'), { autoHideDuration: 1000 });
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('passwordIsChanged'),
                autoDismiss: 10
              })
            )
            this.setState({
              form: {
                newPassword: '',
                reapetNewPassword: '',
                currentPassword: ''
              },
              nPawsErrorMgs: false,
              currentPawsErrorMgs: false
            },()=>this.goToLogoutpage())
          // } else {
          //   throw response
          // }
        })
        .catch(error => {
          errorHandler(error, this.props.dispatch, this.errorCallBack)
          // //this.props.enqueueSnackbar(this.props.translate('wrongCurrentPassword'), { autoHideDuration: 1000 });
          // this.props.dispatch(
          //   Notifications.error({
          //     message: this.props.translate('wrongCurrentPassword'),
          //     autoDismiss: 10
          //   })
          // )
          // this.setState({
          //   currentPawsErrorMgs: true
          // })
        })
    } else {
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { autoHideDuration: 1000 });
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('emptyField'),
          autoDismiss: 10
        })
      )
    }
  }
  errorCallBack = (data) => {
    this.setState({
      currentPawsErrorMgs: true
    })
}
  handleChange = name => event => {
    const { target } = event

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: target.value
        }
      },
      () => this.checkRequiredFields()
    )
  }

  handleChange = name => event => {
    const { target } = event
    if(name === 'newPassword' || name === "reapetNewPassword"){
      let value = target.value
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value
          }
        },
        () => this.verifyPassword()
      )

    }else{
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: target.value
        }
      },
      () => this.checkRequiredFields()
    )
    }
  }
  verifyPassword = () => {
    // Define regular expressions for password criteria
    const minLength = 8;
    const hasLowerCase = /[a-z]/;
    const hasUpperCase = /[A-Z]/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check each criteria
    const isLengthValid = this.state.form.newPassword.length >= minLength;
    const hasLowerCaseValid = hasLowerCase.test(this.state.form.newPassword);
    const hasUpperCaseValid = hasUpperCase.test(this.state.form.newPassword);
    const hasNumberValid = hasNumber.test(this.state.form.newPassword);
    const hasSpecialCharValid = hasSpecialChar.test(this.state.form.newPassword);

    // Check if all criteria are met
    if(isLengthValid){
      this.setState({lengthCheck :  true})
    }else{
      this.setState({lengthCheck :false})
    }
    if(hasLowerCaseValid){
      this.setState({lowerCaseCheck:  true})
    }else{
      this.setState({lowerCaseCheck:false })
    }
    if(hasUpperCaseValid){
      this.setState({upperCaseCheck :  true})
    }else{
      this.setState({upperCaseCheck :false })
    }
    if(hasNumberValid){
      this.setState({ numberCheck : true})
    }else{
      this.setState({numberCheck :false })
    }
    if(hasSpecialCharValid){
      this.setState({ characterCheck : true})
    }else{
      this.setState({ characterCheck : false})
    }
    if(this.state.form.newPassword === this.state.form.reapetNewPassword){
      this.setState({ confirmPasswordCheck : true})
    }else{
      this.setState({ confirmPasswordCheck : false})
    }

    const isPasswordValid =
      isLengthValid && hasLowerCaseValid && hasUpperCaseValid && hasNumberValid && hasSpecialCharValid;
   

    this.setState({ 
      isValidPassword: isPasswordValid },
      () => this.checkRequiredFields());
  };
  // checkRequiredFields () {
  //   let { newPassword, reapetNewPassword, currentPassword } = this.state.form
  //   if (newPassword.trim().length > 0 && reapetNewPassword.trim().length > 0) {
  //     if (newPassword === reapetNewPassword) {
  //       this.setState({
  //         nPawsErrorMgs: false
  //       })
  //     } else {
  //       this.setState({
  //         nPawsErrorMgs: true
  //       })
  //     }
  //   } else {
  //     this.setState({
  //       nPawsErrorMgs: true
  //     })
  //   }
  //   if (currentPassword.trim().length > 0) {
  //     this.setState({
  //       currentPawsErrorMgs: false
  //     })
  //   }
  // }

  checkRequiredFields () {
    let { newPassword, reapetNewPassword , currentPassword} = this.state.form
    let { isValidPassword } = this.state
    
  
    
    if (newPassword && reapetNewPassword && reapetNewPassword === newPassword && isValidPassword && currentPassword) {
      this.setState({
        isVisableUserBtn: true
            })
    }
         else {
          this.setState({
            isVisableUserBtn: false
          })
        }

    // if (currentPassword.trim().length > 0) {
    //   this.setState({
    //     currentPawsErrorMgs: false
    //   })
    // }
  }
  render () {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='newPassword'
              label={this.props.translate('newPassword')}
              type='password'
              placeholder=''
              error={this.state.nPawsErrorMgs}
              value={this.state.form.newPassword}
              onChange={this.handleChange('newPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
           
            <FormHelperText  style={{color:this.props.themecolors.textColor}} >
               <div style={{display:"flex", alignItems:"center"}}>
                {this.state.lengthCheck?
                  <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                  <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                  <span style={{marginLeft:"5px"}}>Length must be atleast 8 characters</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.numberCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Number</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.upperCaseCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Uppercase letter</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.lowerCaseCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Lowercase letter</span>
                </div> 
               <div style={{display:"flex", alignItems:"center"}}>
               {this.state.characterCheck?
                <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                <span style={{marginLeft:"5px"}}>Must contain atleast one Special character (!@#$%^&*(),.?":{}|<></>)</span>
                </div> 
            </FormHelperText>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              id='reapetNewPassword'
              error={this.state.nPawsErrorMgs}
              label={this.props.translate('reNewPassword')}
              type='password'
              placeholder=''
              value={this.state.form.reapetNewPassword}
              onChange={this.handleChange('reapetNewPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
      <FormHelperText  style={{color:this.props.themecolors.textColor}} >
               <div style={{display:"flex", alignItems:"center"}}>
                {this.state.confirmPasswordCheck?
                  <CheckCircleIcon style={{ color: "green", fontSize: 14}}/> :
                  <CancelIcon style={{ color: "red",fontSize: 14 }}/>}
                  <span style={{marginLeft:"5px"}}>Both Passwords must be Equal</span>
                </div> 
                </FormHelperText>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <TextField
              error={this.state.currentPawsErrorMgs}
              id='currentPassword'
              label={this.props.translate('userCurrentPassword')}
              type='password'
              placeholder=''
              value={this.state.form.currentPassword}
              onChange={this.handleChange('currentPassword')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>

        <br />
        <Button
          variant='contained'
          style={{ marginRight: 8 }}
          onClick={() => this.formReset()}
        >
          {' '}
          {this.props.translate('resetButton')}
        </Button>
        <Button
          variant='contained'
          // disabled={!this.state.isVisableBtn}
          disabled={!this.state.isVisableUserBtn}

          onClick={() => this.formSubmit()}
        >
          {' '}
          {this.props.translate('changePassword')}
        </Button>
      </div>
    )
  }
}
const ChangedPasword = withStyles(styles)
export default ChangedPasword(withSnackbar(changedPasword))
