import React from 'react'
import Grid from '@material-ui/core/Grid'
import SearchDevices from '../../../Devices/searchItems'
import DeviceSelector from '../../../Devices/DeviceSelector'
import DriverSelector from '../../../Devices/DriverSelector'
import BinderSelector from '../../../Trailer/BinderSelector'
import VehicleSelector from '../../../Devices/VehicleSelector'
import UserSelector from '../../../Users/userSelector'

const Modal = props => {
  return (
    <Grid container spacing={0} style={{ minHeight: 270 }}>
      {/* <Grid item sm={12} md={12} xs={12}>
          <h4 className='page-title-notification'>
            {props.translate('notificationFor')}
          </h4>
          <div style={{ paddingTop: 8 }}>
            <Button
              style={{ marginRight: 12 }}
              onClick={() => props.typeSelection('device')}
              className={(props.device ? 'active' : '') + ' button-grey'}
            >
              {props.translate('units')}
            </Button>
            <Button
              //onClick={() => props.typeSelection('driver')}
              style={{ marginRight: 12 }}
              className={(props.driver ? 'active' : '') + ' button-grey'}
            >
              {props.translate('driver')}
            </Button>
            <Button
              // onClick={() => props.typeSelection('passenger')}
              style={{ marginRight: 12 }}
              className={(props.passenger ? 'active' : '') + ' button-grey'}
            >
              {props.translate('passenger')}
            </Button>
          </div>
        </Grid> */}
      {(props.device && !(props.vehicle||props.binder||props.driver||props.user || props.parkingAlert)) && (
        <Grid item xs={12}>
          {/* <Button
            onClick={() => props.grouptypeSelection('byGroup')}
            style={{ marginRight: 12 }}
            className={(props.byGroup ? '' : 'active') + ' button-grey'}
          >
            {props.translate('trackers')}
          </Button>
          <Button
            onClick={() => props.grouptypeSelection('byGroup')}
            style={{ marginRight: 12 }}
            className={(props.byGroup ? 'active' : '') + ' button-grey'}
          >
            {props.translate('group')}
          </Button>
 */}
          {/* props.byGroup ? (
            <GroupSelector
              value={props.form.groupIds}
              onChange={props.onChangedGroup}
              rows={10}
            />
          ) : */( 
            <DeviceSelector
              value={props.form?.deviceIds||[]}
              onChange={props.onChangedDevice}
              hideIcons
              rows={10}
            />
          )
          }
        </Grid>
      )}
       {/* {props.driver && (
        <Grid item xs={12}>
          <SearchDevices
            api='drivers'
            fill
            isClearable
            placeholder={props.translate('searchDrivers')}
            onChange={props.onChangedDriver}
            value={props.driverIds}
          />
        </Grid>
      )} */}

      {props.driver && (
        <Grid item xs={12}>
          <DriverSelector
            hideIcons
            value={props.form?.driverIds||[]}
            disableModal={true}
            onChange={props.onChangedDriver}
            rows={8}
          />
     
        </Grid>
      )}
      {props.parkingAlert && (
        <Grid item xs={12}>
           <SearchDevices
              api='devices'
              placeholder={props.translate('searchUnits')}
              fill
              isMulti={false}
              isClearable
              onChange={props.onChangedParkingDevice}
              value={props.form?.deviceIds||[]}
          />
        </Grid>
      )}
      {props.vehicle && (
        <Grid item xs={12}>
          <VehicleSelector
            api='vehicle'
            fill
            hideIcons
            isClearable
            placeholder={props.translate('searchVehicle')}
            onChange={props.onChangedVehicle}
            value={props.form?.vehicleIds||[]}
          />
        </Grid>
      )}
       {props.user && (
        <Grid item xs={12}>
          <UserSelector
           fill
           hideIcons
           isClearable
           placeholder={props.translate('searhUsers')}
           onChange={props.onChangedUsers}
           value={props.form?.userIds||[]}
          />
        </Grid>
      )}
      {props.binder && (
        <Grid item xs={12}>
          <BinderSelector
            fill
            hideIcons
            isClearable
            placeholder={props.translate('searhBinders')}
            onChange={props.onChangedBinder}
            value={props.form?.binderIds ||[]}
          />
        </Grid>
      )}

      {props.passenger && (
        <Grid item xs={12}>
          <SearchDevices
            api='passengers'
            placeholder={props.translate('searchPassengers')}
            fill
            isClearable
            onChange={props.onChangedPassenger}
            value={props.form?.passengerIds||[]}
          />
        </Grid>
      )}
    </Grid>
  )
}
export default Modal
