import React, { Component } from 'react'
import TextField from './../../Components/common/TextFieldLg'
import Button from './../../Components/common/Button'
import Loader from './../../Layout/Loader'
import { logInUserInfo, getRoles } from './../../Actions/Users'
import { ServerInfo } from './../../Actions/serverSetting'
import { getLayer } from './../../Actions/Maps'
import { withLocalize } from 'react-localize-redux'
import { fetchDevicesList, fetchDevices } from './../../Actions/Devices'
import {
  displayErrorMessages,
  checkedDevelopmentType,
  getCookie,
  errorHandler,
  checkPrivileges
} from '../../Helpers/index'
import Checkbox from '../../Components/common/Checkbox'
import SocketController from '../../SocketController'
import { connect } from 'react-redux'
import Style from 'style-it'
import Notifications from 'react-notification-system-redux'
import { NavLink } from 'react-router-dom'
import InfoIcon from './../../assets/monitoring/info.svg'
import { themes } from '../../Reducers/Theme'
import instance from '../../axios'
import ReplayIcon from "@material-ui/icons/Replay";
import center from '@turf/center'
import { Tooltip } from '@material-ui/core'

const mapState = state => {
  return {
    themecolors: themes[2],
    devices: state.devices.data,
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers
  }
}

class LoginForm1 extends Component {
  constructor (props) {
    super(props)
    this.state = { 
      loading: false, 
      loginErrorMessage: '',
      captcha: this.generateCaptcha(),
      userInput: '',
      isCaptchaValid: false,
      isVisableUserBtn: false,

     }
    this.handleChange = this.handleChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.generateCaptcha = this.generateCaptcha.bind(this)
    this.generateRandomAlpha = this.generateRandomAlpha.bind(this)
  }



  // generateCaptcha() {
  //   const numericPart = Math.floor(Math.random() * 100);
  //   const alphaPart = this.generateRandomAlpha(4);
  //   return `${numericPart}${alphaPart}`;
  // }

 
   generateCaptcha() {
    let allCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
    'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
    't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    
    let generatedCaptcha = Array.from({ length: 6 }, () => {
      let randomCharacter = allCharacters[Math.floor(Math.random() * allCharacters.length)];
      return randomCharacter;
    }).join(''); // concatenate characters without spaces
  
    return generatedCaptcha;
  }
  generateRandomAlpha(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  handleRegenerateCaptcha = () => {
    this.setState({
      captcha: this.generateCaptcha(),
      userInput: '',
      isVisableUserBtn: false

    });
  };
  handleCaptchaChange = (event) => {
    this.setState({ userInput: event.target.value },()=>this.checkRequiredFields());
  };

  
  UNSAFE_componentWillMount () {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state !== ''
    ) {
      this.setState({
        failed: true,
        loginErrorMessage: this.props.location.state
      })
    }
    this.setState({
      email: this.getCookie('userName'),
      password: this.getCookie('pass')
    })
  }
  handleChange (event) {
    this.setState({
      [event.target.id]: event.target.value
    }
    // ,()=>this.checkRequiredFields()
    )
  }

  checkRequiredFields() {
    let { email, captcha, userInput} = this.state;
    if (
      email 
      //  && captcha &&
      // userInput &&
      // captcha === userInput
      // && isCaptchaValid 
    ) {
      if(process.env.REACT_APP_CAPTCHA_ENABLE ===  true){
        if (
          userInput &&
          captcha === userInput
          // && isCaptchaValid 
        ) {
          this.setState({
            isVisableUserBtn: true
          });
        }else{
          this.setState({
            isVisableUserBtn: false
          });
        }
      }
       else{
          this.setState({
            isVisableUserBtn: true
          });
      }
    } 
    else {
      this.setState({
        isVisableUserBtn: false
      });
    } 
  }

  handleLogin (event) {
    event.preventDefault()
    const { email, password } = this.state
    this.setState({
      failed: false,
      loading: true,
      loginErrorMessage: ''
    })
    let fetchUrl = checkedDevelopmentType()
    instance({
      url: `/api/session`,
      method: 'POST',
      data:new URLSearchParams({
        email: email,
        password: password,
        app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
        host: fetchUrl
      })
    })
          .then(userInfoFront => {
            // fetch call for Api  privileges
            let lang = userInfoFront.attributes.lang
            this.props.setActiveLanguage(lang || 'en')
            instance({
              url: `/api/privileges`,
              method: 'GET',
              params:{
                roleId: userInfoFront.roleId
              }
            })
                  .then(privileges => {
                    let privilegeKeys = []
                    privileges.map(item => {
                      privilegeKeys.push(item.key)
                      return null
                    })
                    localStorage.setItem(
                      'frontPrivileges',
                      JSON.stringify({ ...privilegeKeys })
                    )
                    if (privileges.length) {
                      this.props.dispatch(logInUserInfo(userInfoFront))
                      checkPrivileges('device')&&this.props.dispatch(fetchDevicesList(userInfoFront))
                      checkPrivileges('device')&&this.props.dispatch(fetchDevices(userInfoFront))

                      if (userInfoFront.userType === -1) {
                        // fetch(`/api/roles?all=true`)
                        instance({
                          url: `/api/roles`,
                          method: 'GET',
                          params:{
                            all: true
                          }
                        })
                        // .then(response => {
                        //   if (response.ok) {
                        //     response.json()
                            .then(role => {
                              this.props.dispatch(getRoles(role))
                          //   })
                          // }
                          // else{
                          //   throw response
                          // }
                        }).catch(error => {
                          // errorHandler(error, this.props.dispatch)
                        })
                      } else if (userInfoFront.userType !== 1 && checkPrivileges('role')) {
                        // fetch(`/api/roles?userId=${userInfoFront.id}`)
                        instance({
                          url: `/api/roles`,
                          method: 'GET',
                          params:{
                            userId: userInfoFront.id
                          }
                        })
                        // .then(response => {
                        //     if (response.ok) {
                        //       response.json()
                              .then(role => {
                                this.props.dispatch(getRoles(role))
                            //   })
                            // }
                            // else{
                            //   throw response
                            // }
                          }).catch(error => {
                            // errorHandler(error, this.props.dispatch)
                          })
                      }

                      // fetch(`/api/accounts?accountId=${userInfoFront.accountId}`)
                      instance({
                        url: `/api/accounts`,
                        method: 'GET',
                        params:{
                          accountId: userInfoFront.accountId
                        }
                      })
                        // .then(response => {
                        //   if (response.ok) {
                        //     response.json()
                            .then(server => {
                              if (server[0]) {
                                //implatation of whitelabeling to replace data
                                this.props.dispatch(ServerInfo(server[0]))
                                this.props.dispatch(
                                  getLayer({
                                    id:
                                      userInfoFront.map ||
                                      (server && server[0].map) ||
                                      'osm'
                                  })
                                )
                                if (
                                  this.props.location &&
                                  this.props.location.state
                                ) {
                                  this.props.history.push(
                                    this.props.location.state
                                  )
                                } else {
                                  this.props.history.push('/')
                                }
                              } else {
                                this.someThingWendWrong(
                                  'Account Data Not Found'
                                )
                              }
                            })
                        //   } else {
                        //     throw response
                        //   }
                        // })
                        .catch(error => {
                          // errorHandler(error, this.props.dispatch, this.errorCallBack)
                          this.setState({
                            failed: true,
                            password: '',
                            loading: false,
                            loginErrorMessage: displayErrorMessages(error.message)
                          })
                        })
                    } else {
                      this.setState({
                        failed: true,
                        password: '',
                        loading: false,
                        loginErrorMessage: 'Lack Of Privileges'
                      })
                    }
                  })
              //   } else {
              //     throw response
              //   }
              // })
              .catch(error => {
                // errorHandler(error, this.props.dispatch, this.errorCallBack)
                this.setState({
                  failed: true,
                  password: '',
                  loading: false,
                  loginErrorMessage: displayErrorMessages(error.message)
                })
              })
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch(error => {
        errorHandler(error, this.props.dispatch, this.errorCallBack)
      })
  }
  // errorMessage = e => {
    // if (e && e.text) {
    //   e.text().then(err => {
    //     console.log("login failed ==", err)
    //     this.setState({
    //       failed: true,
    //       password: '',
    //       loading: false,
    //       loginErrorMessage: displayErrorMessages(err)
    //     })
    //   })
    // }
  // }
  errorCallBack = (data) => {
    this.setState({
      failed: true,
      password: '',
      loading: false,
      loginErrorMessage: displayErrorMessages(data.message)
    })
}
  handleClick = e => {
    let { email, password } = this.state
    if (e.target.checked && email && password) {
      this.setCookie('userName', email)
      this.setCookie('pass', password)
    } else {
      this.setCookie('userName', '')
      this.setCookie('pass', '')
    }
  }
  getCookie (cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  setCookie (name, value, exdays) {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie =
      name + '= ' + encodeURIComponent(value) + ';' + expires + ';path=/login;'
  }
  someThingWendWrong = mgs => {
    this.setState({
      failed: true,
      password: '',
      loading: false,
      loginErrorMessage: this.props.translate(mgs)
    })
  }
  render () {
    if (this.state.loading) {
      return (
        <div className='loginLoaderWrapper'>
          <Loader />
        </div>
      )
    } else {
      let e = this.props.translate('userEmail') + ' *'
      return (
        <form
          className='loginForm'
          onSubmit={this.handleLogin}
          autoComplete='off'
        >
          <div className='login-logo-wrapper'>
            <img
              className='login-logo'
              src={this.props.whiteLabling.logo}
              alt='logo'
            />
          </div>
          {this.state.failed && (
            <div
              className='alert alert-danger'
              style={{
                color: this.props.themecolors['error'],
              }}
            >
              <svg fill='currentColor' width={16} height={16}>
                <use xlinkHref={`${InfoIcon}#icon`} />
              </svg>
              {this.props.translate(this.state.loginErrorMessage)}
            </div>
          )}
          <div className='form-group'>
            <TextField
              themecolors={this.props.themecolors}
              id='email'
              variant='outlined'
              label={e}
              type='text'
              value={this.state.email || ''}
              onChange={this.handleChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className='form-group'>
            <TextField
              themecolors={this.props.themecolors}
              id='password'
              variant='outlined'
              label={this.props.translate('userPassword') + ' *'}
              type='password'
              value={this.state.password || ''}
              onChange={this.handleChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          {process.env.REACT_APP_CAPTCHA_ENABLE ===  true && <>
             <p style={{margin:"10px 0px 0px 0px",fontSize:12, color:this.props.themecolors.textColor}}>Captcha Code:</p>
            <div 
            className='form-group'
            style={{
              display:"flex",  
              justifyContent:"space-between", 
              alignItems:"center",
            }}>
              <p
              style={{
                display:"flex",  
                justifyContent:"center", 
                fontSize:"28px", 
                padding:"2px  5px",
                fontFamily:"Henny Penny , Bradley Hand, cursive",
                backgroundColor:"grey", 
                borderRadius:"2px",
                color:"dimgray", 
                width:"85%",
                textDecoration:"line-through",
                minHeight:"42px",
                userSelect: "none",
            }}
              >{this.state.captcha || ""}</p>
            <p style={{
                display:"flex",  
                justifyContent:"center", 
                fontSize:"28px", 
                padding:"2px  5px",
                backgroundColor:this.props.themecolors.themeListingColor, 
                borderRadius:"2px",
                width:"14%",
              alignItems:"center",
              userSelect: "none",

                minHeight:"42px"
            }}>
            <Tooltip title="Regenerate Captcha"  style={{color:this.props.themecolors.textColor}}>
              <ReplayIcon onClick={this.handleRegenerateCaptcha} className="actionButton" />
              </Tooltip>
              </p>
            </div>
            <div className='form-group'>

            <TextField
              id='captcha'
              label='Enter the CAPTCHA below:'
              type='text'
              value={this.state.userInput || ''}
              onChange={this.handleCaptchaChange}
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderColor: this.state.userInput === this.state.captcha ? 'green' : 'red',
                },
                classes: {
                  root: 'theme-cssOutlinedInput',
                  error: 'theme-input-error',
                  input: 'theme-input',
                  focused: 'theme-cssFocused',
                  notchedOutline: 'theme-notchedOutline',
                },
              }}
            />
            </div>
           </>}
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px'
            }}
          >
            <div
              style={{
                color: this.props.themecolors.textColor,
                marginRight: 'auto',
                marginLeft: -12
              }}
            >
              <Checkbox
                disableRipple
                onChange={event => this.handleClick(event)}
                label={this.props.translate('rememberme')}
              />
            </div>
            <NavLink
              to={'/login'}
              onClick={e => this.props.switchView('forgot')}
            >
              {this.props.translate('forgotPassword')}
            </NavLink>
          </div>
          <p style={{ textAlign: 'center' }}>
            <Button 
            //  disabled={!this.state.isVisableUserBtn}  
             disabled={process.env.REACT_APP_CAPTCHA_ENABLE ===  true ? !this.state.isVisableUserBtn : false}
             themecolors={this.props.themecolors} type='submit' className='btn-submit'>
              {this.props.translate('loginLogin')}
            </Button>
          </p>

          {this.props.logInUser && this.props.logInUser.id ? (
            <SocketController />
          ) : null}
          <div className='login-footer'>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate('about')}
            </a>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate('termsAndConditions')}
            </a>
            <a
              href={
                this.props.whiteLabling &&
                this.props.whiteLabling.privacyPolicyLink
              }
            >
              {this.props.translate('privacy')}
            </a>
          </div>
        </form>
      )
    }
  }
}

const mapStateToProps = connect(mapState)

export const LoginForm = mapStateToProps(withLocalize(LoginForm1))

class ForgotForm1 extends Component {
  constructor (props) {
    super(props)
    this.state = {
        email: '', 
        errorMessage: '',
        loading: false,
        captcha: this.generateCaptcha(),
        userInput: '',
        isCaptchaValid: false,
        isVisableUserBtn: false,
       }
  }


  generateCaptcha() {
    let allCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd',
    'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's',
    't', 'u', 'v', 'w', 'x', 'y', 'z', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    
    let generatedCaptcha = Array.from({ length: 6 }, () => {
      let randomCharacter = allCharacters[Math.floor(Math.random() * allCharacters.length)];
      return randomCharacter;
    }).join(''); // concatenate characters without spaces
  
    return generatedCaptcha;
  }

  generateRandomAlpha(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  handleRegenerateCaptcha = () => {
    this.setState({
      captcha: this.generateCaptcha(),
      userInput: '',
      isVisableUserBtn: false

    });
  };
  handleSubmit = event => {
    event.preventDefault()
    const { email } = this.state
    this.setState(
      {
        loading: true,
        errorMessage: ''
      },
      () => {
        instance({
          url: `/api/password/forget`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
          },
          data:(`email= ${email}`)
        })
            .then(res => {
                this.setState({
                  loading: false,
                  errorMessage: this.props.translate('emailSentSuccessfully')
                })

                this.props.dispatch(
                  Notifications.success({
                    message: this.props.translate('emailSentSuccessfully'),
                    autoDismiss: 10
                  })
                )
        }).catch(error => {errorHandler(error, this.props.dispatch,this.errorCallBack, true)})
      }
    )
  }
  errorCallBack = (data) =>  {
    if(data.statusCode === '401'){
      this.setState({
        loading: false,
        errorMessage: this.props.translate('notEnoughPrivileges')
      })
    }else{
      this.setState({
        loading: false,
        errorMessage: this.props.translate('invalidEmailAddress')
      })
    }
  }


  checkRequiredFields() {
    let { email, captcha, userInput} = this.state;
    if (
      email 
      //  && captcha &&
      // userInput &&
      // captcha === userInput
      // && isCaptchaValid 
    ) {
      if(process.env.REACT_APP_CAPTCHA_ENABLE ===  true){
        if (
          userInput &&
          captcha === userInput
          // && isCaptchaValid 
        ) {
          this.setState({
            isVisableUserBtn: true
          });
        }else{
          this.setState({
            isVisableUserBtn: false
          });
        }
      }
       else{
          this.setState({
            isVisableUserBtn: true
          });
      }
    } 
    else {
      this.setState({
        isVisableUserBtn: false
      });
    } 
  }
  handleCaptchaChange = (event) => {
    this.setState({ userInput: event.target.value },()=>this.checkRequiredFields());
  };
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }
    // ,()=>this.checkRequiredFields()
    )
  }
  render () {
    let { loading, errorMessage } = this.state
    if (loading) {
      return (
        <div className='loginLoaderWrapper'>
          <Loader />
        </div>
      )
    } else {
      return (
        <form
          className='loginForm'
          onSubmit={this.handleSubmit}
          autoComplete='off'
        >
          <Style>
            {`
            a {
              color: ${this.props.themecolors.textColor}
            }
            .loginWrapperInner {
              background: ${this.props.themecolors.backgroundColor}
            }
            .login-footer {
              border-top: 1px solid ${this.props.themecolors.themeLightColor};
            }
            `}
          </Style>
          <div className='login-logo-wrapper'>
            <img
              className='login-logo'
              src={this.props.whiteLabling.logo}
              alt='logo'
            />
          </div>
          <p
            style={{
              marginTop: 0,
              marginBottom: 30,
              fontSize: 12,
              color: this.props.themecolors.textColor
            }}
          >
            {this.props.translate('resetPasswordMessage')}
          </p>
          {/* {console.log("errorMessage====", errorMessage)} */}
          {/* {errorMessage && (
            <div
              className='alert alert-danger'
              style={{
                color:
                  errorMessage === 'Email sent successfully'
                    ? this.props.themecolors['success']
                    : this.props.themecolors['error'],
                background: this.props.themecolors.textColor
              }}
            >
              <svg fill='currentColor' width={16} height={16}>
                <use xlinkHref={`${InfoIcon}#icon`} />
              </svg>
              {errorMessage}
            </div>
          )} */}
          <TextField
            themecolors={this.props.themecolors}
            id='email'
            label={this.props.translate('userEmail') + ' *'}
            type='text'
            value={this.state.email || ''}
            onChange={this.handleChange}
            margin='dense'
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
          />
         {process.env.REACT_APP_CAPTCHA_ENABLE ===  true && <>
          <p style={{margin:"10px 0px 0px 0px",fontSize:12, color:this.props.themecolors.textColor}}>Captcha Code:</p>
          <div 
          className='form-group'
          style={{
            display:"flex",  
            justifyContent:"space-between", 
            alignItems:"center",
          }}>
            <p
            style={{
              display:"flex",  
              justifyContent:"center", 
              fontSize:"28px", 
              padding:"2px  5px",
              fontFamily:"Henny Penny , Bradley Hand, cursive",
              backgroundColor:"grey", 
              borderRadius:"2px",
              color:"dimgray", 
              width:"85%",
              textDecoration:"line-through",
              minHeight:"42px",
              userSelect: "none",

          }}
            >{this.state.captcha || ""}</p>
          <p style={{
              display:"flex",  
              justifyContent:"center", 
              fontSize:"28px", 
              padding:"2px  5px",
              backgroundColor:this.props.themecolors.themeListingColor, 
              borderRadius:"2px",
              width:"14%",
            alignItems:"center",

              minHeight:"42px"
          }}>
          <Tooltip title="Regenerate Captcha"  style={{color:this.props.themecolors.textColor}}>
            <ReplayIcon onClick={this.handleRegenerateCaptcha} className="actionButton" />
            </Tooltip>
            </p>
          </div>
          <div className='form-group'>

          <TextField
            id='captcha'
            label='Enter the CAPTCHA below:'
            type='text'
            value={this.state.userInput || ''}
            onChange={this.handleCaptchaChange}
            margin='dense'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: {
                borderColor: this.state.userInput === this.state.captcha ? 'green' : 'red',
              },
              classes: {
                root: 'theme-cssOutlinedInput',
                error: 'theme-input-error',
                input: 'theme-input',
                focused: 'theme-cssFocused',
                notchedOutline: 'theme-notchedOutline',
              },
            }}
          />
          </div>
           </>}

          <p style={{ textAlign: 'center', marginTop: 0 }}>
            <Button 
            // disabled={!this.state.isVisableUserBtn} 
            disabled={process.env.REACT_APP_CAPTCHA_ENABLE ===  true ? !this.state.isVisableUserBtn : false}
            themecolors={this.props.themecolors} type='submit' className='btn-submit'>
              {this.props.translate('submit')}
            </Button>
          </p>
          <p style={{ textAlign: 'center' }}>
            <NavLink
              to={'/login'}
              onClick={e => this.props.switchView('login')}
            >
              {this.props.translate('loginTitle')}
            </NavLink>
          </p>

          <div className='login-footer'>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate('about')}
            </a>
            <a
              href={
                this.props.whiteLabling && this.props.whiteLabling.termsLink
              }
            >
              {this.props.translate('termsAndConditions')}
            </a>
            <a
              href={
                this.props.whiteLabling &&
                this.props.whiteLabling.privacyPolicyLink
              }
            >
              {this.props.translate('privacy')}
            </a>
          </div>
        </form>
      )
    }
  }
}

export const ForgotForm = mapStateToProps(withLocalize(ForgotForm1))
