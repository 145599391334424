import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Table from '../../../Components/common/TableMultiCheckbox'

export default class CommonTableSection extends Component {
  render () {
    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Table
            {...this.props}
            title={this.props.label}
            disableHead
            checkHandleChange={this.props.component_Selection}
            rows={this.props.componentData}
            isEditable
            searchable
            className='custom-table2'
          />
        </Grid>
      </Grid>
    )
  }
}
