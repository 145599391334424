import React, { Component } from 'react'
import Layout from './../../Layout'
import { Grid } from '@material-ui/core'
import Card from './components/Card/Card'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  fetchSummery,
  applyDevicesFilter,
  applyDevicesCatFilter,
  resetFilters,
  resetCatFilters
} from './../../Actions/Devices'
import barChartDefault from './../../Components/common/Charts/barChartDefault'
import { ReactComponent as TrailerIcon } from './../../assets/dashboard/truck.svg'
import { ReactComponent as NotRegistered } from './../../assets/dashboard/not-registered.svg'
import { ReactComponent as GPSNotSupport } from './../../assets/dashboard/gps-no-support.svg'
import { ReactComponent as OnlineStatus } from './../../assets/dashboard/online.svg'
import { ReactComponent as OfflineStatus } from './../../assets/dashboard/offline.svg'
import { ReactComponent as DriverIcon } from './../../assets/dashboard/driver.svg'
import { ReactComponent as AssignedIcon } from './../../assets/dashboard/assigned.svg'
import { ReactComponent as UnAssignedIcon } from './../../assets/dashboard/not-assigned.svg'
import { ReactComponent as MovingIcon } from './../../assets/dashboard/van-moving.svg'
import { ReactComponent as IdlingIcon } from './../../assets/dashboard/idling.svg'
import { ReactComponent as TowingIcon } from './../../assets/dashboard/tow-truck.svg'
import { ReactComponent as ParkingIcon } from './../../assets/dashboard/parking.svg'
import { ReactComponent as GPSNotUpdated } from './../../assets/dashboard/gps-not-updated.svg'
import { ReactComponent as StopIcon } from './../../assets/dashboard/stop.svg'
import { ReactComponent as UnknownIcon } from './../../assets/dashboard/unknown.svg'
import { ReactComponent as PowerCutIcon } from './../../assets/monitoring/powerCut.svg'
import 'highcharts'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'
import Skeleton from '@material-ui/lab/Skeleton'
import Button from '../../Components/common/Button'
import Dialog from '../../Components/common/Dialog'
import DeviceSelector from '../../Components/Devices/DeviceSelector'
import DriverSelector from '../../Components/Devices/DriverSelector'
import { DistanceUnits, fonts } from '../../Helpers'
import Scrollbar from 'react-scrollbars-custom'

const initState = {
  userInfoFront: {},
  userFetch: false,
  devicesFetch: false,
  isFetching: false,
  enginHourReport: false,
  enginHourReportLoading: false,
  usersCount: {},
  devicesCount: {},
  report: {},
  mileage: {},
  pie1: null,
  positionsFetched: false,
  driverFetched: false,
  motionSelector: false,
  motionSelectorKey: '',
  deviceSelector: false,
  deviceSelectorKey: '',
  categorySelector: false,
  categorySelectorKey: '',
  geoSelector: false,
  geoSelectorKey: '',
  driverSelector: false,
  driverIds: [],
  driverSelectorKey: '',
  pieChart2: null,
  resArr2: {},
  resArr: {},
  drivers: {},
}

/* function compare (b, a) {
  if (a.y < b.y) {
    return -1
  }
  if (a.y > b.y) {
    return 1
  }
  return 0
} */

const colors = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#2c2b79',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#2c2b79',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#434348',
  '#90ed7d'
]

class Dashboard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...initState
    }
  }

  componentWillUnmount () {
    this.setState({
      ...initState
    })
    this.props.dispatch(resetFilters())
    this.props.dispatch(resetCatFilters())
  }

  resetSelectors = () => {
    this.setState({
      motionSelector: false,
      motionSelectorKey: '',
      geoSelector: false,
      geoSelectorKey: '',
      deviceSelector: false,
      deviceSelectorKey: '',
      categorySelector: false,
      categorySelectorKey: '',
      driverSelector: false,
      driverSelectorKey: ''
    })
  }

  openCategorySelector = (key, name) => {
    this.resetSelectors()
    if (this.state.categorySelector === false) {
      this.showSelectedUnits(key, 'category')
    }
    this.setState({
      categorySelector: !this.state.categorySelector,
      categorySelectorKey: name
    })
  }
  openMotionSelector = (key, name) => {
    this.resetSelectors()
    if (this.state.motionSelector === false) {
      this.showSelectedUnits(key, 'device')
    }
    this.setState({
      motionSelector: !this.state.motionSelector,
      motionSelectorKey: name,
      motionSelectorName:key
    })
  }
  openDeviceSelector = (key, name) => {
    this.resetSelectors()
    if (this.state.deviceSelector === false) {
      this.showSelectedUnits(key, 'device')
    }
    this.setState({
      deviceSelector: !this.state.deviceSelector,
      deviceSelectorKey: name
    })
  }
  openGeoSelector = (key, name) => {
    this.resetSelectors()
    if (this.state.geoSelector === false) {
      this.showSelectedUnits(key, 'geofence')
    }
    this.setState({
      geoSelector: !this.state.geoSelector,
      geoSelectorKey: name
    })
  }

  openDriverSelector = (driverIds, name) => {
    this.resetSelectors()

    if (this.state.driverSelector === false) {
      this.setState({ driverIds })
    }
    this.setState({
      driverSelector: !this.state.driverSelector,
      driverSelectorKey: name,
      modalData: {}
    })
  }

  componentWillMount () {
    this.prepareStates(this.props)
    if(this.props.logInUsers && this.props.logInUsers.id) {
      this.props.dispatch(fetchSummery())
    }
  }

  prepareDriver = ({ drivers, positions }) => {
    let fixDriver = null;
    const driverLinked = [];
    let driverLinked2 = [];
  
      fixDriver = this.props.devices3.filter(data => data.attributes.fixDriverId )
      fixDriver.map(d => {
        driverLinked.push(d.attributes.fixDriverId)
      })
      drivers.map(dr => {
        if (dr && dr.id && driverLinked.includes(dr.id)) {
          driverLinked2.push(dr.uniqueId)
        }
        return null
      })

    let ids = []
    let drIds = []
    let inactive = []
    positions.map(pos => {
      if (
        pos &&
        pos.attributes &&
        pos.attributes.driverUniqueId &&
        this.props.allComputedAttributes.includes(pos.deviceId) &&
        pos.attributes.driverUniqueId !== '0'
      ) {
        if (
          !moment(pos.serverTime).isBefore(moment().subtract(10, 'minutes'))
        ) {
          ids.push(pos.attributes.driverUniqueId)
        } else {
          inactive.push(pos.attributes.driverUniqueId)
        }
      }
      return null
    })
    drivers.map(dr => {
      if (dr && dr.uniqueId && dr.uniqueId !== '0') {
        drIds.push(dr.uniqueId)
      }
      return null
    })
    const assigned = drIds.filter(d => driverLinked2.includes(d) || ids.includes(d))
    const unassigned = drIds.filter(d => !ids.includes(d)&&!driverLinked2.includes(d))
    const unknown = ids.filter(d => !drIds.includes(d))
    this.setState({
      drivers: {
        assigned,
        unassigned,
        unknown,
        inactive,
        // total:assigned.length + unassigned.length + unknown.length + inactive.length //it actually adds all values and shows wrong count 
        total:drivers.length}
    })
  }

  prepareStates = n => {
    if (
      n.positions &&
      // n.positions.length && //comment for new user created first staff(driver) not showing on dashboard
      n.drivers &&
      n.drivers.length &&
      n.allComputedAttributes 
      // n.allComputedAttributes.length &&      //comment for new user created first staff(driver) not showing on dashboard
      // this.state.driverFetched === false
    ) {
      this.setState(
        {
          driverFetched: true
        },
        () => {
          this.prepareDriver(n)
        }
      )
    }

    if (n.devices3 && n.devices3.length) {
      const notification = n.allNotifications

      let notRegistered = notification.notRegistered

      let cat = {}

      n.devices3.map(v => {
        let c = v.category || 'default'
        cat[c] = cat[c] || 0
        cat[c]++
        return null
      })

      if (!this.state.pie1) {
        let objList = { total: 0, data: [] }
        Object.keys(cat).map((e, i) => {
          objList.total = n.devices3.length
          objList.data.push({
            value: cat[e],
            color: colors[i],
            name: this.props.translate(e),
            key: e,
            image: <img src={`/assets/category/default/${e}.svg`} alt='' />
          })
          return null
        })

        this.setState({
          pie1: objList
        })
      }

      let resArr2 = {
        total:
          notRegistered.length +
          notification.statusOnline.length +
          notification.statusOffline.length +
          notification.gpsNotUpdated.length,
        online: notification.statusOnline.length,
        offline: notification.statusOffline.length,
        notRegistered: notification.notRegistered.length,
        gpsNotUpdated: notification.gpsNotUpdated.length
      }

      let resArr = {}
      const total =
        notification.Moving.length +
        notification.overSpeed.length +
        notification.gpsNotUpdated.length +
        notification.Idling.length +
        notification.Towing.length +
        notification.statusParking.length +
        notification.stop.length +
        notification.statusOffline.length
      resArr = {
        total,
        data: [
          {
            value: notification.Moving.length,
            color: '#28a745',
            name: this.props.translate('moving'),
            key: 'Moving',
            image: <MovingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.Idling.length,
            color: '#e4d354',
            name: this.props.translate('idling'),
            key: 'Idling',
            image: <IdlingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.Towing.length,
            color: '#f45b5b',
            name: this.props.translate('towing'),
            key: 'Towing',
            image: <TowingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.statusParking.length,
            color: '#1E90FF',
            name: this.props.translate('parking'),
            key: 'statusParking',
            image: <ParkingIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.gpsNotUpdated.length,
            color: 'rgb(25, 118, 210)',
            name: this.props.translate('GPSNotUpdated'),
            key: 'gpsNotUpdated',
            image: <GPSNotUpdated width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.stop.length,
            color: '#87CEFA',
            name: this.props.translate('stop'),
            key: 'stop',
            image: <StopIcon width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.statusOffline.length,
            color: '#dc3545',
            name: this.props.translate('unknown'),
            key: 'statusOffline',
            image: <OfflineStatus width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.notRegistered.length,
            color: '#566374',
            key: 'notRegistered',
            name: this.props.translate('tracker.notRegisteredYet'),
            image: <NotRegistered width={20} height={20} fill="currentColor" />
          },
          {
            value: notification.powerCut.length,
            color: '#566374',
            key: 'powerCut',
            name: this.props.translate('devicePowerCutOn'),
            image: <PowerCutIcon width={20} height={20} fill="currentColor" />
          }
        ]
      }

      this.setState({
        resArr,
        pieChart2: resArr2
      })
    }

    let reports = n.engineHourSummery.data

    const fontId = n.ServerSetting && n.ServerSetting.attributes && n.ServerSetting.attributes.fontFamily || 'ff-4';
    const fontFamily = fonts[fontId];
    if (
      reports &&
      reports.length &&
      this.state.enginHourReportLoading === false
    ) {
      this.setState(
        {
          enginHourReportLoading: true
        },
        () => {
          let report = reports

          if (report && report.length) {
            let idling = [
              {
                name: this.props.translate('reportEngineHours'),
                color: '#e4d354',
                borderColor: '#e4d354',
                data: []
              }
            ]
            let mileage = [
              {
                name: this.props.translate('sensorsTranslation.mileage'),
                color: '#22b2d4',
                borderColor: '#22b2d4',
                data: []
              }
            ]
            const data = []
            const data2 = []
            let d = {},
              m = {}
              report.map(row => {

                row.data && row.data.map(c => {
                  if(row.type === 'hours') {
                    data.push({
                      y: c.hoursRaw / (1000 * 60 * 60),
                      x: c.name,
                      colorIndex: c.hours
                    })
                  } else {
                    const v = parseFloat((c.totalDistance / 1000).toFixed(2))
                    data2.push({
                      y: v > 2000 || v < 0 ? 0 : v,
                      x: c.name
                    })
                  }
                  return null
                })
                return null
              })
  
              /* data.sort(compare)
              data2.sort(compare) */

            const labels = data.map(d => d.x)
            const labels2 = data2.map(d => d.x)

            idling[0].data = data.map(d => ({
              y: d.y,
              colorIndex: d.colorIndex
            }))

            mileage[0].data = data2.map(d => d.y)

            let barChart1 = {
              config: barChartDefault().initializeWithOptions({
                chart: {
                  height: '300px'
                },

                title: {
                  text: this.props.translate('reportEngineHours'),
                  style: {
                    color: this.props.themecolors.textColor,
                    fontFamily
                  }
                },

                xAxis: {
                  lineColor: 'transparent',
                  tickColor: 'transparent',
                  categories: labels,
                  labels: {
                    style: {
                      color: this.props.themecolors.textColor,
                      fontFamily
                    }
                  }
                },
                yAxis: {
                  gridLineWidth: 0,
                  title: {
                    text: null,
                    style: {
                      color: this.props.themecolors.textColor,
                      fontFamily
                    }
                  },
                  labels: false /* {
                    style: {
                      color: this.props.themecolors.textColor,
                      fontFamily
                    }
                  } */
                },
                legend: false /* {
                  reversed: true,
                  itemStyle: {
                    color: this.props.themecolors.textColor,
                    fontFamily
                  }
                } */,
                plotOptions: {
                  series: {
                    stacking: 'normal',
                    borderRadius: 7,
                    text: {
                      style: {
                        color: this.props.themecolors.textColor,
                        fontFamily
                      }
                    }
                  }
                },
                series: idling,
                tooltip: {
                  formatter: function () {
                    return this.x + ': <strong>' + this.colorIndex + '</strong>'
                  }
                }
              })
            }
            let distanceUnit =
              this.props.logInUsers &&
              this.props.logInUsers.attributes &&
              this.props.logInUsers.attributes.distanceUnit
                ? this.props.logInUsers.attributes.distanceUnit
                : 'km'
            let barChart2 = {
              config: barChartDefault().initializeWithOptions({
                chart: {
                  height: '300px'
                },

                title: {
                  text: this.props.translate('deviceMileage'),
                  style: {
                    color: this.props.themecolors.textColor,
                    fontFamily
                  }
                },

                xAxis: {
                  categories: labels2,
                  lineColor: 'transparent',
                  tickColor: 'transparent',
                  labels: {
                    style: {
                      color: this.props.themecolors.textColor,
                      fontFamily
                    }
                  }
                },
                yAxis: {
                  gridLineWidth: 0,
                  title: {
                    text: null,
                    style: {
                      color: this.props.themecolors.textColor,
                      fontFamily
                    }
                  },
                  labels: false /* {
                    style: {
                      color: this.props.themecolors.textColor,
                      fontFamily
                    }
                  } */
                },
                legend: false /* {
                  reversed: false,
                  itemStyle: {
                    color: this.props.themecolors.textColor,
                    fontFamily
                  },
                  itemStyle: {
                    color: this.props.themecolors.textColor,
                    fontFamily
                  }
                } */,
                plotOptions: {
                  series: {
                    //stacking: 'normal',
                    borderRadius: 7,
                    text: {
                      style: {
                        color: this.props.themecolors.textColor,
                        fontFamily
                      }
                    }
                  }
                },
                series: mileage,
                tooltip: {
                  formatter: function () {
                    return (
                      this.x +
                      ': <strong>' +
                      DistanceUnits(this.y * 1000, distanceUnit) +
                      '</strong>'
                    )
                  }
                }
              })
            }

            this.setState({
              report: barChart1,
              mileage: barChart2,
              enginHourReportLoading: false
            })
          }
        }
      )
    }
  }

  componentWillReceiveProps (n) {
    if (
      this.state.isFetching === false &&
      n.positions &&
      n.positions.length &&
      n.ServerSetting &&
      n.logInUsers &&
      !(n.engineHourSummery && n.engineHourSummery.data)
    ) {
      this.setState({ isFetching: true }, () => {
        n.dispatch(
          fetchSummery({
            positions: n.positions,
            ServerSetting: n.ServerSetting,
            logInUsers: n.logInUsers
          })
        )
      })
    }
    this.prepareStates(n)
  }

  checkLastResponseTime = (posDate, lastUpdate) => {
    return (
      posDate &&
      moment(posDate).isBefore(moment().subtract(10, 'minutes')) &&
      !moment(lastUpdate).isBefore(moment().subtract(10, 'minutes'))
    )
  }

  componentDidCatch (error, errorInfo) {
    console.log(error, errorInfo)
  }

  showSelectedUnits = (data, type) => {
    if (type === 'device') {
      let newList = [].concat(data)

      this.props.dispatch(applyDevicesCatFilter([]))
      this.props.dispatch(applyDevicesFilter(newList))
    }

    if (type === 'geofence') {
      let newList = ['geoIds']
      this.props.dispatch(applyDevicesCatFilter([]))
      this.props.dispatch(applyDevicesFilter(newList, data))
    }

    if (type === 'category') {
      let newList = [].concat(data)

      this.props.dispatch(applyDevicesFilter([]))
      this.props.dispatch(applyDevicesCatFilter(newList))
    }
  }

  render () {
    const d = this.props.drivers;
    const g = this.props.geofences;
    const { attributes } = this.props.logInUsers
    const topRow =  g && g.length === 0 && d && d.length === 0 ? 4 : 3;
    return (
      <Layout
        {...this.props}
        noSidebar
        classFromChildren={'has-padding no-sidebar dashboard-page'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={topRow}>
            <Card themecolors={this.props.themecolors}>
                  {!this.state.deviceSelector ? (
                    <>
                      <h3
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          textAlign: 'center',
                          marginBottom: 0,
                          marginTop: 8
                        }}
                      >
                        {this.props.translate('unitsStatus')}
                      </h3>
                      <h3
                        style={{
                          fontSize: 14,
                          fontWeight: 700,
                          marginTop: 8,
                          marginBottom: 8
                        }}
                      >
                        <span>
                          <TrailerIcon style={{
                              verticalAlign: 'middle',
                              marginRight: 10,
                              width: 40,
                              display: 'inline-block'
                            }} />
                          <span
                            style={{
                              fontSize: 30,
                              verticalAlign: 'middle',
                              marginRight: 10,
                              display: 'inline-block'
                            }}
                          >
                            {this.props.devices3.length}
                          </span>
                          <span
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle'
                            }}
                          >
                            {this.props.translate('trackers')}
                          </span>
                        </span>
                      </h3>
                    </>
                  ) : (
                    <>
                      <h3
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          fontWeight: 400,
                          textAlign: 'left',
                          marginBottom: 8,
                          marginTop: 8,
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>
                          {this.props.translate(this.state.deviceSelectorKey)} (
                          {this.props.totalDevices})
                        </span>
                        <Button onClick={e => this.openDeviceSelector(null)}>
                         {this.props.translate('sharedBack')} 
                        </Button>
                      </h3>
                    </>
                  )}
                {!this.state.deviceSelector ? (
                  <>
                    {this.state.pieChart2 && this.state.pieChart2.total ? (
                      <div className='driver-state-wrap'>
                        {/* <h3>{this.state.pieChart2.total} Total</h3> */}
                        <div className='driver-state-container'>
                          <div
                            className='driver-state2 online hover'
                            onClick={e =>
                              this.openDeviceSelector(
                                'statusOnline',
                                this.props.translate('tracker.online')
                              )
                            }
                          >
                            <OnlineStatus width={32} height={32} fill="currentColor" />
                            <h3>{this.state.pieChart2.online}</h3>
                            <p>{this.props.translate('tracker.online')}</p>
                          </div>
                          <div
                            className='driver-state2 offline hover'
                            onClick={e =>
                              this.openDeviceSelector(
                                'statusOffline',
                                this.props.translate('tracker.offline')
                              )
                            }
                          >
                            <OfflineStatus width={32} height={32} fill="currentColor" />
                            <h3>{this.state.pieChart2.offline}</h3>
                            <p>{this.props.translate('tracker.offline')}</p>
                          </div>
                          <div
                            className='driver-state2 not-register hover'
                            onClick={e =>
                              this.openDeviceSelector(
                                'notRegistered',
                                this.props.translate('tracker.notRegisteredYet')
                              )
                            }
                          >
                            <NotRegistered width={32} height={32} fill="currentColor" />
                            <h3>{this.state.pieChart2.notRegistered}</h3>
                            <p>
                              {this.props.translate('tracker.notRegisteredYet')}
                            </p>
                          </div>
                          <div
                            className='driver-state2  not-update hover'
                            onClick={e =>
                              this.openDeviceSelector(
                                'gpsNotUpdated',
                                this.props.translate('tracker.gpsNotUpdated')
                              )
                            }
                          >
                            <GPSNotSupport width={32} height={32} fill="currentColor" />
                            <h3>{this.state.pieChart2.gpsNotUpdated}</h3>
                            <p>
                              {this.props.translate('tracker.gpsNotUpdated')}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <BarGraphSkeleton3 />
                    )}
                  </>
                ) : null}
                {this.state.deviceSelector ? (
                  <div
                    style={{
                      paddingBottom: 10
                    }}
                  >
                    <DeviceSelector readOnly rows={8} />
                  </div>
                ) : null}
            </Card>
          </Grid>
          {this.props.drivers && this.props.drivers.length ? (
            <Grid item xs={12} md={4} lg={topRow}>
              <Card themecolors={this.props.themecolors}>
                    {!this.state.driverSelector ? (
                      <>
                        <h3
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            textAlign: 'center',
                            marginBottom: 0,
                            marginTop: 8
                          }}
                        >
                          {this.props.translate('sharedDrivers')}
                        </h3>
                        <h3
                          style={{
                            fontSize: 14,
                            fontWeight: 700,
                            marginTop: 8,
                            marginBottom: 8
                          }}
                        >
                          <span>
                            <DriverIcon style={{
                              verticalAlign: 'middle',
                              marginRight: 10,
                              width: 40,
                              display: 'inline-block'
                            }} />
                            <span
                              style={{
                                fontSize: 30,
                                verticalAlign: 'middle',
                                marginRight: 10,
                                display: 'inline-block'
                              }}
                            >
                              {this.state.drivers.total}
                            </span>
                            <span
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle'
                              }}
                            >
                              {this.props.translate('sharedDrivers')}
                            </span>
                          </span>
                        </h3>
                      </>
                    ) : this.state.driverSelector ? (
                      <h3
                        style={{
                          fontSize: 18,
                          display: 'flex',
                          fontWeight: 400,
                          textAlign: 'left',
                          marginBottom: 8,
                          marginTop: 8,
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>
                          {this.props.translate(this.state.driverSelectorKey)} (
                          {this.state.driverIds.length})
                        </span>
                        <Button onClick={e => this.openDriverSelector(null)}>
                         {this.props.translate('sharedBack')}
                        </Button>
                      </h3>
                    ) : null}
                  {!this.state.driverSelector ? (
                    <>
                      {this.state.drivers && this.state.drivers.total ? (
                        <>
                          <div className='driver-state-wrap'>
                            <div className='driver-state-container'>
                              <div
                                className='driver-state hover'
                                onClick={e =>
                                  this.openDriverSelector(
                                    this.state.drivers.assigned,
                                    this.props.translate('assigned')
                                  )
                                }
                              >
                                <AssignedIcon width={32} height={32} fill="currentColor" />
                                <h3>{this.state.drivers.assigned.length}</h3>
                                <p>{this.props.translate('assigned')}</p>
                              </div>
                              <div
                                className='driver-state hover'
                                onClick={e =>
                                  this.openDriverSelector(
                                    this.state.drivers.unassigned,
                                    this.props.translate('unassigned')
                                  )
                                }
                              >
                                <UnAssignedIcon width={32} height={32} fill="currentColor" />
                                <h3>{this.state.drivers.unassigned.length}</h3>
                                <p>{this.props.translate('unassigned')}</p>
                              </div>
                              <div
                                className='driver-state hover'
                                onClick={e =>
                                  this.openDriverSelector(
                                    this.state.drivers.unknown,
                                    this.props.translate('unknown')
                                  )
                                }
                              >
                                <NotRegistered width={32} height={32} fill="currentColor" />
                                <h3>{this.state.drivers.unknown.length}</h3>
                                <p>{this.props.translate('unknown')}</p>
                              </div>
                              <div
                                className='driver-state hover'
                                onClick={e =>
                                  this.openDriverSelector(
                                    this.state.drivers.inactive,
                                    this.props.translate('nosignal')
                                    )
                                  }
                              >
                                <UnknownIcon width={32} height={32} fill="currentColor" />
                                <h3>{this.state.drivers.inactive.length}</h3>
                                <p>{this.props.translate('nosignal')}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <BarGraphSkeleton3 />
                      )}
                    </>
                  ) : null}

                  {this.state.driverSelector ? (
                    <div
                      className='driver-state-wrap'
                      style={{
                        paddingBottom: 10
                      }}
                    >
                      <DriverSelector
                        readOnly
                        applyFilter
                        onDriverClick={this.onDriverClick}
                        rows={8}
                        displayList={this.state.driverIds}
                        viewIMEI={this.props.logInUsers.userType===-1?true:attributes&&attributes.viewIMEI?true:false}                      />
                    </div>
                  ) : null}
              </Card>
            </Grid>
          ) : null}

          <Grid item xs={12} md={4} lg={topRow}>
            <div
              style={{
                padding: 15,
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor,
                borderRadius: 6,
                height: '100%'
              }}
            >
              {!this.state.motionSelector ? (
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    textAlign: 'center',
                    marginBottom: 8,
                    marginTop: 0
                  }}
                >
                  {this.props.translate('motionStatus')}
                </h3>
              ) : (
                <h3
                  style={{
                    fontSize: 18,
                    display: 'flex',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginBottom: 8,
                    marginTop: 0,
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    {this.props.translate(this.state.motionSelectorKey)} (
                    {this.props.totalDevices})
                  </span>
                  <Button onClick={e => this.openMotionSelector(null)}>
                    {this.props.translate('sharedBack')}
                  </Button>
                </h3>
              )}
              {!this.state.motionSelector ? (
                <>
                {/* {console.log("reSAa===",this.state.resArr )} */}
                {/* {console.log("re===",this.state.resArr )} */}
                  {this.state.resArr && this.state.resArr.total ? (
                    <MotionList
                      data={this.state.resArr}
                      showSelectedUnits={this.openMotionSelector}
                    />
                  ) : (
                    <BarGraphSkeleton2 />
                  )}
                </>
              ) : null}
              {this.state.motionSelector ? (
                <DeviceSelector motionSelectorName={this.state.motionSelectorName} readOnly rows={8}  dashboradDetails/>
              ) : null}
            </div>
          </Grid>
          {this.props.geofences.length ? (
            <Grid item xs={12} md={4} lg>
              <div
                style={{
                  padding: 15,
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  height: '100%'
                }}
              >
                {!this.state.geoSelector ? (
                  <h3
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      textAlign: 'center',
                      marginBottom: 8,
                      marginTop: 0
                    }}
                  >
                    {this.props.translate('sharedGeofence')}
                  </h3>
                ) : (
                  <h3
                    style={{
                      fontSize: 18,
                      display: 'flex',
                      fontWeight: 400,
                      textAlign: 'left',
                      marginBottom: 8,
                      marginTop: 0,
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>
                      {this.props.translate(this.state.geoSelectorKey)} (
                      {this.props.totalDevices}){' '}
                    </span>{' '}
                    <Button onClick={e => this.openGeoSelector(null)}>
                      {this.props.translate('sharedBack')}
                    </Button>
                  </h3>
                )}
                {!this.state.geoSelector &&
                this.props.geofences &&
                this.props.geofences.length ? (
                  <>
                    <Scrollbar
                      disableTracksWidthCompensation={true}
                      style={{
                        height: 38 * 9
                      }}
                    >
                      {this.props.geofences && this.props.geofences.length ? (
                        <GeoList
                          data={this.props.geofences}
                          showSelectedUnits={this.openGeoSelector}
                          geoIds={this.props.insideGeofence}
                        />
                      ) : (
                        <BarGraphSkeleton2 />
                      )}
                    </Scrollbar>
                  </>
                ) : null}
                {this.state.geoSelector ? (
                  <DeviceSelector readOnly rows={9} />
                ) : null}
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12} md={4} lg={topRow}>
            <div
              style={{
                padding: 15,
                background: this.props.themecolors.backgroundColor,
                color: this.props.themecolors.textColor,
                borderRadius: 6,
                height: '100%'
              }}
            >
              {!this.state.categorySelector ? (
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    textAlign: 'center',
                    marginBottom: 8,
                    marginTop: 0
                  }}
                >
                  {this.props.translate('unitsTypes')}
                </h3>
              ) : (
                <h3
                  style={{
                    fontSize: 18,
                    display: 'flex',
                    fontWeight: 400,
                    textAlign: 'left',
                    marginBottom: 8,
                    marginTop: 0,
                    justifyContent: 'space-between'
                  }}
                >
                  <span>
                    {this.props.translate(this.state.categorySelectorKey)} (
                    {this.props.totalDevices})
                  </span>
                  <Button onClick={e => this.openCategorySelector(null)}>
                    {this.props.translate('sharedBack')}
                  </Button>
                </h3>
              )}
              {!this.state.categorySelector ? (
                <>
                  <Scrollbar
                    disableTracksWidthCompensation={true}
                    style={{
                      height:
                        this.props.drivers && this.props.drivers.length
                          ? 38 * 6
                          : 38 * 9
                    }}
                  >
                    {this.state.pie1 && this.state.pie1.total ? (
                      <MotionList
                        data={this.state.pie1}
                        showSelectedUnits={this.openCategorySelector}
                      />
                    ) : (
                      <BarGraphSkeleton2 />
                    )}
                  </Scrollbar>
                </>
              ) : null}
              {this.state.categorySelector ? (
                <DeviceSelector readOnly rows={8} />
              ) : null}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={
              this.props.drivers && this.props.drivers.length
                ? this.props.geofences && !this.props.geofences.length
                  ? 12
                  : 9
                : 12
            }
          >
            <Grid container spacing={2}>
              {this.state.report &&
              this.state.report.config &&
              !this.state.enginHourReportLoading ? (
                <Grid item xs={12} md={6}>
                  <Card themecolors={this.props.themecolors}>
                    <div>
                      <ReactHighcharts
                        domProps={{ id: 'barChart1' }}
                        config={this.state.report.config}
                      />
                    </div>
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <Card themecolors={this.props.themecolors}>
                    <BarGraphSkeleton type={1} />
                  </Card>
                </Grid>
              )}
              {this.state.report &&
              this.state.report.config &&
              !this.state.enginHourReportLoading ? (
                <Grid item xs={12} md={6}>
                  <Card themecolors={this.props.themecolors}>
                    <div>
                      <ReactHighcharts
                        domProps={{ id: 'barChart2' }}
                        config={this.state.mileage.config}
                      />
                    </div>
                  </Card>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <Card themecolors={this.props.themecolors}>
                    <BarGraphSkeleton />
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

function onlyUnique (value, index, self) {
  return self.indexOf(value) === index
}

function BarGraphSkeleton (props) {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 1
      }}
    >
      <div style={{ marginBottom: 36 }}>
        <Skeleton className='skeleton-pulse' width={100} height={20} />
      </div>
      <table
        style={{ width: '100%', boderCollapse: 'collapse' }}
        border='0'
        cellPadding='0'
        cellSpacing='0'
      >
        <tbody>
          <tr>
            <td
              align='right'
              width={200}
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className='skeleton-pulse' width={150} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className='skeleton-pulse' width={400} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align='right'
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className='skeleton-pulse' width={200} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className='skeleton-pulse' width={260} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align='right'
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className='skeleton-pulse' width={170} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className='skeleton-pulse' width={370} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align='right'
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className='skeleton-pulse' width={250} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className='skeleton-pulse' width={330} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align='right'
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className='skeleton-pulse' width={230} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className='skeleton-pulse' width={450} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align='right'
              style={{ borderRight: '1px solid #ccd6eb', padding: '7px 20px' }}
            >
              <Skeleton className='skeleton-pulse' width={120} height={14} />
            </td>
            <td style={{ padding: '7px 20px' }}>
              <Skeleton className='skeleton-pulse' width={320} height={14} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginBottom: 20, marginTop: 30, display: 'flex' }}>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            paddingRight: 40
          }}
        >
          <div style={{ marginRight: 9 }}>
            <Skeleton
              className='skeleton-pulse'
              variant='circle'
              width={12}
              height={12}
            />
          </div>
          <Skeleton className='skeleton-pulse' width={60} height={14} />
        </div>
        {props.type === 1 && (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              paddingRight: 40
            }}
          >
            <div style={{ marginRight: 9 }}>
              <Skeleton
                className='skeleton-pulse'
                variant='circle'
                width={12}
                height={12}
              />
            </div>
            <Skeleton className='skeleton-pulse' width={60} height={14} />
          </div>
        )}
      </div>
    </div>
  )
}

function BarGraphSkeleton2 () {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
      </div>
    </div>
  )
}
function BarGraphSkeleton3 () {
  return (
    <div
      style={{
        marginTop: 20,
        padding: 16,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <Skeleton className='skeleton-pulse' width={'100%'} height={35} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
        <Skeleton className='skeleton-pulse' width={'48%'} height={116} />
      </div>
    </div>
  )
}

export const MotionList = ({ data, showSelectedUnits, className, category }) => {
  
  
  const html = data.data.map(d => (
    <div
      key={d.name}
      className={'motion-status-bar hover ' + className}
      onClick={() => showSelectedUnits && showSelectedUnits(d.key, category ? 'category' : 'device', d.name)}
    >
      <span style={{ background: d.color }} className='motion-status-border' />{' '}
      <span className='motion-status-img'>{d.image}</span> <span className='motion-status-name'>{<Translate id={d.name}/>}</span>
      <span className='motion-status-count'>{d.value}</span>
    </div>
  ))
  return <div>{html}</div>
}

export const GeoList = ({ data, showSelectedUnits, className, geoIds }) => {
  data.sort((b, a) => (geoIds[a.id] && geoIds[a.id].length || 0)-(geoIds[b.id] && geoIds[b.id].length || 0))
  const html = data.map((d, i) => {
    return (
    <div
      key={d.id}
      className={'motion-status-bar hover ' + className}
      onClick={() => showSelectedUnits && showSelectedUnits(d.id, 'geofence', d.name)}
    >
      <span
        style={{ background: colors[i % colors.length] }}
        className='motion-status-border'
      />
      <span className='motion-status-img' />
      <span className='motion-status-name'>{d.name}</span>
      <span className='motion-status-count'>
        {geoIds && geoIds[d.id] && geoIds[d.id].length
          ? geoIds[d.id].length
          : 0}
      </span>
    </div>
  )
  
})
  return <div>{html}</div>
}

const mapStateToProps = state => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({item, deviceIds}) => {
    // if(deviceIds && item.attribute === 'driverUniqueId') { { staff are not showing if item.attribute doesnot have driverUniqueId. We added manualy driverUniqueId at units->sensors->add Binders and added driverUniqueId
    if(deviceIds && item.attribute) {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null
  });
  return {
    ServerSetting: state.ServerSetting,
    logInUsers: state.logInUsers,
    engineHourSummery: state.engineHourSummery,
    devices3: state.devices3,
    geofences: state.geoFence,
    totalDevices: state.devices.total,
    allNotifications: state.allNotifications,
    drivers: state.drivers,
    positions: state.positions,
    insideGeofence: state.insideGeofence,
    allComputedAttributes: driverIdsList,
    themecolors: state.themeColors
  }
}

export default connect(mapStateToProps)(withLocalize(Dashboard))
