import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import EmptyState from '../../Components/common/EmptyState'
import Layout from '../../Layout'
import { JobsModal } from '../../Components/Jobs/jobModal'
import ConfirmDialoag from '../../Components/common/ConfirmDialoag'
import { getJobs } from '../../Actions/Jobs'
import { updateTemplates } from '../../Actions/Templates'
import axios from 'axios'
import instance from '../../axios'
import { errorHandler } from '../../Helpers'

const CancelToken = axios.CancelToken
let source

class Jobs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      templatesCall: false,
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      activeOperation: '',
      selecteditemId: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    }
    this.onCloseModal = this.onCloseModal.bind(this)
    source = CancelToken.source()
  }

  componentWillMount () {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.fetchData(
        this.props.logInUser.id,
        this.state.currentPage,
        this.state.pagesize
      )
    }
  }

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
    this.setState({
      selecteditem: '',
      isVisable: false,
      showItemDetail: false,
      activeOperation: '',
      selecteditemId: '',
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      }
    })
  }
  componentWillReceiveProps (n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true
        },
        () => {
          this.fetchData(
            n.logInUser.id,
            this.state.currentPage,
            this.state.pagesize
          )
        }
      )
    }
  }

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser.id,
      this.state.currentPage,
      this.state.pagesize
    )
  }

  searchItems = text => {
    if (source) {
      source.cancel()
    }
    this.setState(
      {
        searchText: text
      },
      () => {
        source = CancelToken.source()
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true)
      }
    )
  }

  removeDuplicates = (n, o) => {
    let items = []
    o.map(j => {
      let found = n.data.find(e => e.id === j.id)
      if (found) {
        items.push(found)
      }
    })

    if (!items.length) {
      this.setState(
        {
          itemPagination: {
            ...n,
            items: o.concat(n.data)
          },
          currentPage: n.hasNext ? n.page + 1 : n.page
        },
        () => {
          this.props.dispatch(getJobs(o.concat(n.data)))
        }
      )
    }
  }

  fetchData = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText

    if (searchText) {
      searchText = '&search=' + searchText
    } else {
      searchText = ''
    }

    let items = this.state.itemPagination.items
    if (reset) {
      this.setState(
        {
          itemPagination: { ...this.state.itemPagination, items: [] }
        },
        () => {
          items = []
        }
      )
    }

    instance({
      method: 'GET',
      url: `/api/jobs/get?userId=${userId}&page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      // if (response.status === 200) {
        // if (response.data.status === 'success') {
          this.removeDuplicates(response, items)
        // } else {
          //something went wrong
        // }
      // }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  removedItem = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: 'delete',
      address: ''
    })
  }

  editItem = item => {
    this.setState(
      {
        isVisable: false
      },
      () => {
        this.setState({
          isVisable: true,
          showItemDetail: false,
          selecteditem: {
            ...item,
            attributes: {
              ...item.attributes,
              controlDays: item.attributes.controlDays || [],
              controlTimeStart: item.attributes.controlTimeStart || '00:00',
              controlTimeEnd: item.attributes.controlTimeEnd || '23:59',
              geoTimeThreshold: (item.attributes.geoTimeThreshold || 60000) / 60000
            }
          },
          activeOperation: 'edit'
        })
      }
    )
  }

  addItem = () => {
    this.props.history.push('/schedules')
    this.setState({
      isVisable: true,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: 'add'
    })
  }
  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: '',
      activeOperation: '',
      onDeleteConfirmation: false
    })
    this.props.history.push('/schedules')
  }
  selecteItem = id => {
    this.setState({
      selecteditemId: id
    })
    if (this.props.jobs.length) {
      this.props.jobs.map(item => {
        if (item.id.toString() === id.toString()) {
          this.editItem(item)
        }
      })
    }
  }
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  onRemovedItem = item => {
    this.setState({
      selecteditem: item,
      onDeleteConfirmation: true
    })
  }

  render () {
    if (
      this.props.match.params.id &&
      this.props.jobs &&
      this.props.jobs.length &&
      this.props.match.params.id.toString() !==
        this.state.selecteditemId.toString()
    ) {
      this.selecteItem(this.props.match.params.id)
    }
    return (
      <div>
        <Layout
          {...this.props}
          addJob={this.addItem}
          editItem={this.editItem}
          removedItem={this.onRemovedItem}
          classFromChildren={'has-padding'}
          fetchMoreItems={this.fetchMoreItems}
          itemPagination={{ ...this.state.itemPagination }}
          searchItems={this.searchItems}
        >
          <div className='main-content-page'>
            {this.state.isVisable && (
              <JobsModal
                onCloseModal={this.onCloseModal}
                selecteditem={this.state.selecteditem}
                showItemDetail={this.state.showItemDetail}
                activeOperation={this.state.activeOperation}
                logInUser={this.props.logInUser}
              />
            )}
            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removedItem}
                title={this.props.translate('areYouWantToDelete')}
                children={this.state.selecteditem.description}
              />
            )}
            {!this.state.isVisable && !this.state.showItemDetail && (
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6
                }}
              >
                <EmptyState text={this.props.translate('noScheduleSelected')} />
              </div>
            )}
          </div>
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themeColors,
  jobs: state.jobs
})

export default connect(mapStateToProps)(withLocalize(Jobs))
